import React, { ReactElement } from 'react'
import styles from './styles.module.scss'
import SalesText from '../../_atoms/SalesText/SalesText'
import { fontSizes, fontWeights } from "../../../constants/salesStyles"
import SalesCardInnerTitle from "../SalesCardInnerTitle/SalesCardInnerTitle"

interface IProps {
    children: ReactElement
    outerTitle?: string
    innerTitle?: string
    innerTitleCount?: number
    innerSubtitle?: string
    innerSubtitleCount?: number
    footer?: boolean
    footerContent?: ReactElement
    marginRightPixels?: number
    marginLeftPixels?: number
    marginTopPixels?: number
    marginBottomPixels?: number
    widthInPixels?: number
    heightInPixels?: number
    preserveMarginTop?: boolean
    overflowY?: "scroll" | "hidden" | "visible"
    maxHeightInPixels?: number
    minHeightInPixels?: number
    width?: string
    footerJustifyContentBetween?: boolean
    paddingTop?: number
    paddingBottom?: number
}


const SalesCard = ({
    outerTitle,
    innerTitle,
    children,
    footerContent,
    innerTitleCount,
    footer,
    innerSubtitleCount,
    innerSubtitle,
    marginRightPixels,
    marginLeftPixels,
    marginTopPixels,
    marginBottomPixels,
    widthInPixels,
    heightInPixels,
    preserveMarginTop,
    overflowY,
    maxHeightInPixels,
    minHeightInPixels,
    width = 'auto',
    footerJustifyContentBetween = false,
    paddingTop,
    paddingBottom,
}: IProps) => (
    <div style={{
        marginTop: marginTopPixels ? marginTopPixels + 'px' : 0,
        marginBottom: marginBottomPixels ? marginBottomPixels + 'px' : 0,
        marginRight: marginRightPixels ? marginRightPixels + 'px' : 0,
        marginLeft: marginLeftPixels ? marginLeftPixels + 'px' : 0,
    }}>
        {outerTitle &&
            <SalesText
                text={outerTitle}
                fontWeight={fontWeights.semiBold}
                fontSize={fontSizes.xl}
                marginBottomPixels={28}

            />
        }

        <div
            className={styles.cardWap}
            style={{
                width: widthInPixels ? widthInPixels + 'px' : width,
                height: heightInPixels ? heightInPixels + 'px' : 'auto',
                overflowY: overflowY ? overflowY : 'auto',
                minHeight: minHeightInPixels ? minHeightInPixels + 'px' : 'auto',
                maxHeight: maxHeightInPixels ? maxHeightInPixels + 'px' : 'auto',
                paddingTop: paddingTop && paddingTop,
                paddingBottom: paddingBottom && paddingBottom,
            }}
        >
            {innerTitle &&
                <SalesCardInnerTitle
                    type={'title'}
                    text={innerTitle}
                    count={innerTitleCount}
                />
            }

            {innerSubtitle &&
                <SalesCardInnerTitle
                    type={'subTitle'}
                    text={innerSubtitle}
                    count={innerSubtitleCount}
                />
            }

            {children}

            {footer &&
                <div
                    className={`
                        ${styles.footer}
                        ${preserveMarginTop ? styles.footerMarginTop : ''}
                        ${footerJustifyContentBetween ? styles.justifyContentBetween : ''}
                    `}>
                    {footerContent}
                </div>
            }

        </div>
    </div>
)

export default SalesCard
