import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { CarData, Part } from '../../../types/part/partTypes'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'

const createAllCarParts =
    (car: CarData) =>
    async (
        dispatch: Dispatch,
        getState: () => RootState,
    ): Promise<void | Part[]> => {
        const { userData } = getState()
        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
        }
        const params = {
            userId: userData.smsAuth.userId,
            car,
        }

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/createAllCarParts', params, config)
            if (status === 'ok') {
                return data
            }
        } catch (e) {
            console.error('create all car parts error', e)
            throw e
        }
    }

export default createAllCarParts
