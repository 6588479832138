import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import getAccountCashierInfo from '../../modules/redux/user/getAccountCashierInfo'
import { AppDispatch } from '../../modules/store/customDispatch'

export const useGetAccountCashierInfo = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getAccountCashierInfo())
    }, [])
}
