import React, { Dispatch, useEffect, useState } from 'react'
import { Clipboard2Plus, Pencil } from "react-bootstrap-icons"
import { AnyAction } from "redux"
import styles from './styles.module.scss'
import { colors, fontSizes } from "../../../constants/salesStyles"
import useHoverHandler from "../../../hooks/sales/useHoverHandler"
import SalesRow from '../../_atoms/SalesRow/SalesRow'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesTableData from "../../_atoms/SalesTableData/SalesTableData"
import SalesButtonIcon from '../SalesButtonIcon/SalesButtonIcon'
import { contactFields, contactInput, contactSpecialPropsFields, salesContactActionTypes, } from "../../../types/sales/salesTypes"
import SalesTableDataContent from "../SalesTableDataContent/SalesTableDataContent"
import { fieldTypes } from '../../../types/common/commonTypes'
import SalesTableDataInfo from "../SalesTableDataInfo/SalesTableDataInfo"
import { partSpecialPropsFields } from '../../../types/part/partTypes'

interface IProps {
    input: contactInput,
    isEdited: boolean;
    setIsEdited: (isEdited: boolean) => void
    dispatch: Dispatch<AnyAction>
    isLast?: boolean;
}

const SalesCardRowContacts = ({
    input,
    isLast,
    isEdited,
    setIsEdited,
    dispatch,
}: IProps) => {
    const { title, field, value, fieldType, specialProps } = input
    const [isHover, hoverOn, hoverOff] = useHoverHandler()
    const [isEditableLocal, setIsEditableLocal] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const onChangeValue = (newValue: string | number | boolean) => {
        dispatch({
            type: salesContactActionTypes.updateField,
            value: {
                field,
                value: newValue,
                specialProps: {
                    ...specialProps,
                    [partSpecialPropsFields.renderValue]: newValue,
                }
            },
        })
    }

    const onChangeRenderValue = (newValue: string) => {
        if (field === contactFields.address) {
            onChangeValue(newValue)
        } else {
            dispatch({
                type: salesContactActionTypes.updateSpecialProps,
                value: { field, specialPropsValue: newValue, specialPropsField: contactSpecialPropsFields.renderValue }
            })
        }
    }

    useEffect(() => {
        if (!isEdited) {
            setIsEditableLocal(false)
        }
    }, [isEdited])

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setIsVisible(false)
            }, 1200)
        }
    }, [isVisible])

    return (
        <SalesRow hoverOn={hoverOn} hoverOff={hoverOff} lastRowBottomBorder={isLast}>
            <>
                <SalesTableData widthInPixels={120}>
                    <>
                        <SalesText
                            text={title}
                            color={colors.black}
                        />
                        {specialProps?.hoverText
                            ? <SalesTableDataInfo text={specialProps.hoverText}/>
                            : null
                        }
                    </>


                </SalesTableData>
                <SalesTableData widthInPixels={300}>
                    <>
                        {isVisible
                            ? <div className={styles.clipboardAlert}>Скопировано в буфер обмена!</div>
                            : null
                        }
                        <SalesTableDataContent
                            input={input}
                            isEditable={isEditableLocal}
                            isHover={isHover}
                            onChangeValue={onChangeValue}
                            onChangeRenderValue={onChangeRenderValue}
                        />

                    </>
                </SalesTableData>
                <SalesTableData justifyContent={'flex-end'} widthInPixels={40}>
                    {isHover
                        ? (
                            fieldType === fieldTypes.url
                                ? <SalesButtonIcon
                                    onClick={() => {
                                        if (isVisible) {
                                            setIsVisible(false)
                                        }
                                        navigator.clipboard.writeText(String(value)).then(() => {
                                            setIsVisible(true)
                                        })
                                    }}
                                >
                                    <Clipboard2Plus size={fontSizes.s} color={colors.blue}/>
                                </SalesButtonIcon>
                                : <SalesButtonIcon
                                    onClick={
                                        () => {
                                            setIsEditableLocal(true)
                                            setIsEdited(true)
                                        }
                                    }
                                >
                                    <Pencil size={fontSizes.s} color={colors.purple}/>
                                </SalesButtonIcon>)
                        : null
                    }
                </SalesTableData>
            </>
        </SalesRow>
    )
}

export default SalesCardRowContacts
