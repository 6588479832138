import { Dispatch } from 'redux'
import { RootState } from '../../store/rootReducer'
import { CarData } from '../../../types/part/partTypes'
import { apiPriceChart } from '../../api/apiInstance'

const evaluatePrankPart = (car: CarData, carTitle: string, partTypeId: number) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void | number> => {
    const params = {
        evp: 0,
        regxp: carTitle,
        spTypeId: partTypeId,
        markId: car.markId,
        modelId: car.modelId,
    }

    try {
        const { data: { pdata } } = await apiPriceChart.post('/evaluate_prank3', params)
        if (pdata) {
            return pdata.rcmd_price
        }
    } catch (e) {
        console.error('evaluate prank part error', e)
    }
}

export default evaluatePrankPart
