import React from 'react'
import { colors, fontSizes, fontWeights } from "../../../constants/salesStyles"
import SalesText from "../../_atoms/SalesText/SalesText"
import { userSummaryFields } from "../../../types/sales/salesTypes"

interface IProps {
    field: userSummaryFields | "publishedPartsCount"
    value: number
    isLast?: boolean
    prefix?: string
}

const SalesUserSummaryText = ({ field, value, isLast, prefix }: IProps) => {
    const getUnitOfMeasurement = () => {
        if(field === 'soldCount' || field === 'totalCount' || field === 'publishedPartsCount' || field === 'totalCountWithQty'){
            return 'шт'
        } else if (field === 'soldSum' || field === 'totalSum') {
            return '₽'
        } else if (field === 'soldRate') {
            return '%'
        }
    }

    return (
        <SalesText
            text={`${prefix? prefix + ' ': ''} ${String(value.toLocaleString('ru-RU'))} ${getUnitOfMeasurement()} ${isLast ? '' : '\u00a0•\u00a0'}`}
            fontWeight={fontWeights.semiBold}
            fontSize={fontSizes.l}
            color={colors.blue}
            marginTopPixels={8}
        />
    )
}

export default SalesUserSummaryText
