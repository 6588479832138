import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllOilsATF =
    (searchTerm: string, typeId?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsATF', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/OilsATF', { typeId })
            const oilsATF: SelectOptionLocal[] = []

            if (status === 'ok') {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const ATFsNodes = xmlDoc.getElementsByTagName('Brand')

                for (let i = 0; i < ATFsNodes.length; i++) {
                    const ATFName = ATFsNodes[i].textContent?.trim()
                    if (ATFName && ATFName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        oilsATF.push({ name: ATFName, value: ATFName })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllOilsATF, value: { oilsATF } })
        } catch (e) {
            console.error('load OilsATF failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsATF', value: false } })
    }

export default getAllOilsATF
