import React, { forwardRef } from 'react'
import { InfoCircle } from 'react-bootstrap-icons'
import styles from './styles.module.scss'
import SalesTextArea, { SalesTextAreaRef } from '../../_atoms/SalesTextArea/SalesTextArea' // Update the path to the actual location of SalesTextArea
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesButtonStandard from '../SalesButtonStandard/SalesButtonStandard'
import { colors, fontSizes } from '../../../constants/salesStyles'
import { templateButton } from '../../../types/part/partTypes'

interface SalesDescriptionProps {
  value: string;
  placeholder?: string;
  title?: string;
  marginTopPixels?: number;
  onChangeTextArea: (value: string) => void;
  textareaMaxLength?: number;
  isDisabled?: boolean;
  fontSize?: fontSizes;
  templateButtons?: templateButton[];
  errorMessage?: string;
  width?: string;
  onSubmit?: () => void;
  hideButton?: boolean;
  children?: React.ReactNode
}

const SalesDescriptionInput = forwardRef<SalesTextAreaRef, SalesDescriptionProps>(
  (
    {
      title,
      hideButton,
      width,
      marginTopPixels,
      onSubmit,
      fontSize,
      value,
      placeholder,
      onChangeTextArea,
      textareaMaxLength = 4000,
      isDisabled,
      errorMessage,
      templateButtons,
      children,
    },
    ref
  ) => (
      <div className={styles.wrap}>
        {title && (
          <div className={styles.info} style={{ width: width || '510px' }}>
            <InfoCircle size={fontSize || 24} className={styles.icon} />
            <SalesText fontSize={fontSize} text={title || 'Этот текст добавляется к описаниям во всех объявлениях'} />
          </div>
        )}
        <SalesTextArea
          ref={ref} // Forward the ref to the SalesTextArea component
          minHeightInPixels={100}
          defaultValue={value || ''}
          value={value || ''}
          placeholder={placeholder || ''}
          onChangeValue={onChangeTextArea}
          disableAutoFocus
          maxLength={textareaMaxLength}
          isDisabled={isDisabled}
          errorMessage={value?.length > textareaMaxLength ? errorMessage : undefined}
          marginTopPixels={marginTopPixels}
          marginBottomPixels={12}
          templateButtons={templateButtons}
        />
        {children && children}
        {onSubmit && !hideButton && (
          <SalesButtonStandard
            text={'Сохранить'}
            onClick={onSubmit}
            backgroundColor={colors.grayFooter}
            disabled={value?.length > textareaMaxLength}
          />
        )}
      </div>
    )
)

export default SalesDescriptionInput
