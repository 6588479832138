import { useEffect, useRef } from 'react'
import { FormCheck, FormCheckProps } from 'react-bootstrap'
import styles from './styles.module.scss'

export enum IndeterminateCheckboxValue {
    Unchecked,
    Checked,
    Indeterminate,
}

interface IProps extends FormCheckProps {
    value: IndeterminateCheckboxValue
}

const IndeterminateCheckbox = (props: IProps) => {
    const { value, ...otherProps } = props
    const checkRef = useRef<HTMLInputElement>()

    useEffect(() => {
        if (checkRef.current) {
            checkRef.current.checked = value === IndeterminateCheckboxValue.Checked
            checkRef.current.indeterminate = value === IndeterminateCheckboxValue.Indeterminate
        }
    }, [value])

    return (
        <FormCheck type='checkbox' ref={checkRef} {...otherProps} className={styles.customCheckbox}/>
    )
}

export default IndeterminateCheckbox
