import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from '../../modules/store/customDispatch'
import getUserTaskByCode from '../../modules/redux/tasksService/GetUserTaskByCode'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { useSelector } from '../../modules/store/customSelector'

export const useOnboarding = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onboardingStatus = useSelector(state => state.userData.tasksInfo?.onboarding)

    useEffect(() => {
        dispatch(getUserTaskByCode('onboarding'))
    }, [])

    useEffect(() => {
        if (onboardingStatus === null) {
            navigate(getAdminNavigationPath(adminRouteAlias.onboarding.location))
        }
    }, [onboardingStatus])
}
