import { ReactNode } from 'react'
import styles from './styles.module.scss'
import onboardingImg from '../../../assets/img/onboarding.jpg'

interface IProps {
    children: ReactNode
}

const OnboardingLayout = ({ children }: IProps) => (
    <div className={styles.wrap}>
        <div className={styles.wrapItem}>
            <div className={'row justify-content-between'}>
                <div className={'col-7'}>
                    {children}
                </div>
                <div className={'col-5'}>
                    <img src={onboardingImg} alt='Картинка onboarding' className={styles.onboardingImg} />
                </div>
            </div>
        </div>
    </div>
)

export default OnboardingLayout
