import { useCallback } from "react"
import { useGetSmsUserId } from "./useAuth"

export const useLogout = () => {
    const  userId = useGetSmsUserId()
    return useCallback(async () => {
        if (userId) {
            await localStorage.clear()
        }
    }, [userId])
}
