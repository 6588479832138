import { Dispatch } from 'redux'
import getBrowserFingerprint from 'get-browser-fingerprint'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { apiAuth } from "../../api/apiInstance"

const fingerprint = String(getBrowserFingerprint())

const getSmsCode = (phone: string) => async(
    dispatch: Dispatch,
): Promise<void> => {
    try {
        dispatch({
            type: storeDispatchTypes.setIsLoadingSms,
            value: {
                isLoading: true
            }
        })
        const { data: { status, msg } } = await apiAuth.post('/sendCode', {
            phone,
            deviceId: fingerprint
        })
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.setCountDownResendSms,
                value: {
                    countDownResend: 45000
                }
            }))
            dispatch(({
                type: storeDispatchTypes.setCodeSentSms,
                value: {
                    isCodeSent: true
                }
            }))
        }

    } catch (e) {
        console.error('sms code request failed', e)
    }
    dispatch({
        type: storeDispatchTypes.setIsLoadingSms,
        value: {
            isLoading: false
        }
    })
}

export default getSmsCode
