import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllOilsSAE =
    (searchTerm: string, typeId?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsSAE', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/OilsSAE', { typeId })
            const oilsSAE: SelectOptionLocal[] = []

            if (status === 'ok') {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const SAENodes = xmlDoc.getElementsByTagName('SAE')

                for (let i = 0; i < SAENodes.length; i++) {
                    const SAEName = SAENodes[i].textContent?.trim()
                    if (SAEName && SAEName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        oilsSAE.push({ name: SAEName, value: SAEName })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllOilsSAE, value: { oilsSAE } })
        } catch (e) {
            console.error('load oilsSAE failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsSAE', value: false } })
    }

export default getAllOilsSAE
