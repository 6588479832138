import { applyMiddleware, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import RootReducer from "./rootReducer"

// Redux: Store
const store = createStore(
    RootReducer,
    undefined,
    applyMiddleware(ReduxThunk, createLogger({ collapsed: true })),
)
export default store
