const rimTypes =
    [
        { value: "Кованые", name: "Кованые" },
        { value: "Литые", name: "Литые" },
        { value: "Штампованные", name: "Штампованные" },
        { value: "Спицованные", name: "Спицованные" },
        { value: "Сборные", name: "Сборные" },
    ]

export default rimTypes
