import { useEffect, useRef, useState } from 'react'
import { Carousel, OverlayTrigger, Popover } from 'react-bootstrap'
import styles from "./styles.module.scss"
import { Part, PartImage } from '../../../types/part/partTypes'
import CellPicture from "../../_atoms/CellPicture/CellPicture"
import ModalCarousel from "../../_atoms/ModalCarousel/ModalCarousel"

interface IProps {
    pictures: PartImage[]
    part?: Part
}

const CellThumbCarousel = ({ pictures, part }: IProps) => {
    const modalHoveredRef = useRef(false)
    const imageHoveredRef = useRef(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isImageHovered, setIsImageHovered] = useState(false)
    const [show, setShow] = useState(false)
    const [index, setIndex] = useState(0)
    const target = useRef<HTMLDivElement | null>(null)

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex)
    }

    const handleImageClick = () => {
        setShow(false)
        setIsModalOpen(true)
    }

    const onMouseEnter = () => {
        setIsImageHovered(true)
        setTimeout(() => {
            if (imageHoveredRef.current && !modalHoveredRef.current) {
                setShow(true)
            }
        }, 500)
    }

    const onMouseLeave = () => {
        setIsImageHovered(false)
        setTimeout(() => {
            if (!imageHoveredRef.current) {
                setShow(false)
            }
        }, 300)
    }

    useEffect(() => {
        modalHoveredRef.current = isModalOpen
    }, [isModalOpen])

    useEffect(() => {
        imageHoveredRef.current = isImageHovered
    }, [isImageHovered])

    return (
        <>
            {pictures.length === 0
                ? ''
                : (
                    <div
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <OverlayTrigger
                            placement='top'
                            show={show}
                            overlay={
                                <Popover className={styles.popover}>
                                    <Carousel
                                        activeIndex={index}
                                        onSelect={handleSelect}
                                        interval={null}
                                        className={styles.modalCarouselItem}
                                    >
                                        {pictures.map((picture, i) => (
                                            <Carousel.Item key={`slideKey-${i + 1}`}>
                                                <div>
                                                    <img
                                                        src={picture.publicUrl}
                                                        alt={`slide-${i + 1}`}
                                                        onClick={handleImageClick}
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Popover>
                            }
                        >
                            <div
                                className={styles.wrap}
                                onClick={handleImageClick}
                                ref={target}
                            >
                                <CellPicture picture={pictures[0]}/>
                            </div>
                        </OverlayTrigger>
                    </div>
                )
            }

            {isModalOpen && (
            <ModalCarousel
                closeModal={()=> setIsModalOpen(false)}
                pictures={pictures}
                isOpen={isModalOpen}
                part={part}
                pictureIndex={index}
            />)}
        </>
    )
}

export default  CellThumbCarousel
