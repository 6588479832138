import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { Warehouse } from '../../../types/warehouse/warehouseTypes'
import createEntityId from '../../../utils/createEntityId'

const saveWarehouse = (warehouse: Warehouse) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()

    dispatch({
        type: storeDispatchTypes.setWarehousesLoading,
        value: true,
    })

    try {
        const { data: { status } } = await apiInstance.post('/warehouses/saveWarehouse', {
            ...warehouse,
            userId: userData.smsAuth.userId,
            warehouseId: warehouse.id || warehouse.warehouseId || createEntityId(warehouse),
            createdAt: warehouse.createdAt ||  Date.now(),
            updatedAt: Date.now(),
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setWarehousesLoading,
                value: false,
            })

        }
    } catch (e) {
        console.error('set part price failed', e)
    }

    dispatch({
        type: storeDispatchTypes.setWarehousesLoading,
        value: false,
    })
}


export default saveWarehouse
