import anchor from "../../constants/anchor"
import { Part } from "../../types/part/partTypes"
import { Car } from "../../types/car/carTypes"
import { apiInstance } from "./apiInstance"

class DatahubApiService {
    public async getPartByPartId(partId: string, isDraft?: boolean, isSold?: boolean): Promise<Part | null> {
        try {
            const res = await apiInstance.post('/getPartsAdminList', {
                userId: localStorage.getItem('userId'),
                params: {
                    partId: [partId],
                    isDraft,
                    isSold,
                }
            }, {
                headers: {
                    Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
                }
            })

            const data = res.data?.data

            return data && data.length ? data[0] : null
        } catch (error) {
            console.error(error)
            return null
        }
    }
    public async getCarByCarId(carId: string): Promise<Car | null> {
        try {
            const res = await apiInstance.post('/getCarsAdminList', {
                userId: localStorage.getItem('userId'),
                params: {
                    carId: [carId]
                }
            }, {
                headers: {
                    Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
                }
            })

            const data = res.data?.data

            return data && data.length ? data[0] : null
        } catch (error) {
            console.error(error)
            return null
        }
    }
}

export default new DatahubApiService()
