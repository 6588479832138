import { Dispatch } from "redux"
import { AxiosRequestConfig } from "axios"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { DocumentType } from "../../../types/document/documentTypes"

const dowloadDocument = (
    id: string,
    documentType: DocumentType,
    fileType: string,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        id,
        fileType,
        documentType,
        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
    }
    const config: AxiosRequestConfig = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` },
        responseType: 'blob'
    }
    dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentDownloaded', value: true } })
    try {
        const response = await apiDocument.post('/download', params, config)

        const href = URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = href
        a.download = `${id}.${fileType}`
        a.click()
        URL.revokeObjectURL(href)
    } catch(e) {
        console.error('download document failed', e)
    } finally {
        dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentDownloaded', value: false } })
    }
}

export default dowloadDocument
