import React, { useState } from 'react'
import { ChevronLeft } from "react-bootstrap-icons"
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'
import SalesButtonStandard from "../../components/_molecules/SalesButtonStandard/SalesButtonStandard"
import { colors, fontSizes, fontWeights } from "../../constants/salesStyles"
import SalesText from '../../components/_atoms/SalesText/SalesText'
import { useSelector } from "../../modules/store/customSelector"
import { contactFields } from "../../types/sales/salesTypes"
import SalesCardContact from '../../components/_orgamisms/SalesCardContact/SalesCardContact'
import SalesCardMarketPlaces from '../../components/_molecules/SalesCardMarketPlaces/SalesCardMarketPlaces'
import { fieldTypes, valueTypes } from '../../types/common/commonTypes'
import ModalMarketplace from '../../components/_molecules/ModalMarketplace/ModalMarketplace'

const Marketplaces = () => {
    const navigate = useNavigate()
    const marketPlacesContact = useSelector((state) => state.sales.marketplaces.contact)
    const isLoading = useSelector((state) => state.sales.loaders.marketplacesContact)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [marketplace, setMarketplace] = useState<string | null>(null)

    const openModal = (marketplace: string) => {
        setIsModalOpen(true)
        setMarketplace(marketplace)
    }

    const initialStateLocal = {
        fields: [
            {
                field: contactFields.useVirtualPhone,
                fieldType: fieldTypes.checkbox,
                title: '',
                value: marketPlacesContact[contactFields.useVirtualPhone],
                valueType: valueTypes.boolean,
                specialProps: {
                    checkboxPrice: 490,
                    isHidden: true
                }
            },
            {
                field: contactFields.address,
                fieldType: fieldTypes.selectSearch,
                title: 'Адрес',
                value: marketPlacesContact[contactFields.address],
                valueType: valueTypes.string,
                specialProps: {
                    renderValue: marketPlacesContact[contactFields.address],
                }
            },
            {
                field: contactFields.name,
                fieldType: fieldTypes.input,
                title: 'Название',
                value: marketPlacesContact[contactFields.name],
                valueType: valueTypes.string,
                specialProps: {
                    hoverText: 'Название для маркетплейсов'
                }
            },
            {
                field: contactFields.phone,
                fieldType: fieldTypes.input,
                title: 'Телефон',
                value: marketPlacesContact[contactFields.phone],
                valueType: valueTypes.string,
            },
            {
                field: contactFields.email,
                fieldType: fieldTypes.input,
                title: 'E-mail',
                value: marketPlacesContact[contactFields.email],
                valueType: valueTypes.string,
                specialProps: {
                    truncate: true
                }
            },
        ]
    }

    return (
        <div className={styles.wrap}>
            {isModalOpen && marketplace &&
                <ModalMarketplace
                    isOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    marketplace={marketplace}
                />
            }
            <div className={styles.contentWrap}>
                <SalesText
                    text={"Маркетплейсы"}
                    fontSize={fontSizes.xxl}
                    fontWeight={fontWeights.bold}
                    letterSpacing={-0.02}
                    marginBottomPixels={40}
                />
                <div className={styles.cardsWrap}>
                    <SalesCardMarketPlaces
                        openModal={openModal}
                        footerContent={<>
                            <SalesButtonStandard
                                text={"Назад"}
                                backgroundColor={colors.white}
                                marginRightPixels={20}
                                prefixElement={<ChevronLeft color={colors.black} size={fontSizes.m}/>}
                                onClick={() => navigate(-1)}
                            />
                            {/*<SalesButtonStandard*/}
                            {/*    text={'Управление публикацией'}*/}
                            {/*    backgroundColor={colors.white}*/}
                            {/*    marginRightPixels={0}*/}
                            {/*/>*/}
                        </>}
                    />
                    <SalesCardContact
                        initialState={initialStateLocal}
                        apiObject={marketPlacesContact}
                        contactsType={'marketplacesContact'}
                        footerText={"Контактная информация будет размещена на маркетплейсах."}
                        isLoading={isLoading}
                        checkboxIndex={0}
                    />

                </div>
            </div>

        </div>
    )
}

export default Marketplaces
