import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const setPartQuantity = (partId: string, qty: number) => async( 
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<boolean> => {
    const { userData } = getState()
    try {
        const { data: { status } } = await apiInstance.post('/setPartExtraDataFieldValue', {
            userId:  userData.smsAuth.userId,
            partId,
            extraDataField: 'qty',
            extraDataValue: qty,
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        if (status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.updatePartExtraDataQuantity,
                value: {
                    id: partId,
                    qty,
                }
            }))
            return true
        } else {
            return false
        }
    } catch (e) {
        console.error('setPartQuantity failed', e)
        return false
    }
}

export default setPartQuantity
