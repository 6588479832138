import { Dispatch } from 'redux'
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"

const getNextNumberVendorCodeByUserId = () => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void | string> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
    }
    try {
        const { data: { status, data } } = await apiInstance.post('/getNextNumberVendorCodeByUserId', params , config)
        if(status === 'ok') {
            return data
        }
    } catch (e) {
        console.error('get next number vendorCode by userId error', e)
    }
}

export default getNextNumberVendorCodeByUserId
