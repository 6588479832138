import React from 'react'
import searchCarListModels from '../../../modules/redux/carList/SearchCarListModels'
import { useSelector } from "../../../modules/store/customSelector"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'


const TableHeadModelSearchCars = () => {
    const modelIdFilter = useSelector((state) => state.carList.filters.modelId)
    const models = useSelector(state => state.carList.models)
    const markIdFilter = useSelector((state) => state.carList.filters.markId)

    return (
        <TableHeadSelectSearch
            slice={'carList'}
            tableHead={'Модель'}
            placeholder={'Выберите модель'}
            actionType={storeDispatchTypes.setModelFilterCars}
            options={models}
            filterId={modelIdFilter}
            searchOptions={searchCarListModels}
            additionalFilterId={markIdFilter ? `${markIdFilter}` : undefined}
        />
    )
}

export default TableHeadModelSearchCars

