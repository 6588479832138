import React, { ReactElement } from 'react'
import styles from "./styles.module.scss"
import { colors } from '../../../constants/salesStyles'

interface IProps {
    hoverOn?: () => void;
    hoverOff?: () => void;
    children: ReactElement
    debug?: boolean;
    lastRowBottomBorder?: boolean;
    removeBorderTop?: boolean;
    height?: number;
    borderColor?: colors;
}

const SalesRow = ({ hoverOn, hoverOff, children, debug, lastRowBottomBorder, removeBorderTop, height, borderColor }: IProps) => (
    <tr
        className={`
            ${styles.row}
            ${debug ? styles.debug : ''}
            ${lastRowBottomBorder ? styles.lastRowBottomBorder : ''}
            ${removeBorderTop ? styles.removeBorderTop : ''}
        `}
        style={{
            minHeight: height && height + 'px',
            borderTopColor: borderColor && borderColor,
        }}
        onMouseEnter={hoverOn}
        onMouseLeave={hoverOff}

    >
        {children}
    </tr>
)

export default SalesRow
