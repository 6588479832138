import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const loadWarehouseList = (
    page:number,
    perPage:number,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()

    dispatch({
        type: storeDispatchTypes.setWarehousesLoading,
        value: true,
    })
    try {
        const params = {
            userId: userData.smsAuth.userId,
            offset: page,
            limit: perPage,
        }
        const { data } = await apiInstance.post('/warehouses/getWarehouse', {
            userId: userData.smsAuth.userId,
            params: params
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        dispatch({
            type: storeDispatchTypes.setWarehouseList,
            value: data.data,
        })
    } catch (e) {
        console.error('load visible cars error', e)
    }

    dispatch({
        type: storeDispatchTypes.setWarehousesLoading,
        value: false,
    })
}

export default loadWarehouseList
