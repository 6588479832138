import { Reducer } from 'redux'
import { storeDispatchTypes } from "../storeDispatchTypes"
import { Warehouse } from '../../../types/warehouse/warehouseTypes'
import { ApiOption, SelectOptionLocal } from '../../../types/common/commonTypes'

interface WarehouseList {
    warehouses: Warehouse[],
    count: number,
}

export interface stateTypeCars {
    warehouseList: WarehouseList
    warehousesSS: SelectOptionLocal[],
    isLoading: boolean
    loaders: {
        warehouseSS: boolean
    }
}

const initialState = {
    warehouseList: { warehouses: [], count: 0 },
    isLoading: false,

    warehousesSS: [],
    loaders: {
        warehouseSS: false
    }
}

const createWarehouseListReducer: Reducer<stateTypeCars> = (
    state = initialState,
    action,
) => {
    // find and change isDeleted to true by warehouseId
    const deleteWarehouse = (warehouseId: string) => {
        const warehouseList = state.warehouseList.warehouses.map(warehouse => {
            if (warehouse.id === warehouseId) {
                warehouse.isDeleted = true
            }
            return warehouse
        })
        return warehouseList
    }

    switch (action.type) {
        case storeDispatchTypes.setWarehouseList: {
            return {
                ...state,
                warehouseList: action.value,
            }
        }
        case storeDispatchTypes.setWarehousesLoading: {
            return {
                ...state,
                isLoading: action.value,
            }
        }
        case storeDispatchTypes.deleteWarehouseById: {
            return {
                ...state,
                warehouseList: {
                    ...state.warehouseList,
                    warehouses: deleteWarehouse(action.value),
                },
            }
        }
        case storeDispatchTypes.setWarehousesSelectSearch: {
            const warehouses = action.value.warehouses?.map((w: ApiOption) => ({ value: w.id, name: w.title }))
            return { ...state, warehousesSS: warehouses || [] }
        }
        default: {
            return state
        }
    }
}

export default createWarehouseListReducer
