import { useNavigate } from 'react-router'
import OnboardingLayout from './OnboardingLayout/OnboardingLayout'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { useDispatch } from '../../modules/store/customDispatch'
import updateUserTaskResult from '../../modules/redux/tasksService/UpdateUserTaskResult'
import { useSelector } from '../../modules/store/customSelector'
import createUserTaskResult from '../../modules/redux/tasksService/CreateUserTaskResult'

const Onboarding = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onboardingTask = useSelector(state => state.userData.tasksInfo?.onboarding)

    const handleOnboardingDone = () => {
        if (onboardingTask) {
            if (onboardingTask.status !== 'done') {
                dispatch(updateUserTaskResult({ code: 'onboarding', userTaskId: onboardingTask.id, status: 'done' }))
            }
        } else {
            dispatch(createUserTaskResult({ code: 'onboarding', status: 'done' }))
        }
    }

    return (
        <OnboardingLayout>
            <div style={{ marginRight: '100px' }}>
                <SalesText
                    text={'Добро пожаловать!'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                />
                <SalesText
                    text={
                        `Наша программа позволяет быстро и удобно
                        вносить запчасти вручную, либо автоматически.
                        Выберите вариант, который подходит вам больше всего`
                    }
                    fontWeight={fontWeights.normal}
                    fontSize={fontSizes.m}
                    marginTopPixels={20}
                />
                <div className={'row mt-5 align-items-start justify-content-between'}>
                    <div className={'col-5'}>
                        <SalesText
                            text={'Ручное внесение запчастей'}
                            fontWeight={fontWeights.bold}
                            fontSize={fontSizes.l}
                        />
                    </div>
                    <div className={'col-5'}>
                        <SalesButton
                            backgroundColor={colors.blue}
                            paddingVerticalPixels={14}
                            className='w-100 justify-content-center'
                            onClick={() => {
                                handleOnboardingDone()
                                navigate(getAdminNavigationPath(adminRouteAlias.newPart.location))
                            }}
                        >
                            <SalesText
                                text={'Начать!'}
                                color={colors.white}
                                fontSize={fontSizes.l}
                            />
                        </SalesButton>
                    </div>
                </div>
                <div className={'row mt-5 align-items-start justify-content-between'}>
                    <div className={'col-5'}>
                        <SalesText
                            text={'Разобрать авто на запчасти'}
                            fontWeight={fontWeights.bold}
                            fontSize={fontSizes.l}
                        />
                    </div>
                    <div className={'col-5'}>
                        <SalesButton
                            backgroundColor={colors.blue}
                            paddingVerticalPixels={14}
                            className='w-100 justify-content-center'
                            onClick={() => {
                                handleOnboardingDone()
                                navigate(getAdminNavigationPath(adminRouteAlias.onboardingCarDisassembly.location))
                            }}
                        >
                            <SalesText
                                text={'Это удобно!'}
                                color={colors.white}
                                fontSize={fontSizes.l}
                            />
                        </SalesButton>
                    </div>
                </div>
                <div className={'row mt-5 align-items-start justify-content-between'}>
                    <div className={'col-5'}>
                        <SalesText
                            text={'Загрузить запчасти из файла'}
                            fontWeight={fontWeights.bold}
                            fontSize={fontSizes.l}
                        />
                    </div>
                    <div className={'col-5'}>
                        <SalesButton
                            backgroundColor={colors.blue}
                            paddingVerticalPixels={14}
                            className='w-100 justify-content-center'
                            onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.onboardingDownloadFromFile.location))}
                        >
                            <SalesText
                                text={'Мой вариант!'}
                                color={colors.white}
                                fontSize={fontSizes.l}
                            />
                        </SalesButton>
                    </div>
                </div>
            </div>
        </OnboardingLayout>
    )
}

export default Onboarding
