import { useState, useEffect, useRef } from 'react'
import { Form, Overlay, Tooltip } from 'react-bootstrap'
import { Exclamation } from 'react-bootstrap-icons'
import styles from './styles.module.scss'

interface Props {
    label: string
    isChecked?: boolean
    onChange: (value: boolean) => void
    isDisabled?: boolean
    className?: string
    overlayText?: string
}
const SalesCheckboxMarketplace = ({ label, isChecked, onChange, isDisabled, className, overlayText }: Props) => {
    const [checked, setChecked] = useState(isChecked !== undefined ? isChecked : true)
    const [showOverlay, setShowOverlay] = useState(false)

    const exclamation = useRef(null)

    const handleOverlay = () => {
        setShowOverlay(true)
        setTimeout(() => {
            setShowOverlay(false)
        }, 5000)
    }

    const handleChange = (value: boolean) => {
        setChecked(value)
        onChange(value)
    }

    useEffect(() => {
        setChecked(isChecked !== undefined ? isChecked : true)
    }, [isChecked])

    return (
        <Form.Group className={className} controlId={label}>
            <Form.Check
                type="checkbox"
                label={label}
                checked={checked}
                onChange={(e) => handleChange(e.target.checked)}
                disabled={isDisabled}
            />
            {overlayText &&
            <span ref={exclamation} onClick={handleOverlay} className={styles.icon}>
                <Exclamation color='red' size={24}/>
            </span>}
            <Overlay target={exclamation.current} show={showOverlay} placement="top">
                {(props) => (
                    <Tooltip id="overlay" {...props}>
                        {overlayText ? overlayText : 'Недостаточно информации'}
                    </Tooltip>
                )}
            </Overlay>
        </Form.Group>
    )
}

export default SalesCheckboxMarketplace
