export enum colors  {
    black = '#1B1D2C',
    grayLight = '#868690',
    grayLighter = '#F1F1F1',
    grayDark = '#60616C',
    grayDisabled = '#626669',
    grayFooter = '#EBEBF0',
    grayBackground = '#F6F7FA',
    grayBorderApp = '#C0BFC7',
    grayBorder = '#A1ACC1',
    white = '#FFFFFF',
    blue = '#00389C',
    blueDark = '#00266A',
    blueLight = '#0D6EFD',
    purple = '#ED006D',
    transparent = 'transparent',
    green = '#15A486',
    greenLight = '#A3CD49',
    greenDark = '#198754',
    pinkLight = '#CC5FF6',
    pinkDark = '#BA1274',
    pinkTransparent = '#F8E7F1',
    yellowLight = '#EEA81E',
    red = '#F14726',
}

export enum fontSizes {
    xxxs = '10px',
    xxs = '12px',
    xs = '14px',
    s = '16px',
    m = '18px',
    l = '22px',
    xl = '26px',
    xxl = '38px',
}

export enum fontWeights {
    bold = 700,
    semiBold = 600,
    normal = 400,
}

export enum borderStyles {
    none = 'none',
    solid = 'solid',
}
