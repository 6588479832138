import React from 'react'
import styles from './styles.module.scss'

interface IProps {
    diameterInPx?: number;
    thicknessInPx?: number;
    color?: string;
    isDisplayFlex?: boolean
    margin?: number
}

const children = [1, 2, 3]

const Loader = ({ diameterInPx = 80, thicknessInPx = 8, color = '#E82FA4', isDisplayFlex, margin }: IProps) => (
    <div
        className={styles.ldsRing}
        style={{
            width: `${diameterInPx*1.1}px`,
            height: `${diameterInPx*1.1}px`,
            display: isDisplayFlex ? 'flex' : 'auto',
            margin: margin && `${margin}px`,
        }}
    >
        {children.map((i) => (
            <div
                style={{
                    border: `${thicknessInPx}px solid ${color}`,
                    borderColor: `${color} transparent transparent transparent`,
                    width: `${diameterInPx}px`,
                    height: `${diameterInPx}px`,
                }}
                key={'loader' + i}
            />
        ))
        }
    </div>
)

export default Loader
