import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { toast, ToastContainer } from 'react-toastify'

import CustomLink from '../../../components/_atoms/CustomLink/CustomLink'
import SalesButton from '../../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../../components/_atoms/SalesText/SalesText'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import createUserTaskResult from '../../../modules/redux/tasksService/CreateUserTaskResult'
import updateUserTaskResult from '../../../modules/redux/tasksService/UpdateUserTaskResult'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import OnboardingLayout from '../OnboardingLayout/OnboardingLayout'

import styles from './styles.module.scss'

const DownloadFromFile = () => {
    const [importUrl, setImportUrl] = useState('')
    const dispatch = useDispatch()
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
        },
    })

    const onboardingTask = useSelector(state => state.userData.tasksInfo?.onboarding)

    const handleOnboardingDone = async () => {
        if (!importUrl) {
            toast.error(`Файл отсутствует`)
            return
        }

        try {
            if (onboardingTask) {
                await dispatch(
                    updateUserTaskResult({
                        code: 'onboarding',
                        userTaskId: onboardingTask.id,
                        status: 'done',
                        result: {
                            step: 'autoimport',
                            import_url: importUrl,
                        },
                    }),
                )
            } else {
                await dispatch(
                    createUserTaskResult({
                        code: 'onboarding',
                        status: 'done',
                        result: {
                            step: 'autoimport',
                            import_url: importUrl,
                        },
                    }),
                )
            }
            toast.success('Файл отправлен, мы свяжемся с Вами')
        } catch (error) {
            toast.error(`Не удалось загрузить файл`)
            console.error('Ошибка при загрузке файла', error)
        }
    }

    const handleBtnClick = () => {
        handleOnboardingDone()
    }

    return (
        <OnboardingLayout>
            <ToastContainer position="bottom-center" autoClose={5000} />
            <SalesText text={'Загрузить запчасти из файла'} fontWeight={fontWeights.bold} fontSize={fontSizes.xxl} />
            {acceptedFiles.length ? (
                <div style={{ marginRight: '100px' }}>
                    <SalesText
                        text={`Мы приняли ваш файл в работу и загрузим запчасти в течении двух дней.`}
                        fontWeight={fontWeights.normal}
                        fontSize={fontSizes.m}
                        marginTopPixels={28}
                    />
                    <SalesText
                        text={`Чтобы работа началась, необходимо оплатить услугу загрузки файла.`}
                        fontWeight={fontWeights.normal}
                        fontSize={fontSizes.m}
                        marginTopPixels={28}
                    />
                    <SalesButton
                        backgroundColor={colors.blue}
                        paddingVerticalPixels={10}
                        paddingHorizontalPixels={36}
                        marginTopPixels={30}
                        className="justify-content-center"
                        onClick={handleBtnClick}
                    >
                        <SalesText text={'Оплатить'} color={colors.white} fontSize={fontSizes.l} />
                    </SalesButton>
                </div>
            ) : (
                <div style={{ marginRight: '80px' }}>
                    <SalesText
                        text={`Добавьте ссылку на ваш файл автозагрузки Авито или пришлите нам ваш
                        склад с Авито в формате эксель. И мы автоматически загрузим его в ваш аккаунт.`}
                        fontWeight={fontWeights.normal}
                        fontSize={fontSizes.m}
                        marginTopPixels={28}
                    />
                    <Form.Group controlId="formFile" className={styles.customFormGroup}>
                        <Form.Control
                            placeholder="Укажите ссылку на файл автозагрузки"
                            value={importUrl}
                            onChange={e => setImportUrl(e.target.value)}
                        />
                        <CustomLink
                            text={'Где взять файл автозагрузки'}
                            href={'https://partsauto.expert/find-autoload-file-avito'}
                            target={'_blank'}
                            marginTop={4}
                            marginLeft={14}
                        />
                    </Form.Group>

                    {/* <div {...getRootProps({ className: styles.dropAreaWrap })}>
                    <input {...getInputProps()} />
                    <SalesText text={'Либо перетащите сюда файл эксель'} fontSize={fontSizes.s} />
                </div>
                <CustomLink text={'Как скачать товары с Авито'} href={'https://docs.google.com/document/d/1JyB_c0FcULmMcHefG8SJ0D_kCvskpOe_6GERYRdKxLw/edit'} target={'_blank'} marginTop={4} marginLeft={14}/> */}

                    <SalesText text={'Стоимость загрузки файла запчастей составляет 4 тыс руб'} marginTopPixels={40} />
                    <SalesButton
                        backgroundColor={colors.blue}
                        paddingVerticalPixels={10}
                        paddingHorizontalPixels={36}
                        marginTopPixels={24}
                        className="justify-content-center"
                        onClick={handleBtnClick}
                    >
                        <SalesText text={'Готово!'} color={colors.white} fontSize={fontSizes.l} />
                    </SalesButton>
                </div>
            )}
        </OnboardingLayout>
    )
}

export default DownloadFromFile
