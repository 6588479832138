import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import removeFromListDelay from "../../../constants/removeFromListDelay"
import anchor from '../../../constants/anchor'

const setCarDeletedStatus = (carId: string, newDeletedStatus = true) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        carId,
        newDeletedStatus
    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    try {
        const { data: { status } } = await apiInstance.post('/setCarDeletedStatus', params, config)
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.setCarDeletedStatus,
                value: {
                    id: carId,
                    isDeleted: newDeletedStatus
                },
            }))
            setTimeout(() => {
                dispatch(({
                    type: storeDispatchTypes.removeDeletedCarFormList
                }))
            }, removeFromListDelay)
        }

    } catch (e) {
        console.error('set car deleted status failed', e)
    }
}

export default setCarDeletedStatus
