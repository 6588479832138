import { useState } from "react"


const useHoverHandler = () => {
    const [isHover, setIsHover] = useState(false)
    const hoverOn = () => setIsHover(true)
    const hoverOff = () => setIsHover(false)

    return [isHover, hoverOn, hoverOff] as const
}

export default  useHoverHandler
