import React from 'react'
import { NavLink } from 'react-router-dom'

import classNames from 'classnames'

import { colors } from '../../../constants/salesStyles'
import { TCustomLink } from '../../../types/common/commonTypes'

import styles from './styles.module.scss'

type IProps = {
    className?: string
    marginLeft?: number
    marginRight?: number
    marginTop?: number
    marginBottom?: number
    color?: colors
}

const CustomLink = ({
    className,
    upperText,
    text,
    href,
    onClick,
    target,
    truncate,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    color,
}: TCustomLink & IProps) => (
    <div className={classNames(className, `${styles.wrap} ${truncate ? styles.truncate : ''}`)}>
        {upperText && <div className={`${styles.upperText} ${truncate ? styles.truncate : ''}`}>{upperText}</div>}
        {target ? (
            <a
                href={href}
                target={target}
                className={`${styles.headerLink} ${truncate ? styles.truncate : ''}`}
                onClick={onClick}
                rel={target == '_blank' ? 'noopener noreferrer' : ''}
                style={{
                    marginLeft: marginLeft && marginLeft + 'px',
                    marginRight: marginRight && marginRight + 'px',
                    marginTop: marginTop && marginTop + 'px',
                    marginBottom: marginBottom && marginBottom + 'px',
                    color: color && color,
                }}
            >
                {text}
            </a>
        ) : (
            <NavLink
                to={href}
                className={`${styles.headerLink} ${truncate ? styles.truncate : ''}`}
                onClick={onClick}
                style={{
                    marginLeft: marginLeft && marginLeft + 'px',
                    marginRight: marginRight && marginRight + 'px',
                    marginTop: marginTop && marginTop + 'px',
                    marginBottom: marginBottom && marginBottom + 'px',
                    color: color && color,
                }}
            >
                {text}
            </NavLink>
        )}
    </div>
)

export default CustomLink
