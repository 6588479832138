import { useEffect, useRef, useState } from 'react'
import Carousel, { CarouselRef } from 'react-bootstrap/esm/Carousel'
import Modal from 'react-bootstrap/esm/Modal'
import styles from './styles.module.scss'
import { Image, Part, PartImage } from "../../../types/part/partTypes"

interface IProps {
    pictures: (PartImage | Image)[]
    isOpen: boolean;
    closeModal: () => void;
    part?: Part
    pictureIndex?: number
}

const ModalCarousel = ({ pictures, isOpen, closeModal, part, pictureIndex = 0 }: IProps) => {
    const [index, setIndex] = useState(pictureIndex)
    const carouselRef = useRef<CarouselRef>(null)
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex)
    }
    const partTitle = part ? part.customTitle ? part.customTitle : part.title : ''

    useEffect(() => {
        setIndex(pictureIndex)
        if (isOpen && carouselRef.current) {
            carouselRef.current.element?.focus()
        }
    }, [isOpen])

    const getSrc = (picture: PartImage | Image): string => {
        if ('publicUrl' in picture) {
            return picture.publicUrl
        } else {
            return URL.createObjectURL(picture.file)
        }
    }

    return (
        <Modal
            size="xl"
            fullscreen={true}
            show={isOpen}
            onHide={closeModal}
            dialogClassName={styles.modalCustomDialog}
        >
            <Modal.Header closeButton>
                { partTitle }
            </Modal.Header>
            <Modal.Body>
                <Carousel
                    variant="dark"
                    activeIndex={index}
                    onSelect={handleSelect}
                    indicators
                    tabIndex={0}
                    ref={carouselRef}
                    className={styles.modalCarousel}
                >
                    {pictures.map((picture, i) => (
                        <Carousel.Item key={`slideKey-${i + 1}`}>
                            <div className={styles.modalCarouselItem}>
                                <img
                                    className="d-block w-100"
                                    src={getSrc(picture)}
                                    alt={`slide-${i + 1}`}
                                />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
        </Modal>
    )
}

export default ModalCarousel
