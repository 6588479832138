import React, { ReactNode } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesCard from '../SalesCard/SalesCard'

interface SingleAccordionProps {
  title: string;
  children: ReactNode;
  width?: string
  marginTopPixels?: number;
}

const SingleAccordion: React.FC<SingleAccordionProps> = ({ marginTopPixels, width, title, children }) => (
    <Accordion as={SalesCard} marginTopPixels={marginTopPixels} width={width}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <SalesText text={title} marginRightPixels={24} />
        </Accordion.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <>
              {children}
            </>
          </Card.Body>
        </Accordion.Collapse>
        </Accordion.Item>
    </Accordion>
  )

export default SingleAccordion
