import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { ApiPartCreateUpdate } from '../../../types/part/partTypes'
import removeFromListDelay from '../../../constants/removeFromListDelay'


const savePartInCloud = (
    partObject: ApiPartCreateUpdate,
    withImages: boolean,
    isDraft?: boolean,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void | string> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        part: {
            ...partObject,
            userId: userData.smsAuth.userId,
            isDraft,
        },
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'isPartCreated', value: false } })
    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'isPartSaving', value: true } })

    try {
        const { data: { createdAt, id } } = await apiInstance.post('/savePartInCloud', params, config)
        if (createdAt && !withImages) {
            dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'isPartCreated', value: true } })
        }
        //@ts-ignore
        window.ym(74629465, 'reachGoal', 'addpartweb')
        dispatch({
            type: storeDispatchTypes.setIsPartSavedSuccessfully,
            value: {
                partId: partObject.id,
                success: true,
            }
        })
        
        return id
    } catch (e) {
        console.error('save part in cloud failed', e)
        dispatch({
            type: storeDispatchTypes.setIsPartSavedSuccessfully,
            value: {
                partId: partObject.id,
                success: false,
            }
        })
    } finally {
        if (!withImages) {
            dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'isPartSaving', value: false } })
        }

        setTimeout(() => {
            dispatch(({ type: storeDispatchTypes.removeIsPartSavedSuccessfully }))
        }, removeFromListDelay)
    }
}

export default savePartInCloud
