import React from 'react'
import { Button } from "react-bootstrap"
import { Trash3, Pencil } from "react-bootstrap-icons"
import { useDispatch } from 'react-redux'
import setCarDeletedStatus from '../../../modules/redux/carList/SetCarDeletedStatus'
import HoverOverlay from "../HoverOverlay/HoverOverlay"

interface IProps {
    carId: string;
    editModal: () => void;
    deleteModal: () => void;
}

const CellButtonsCars = ({ carId, editModal, deleteModal }: IProps) => {
    const dispatch = useDispatch()

    return (
        <>
            <HoverOverlay tooltipText={'Редактировать'}>
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={editModal}
                    className="d-flex align-items-center"
                >
                    <Pencil color={'white'} size={18} />
                </Button>
            </HoverOverlay>
            <HoverOverlay tooltipText={'Удалено'}>
                <Button
                    variant="danger"
                    size="sm"
                    // onClick={() => dispatch(setCarDeletedStatus(carId))}
                    onClick={deleteModal}
                    className="d-flex align-items-center"
                >
                    <Trash3 color={'white'} size={18}/>
                </Button>
            </HoverOverlay>
        </>
    )
}

export default CellButtonsCars
