import React, { useEffect } from "react"
import { useNavigate } from "react-router"
import styles from './styles.module.scss'
import QrCode from '../../assets/img/shop-qr-code.svg'
import Header from "../_orgamisms/Header/Header"
import Footer from "../_atoms/Footer/Footer"
import AuthInputBox from "../_atoms/AuthInputBox/AuthInputBox"
import { adminRouteAlias, getAdminNavigationPath } from "../../router/adminRouteAlias"
import { useGetSmsUserId } from "../../hooks/user/useAuth"


const AuthForm = () => {
    const navigate = useNavigate()
    const userId = useGetSmsUserId()

    useEffect(() => {
        if (userId) {
            navigate(getAdminNavigationPath(adminRouteAlias.parts.location))
        }
    }, [userId])

    return (
        <div className={styles.authWrap}>
            <Header />
            <div className={styles.authBox}>
                <div className={styles.authBox__description}>
                    <p className={styles.authBox__subtitle}>Приложение для авторазбора PARTS AUTO Expert</p>
                    <p>Авторизуйтесь под номером телефона, который вы используете для входа в приложения в форме справа.</p>
                    <p>В личном кабинете доступно управление запчастями из вашего стока, редактирование цены и добавление
            фотографий.</p>
                    <div className={styles.authBox__qrContainer}>
                        <a href="https://stock-pro.onelink.me/XqES/shop" target="_blank" className={styles.authBox__link}>Скачать
              приложение PA EXPERT бесплатно</a>
                        <img src={QrCode} alt={'QR код для загрузки приложения'} className={styles.authBox__qr}/>
                    </div>
                </div>
                <div className={styles.authBox__border}/>
                <AuthInputBox/>
            </div>
            <Footer/>
        </div>
    )
}


export default AuthForm
