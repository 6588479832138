import { Reducer } from 'redux'
import { storeDispatchTypes } from "../storeDispatchTypes"
import {
    apiPartCategory,
    apiHighlight,
    apiMarketplacesContact,
    apiMarketplacesInfoItem,
    apiShopInfo,
    apiUserSummary, daDataSuggestion, TypeMarketplace, marketplacesSettings
} from "../../../types/sales/salesTypes"
import { SelectOptionLocal } from '../../../types/common/commonTypes'

interface stateType {
    loaders: {
        marketplacesContact: boolean
        marketplacesInfo: boolean
        highlights: boolean
        shopInfo: boolean
        shopCategories: boolean
        addressSuggestions: boolean
    }
    marketplaces: {
        contact: apiMarketplacesContact
        info: apiMarketplacesInfoItem[]
        list: TypeMarketplace[]
        mpDescTemplate: string
        mpDescAddVendorCode: boolean
        settings: marketplacesSettings
    }
    shop: {
        info: apiShopInfo
        categories: apiPartCategory[]
    }
    highlights: apiHighlight[]
    userSummary: apiUserSummary
    addressSuggestions: SelectOptionLocal[]
}

const initialState: stateType = {
    loaders: {
        marketplacesContact: true,
        marketplacesInfo: true,
        highlights: true,
        shopInfo: true,
        shopCategories: true,
        addressSuggestions: true
    },
    marketplaces: {
        contact: {
            name: '',
            address: '',
            phone: '',
            email: '',
            useVirtualPhone: false,
        },
        info: [],
        list: [],
        mpDescTemplate: '',
        mpDescAddVendorCode: false,
        settings: {
            withPrice: false,
            withPhoto: false,
            priceFrom: 0,
            selectedCategories: [],
        }
    },
    shop: {
        info: {
            shopUrl: '',
            shopName: '',
            address: '',
            phone: '',
            email: '',
            publishedPartsCount: 0,

        },
        categories: [],
    },
    highlights: [],
    userSummary: {
        soldCount: 0,
        soldSum: 0,
        soldRate: 0,
        totalCountWithQty: 0,
        totalSum: 0,
        totalCount: 0,
    },
    addressSuggestions: [],
}

const createSalesReducer: Reducer<stateType> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
    case storeDispatchTypes.setMarketplacesContact:
        return { ...state, marketplaces: { ...state.marketplaces, contact: action.value.contact } }
    case storeDispatchTypes.setSalesLoader:
        return { ...state, loaders: { ...state.loaders, [action.value.field]: action.value.value } }
    case storeDispatchTypes.setMarketplacesInfo:
        return { ...state, marketplaces: { ...state.marketplaces, info: action.value.info } }
    case storeDispatchTypes.setMpDescTemplate:
        return { ...state, marketplaces: { ...state.marketplaces, mpDescTemplate: action.value } }
    case storeDispatchTypes.setMpDescAddVendorCode:
        return { ...state, marketplaces: { ...state.marketplaces, mpDescAddVendorCode: action.value } }
    case storeDispatchTypes.setShopInfo:
        return { ...state, shop: { ...state.shop, info: action.value.info } }
    case storeDispatchTypes.setHighlights:
        return { ...state, highlights: action.value.highlights }
    case storeDispatchTypes.setUserSummary:
        return { ...state, userSummary: action.value.userSummary }
    case storeDispatchTypes.setShopCategories:
        return { ...state, shop: { ...state.shop, categories: action.value.categories } }
    case storeDispatchTypes.setSalesAddressSuggestions:
        return {
            ...state,
            addressSuggestions: action.value.addressSuggestions.map((addressSuggestion: daDataSuggestion) => ({
                value: addressSuggestion.value,
                name: addressSuggestion.value,
            }))
        }
    case storeDispatchTypes.setMarketplacesList:
        return { ...state, marketplaces: { ...state.marketplaces, list: action.value.list } }
    case storeDispatchTypes.setMarketplacesSettings:
        return { ...state, marketplaces: { ...state.marketplaces, settings: action.value } }
    default:
        return state
    }
}

export default createSalesReducer
