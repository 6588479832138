import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import { RangeInput, SortFieldParts, SortOrder } from "../../../types/common/commonTypes"
import anchor from '../../../constants/anchor'
import { SoldChartData, SoldChartDataDefaults } from "../../../types/sales/statsTypes"

const loadSalesChartData = () => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<SoldChartData> => {
        const { userData } = getState()

        dispatch({
            type: storeDispatchTypes.setPartListLoading,
            value: true,
        })
        try {
            const { data } = await apiInstance.post('/sales/stats/getSalesChartData', {
                userId: userData.smsAuth.userId,
            }, {
                headers: {
                    Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
                }
            })

            return data.data

        } catch (e) {
            console.error('load getSalesChartData failed', e)
        }

        return SoldChartDataDefaults
    }

export default loadSalesChartData
