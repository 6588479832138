import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import removeFromListDelay from "../../../constants/removeFromListDelay"
import anchor from '../../../constants/anchor'

const DeleteWarehouseInCloud = (warehouseId: string) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        warehouseId: warehouseId
    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    try {
        const { data: { status } } = await apiInstance.post('/warehouses/deleteWarehouse', params, config)

        if(status !== 'ok') {
            throw new Error('DeleteWarehouseInCloud status is not ok')
        }

    } catch (e) {
        console.error('DeleteWarehouseInCloud ', e)
    }
}

export default DeleteWarehouseInCloud
