import React, { useRef } from "react"
import { Outlet } from "react-router-dom"
import { useLocation } from "react-router"
import classNames from "classnames"
import styles from "./styles.module.scss"
import { useAuthGuard } from "../hooks/user/useAuthGuard"
import Sidebar from "../components/_molecules/Sidebar/Sidebar"
import Header from "../components/_orgamisms/Header/Header"
import Footer from "../components/_atoms/Footer/Footer"
import { usePayWall } from "../hooks/user/usePayWall"
import SubscriptionExpiryBanner from "../components/_atoms/SubscriptionExpiryBanner/SubscriptionExpiryBanner"
import { useGetAccountCashierInfo } from "../hooks/user/useGetAccountCashierInfo"
import { adminRouteAlias } from "../router/adminRouteAlias"
import { useOnboarding } from "../hooks/user/useOnboarding"

function Admin() {
    useAuthGuard()
    usePayWall()
    useGetAccountCashierInfo()
    useOnboarding()
    const mainPanel = useRef(null)
    const location = useLocation()

    const isOnboardingPage = location.pathname.includes(adminRouteAlias.onboarding.location)

    return (
        <>
            <div className={styles.adminWrap}>
                <Header/>
                <div className={classNames(
                    styles.adminWrap__sideBarContent,
                    { [styles.adminWrap__linearBackground]: !isOnboardingPage }
                )}>
                    <Sidebar/>
                    <div className={styles.adminWrap__content} ref={mainPanel}>
                        <SubscriptionExpiryBanner/>
                        <Outlet/>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Admin
