import React from 'react'
import searchCarListModifications from '../../../modules/redux/carList/SearchCarListModifications'
import { useSelector } from "../../../modules/store/customSelector"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'


const TableHeadModificationSearchCars = () => {
    const modificationIdFilter = useSelector((state) => state.carList.filters.modificationId)
    const modifications = useSelector(state => state.carList.modifications)

    return (
        <TableHeadSelectSearch
            slice={'carList'}
            tableHead={'Модификация'}
            placeholder={'Выберите модификацию'}
            actionType={storeDispatchTypes.setModificationFilterCars}
            options={modifications}
            filterId={modificationIdFilter}
            searchOptions={searchCarListModifications}
        />
    )
}

export default TableHeadModificationSearchCars

