import React from 'react'
import styles from './styles.module.scss'
import SalesText from "../../_atoms/SalesText/SalesText"
import { colors, fontSizes, fontWeights } from "../../../constants/salesStyles"
import SalesButton from "../../_atoms/SalesButton/SalesButton"


interface IProps {
    text: string
    count?: number
    type: 'title' | 'subTitle'
}

const SalesCardInnerTitle = ({ text, count, type }: IProps) => {
    const isTitle = type === 'title'

    return (
        <div className={styles.wrap}>
            <SalesText
                text={text}
                fontWeight={isTitle ? fontWeights.semiBold : fontWeights.normal}
                fontSize={isTitle ? fontSizes.l : fontSizes.m}
            />
            {count &&
                <SalesButton
                    children={
                        <SalesText
                            text={String(count) + ' объяв.'}
                            fontSize={fontSizes.m}
                            fontWeight={isTitle ? fontWeights.semiBold : fontWeights.normal}
                            color={isTitle ? colors.white : colors.purple}/>
                    }
                    marginLeftPixels={10}
                    paddingVerticalPixels={isTitle ? 2: 0}
                    paddingHorizontalPixels={isTitle ? 15: 0}
                    backgroundColor={isTitle ? colors.purple : colors.transparent}
                />
            }
        </div>
    )
}

export default SalesCardInnerTitle
