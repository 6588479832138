import React from 'react'
import styles from './styles.module.scss'

interface IProps {
    phoneNumber: string
    displayPhone: string
}

const PayWallPhone = ({ phoneNumber, displayPhone }: IProps) => (
    <div className={styles.wrap}>
        <span>
            Отдел продаж
        </span>
        <a href={`tel:${phoneNumber}`}>{displayPhone}</a>
    </div>

)

export default  PayWallPhone
