import { fieldTypes, valueTypes } from '../../../types/common/commonTypes'
import { partFields } from '../../../types/part/partTypes'

export const initialPartState = {
    fields: [
        {
            field: partFields.groupId,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Категория',
            specialProps: {
                placeholder: 'определяется автоматически',
                column: 1,
            },
        },
        {
            field: partFields.typeId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            /*
                13000 - колёса в сборе
                13003 - запасное колесо

                13001 - шины
                13004 - шина

                13002 - диски
                13005 - диск

                9072 - аккумулятор

                13013 - Колпаки колесные

                255 - Антифриз - охлаждающие жидкости
                256 - Тормозная жидкость - тормозные жидкости
                257 - Жидкость ГУР - гидравлические жидкости
                258 - Масло двухтактное - моторные масла
                260 - Моторное масло - моторные масла
            */
            title: 'Название',
            specialProps: {
                isRequired: true,
                placeholder: 'название запчасти',
                column: 1,
            },
        },
        {
            field: partFields.Quantity,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Количество',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                hoverText: 'Количество шт. в комплекте. От 1 до 8',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireType,
            fieldType: fieldTypes.select,
            valueType: valueTypes.string,
            value: '',
            title: 'Сезонность',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                hoverText: 'Сезонность: Всесезонные, Летние, Зимние нешипованные, Зимние шипованные',
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimType,
            fieldType: fieldTypes.select,
            valueType: valueTypes.string,
            value: '',
            title: 'Тип диска',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                hoverText: 'Тип диска: Кованые, Литые, Штампованные, Спицованные, Сборные',
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.Brand,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Бренд',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireModel,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Модель',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                fieldParent: partFields.Brand,
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimDiameter,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Диаметр',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                    { field: partFields.typeId, value: 13013 },
                ],
                isRequired: true,
                hoverText: 'Диаметр, дюймы',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireSectionWidth,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Ширина профиля',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                hoverText: 'Ширина профиля шины',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireAspectRatio,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Высота профиля',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                hoverText: 'Высота профиля шины',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimWidth,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Ширина обода',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Ширина обода, дюймы',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimOffset,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Вылет (ET)',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Вылет (ET)',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimBolts,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Болты',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Количество отверстий под болты',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimBoltsDiameter,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Диаметр под болты',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Диаметр расположения отверстий под болты',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimDIA,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Диаметр ЦО',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Диаметр центрального отверстия',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.OilBrand,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Производитель',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 255 },
                    { field: partFields.typeId, value: 256 },
                    { field: partFields.typeId, value: 257 },
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.SAE,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Класс вязкости',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                hoverText: 'Класс вязкости SAE',
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.Volume,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Объём',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 255 },
                    { field: partFields.typeId, value: 256 },
                    { field: partFields.typeId, value: 257 },
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.ACEA,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт ACEA',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.API,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт API',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.OEMOil,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Допуски OEM',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 255 },
                    { field: partFields.typeId, value: 256 },
                    { field: partFields.typeId, value: 257 },
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.Color,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Цвет',
            specialProps: {
                isVisibleAny: [{ field: partFields.typeId, value: 255 }],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.ASTM,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт ASTM',
            specialProps: {
                isVisibleAny: [{ field: partFields.typeId, value: 255 }],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.DOT,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт DOT',
            specialProps: {
                isVisibleAny: [{ field: partFields.typeId, value: 256 }],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
    ],
}
