import React from 'react'
import styles from "./styles.module.scss"
import SalesText from "../../components/_atoms/SalesText/SalesText"
import { colors, fontSizes, fontWeights } from "../../constants/salesStyles"
import SalesCardContact from '../../components/_orgamisms/SalesCardContact/SalesCardContact'
import { useSelector } from "../../modules/store/customSelector"
import { contactFields } from "../../types/sales/salesTypes"
import ShopImage from '../../assets/img/shop.png'
import SalesUserSummaryText from "../../components/_molecules/SalesUserSummaryText/SalesUserSummaryText"
import { fieldTypes, valueTypes } from '../../types/common/commonTypes'

const Shop = () => {
    const title = 'Витрина'
    const shopInfo = useSelector((state) => state.sales.shop.info)
    const isLoading = useSelector((state) => state.sales.loaders.shopInfo)

    const paragraphs = [
        'Витрина это вебсайт вашей разборки, где автоматически публикуются все детали из вашего стока и ваши контактные данные.',
        'Витрина настроена таким образом, что она хорошо индексируется поисковиками Яндекс и Google, привлекая покупателей из поиска.',
        'Создать витрину можно бесплатно за 1 минуту.',
        'Витрина публикуется в течение 5 минут.'
    ]

    const initialStateLocal = {
        fields: [
            {
                field: contactFields.shopUrl,
                fieldType: fieldTypes.url,
                valueType: valueTypes.string,
                title: 'Адрес витрины',
                value: shopInfo[contactFields.shopUrl],
                specialProps: {
                    canCopy: true,
                }
            },
            {
                field: contactFields.address,
                fieldType: fieldTypes.selectSearch,
                title: 'Адрес',
                value: shopInfo[contactFields.address],
                valueType: valueTypes.string,
                specialProps: {
                    renderValue: shopInfo[contactFields.address],
                }
            },
            {
                field: contactFields.shopName,
                fieldType: fieldTypes.input,
                title: 'Название',
                value: shopInfo[contactFields.shopName],
                valueType: valueTypes.string,
                specialProps: {
                    hoverText: 'Название для витрины'
                }
            },
            {
                field: contactFields.phone,
                fieldType: fieldTypes.input,
                title: 'Телефон',
                value: shopInfo[contactFields.phone],
                valueType: valueTypes.string,

            },
            {
                field: contactFields.email,
                fieldType: fieldTypes.input,
                title: 'E-mail',
                value: shopInfo[contactFields.email],
                valueType: valueTypes.string,
                specialProps: {
                    truncate: true
                }
            },
        ],
    }

    return (
        <div className={styles.wrap}>
            <SalesText
                text={title}
                fontSize={fontSizes.xxl}
                fontWeight={fontWeights.bold}
                letterSpacing={-0.02}
            />
            {shopInfo.publishedPartsCount > 0
                ? <SalesUserSummaryText field={'publishedPartsCount'} value={shopInfo.publishedPartsCount} isLast
                    prefix={'опубликовано'}/>
                : <SalesText
                    text={`пока ничего не опубликовано`}
                    fontWeight={fontWeights.semiBold}
                    fontSize={fontSizes.l}
                    color={colors.blue}
                    marginTopPixels={8}
                    marginBottomPixels={30}
                />
            }

            <div className={styles.contentWrap}>
                <SalesCardContact
                    contactsType={'shopInfo'}
                    apiObject={shopInfo}
                    initialState={initialStateLocal}
                    isLoading={isLoading}
                />
                <div className={styles.rightColumnWrap}>
                    <div className={styles.image}>
                        <img src={ShopImage} alt="shop-promo-image"/>
                    </div>
                    {paragraphs.map((paragraph) => (
                        <SalesText
                            key={paragraph}
                            text={paragraph}
                            color={colors.grayLight}
                            marginTopPixels={15}
                        />
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Shop
