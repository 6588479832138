import React from 'react'
import styles from './styles.module.scss'
import { SelectOptionLocal } from "../../../types/common/commonTypes"
import SalesCard from "../SalesCard/SalesCard"
import SalesSelectSearchOption from "../SalesSelectSearchOption/SalesSelectSearchOption"
import SalesText from "../../_atoms/SalesText/SalesText"
import { colors, fontSizes } from "../../../constants/salesStyles"


interface IProps {
    options: SelectOptionLocal[]
    maxOptions: number
    currentOption: number
    updateField: (value: string | number, name: string, secondValue?: number | string, secondName?: string) => void
    hideOptions: () => void
    noOptionsText: string
    fontSize: fontSizes
    fontSizeSubName?: fontSizes
    maxHeightInPixels?: number
    widthInPixels?: number
    color?: colors
    colorHover?: colors
    backgroundColor?: colors
    backgroundColorHover?: colors
    top?: number
    paddingTop?: number
    paddingBottom?: number
    showOptions?: boolean
    optionBeforeText?: JSX.Element
}


const SalesSelectOptions = ({
    options,
    maxOptions,
    updateField,
    currentOption,
    hideOptions,
    noOptionsText,
    fontSize,
    fontSizeSubName,
    maxHeightInPixels,
    widthInPixels,
    color,
    colorHover,
    backgroundColor,
    backgroundColorHover,
    top,
    paddingTop,
    paddingBottom,
    showOptions = true,
    optionBeforeText,
}: IProps) => (
    <div
        className={styles.wrap}
        style={{ top: `${top}px` }}
    >
        <SalesCard
            maxHeightInPixels={maxHeightInPixels ? maxHeightInPixels : 300}
            widthInPixels={widthInPixels ? widthInPixels : 350}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
        >
            <>
                {options.length > 0 && showOptions
                    ? options.map((option: SelectOptionLocal, index: number) => {
                        if (index < maxOptions) {
                            return (
                                <SalesSelectSearchOption
                                    key={option.value + option.name}
                                    name={option.name}
                                    value={option.value}
                                    subName={option.subName}
                                    subValue={option.subValue}
                                    updateField={updateField}
                                    hideOptions={hideOptions}
                                    isActive={index === currentOption}
                                    fontSize={fontSize}
                                    fontSizeSubName={fontSizeSubName}
                                    subText={option.subText}
                                    badgeName={option.badgeName}
                                    color={color}
                                    colorHover={colorHover}
                                    backgroundColor={backgroundColor}
                                    backgroundColorHover={backgroundColorHover}
                                    beforeText={optionBeforeText}
                                />
                            )
                        }
                    })
                    : <SalesText
                        text={noOptionsText}
                        color={colors.grayDark}
                        fontSize={fontSize}
                    />
                }
            </>
        </SalesCard>
    </div>
)

export default SalesSelectOptions
