import React, { CSSProperties, ReactElement } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { borderStyles, colors } from "../../../constants/salesStyles"

interface IProps {
    className?: string
    marginRightPixels?: number
    marginLeftPixels?: number
    marginTopPixels?: number
    marginBottomPixels?: number
    marginLeftAuto?: boolean
    paddingHorizontalPixels?: number
    paddingVerticalPixels?: number
    children: ReactElement;
    backgroundColor?: colors
    hoverBackgroundColor?: colors
    borderColor?: colors
    borderStyle?: borderStyles
    borderWidth?: number
    borderRadius?: number
    onClick?: () => void
    disabled?: boolean
}

const SalesButton = ({
    className,
    marginRightPixels,
    marginLeftPixels,
    marginTopPixels,
    marginBottomPixels,
    marginLeftAuto,
    paddingHorizontalPixels,
    paddingVerticalPixels,
    children,
    backgroundColor,
    hoverBackgroundColor,
    borderColor,
    borderStyle,
    borderWidth,
    borderRadius,
    onClick,
    disabled
}: IProps) => {

    const getCursorType = () => {
        switch (true) {
        case disabled:
            return 'not-allowed'
        case onClick && !disabled:
            return 'pointer'
        default:
            return 'pointer'
        }
    }

    return (
        <button
            disabled={disabled}
            onClick={(e) => {
                if (onClick) {
                    e.preventDefault()
                    onClick()
                }
                // else {
                //     console.warn('Add onClick prop!!!')
                // }
            }}

            className={classNames(styles.wrap, className)}
            style={{
                marginRight: marginRightPixels ? marginRightPixels + 'px' : 0,
                marginLeft: marginLeftAuto ? 'auto' : marginLeftPixels ? marginLeftPixels + 'px' : 0,
                marginTop: marginTopPixels ? marginTopPixels + 'px' : 0,
                marginBottom: marginBottomPixels ? marginBottomPixels + 'px' : 0,
                padding: `${paddingVerticalPixels ? paddingVerticalPixels : 0}px ${paddingHorizontalPixels ? paddingHorizontalPixels : 0}px`,
                borderColor: borderColor ? borderColor : colors.transparent,
                borderStyle: borderStyle ? borderStyle : borderStyles.none,
                borderWidth: borderWidth ? borderWidth + 'px' : 0,
                borderRadius: borderRadius ? borderRadius + 'px' : '10px',
                cursor: getCursorType(),
                '--background-color': backgroundColor ? backgroundColor : colors.grayBackground,
                '--hover-background-color': hoverBackgroundColor ? hoverBackgroundColor : backgroundColor,
            } as CSSProperties}
        >
            {children}
        </button>
    )
}

export default SalesButton
