import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import TableHeadRangeFilter from '../../_atoms/TableHeadRangeFilter/TableHeadRangeFilter'

interface IProps {
    initialFromVal: number | undefined,
    initialToVal: number | undefined,
    initialTableHead?: string
}

const TableHeadPriceRangeCars = ({ initialFromVal, initialToVal, initialTableHead = 'Цена' }: IProps) => (
    <TableHeadRangeFilter
        initialTableHead={initialTableHead}
        step={50000}
        rightInPixels={-50}
        initialFromVal={initialFromVal}
        initialToVal={initialToVal}
        widthInPixels={150}
        storeActionSetRange={storeDispatchTypes.setPriceRangeCars}
        justifyContent={'flex-end'}
    />
)

export default TableHeadPriceRangeCars
