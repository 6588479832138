import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'
import { defaultPartList } from '../../modules/store/partList/partListReducer'
import { AppDispatch } from '../../modules/store/customDispatch'

export const resetFilters = (dispatch: AppDispatch) => {
    dispatch({
        type: storeDispatchTypes.resetFiltersParts
    })
    dispatch({
        type: storeDispatchTypes.setPartList,
        value: defaultPartList,
    })
    dispatch({
        type: storeDispatchTypes.setSelectAllParts,
        value: false,
    })
}

const useResetFiltersOnUnmount = () => {
    const dispatch = useDispatch<AppDispatch>()
    // Сброс фильтров при уходе со страницы
    useEffect(() => () => {
        resetFilters(dispatch)
    }, [dispatch])
}

export default useResetFiltersOnUnmount
