import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"

const getApplicabilityByPartId = (
    partId: string,
) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        partId,
    }
    try {

        const { data: { status, data } } = await apiInstance.post('/getApplicabilityByPartId', params , config)
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.setApplicabilitySelected,
                value: data,
            }))
        }

    } catch (e) {
        console.error('get applicability by partId error', e)
    }
}

export default getApplicabilityByPartId
