import { AnyAction } from "redux"
import { v4 as uuidv4 } from 'uuid'
import {
    checkbox,
    fieldUpdateObject,
    partCreateUpdateActionTypes,
    partFields,
    partInput,
    partState
} from "../../types/part/partTypes"
import { carFields } from "../../types/car/carTypes"
import { valueTypes } from "../../types/common/commonTypes"

// Проверка на тип массива
export function isArrayOfTypeFile(arr: (File | checkbox)[]): arr is File[] {
    return arr.every((item) => item instanceof File)
}

export const partReducer = (state: partState, action: AnyAction) => {
    const getFieldIndex = (fieldToSearch: carFields | partFields) => state.fields.findIndex((field: partInput) => field.field === fieldToSearch)

    const convertValueType = (value: number | string | boolean, valueType: valueTypes) => {
        switch (true) {
        case valueType === valueTypes.string:
            return String(value)
        case valueType === valueTypes.boolean:
            return Boolean(value)
        case valueType === valueTypes.number:
            return Number(String(value).replaceAll(/[^0-9]/g, ''))
        case valueType === valueTypes.price:
            return Number(String(value).substring(0,10).replaceAll(/[^0-9]/g, ''))
        case valueType === valueTypes.files:
            return value
        default:
            console.error(`check value type ${value} is ${typeof value}`)
            return value
        }
    }

    const updateFieldValue = () => {
        const stateFields: partInput[] = [...state.fields]

        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        const convertedValue = convertValueType(action.value.value, stateFields[fieldToUpdateIndex].valueType)
        stateFields[fieldToUpdateIndex] = { ...stateFields[fieldToUpdateIndex], value: convertedValue }

        const specialPropsToUpdate = action.value.specialProps
        if (specialPropsToUpdate) {
            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                specialProps: { ...specialPropsToUpdate }
            }
        }

        return { ...state, fields: stateFields }
    }

    const updateMultipleFieldValues = () => {
        const stateFields: partInput[] = [...state.fields]

        action.value.map((item: fieldUpdateObject) => {

            const fieldToUpdateIndex = getFieldIndex(item.field)
            const newValue = item.value

            if (fieldToUpdateIndex === -1) return

            if (newValue || newValue === '' || newValue === 0) {
                const convertedValue = convertValueType(newValue, stateFields[fieldToUpdateIndex].valueType)
                stateFields[fieldToUpdateIndex] = { ...stateFields[fieldToUpdateIndex], value: convertedValue }
            }

            const specialPropsFieldToUpdate = item.specialPropsField
            if (specialPropsFieldToUpdate) {
                stateFields[fieldToUpdateIndex] = {
                    ...stateFields[fieldToUpdateIndex],
                    specialProps: {
                        ...stateFields[fieldToUpdateIndex]?.specialProps,
                        [specialPropsFieldToUpdate]: item.specialPropsValue
                    }
                }
            }
        })

        return { ...state, fields: stateFields }
    }

    const updateFieldSpecialProps = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        const stateFields: partInput[] = [...state.fields]

        stateFields[fieldToUpdateIndex] = {
            ...stateFields[fieldToUpdateIndex],
            specialProps: { ...action.value.specialProps }
        }

        return { ...state, fields: stateFields }
    }

    const uploadFiles = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        const stateFields: partInput[] = [...state.fields]

        const currentFiles = stateFields[fieldToUpdateIndex]?.value || []
        if (currentFiles && Array.isArray(currentFiles)) {
            const newFiles = [...currentFiles, ...action.value.files]

            if (newFiles.length > 10) {
                newFiles.length = 10
            }

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: newFiles
            }
        }

        return { ...state, fields: stateFields }
    }

    const removeFile = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        const stateFields: partInput[] = [...state.fields]

        const currentFiles = stateFields[fieldToUpdateIndex]?.value || []
        if (currentFiles && Array.isArray(currentFiles) && isArrayOfTypeFile(currentFiles)) {
            currentFiles.splice(action.value.fileIndex, 1)

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: [...currentFiles]
            }
        }


        return { ...state, fields: stateFields }
    }

    const reorderFiles = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        const stateFields: partInput[] = [...state.fields]

        const currentFiles = stateFields[fieldToUpdateIndex]?.value || []
        if (currentFiles && Array.isArray(currentFiles)) {
            const newFiles = [...action.value.files]

            if (newFiles.length > 10) {
                newFiles.length = 10
            }

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: newFiles
            }
        }

        return { ...state, fields: stateFields }
    }

    const addField = () => {
        const stateFields: partInput[] = [...state.fields]
        const fieldToAdd = action.value.field

        const filteredAddFields = stateFields.filter(field => field.specialProps?.addedField === fieldToAdd.specialProps?.addedField)
        const index = filteredAddFields.length
        ? stateFields.findIndex((field: partInput) => field.field === filteredAddFields[filteredAddFields.length - 1].field)
        : stateFields.findIndex((field: partInput) => field.field === action.value.after)
        
        const { maxAddedFields } = fieldToAdd.specialProps

        if (index !== -1) {
            const addedFields = filteredAddFields.length
            if (addedFields < maxAddedFields) {
                stateFields.splice(index + 1, 0, {
                    ...fieldToAdd,
                    title: fieldToAdd.title + ` #${filteredAddFields.length + 1}`,
                    field: uuidv4(),
                    specialProps: {
                        ...fieldToAdd.specialProps,
                        column: stateFields[index].specialProps?.column,
                    }
                })
            }
        }

        return { ...state, fields: stateFields }
    }

    const deleteField = () => {
        const stateFields: partInput[] = [...state.fields]
        const fieldToDelete = action.value.field
        const index = stateFields.findIndex((field: partInput) => field.field === fieldToDelete)

        if (index !== -1) {
            stateFields.splice(index, 1)
        }

        return { ...state, fields: stateFields }
    }

    const fillDimensionsAndWeight = () => {
        const stateFields: partInput[] = [...state.fields]
        const heightField = stateFields.findIndex(field => field.field === partFields.height)
        const widthField = stateFields.findIndex(field => field.field === partFields.width)
        const lengthField = stateFields.findIndex(field => field.field === partFields.length)
        const weightField = stateFields.findIndex(field => field.field === partFields.weight)

        if (!stateFields[heightField].value && !stateFields[widthField].value
            && !stateFields[lengthField].value && !stateFields[weightField].value)
        {
            if (stateFields[heightField].specialProps?.placeholder) {
                stateFields[heightField].value = stateFields[heightField].specialProps?.placeholder
            }
            if (stateFields[widthField].specialProps?.placeholder) {
                stateFields[widthField].value = stateFields[widthField].specialProps?.placeholder
            }
            if (stateFields[lengthField].specialProps?.placeholder) {
                stateFields[lengthField].value = stateFields[lengthField].specialProps?.placeholder
            }
            if (stateFields[weightField].specialProps?.placeholder) {
                stateFields[weightField].value = stateFields[weightField].specialProps?.placeholder
            }
        }
        
        return { ...state, fields: stateFields }
    }

    const updateApplicabilityInSuggestion = (isAdded: boolean) => {
        const stateFields: partInput[] = [...state.fields]
        const applicabilitySuggestionFieldIndex = stateFields.findIndex(field => field.field === partFields.applicabilitySuggestion)
    
        const applicability = stateFields[applicabilitySuggestionFieldIndex].specialProps?.applicabilitySuggestionItems?.find(item => item.id === action.value.id)
        if (applicability) {
            applicability.isAdded = isAdded
        }
    
        return { ...state, fields: stateFields }
    }

    switch (action.type) {
    case partCreateUpdateActionTypes.updateField: {
        return updateFieldValue()
    }
    case partCreateUpdateActionTypes.updateManyFields: {
        return updateMultipleFieldValues()
    }
    case partCreateUpdateActionTypes.initializeState:
        return action.value.state
    case partCreateUpdateActionTypes.updateSpecialProps:
        return updateFieldSpecialProps()
    case partCreateUpdateActionTypes.addFiles:
        return uploadFiles()
    case partCreateUpdateActionTypes.removeFile:
        return removeFile()
    case partCreateUpdateActionTypes.reorderFiles:
        return reorderFiles()
    case partCreateUpdateActionTypes.addField:
        return addField()
    case partCreateUpdateActionTypes.deleteField:
        return deleteField()
    case partCreateUpdateActionTypes.fillDimensionsAndWeight:
        return fillDimensionsAndWeight()
    case partCreateUpdateActionTypes.addApplicabilityFromSuggestion:
        return updateApplicabilityInSuggestion(true)
    case partCreateUpdateActionTypes.returnApplicabilityToSuggestion:
        return updateApplicabilityInSuggestion(false)
    default:
        return state
    }
}
