import React from 'react'
import { useSelector } from "../../../modules/store/customSelector"
import searchPartListMarks from "../../../modules/redux/partList/SearchPartListMarks"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'


const TableHeadMarkSearchParts = () => {
    const marks = useSelector(state => state.partList.marks)
    const marksIdFilter = useSelector((state) => state.partList.filters.markId)

    return (
        <TableHeadSelectSearch
            slice={'partList'}
            tableHead={'Марка'}
            placeholder={'Выберите марку'}
            actionType={storeDispatchTypes.setMarkFilterParts}
            options={marks}
            filterId={marksIdFilter}
            searchOptions={searchPartListMarks}
        />
    )
}

export default TableHeadMarkSearchParts

