import { Dispatch } from "redux"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"

const addPartsToInvoice = ({
    partsId,
    id,
}: {
    partsId: string[],
    id: string,
}) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        partsId,
        id,
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentUpdated', value: true } })
    try {
        await apiDocument.post('/addPartsToInvoice', params, config)
    } catch(e) {
        console.error('update document parts failed', e)
    } finally {
        dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentUpdated', value: false } })
    }
}

export default addPartsToInvoice
