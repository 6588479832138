import React from 'react'
import { useSelector } from "../../../modules/store/customSelector"
import searchPartListCategories from "../../../modules/redux/partList/SearchPartListCategories"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'


const TableHeadCategorySearchParts = () => {
    const categories = useSelector(state => state.partList.categories)
    const categoryIdFilter = useSelector((state) => state.partList.filters.categoryId)

    return (
        <TableHeadSelectSearch
            slice={'partList'}
            tableHead={'Кат.'}
            placeholder={'Выберите категорию'}
            actionType={storeDispatchTypes.setCategoryFilterParts}
            options={categories}
            filterId={categoryIdFilter}
            searchOptions={searchPartListCategories}
        />
    )
}

export default TableHeadCategorySearchParts


