import React from 'react'
import styles from "./styles.module.scss"
import { IPriceChartBar } from "../../../types/part/partTypes"
import ChartBar from "../../_atoms/ChartBar/ChartBar"

interface IProps {
    bars: IPriceChartBar[]
}

const PriceChart = ({ bars }: IProps) => (
    <div className={styles.wrap}>
        {bars.map(bar => (
            <ChartBar
                key={String(bar.range.min)+String(bar.range.max)}
                value={bar.value}
                label={bar.label}
                count={bar.count}
                range={bar.range}
                isFirst={bar.isFirst}
                isLast={bar.isLast}
            />
        ))}
    </div>
)

export default PriceChart
