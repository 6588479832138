import { Dispatch } from 'redux'
import axios from "axios"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { CatalogPartGroup, CatalogPartType, SelectOptionLocal } from "../../../types/common/commonTypes"

let localPartGroups: CatalogPartGroup[] = []
let localPartTypes = {}

const getAllPartTypes = (searchTerm: string) => async (dispatch: Dispatch): Promise<void> => {

    const loadPartGroups = async () => {
        if (localPartGroups.length) {
            return
        }
        try {
            const partGroups = await axios.get('https://api.stock-pro.net/catalog/data/part_groups.json')
            if (partGroups.data) {
                localPartGroups = partGroups.data
            }
        } catch (e) {
            console.error('load part groups error', e)
        }
    }

    const loadPartTypes = async () => {
        if (Object.keys(localPartTypes).length) {
            return
        }
        const getCurrentHour = function (): number {
            const date: Date = new Date()
            const currentHour: number = Math.round(date.getTime() / (1000 * 60 * 60))
            return currentHour
        }

        try {
            const partTypes = await axios.get('https://api.stock-pro.net/catalog/data/part_types2.json?v=' + getCurrentHour())
            if (partTypes.data) {
                localPartTypes = partTypes.data
            }
        } catch (e) {
            console.error('load part groups error', e)
        }
    }

    try {
        await Promise.all([
            loadPartGroups(),
            loadPartTypes(),
        ])
        const data: SelectOptionLocal[] = []

        const getGroupTitle = (groupId: number | string) => localPartGroups.find((partGroup: CatalogPartGroup) => partGroup.id === Number(groupId))?.title
        const partTypesData: Record<string, CatalogPartType[]> = localPartTypes
        for (const key in partTypesData) {
            const groupTitle = getGroupTitle(key)
            if (groupTitle) {
                partTypesData[key].forEach((partType: CatalogPartType) => {
                    data.push({
                        value: Number(partType.id),
                        name: partType.title,
                        subName: groupTitle,
                        subValue: Number(key)
                    })
                })
            }
        }

        const filteredList = data.filter((partType: SelectOptionLocal) => partType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || partType.subName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))

        dispatch({ type: storeDispatchTypes.setAllPartTypesParts, value: { partTypes: filteredList } })

    } catch (e) {
        console.error('load cars marks failed', e)
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'modifications', value: false } })
}

export default getAllPartTypes
