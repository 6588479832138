import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'


const updatePartExtraDataValue = (partId: string, extraDataValue: any) => async( 
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()

    try {
        const { data: { status, extraData } } = await apiInstance.post('/updatePartExtraDataValue', {
            userId:  userData.smsAuth.userId,
            partId,
            extraDataValue
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.updatePartExtraDataValue,
                value: {
                    id: partId,
                    extraData: extraData
                },
            }))
        }

    } catch (e) {
        console.error('updatePartExtraDataValue failed', e)
    }
}


export default updatePartExtraDataValue
