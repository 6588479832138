import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"
import { ApplicabilityCreation } from '../../../types/part/partTypes'

const setApplicability = (
    applicability: ApplicabilityCreation,
    typeId: number,
    partNumber: string[],
    partId?: string,
) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        data: {
            applicability,
            ...(partId && { partId }),
            typeId,
            partNumber,
        }
    }
    try {
        await apiInstance.post('/setApplicability', params , config)
    } catch (e) {
        console.error('set applicability error', e)
    }
}

export default setApplicability
