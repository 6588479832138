import React from 'react'
import styles from "./styles.module.scss"
import OverlayWithText from "../../_atoms/OverlayWithText/OverlayWithText"
import { Car } from "../../../types/car/carTypes"
import CellButtonsCars from '../../_atoms/CellButtonsCars/CellButtonsCars'

interface IProps {
    car: Car
    isSafari: boolean
    editModal: () => void
    deleteModal: () => void
}

const CellActionsCars = ({ car, isSafari, editModal, deleteModal }: IProps) => {
    const overlayHeightInPx = () => {
        const baseHeight = 28
        const perLineHeight = 18
        if (isSafari) {
            const displayedTitle = car.modelTitle
            const additionalLinesCount = Math.ceil(displayedTitle.length / 27)
            return baseHeight + (perLineHeight * additionalLinesCount)
        } else {
            return 0
        }
    }
    return (
        <div className={styles.wrap}>
            {car.isDeleted
                ? (
                    <OverlayWithText
                        text={'УДАЛЕНО'}
                        backgroundBootstrapColor={'bg-danger'}
                        textBootstrapColor={'text-light'}
                        heightInPx={overlayHeightInPx()}
                    />
                ) : (
                    <CellButtonsCars
                        carId={car.id}
                        editModal={editModal}
                        deleteModal={deleteModal}
                    />
                )
            }
        </div>
    )
}

export default CellActionsCars
