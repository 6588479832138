import { Dispatch } from 'redux'
import axios from "axios"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { CatalogMark } from "../../../types/common/commonTypes"

let localMarks = {}

const getAllCarMarks = (searchTerm: string) => async (dispatch: Dispatch): Promise<void> => {
    const loadMarks = async () => {
        if (Object.keys(localMarks).length) {
            return
        }
        try {
            const marks = await axios.get('https://api.stock-pro.net/catalog/data/visibleMarks.json')
            if (marks.data) {
                localMarks = marks.data
            }
        } catch (e) {
            console.error('loadMarks error', e)
        }
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'marks', value: true } })
    try {
        await loadMarks()
        const data: Record<string, CatalogMark> = localMarks
        const filteredList = []
        if (searchTerm.length > 0) {
            for (const key in data) {
                if (data[key].name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    filteredList.push(data[key])
                }
            }
        } else {
            for (const key in data) {
                filteredList.push(data[key])
            }
        }

        filteredList.sort((a,b) => {
            if (a.name < b.name) {
                return -1
            }
            return 1
        })

        dispatch({ type: storeDispatchTypes.setAllMarksCars, value: { allMarks: filteredList } })

    } catch (e) {
        console.error('load cars marks failed', e)
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'marks', value: false } })
}

export default getAllCarMarks
