import React, { ReactElement, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { colors, fontSizes, fontWeights } from "../../../constants/salesStyles"
import { KeyboardEventLocal } from "../../../types/common/commonTypes"
import HoverOverlay from "../HoverOverlay/HoverOverlay"
import useDebounce from '../../../hooks/useDebounce'

interface IProps {
    value: string
    onChangeValue: (value: string) => void
    placeholder?: string
    fontSize?: fontSizes
    fontWeight?: fontWeights
    color?: colors
    marginRightPixels?: number
    marginLeftPixels?: number
    marginTopPixels?: number
    marginBottomPixels?: number
    suffixElement?: ReactElement
    disableAutoFocus?: boolean
    isDisabled?: boolean
    errorMessage?: string
    isHover?: boolean
    maxLength?: number
    onSubmit?: () => void
    backgroundColor?: colors
    paddingRight?: number
    onClick?: () => void
    isNumber?: boolean
    delay?: number
}

const SalesInput = ({
    value,
    onChangeValue,
    placeholder,
    fontSize,
    fontWeight,
    color,
    marginRightPixels,
    marginLeftPixels,
    marginTopPixels,
    marginBottomPixels,
    suffixElement,
    disableAutoFocus,
    isDisabled,
    errorMessage,
    maxLength,
    onSubmit,
    backgroundColor,
    paddingRight,
    onClick,
    isNumber,
    delay = 0,
}: IProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [localValue, setLocalValue] = useState(value)

    const debouncedValue = useDebounce(localValue, delay)

    useEffect(() => {
        onChangeValue(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {
        if (value !== localValue) {
            setLocalValue(isNumber ? formatValue(value) : value)
        }
    }, [value])

    const keyHandler = (event: KeyboardEventLocal) => {
        switch (true) {
        case event.code === 'ArrowDown':
            event.preventDefault()
            break
        case event.code === 'ArrowUp':
            event.preventDefault()
            break
        case event.code === 'Enter':
            event.preventDefault()
            if (onSubmit && !errorMessage) onSubmit()
            break
        default:
            break
        }
    }

    const [autoFocus, setAutofocus] = useState(disableAutoFocus === undefined ? true : !disableAutoFocus)

    const handleInputChange = (newValue: string) => {
        if (!isNumber) {
            setLocalValue(newValue)
            return
        }
        const formattedValue = formatValue(newValue)
        setLocalValue(formattedValue)
    }

    const formatValue = (value: string) => Number(String(value).substring(0,10).replaceAll(/[^0-9]/g, '')).toLocaleString('ru-RU')

    return (
        <div className={styles.wrap} tabIndex={0}>
            <HoverOverlay
                tooltipText={errorMessage}
                maxWidthInPixels={300}
            >
                <>
                    <input
                        disabled={isDisabled}
                        onKeyDown={keyHandler}
                        ref={inputRef}
                        autoFocus={autoFocus}
                        type="text"
                        className={classNames(styles.input, {
                            [styles.disabled]: isDisabled,
                            [styles.error]: errorMessage,
                            [styles.suffixPadding]: suffixElement
                        })}
                        value={localValue}
                        onChange={(e) => handleInputChange(e.target.value)}
                        onFocus={() => setAutofocus(true)}
                        onBlur={() => setAutofocus(false)}
                        placeholder={placeholder}
                        style={{
                            fontSize: fontSize ? fontSize : fontSizes.m,
                            fontWeight: fontWeight ? fontWeight : fontWeights.normal,
                            color: color ? color : colors.grayDisabled,
                            marginRight: marginRightPixels ? marginRightPixels + 'px' : 0,
                            marginLeft: marginLeftPixels ? marginLeftPixels + 'px' : 0,
                            marginTop: marginTopPixels ? marginTopPixels + 'px' : 0,
                            marginBottom: marginBottomPixels ? marginBottomPixels + 'px' : 0,
                            backgroundColor: backgroundColor && backgroundColor,
                            paddingRight: paddingRight && paddingRight + 'px',
                        }}
                        maxLength={maxLength}
                        onClick={onClick}
                    />
                    {suffixElement
                        ? <div className={styles.suffixElement}>
                            {suffixElement}
                        </div>
                        : null
                    }
                </>
            </HoverOverlay>
        </div>
    )
}

export default SalesInput
