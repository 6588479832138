import { useState, useEffect } from 'react'
import { StarFill } from 'react-bootstrap-icons'
import styles from './styles.module.scss'
import { colors } from "../../../constants/salesStyles"

interface IProps {
    value?: number
    onChangeValue: (value: number) => void
}

const StarRating = ({
    value = 0,
    onChangeValue,
}: IProps) => {
    const [rating, setRating] = useState(value)
    const [hoveredRating, setHoveredRating] = useState(value)

    const handleStarClick = (value: number) => {
        setRating(value)
        onChangeValue(value)
    }

    useEffect(() => {
        setRating(value && value)
        setHoveredRating(value && value)
    }, [value])

    return (
        <div className={styles.wrap} tabIndex={0}>
            {[1, 2, 3, 4].map((value) => (
                <span
                    className={styles.icon}
                    key={value}
                    onClick={() => handleStarClick(value)}
                    onMouseEnter={() => setHoveredRating(value)}
                    onMouseLeave={() => setHoveredRating(rating)}
                >
                    <StarFill size={30} color={value <= hoveredRating ? colors.purple : colors.grayLight}/>
                </span>
            ))}
        </div>

    )
}

export default StarRating
