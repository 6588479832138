import { Dispatch } from "redux"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import { Document } from "../../../types/document/documentTypes"
import anchor from '../../../constants/anchor'

const getDocumentById = (
    id: string
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<Document | undefined> => {
    const { userData } = getState()

    try {
        const { data } = await apiDocument.post(`/getDocument/${id}`, {
            userId: userData.smsAuth.userId,
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        return data
    } catch (e) {
        console.error('get document by id error', e)
    }
}

export default getDocumentById
