import { Dispatch } from "redux"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import { DocumentType } from "../../../types/document/documentTypes"
import anchor from '../../../constants/anchor'

const getDocumentsSelectSearch = (
    type: DocumentType,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()

    try {
        const params = {
            type
        }
        const { data: { data } } = await apiDocument.post('/getDocumentList/getDocumentsSelectSearch', {
            userId: userData.smsAuth.userId,
            params: params,
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        dispatch({
            type: storeDispatchTypes.setAllDocuments,
            value: {
                documents: data,
            }
        })
    } catch (e) {
        console.error('load visible documents error', e)
    }
}

export default getDocumentsSelectSearch
