import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useGetSmsUserId } from "./useAuth"

export const useAuthGuard = () => {
    const user = useGetSmsUserId()
    const navigate = useNavigate()
    useEffect(() => {
        if (!user) {
            navigate('/auth')
        }
    }, [user])
}
