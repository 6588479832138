import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { Marketplaces, MarketplacesKeys, Part } from '../../../types/part/partTypes'
import IndeterminateCheckbox, {
    IndeterminateCheckboxValue,
} from '../../_atoms/IndeterminateCheckbox/IndeterminateCheckbox'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

import styles from './styles.module.scss'

interface IProps {
    parts: Part[]
    count: number
    isOpen: boolean
    closeModal: () => void
    confirmChanges: (partsId: string[], checkedMarketplaces: Record<string, boolean>) => Promise<void>
    loadPartsMarketplacesCount: () => Promise<void | Record<string, number>>
}

const ModalMarketplaces = ({
    parts,
    count,
    isOpen,
    closeModal,
    confirmChanges,
    loadPartsMarketplacesCount,
}: IProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [checkedMarketplaces, setCheckedMarketplaces] = useState<Record<string, boolean> | null>(null)
    const [marketplaceCounts, setMarketplaceCounts] = useState<Record<string, number>>({})
    const allMarketplaces = Object.keys(Marketplaces) as MarketplacesKeys[]

    const onCloseModal = () => {
        closeModal()
    }

    const confirm = async () => {
        setIsLoading(true)
        if (checkedMarketplaces) {
            await confirmChanges(
                parts.map(part => part.id),
                checkedMarketplaces,
            )
        }
        closeModal()
        setIsLoading(false)
    }

    // Функция для проверки, привязан ли конкретный маркетплейс хотя бы к одной детали
    const isMarketplaceBoundToAtLeastOnePart = (marketplace: string) =>
        parts.some(part =>
            part.extraData
                ? part.extraData.marketplacesDest
                    ? part.extraData.marketplacesDest.includes(marketplace)
                    : true
                : true,
        )

    // Функция для проверки, привязан ли конкретный маркетплейс ко всем деталям
    const isMarketplaceBoundToParts = (marketplace: string): IndeterminateCheckboxValue => {
        let allPartsBound = false
        let atLeastOnePartBounds = false
        if (parts.length) {
            allPartsBound = parts.every(part =>
                part.extraData
                    ? part.extraData.marketplacesDest
                        ? part.extraData.marketplacesDest.includes(marketplace)
                        : true
                    : true,
            )
        } else {
            if (marketplaceCounts[marketplace] === count) {
                allPartsBound = true
            } else if (marketplaceCounts[marketplace] !== 0) {
                atLeastOnePartBounds = true
            }
        }

        if (allPartsBound) {
            return IndeterminateCheckboxValue.Checked // Если привязан ко всем деталям
        } else if (isMarketplaceBoundToAtLeastOnePart(marketplace) || atLeastOnePartBounds) {
            return IndeterminateCheckboxValue.Indeterminate // Если привязан хотя бы к одной детали, но не ко всем
        } else {
            return IndeterminateCheckboxValue.Unchecked // Если не привязан ни к одной детали
        }
    }

    // Функция для подсчета количества деталей, привязанных к маркетплейсу
    const countPartsForMarketplaces = () => {
        const counts: Record<string, number> = {}
        allMarketplaces.forEach(marketplace => {
            counts[marketplace] = parts.filter(part =>
                part.extraData
                    ? part.extraData.marketplacesDest
                        ? part.extraData.marketplacesDest.includes(marketplace)
                        : true
                    : true,
            ).length
        })
        setMarketplaceCounts(counts)
    }

    const handleCheckboxChange = (marketplace: string, event: ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked
        setCheckedMarketplaces(prevState => ({
            ...prevState,
            [marketplace]: isChecked,
        }))

        setMarketplaceCounts(prevState => ({
            ...prevState,
            [marketplace]: isChecked ? count : 0,
        }))
    }

    useEffect(() => {
        countPartsForMarketplaces()
    }, [parts])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const marketplacesCount = await loadPartsMarketplacesCount()
            if (marketplacesCount) {
                setMarketplaceCounts(marketplacesCount)
            }
            setIsLoading(false)
        }

        if (!parts.length) {
            fetchData().catch(console.error)
        }
    }, [])

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Привязать к Маркетплейсам</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {allMarketplaces.map(marketplace => (
                    <IndeterminateCheckbox
                        value={isMarketplaceBoundToParts(marketplace)}
                        key={marketplace}
                        type="checkbox"
                        id={`checkbox-${marketplace}`}
                        label={`${Marketplaces[marketplace]} (${marketplaceCounts[marketplace] || 0})`}
                        onChange={e => handleCheckboxChange(marketplace, e)}
                    />
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirm}>
                    Применить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalMarketplaces
