import Transmission from '../../assets/img/types/Transmission.svg'
import UnderSystem from '../../assets/img/types/UnderSystem.svg'
import Engine from '../../assets/img/types/Engine.svg'
import BrakeSystem from '../../assets/img/types/BrakeSystem.svg'
import DriverWhell from '../../assets/img/types/DriverWhell.svg'
import Body from '../../assets/img/types/Body.svg'
import Shine from '../../assets/img/types/Shine.svg'
import Glass from '../../assets/img/types/Glass.svg'
import Electro from '../../assets/img/types/Electro.svg'
import Security from '../../assets/img/types/Security.svg'
import GasSystem from '../../assets/img/types/GasSystem.svg'
import ColdSystem from '../../assets/img/types/ColdSystem.svg'
import Rims from '../../assets/img/types/Rims.svg'
import LockSystem from '../../assets/img/types/LockSystem.svg'
import FuelSystem from '../../assets/img/types/FuelSystem.svg'


export const getPartStubImage = (groupId:number) => {
    groupId = Number(groupId)
    switch (groupId) {
    case 1:
        return Transmission
    case 2:
        return UnderSystem
    case 3:
        return Engine
    case 4:
        return BrakeSystem
    case 5:
        return DriverWhell
    case 6:
        return Body
    case 7:
        return Shine
    case 8:
        return Glass
    case 9:
        return Electro
    case 10:
        return Security
    case 11:
        return GasSystem
    case 12:
        return ColdSystem
    case 13:
        return Rims
    case 14:
        return LockSystem
    case 15:
        return FuelSystem
    case 17:
        return Engine
    default:
        return Body
    }
}
