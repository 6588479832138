import { fieldTypes, valueTypes } from "../common/commonTypes"
import { Part } from "../part/partTypes"

export type apiMarketplacesContact = {
    name: string,
    address: string,
    phone: string,
    email: string,
    useVirtualPhone: boolean
}

export type apiShopInfo = {
    shopUrl: string
    shopName: string
    address: string
    phone: string
    email: string
    publishedPartsCount: number
}

export enum contactFields {
    name = 'name',
    address = 'address',
    phone = 'phone',
    email = 'email',
    useVirtualPhone = 'useVirtualPhone',
    shopName = 'shopName',
    shopUrl = 'shopUrl',
}

export type contactSpecialProps = {
    canCopy?: boolean
    hoverText?: string
    truncate?: boolean
    renderValue?: string
    checkboxPrice?: number
    isHidden?: boolean
}

export enum contactSpecialPropsFields {
    canCopy = 'canCopy',
    hoverText = 'hoverText',
    truncate = 'truncate',
    renderValue = 'renderValue',
    checkboxPrice = 'checkboxPrice',
    isHidden = 'isHidden',
}

export type contactInput = {
    field: contactFields,
    fieldType: fieldTypes
    value: string | boolean | number,
    valueType: valueTypes,
    title: string,
    specialProps?: contactSpecialProps
}

export type contactState = {
    fields: contactInput[],
}

export enum salesContactActionTypes {
    updateField = 'updateField',
    initializeState = 'initializeState',
    updateSpecialProps = 'updateSpecialProps',
}

export type apiMarketplacesInfoItem = {
    alias: string
    status: string
    link: string
    publishedPartsCount: number
    title: string
    icon: string
    date: string
    previousCount: number
}

export type TypeMarketplace = {
    id: string
    title: string
    icon: string
}

export type apiHighlight = {
    alias: string
    label: string
    desc: string
    call2action: string,
    count: number
    partIds: string[]
    parts?: Part[]
    totalCount: number
    price: number
    params?: string
}

export type apiUserSummary = {
    soldCount: number
    soldSum: number
    soldRate: number
    totalSum: number
    totalCount: number
    totalCountWithQty: number
}

export type userSummaryFields = keyof apiUserSummary

export type apiPartCategory = { id: number, title: string, count: number }

export type daDataSuggestion = {
    value: string,
    unrestricted_value: string,
    data: {
        postal_code: string,
        country: string,
        country_iso_code: string,
        region_fias_id: string,
        region_kladr_id: string,
        region_iso_code: string,
        region_with_type: string,
        region_type: string,
        region_type_full: string,
        region: string,
        city_fias_id: string,
        city_kladr_id: string,
        city_with_type: string,
        city_type: string,
        city_type_full: string,
        city: string,
        fias_id: string,
        fias_level: string,
        fias_actuality_state: string,
        kladr_id: string,
        geoname_id: string,
        capital_marker: string,
        okato: string,
        oktmo: string,
        tax_office: string,
        tax_office_legal: string,
        geo_lat: string,
        geo_lon: string,
        qc_geo: string,
    }
}

export type marketplacesSettings = {
    withPrice?: boolean
    withPhoto?: boolean
    priceFrom?: number
    priceIncrease?: number
    selectedCategories?: string[]
}
