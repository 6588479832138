import { useRef, useEffect } from 'react'

interface YandexShareProps {
    content?: {
        title?: string
        url?: string
        description?: string
        image?: string
    }
    theme?: {
        services?: string
        size?: 'l' | 'm' | 's'
        colorScheme?: 'blackwhite' | 'whiteblack' | 'normal'
    }
    className?: string
}

const YandexShare = ({ content, theme, className }: YandexShareProps) => {
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://yastatic.net/share2/share.js'
        script.async = true
        script.onload = () => {
            if (window.Ya && window.Ya.share2 && containerRef.current) {
                window.Ya.share2(containerRef.current, {
                    content,
                    theme,
                })
            }
        }
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [content, theme])

    return (
        <div ref={containerRef} className={className}/>
    )
}

export default YandexShare
