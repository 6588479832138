import { Reducer } from 'redux'
import { storeDispatchTypes } from '../storeDispatchTypes'
import { ActionLog, ActionLogObjectType } from '../../../types/actionLog/actionLogTypes'
import { ApiOption, RangeInput, SelectOptionLocal, SortFieldActionLogs, SortOrder } from '../../../types/common/commonTypes'
import { apiPartCategory } from '../../../types/sales/salesTypes'

interface ActionLogList {
    data: ActionLog[]
    total: number
    dataCount: number
}

export const defaultActionLogList: ActionLogList = {
    data: [],
    total: 0,
    dataCount: 0,
}

enum RangeFields {
    priceRange = 'priceRange',
}

export interface stateTypeActionLogList {
    actionLogList: ActionLogList
    isLoading: boolean

    sortOrder: [SortFieldActionLogs, SortOrder][],
    marks: SelectOptionLocal[]
    models: SelectOptionLocal[]
    partNumbers: SelectOptionLocal[]

    filters: {
        objectType?: ActionLogObjectType
        page: number
        perPage: number
        partName?: string
        partNumber?: string,
        markId?: number,
        modelId?: number,
        filterVendorCode?: string
        filterDate?: string
        [RangeFields.priceRange]?: RangeInput;
    }
}

const initialState = {
    actionLogList: {
        data: [],
        total: 0,
        dataCount: 0,
    },
    isLoading: false,
    
    sortOrder: [],
    marks: [],
    models: [],
    partNumbers: [],

    filters: {
        page: 1,
        perPage: 50,
    },
}

const createActionLogListReducer: Reducer<stateTypeActionLogList> = (
    state = initialState,
    action
) => {
    const setRangeByField = (field: RangeFields) => {
        const { from, to } = action.value
        if (from || to) {
            const newRange = {
                from: from ? Number(from) : undefined,
                to: to ? Number(to) : undefined
            }
            return { ...state, filters: { ...state.filters, [field]: newRange } }
        }
        const filters = { ...state.filters }
        if (field in filters) {
            delete filters[field]
        }
        return { ...state, filters }
    }

    switch (action.type) {
        case storeDispatchTypes.setActionLogList: {
            return {
                ...state,
                actionLogList: action.value,
            }
        }
        case storeDispatchTypes.setActionLogListLoading: {
            return {
                ...state,
                isLoading: action.value,
            }
        }
        case storeDispatchTypes.resetFiltersActionLogList: {
            return { ...state, filters: { page: 1, perPage: 50 }, sortOrder: [] }
        }
        case storeDispatchTypes.setPageFilterActionLogList: {
            return { ...state, filters: { ...state.filters, page: action.value.page } }
        }
        case storeDispatchTypes.setPartNameTextFilterActionLogList: {
            return { ...state, filters: { ...state.filters, partName: action.value.partName } }
        }
        case storeDispatchTypes.setMarksActionLogList: {
            const marks = action.value.categories.map((item: apiPartCategory) => ({
                value: String(item.id),
                name: item.title,
                subText: `(${item.count})`,
            }))
            return { ...state, marks }
        }
        case storeDispatchTypes.setModelFilterActionLogList: {
            return { ...state, filters: { ...state.filters, modelId: action.value.filterId } }
        }
        case storeDispatchTypes.setMarkFilterActionLogList: {
            return { ...state, filters: { ...state.filters, markId: action.value.filterId } }
        }
        case storeDispatchTypes.setFilterDateFilterActionLogList: {
            return { ...state, filters: { ...state.filters, filterDate: action.value.filterDate } }
        }
        case storeDispatchTypes.setActionLogListSort: {
            const draftSortOrder = [...state.sortOrder]
            const currentSortOrderIndex = draftSortOrder.findIndex(item => item[0] === action.value.sortField)
            if (currentSortOrderIndex < 0) {
                draftSortOrder.push([action.value.sortField, action.value.sortOrder])
            } else {
                draftSortOrder[currentSortOrderIndex] = [action.value.sortField, action.value.sortOrder]
            }
            const finalSortOrder = draftSortOrder.filter(item => item[1] !== SortOrder.NONE)
            return { ...state, sortOrder: finalSortOrder }
        }
        case storeDispatchTypes.setPartNumbersActionLogList: {
            const partNumbers = action.value.partNumbers?.map((partNumber: ApiOption) => ({
                value: partNumber.title,
                name: partNumber.title
            }))
            return { ...state, partNumbers }
        }
        case storeDispatchTypes.setPartNumberFilterActionLogList: {
            return { ...state, filters: { ...state.filters, partNumber: action.value.filterId } }
        }
        case storeDispatchTypes.setPriceRangeActionLogList: {
            return setRangeByField(RangeFields.priceRange)
        }
        default: {
            return state
        }
    }
}

export default createActionLogListReducer
