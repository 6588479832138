import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { ActionLogListSearchParams } from '../../../types/actionLog/actionLogTypes'
import { apiInstance } from '../../api/apiInstance'
import { defaultActionLogList } from '../../store/actionLogList/actionLogListReducer'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

let prevController: AbortController | null = null

const getActionLogList =
    (params: ActionLogListSearchParams) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        // Отменить предыдущий запрос, если он существует
        if (prevController) {
            prevController.abort()
        }

        const controller = new AbortController()
        const { signal } = controller
        prevController = controller

        const { userData } = getState()
        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
        }

        dispatch({
            type: storeDispatchTypes.setActionLogListLoading,
            value: true,
        })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post(
                '/getActionLogList',
                {
                    userId: userData.smsAuth.userId,
                    params,
                },
                config,
            )
            if (status === 'ok' && data) {
                dispatch({
                    type: storeDispatchTypes.setActionLogList,
                    value: data,
                })
            }
        } catch (e) {
            console.error('get action log list error', e)
            dispatch({
                type: storeDispatchTypes.setActionLogList,
                value: defaultActionLogList,
            })
        } finally {
            if (!signal.aborted) {
                dispatch({
                    type: storeDispatchTypes.setActionLogListLoading,
                    value: false,
                })
            }
        }
    }

export default getActionLogList
