import React from 'react'
import { SortAlphaDown, SortAlphaDownAlt, SortNumericDown, SortNumericDownAlt } from "react-bootstrap-icons"
import { SortIconType, SortOrder } from "../../../types/common/commonTypes"


interface IProps {
    color: string;
    size: number;
    iconType: SortIconType
    sortOrder: SortOrder
    reverseSort?: boolean
}

const SortIcon = ({ color, size, iconType, sortOrder, reverseSort }: IProps) => {
    const icon = () => {
        if (iconType === SortIconType.ALPHABETIC) {
            if (reverseSort) {
                if (sortOrder === SortOrder.ASC) {
                    return <SortAlphaDownAlt color={color} size={size}/>
                } else {
                    return <SortAlphaDown color={color} size={size}/>
                }
            } else {
                if (sortOrder === SortOrder.ASC) {
                    return <SortAlphaDown color={color} size={size}/>
                } else {
                    return <SortAlphaDownAlt color={color} size={size}/>
                }

            }
        } else if (iconType === SortIconType.NUMERIC) {
            if (reverseSort) {
                if (sortOrder === SortOrder.ASC) {
                    return <SortNumericDownAlt color={color} size={size}/>
                } else {
                    return <SortNumericDown color={color} size={size}/>
                }
            } else {
                if (sortOrder === SortOrder.ASC) {
                    return <SortNumericDown color={color} size={size}/>
                } else {
                    return <SortNumericDownAlt color={color} size={size}/>
                }

            }

        }
    }

    return (
        <>
            {icon()}
        </>
    )
}

export default SortIcon
