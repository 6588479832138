import { useEffect, useRef, useState, KeyboardEvent, } from 'react'
import { Form, Overlay, Tooltip } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import setPartPrice from '../../../modules/redux/partList/SetPartPrice'

interface IProps {
    id: string
    price?: number
}

const CellEditPrice = ({ id, price }: IProps) => {
    const [newPrice, setNewPrice] = useState(price ? price : 0)
    const dispatch = useDispatch()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false)

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'Delete' || e.key.includes('Arrow'))) {
            e.preventDefault()
        }
        if (e.key === 'Enter') {
            const inputElement = e.target
            if (inputElement instanceof HTMLInputElement) {
                inputElement.blur()
            }
        }
    }

    const handleBlur = async() => {
        if (price == newPrice) return
        setIsLoading(true)
        const result = await dispatch(setPartPrice(id, newPrice))
        if (!result) {
            setShowOverlay(true)
            setTimeout(() => {
                setShowOverlay(false)
            }, 5000)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setNewPrice(price ? price : 0)
    }, [price])

    return (
        <Form>
            <Form.Control
                value={newPrice.toLocaleString('ru-RU')}
                size='sm'
                type='text'
                onKeyDown={handleKeyDown}
                onChange={(e) => {setNewPrice(+e.target.value.replace(/[^0-9]/g, ''))}}
                onBlur={handleBlur}
                ref={inputRef}
                disabled={isLoading}
                placeholder='Цена'
                style={{ minWidth: 70 }}
            />

            <Overlay
                target={inputRef.current}
                show={showOverlay}
                placement='top'
            >
                {(props) => (
                    <Tooltip id='overlay-error' {...props}>
                        Не удалось сохранить информацию. Обновите страницу и попробуйте еще раз
                    </Tooltip>
                )}
            </Overlay>
        </Form>
    )
}

export default CellEditPrice
