import React from 'react'
import { InfoCircle } from "react-bootstrap-icons"
import styles from "./styles.module.scss"
import { colors, fontSizes } from "../../../constants/salesStyles"
import HoverOverlay from "../../_atoms/HoverOverlay/HoverOverlay"

interface IProps {
    text?: string
    widthInPixels?: number
    marginLeftPixels?: number
}

const SalesTableDataInfo = ({ text, widthInPixels, marginLeftPixels }: IProps) => (
    <HoverOverlay
        tooltipText={text}
        maxWidthInPixels={widthInPixels ? widthInPixels : 230}
    >
        <div className={styles.icon} style={{ marginLeft: marginLeftPixels || marginLeftPixels === 0 ? marginLeftPixels : 5 }}>
            <InfoCircle size={fontSizes.m} color={colors.grayDark}/>
        </div>
    </HoverOverlay>
)

export default SalesTableDataInfo
