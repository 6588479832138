import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { Warehouse } from '../../../types/warehouse/warehouseTypes'
import createEntityId from '../../../utils/createEntityId'

interface IPararms {
    userId: string
    partId: string
    warehouseId?: string
    row?: string
    shelf?: string
    place?: string
}


const setPartWarehousePosition = (params: IPararms) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const reqParams = {
        userId: params.userId,
        partId: params.partId,
        warehouseId: params.warehouseId,
        ...(params.row && { row: params.row }),
        ...(params.shelf && { shelf: params.shelf }),
        ...(params.place && { place: params.place }),
    }

    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }

    dispatch({
        type: storeDispatchTypes.setWarehousesLoading,
        value: true,
    })

    try {
        const { data: { status } } = await apiInstance.post('/warehouses/setPartWarehousePosition', reqParams, config)
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setWarehousesLoading,
                value: false,
            })

        }
    } catch (e) {
        console.error('set part price failed', e)
    }

    dispatch({
        type: storeDispatchTypes.setWarehousesLoading,
        value: false,
    })
}


export default setPartWarehousePosition
