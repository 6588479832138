import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/bootstrap.css'
import ru from 'react-phone-input-2/lang/ru.json'
import Button from 'react-bootstrap/esm/Button'
import PhoneInput from 'react-phone-input-2'
import { useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import styles from './styles.module.scss'
import getSmsCode from "../../../modules/redux/user/getSmsCode"
import { useSelector } from "../../../modules/store/customSelector"
import OverlayWithText from "../OverlayWithText/OverlayWithText"
import Loader from "../Loader/Loader"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import { getTimeFromMilliseconds } from "../../../utils/converters"
import checkSmsCode from "../../../modules/redux/user/checkSmsCode"
import { KeyboardEventLocal } from "../../../types/common/commonTypes"


const AuthInputBox = () => {
    const dispatch = useDispatch()

    const [phoneNumber, setPhoneNumber] = useState('')
    const [code, setCode] = useState('')
    const [title, setTitle] = useState('Введите номер телефона')

    const isLoading = useSelector((state) => state.userData.smsAuth.isLoading)
    const isCodeSent = useSelector((state) => state.userData.smsAuth.isCodeSent)
    const countDownResend = useSelector((state) => state.userData.smsAuth.countDownResend)
    const errorMessage = useSelector((state) => state.userData.smsAuth.authErrorMessage)

    const [countDown, setCountDown] = useState(countDownResend)

    const handleSubmitPhone = () => {
        dispatch(getSmsCode(`+${phoneNumber}`))
        if (countDown === 0) {
            setCountDown(countDownResend)
        }
    }

    const keyHandler = (event: KeyboardEventLocal, callback: () => void) => {
        switch (true) {
        case event.code === 'Enter' || event.code === 'NumpadEnter':
            callback()
        }
    }

    const handleCancel = () => {
        dispatch({
            type: storeDispatchTypes.setCodeSentSms,
            value: {
                isCodeSent: false
            }
        })

        dispatch({ type: storeDispatchTypes.setAuthErrorSms, value: { msg: '' } })

        setPhoneNumber('')
    }

    const handleSubmitCode = () => {
        dispatch(checkSmsCode(`+${phoneNumber}`, code))
    }

    const getTitle = () => {
        switch (true) {
        case isCodeSent:
            setTitle('Подтвердите номер телефона')
            break
        default :
            setTitle('Введите номер телефона')
            break
        }
    }

    const timer = () => {
        if (countDown > 0) {
            setCountDown(countDown - 1000)
        }
    }

    useEffect(() => {
        getTitle()
    }, [isCodeSent])

    useEffect(() => {
        if (isCodeSent) {
            const intervalId = setInterval(timer, 1000)
            return () => clearInterval(intervalId)
        }
    }, [isCodeSent, countDown])

    useEffect(() => {
        if (phoneNumber || isCodeSent) {
            handleCancel()
        }
    }, [])

    return (
        <div className={styles.wrap}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10}/>
                </OverlayWithText>
            )}
            <p className={styles.title}>{title}</p>
            {!isCodeSent ? (
                <>
                    <PhoneInput
                        country={'ru'}
                        value={phoneNumber}
                        onChange={(phone: string) => {
                            if (errorMessage) {
                                dispatch({ type: storeDispatchTypes.setAuthErrorSms, value: { msg: '' } })
                            }
                            setPhoneNumber(phone)
                        }}
                        onlyCountries={['ru']}
                        localization={ru}
                        containerClass={styles.inputWrap}
                        inputClass={styles.inputPadding}
                        defaultErrorMessage={'Введите действительный номер телефона.'}
                        onKeyDown={(e) => keyHandler(e, handleSubmitPhone)}
                        placeholder={'+7 (960) 123-45-67'}

                    />
                    {errorMessage

                        ? (<div>
                            <p className={styles.errorMessage}>{errorMessage} </p>
                        </div>)
                        : null
                    }
                    <Button className={styles.submitButton} onClick={handleSubmitPhone}>Подтвердить</Button>
                    <p className={styles.chargeWarning}>Нажимая кнопку "Подтвердить", вы соглашаетесь получить SMS. За
                        его отправку и обмен данными может взиматься плата.</p>
                </>
            ) : (
                <>
                    <div className={styles.codeSentText}>
                        <p> На номер <span>{`+${phoneNumber}`}</span></p>
                        <p> отправлен проверочный код</p>
                    </div>
                    <Form.Group controlId="formSmsCode">
                        <Form.Control
                            type="text"
                            placeholder=""
                            autoFocus
                            onChange={(e) => setCode(e.target.value)}
                            onKeyDown={(e) => keyHandler(e, handleSubmitCode)}
                        />
                    </Form.Group>
                    {errorMessage
                        ? (<div>
                            <p className={styles.errorMessage}>{errorMessage} </p>
                        </div>)
                        : null
                    }
                    <div className={styles.codeSentButtonWrap}>
                        <Button className={styles.submitButton} variant="secondary"
                            onClick={handleCancel}>Отмена</Button>
                        <Button className={styles.submitButton} onClick={handleSubmitCode}>Продолжить</Button>
                    </div>
                    {countDown !== 0
                        ?
                        <p className={styles.chargeWarning}>{`Повторная отправка возможна через ${getTimeFromMilliseconds(countDown)}`}</p>
                        : <div className={styles.resendButtonWrap}>
                            <Button className={styles.submitButton} variant={'link'} size={'sm'}
                                onClick={handleSubmitPhone}>Отправить повторно</Button>
                        </div>

                    }
                </>
            )}
        </div>
    )

}

export default AuthInputBox
