import React from 'react'
import styles from "./styles.module.scss"
import OverlayWithText from "../../_atoms/OverlayWithText/OverlayWithText"
import { Car } from "../../../types/car/carTypes"
import CellButtonsCars from '../../_atoms/CellButtonsCars/CellButtonsCars'
import { Warehouse } from '../../../types/warehouse/warehouseTypes'
import CellButtonsWarehouses from '../../_atoms/CellButtonsWarehouses/CellButtonsWarehouses'

interface IProps {
    warehouse: Warehouse
    isSafari: boolean
    editModal: () => void

}

const CellActionsWarehouses = ({ warehouse, isSafari, editModal }: IProps) => {
    const overlayHeightInPx = () => {
        const baseHeight = 28
        const perLineHeight = 18
        if (isSafari) {
            const displayedTitle = warehouse.title
            const additionalLinesCount = Math.ceil(displayedTitle.length / 27)
            return baseHeight + (perLineHeight * additionalLinesCount)
        } else {
            return 0
        }
    }
    return (
        <div className={styles.wrap}>
            {warehouse.isDeleted
                ? (
                    <OverlayWithText
                        text={'УДАЛЕНО'}
                        backgroundBootstrapColor={'bg-danger'}
                        textBootstrapColor={'text-light'}
                        heightInPx={overlayHeightInPx()}
                    />
                ) : (
                    <CellButtonsWarehouses
                        warehouseId={warehouse.id}
                        editModal={editModal}
                    />
                )
            }
        </div>
    )
}

export default CellActionsWarehouses
