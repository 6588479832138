import { Dispatch } from "redux"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { Document } from "../../../types/document/documentTypes"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"

const createDocument = (
    documentObject: Omit<Document, 'id' | 'user_id'>,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        document: {
            ...documentObject,
            user_id: userData.smsAuth.userId,
            createdAt: Date.now()
        },
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentCreated', value: true } })
    try {
        await apiDocument.post('/createDocument', params, config)
    } catch(e) {
        console.error('save document failed', e)
    } finally {
        dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentCreated', value: false } })
    }
}

export default createDocument
