import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

interface Item {
    name: string;
    descTemplate: string;
    // Add other properties if present in the data object
  }
  
const getPartDescTemplates = async () => {
    try {
        const { data: responseData } = await apiInstance.post('/getPartDescTemplates',{
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        const modifiedData = responseData.data.map((item: Item) => ({
              title: item.name,
              value: item.descTemplate,
            }))

        return modifiedData
    } catch (e) {
        console.error('part desc template failed to load', e)
    }
}

export default getPartDescTemplates
