import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { apiShopInfo } from "../../../types/sales/salesTypes"


const updateShopInfo = (data: apiShopInfo) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        data
    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }

    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'shopInfo', value: true } })
    try {
        const { data: { status } } = await apiInstance.post('/sales/shop/updateShopInfo', params, config)
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setShopInfo,
                value: {
                    info: data
                },
            })
            //@ts-ignore
            window.ym(74629465, 'reachGoal', 'createshowcaseweb')
        }

    } catch (e) {
        console.error('update shopInfo failed', e)
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'shopInfo', value: false } })

}


export default updateShopInfo
