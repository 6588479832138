import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import { RangeInput, SortFieldCars, SortOrder } from "../../../types/common/commonTypes"
import anchor from '../../../constants/anchor'

const loadVisibleCars = (
    page:number,
    perPage:number,
    markId?:number,
    modelId?:number,
    modificationId?:number,
    bodyTypeId?:number,
    partsCntRange?: RangeInput,
    priceRange?: RangeInput,
    mileageRange?: RangeInput,
    order?: [SortFieldCars, SortOrder][]
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()

    dispatch({
        type: storeDispatchTypes.setCarsLoading,
        value: true,
    })
    try {
        const params = {
            "pagination": {
                page,
                perPage,
            },
            markId,
            modelId,
            modificationId,
            bodyTypeId,
            partsCntRange,
            priceRange,
            mileageRange,
            order,
            // onlyWithParts: true,
        }
        const { data } = await apiInstance.post('/getCarsAdminList', {
            userId: userData.smsAuth.userId,
            params: params
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        dispatch({
            type: storeDispatchTypes.setCarList,
            value: data,
        })
    } catch (e) {
        console.error('load visible cars error', e)
    }

    dispatch({
        type: storeDispatchTypes.setCarsLoading,
        value: false,
    })
}

export default loadVisibleCars
