import React from 'react'
import { TSvgComponent } from "../../../types/common/commonTypes"

type SvgProps = {
    logo: TSvgComponent,
}

const ComponentFromSvg = ({ logo }:SvgProps) => {
    const SelectLogo = logo
    return <SelectLogo/>
}

export default  ComponentFromSvg
