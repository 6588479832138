import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import { RangeInput, SortFieldParts, SortOrder } from "../../../types/common/commonTypes"
import anchor from '../../../constants/anchor'
import { defaultPartList } from '../../store/partList/partListReducer'
import { PartListSearchParams } from '../../../types/part/partTypes'

let prevController: AbortController | null = null

const loadPartList = (params: PartListSearchParams) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    // Отменить предыдущий запрос, если он существует
    if (prevController) {
        prevController.abort()
    }
    
    const controller = new AbortController()
    const { signal } = controller
    prevController = controller

    const { userData } = getState()
    dispatch({
        type: storeDispatchTypes.setPartListLoading,
        value: true,
    })

    try {
        // Сортировка по умолчанию по дате продажи для SoldParts
        const order = (params.order?.length || !params.isSold) ? params.order : [[SortFieldParts.SOLD_AT, SortOrder.DESC]]
        const data = await apiInstance.post('/getPartsAdminList', {
            userId: userData.smsAuth.userId,
            params: {
                ...params,
                order,
            }
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            },
            signal
        })

        if (data) {
            dispatch({
                type: storeDispatchTypes.setPartList,
                value: data.data,
            })
        }
    } catch (e) {
        console.error('load  parts failed', e)
        dispatch({
            type: storeDispatchTypes.setPartList,
            value: defaultPartList,
        })
    } finally {
        if (!signal.aborted) {
            dispatch({
                type: storeDispatchTypes.setPartListLoading,
                value: false,
            })
        }
    }
}

export default loadPartList
