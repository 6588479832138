import React from 'react'
import styles from "./styles.module.scss"
import SalesText from "../../_atoms/SalesText/SalesText"
import { colors, fontSizes } from "../../../constants/salesStyles"
import useHoverHandler from "../../../hooks/sales/useHoverHandler"
import Badge from '../../_atoms/Badge/Badge'

interface IProps {
    name: string
    value: string | number
    subName?: string
    subValue?: string | number
    updateField: (value: string | number, name: string, secondValue?: number | string, secondName?: string) => void
    hideOptions: () => void
    isActive: boolean
    fontSize: fontSizes
    fontSizeSubName?: fontSizes
    subText?: string
    badgeName?: string
    color?: colors
    colorHover?: colors
    backgroundColor?: colors
    backgroundColorHover?: colors
    beforeText?: JSX.Element
}

const SalesSelectSearchOption = ({
    name,
    value,
    updateField,
    hideOptions,
    isActive,
    fontSize,
    subValue,
    subName,
    fontSizeSubName,
    subText,
    badgeName,
    color = colors.white,
    colorHover = colors.white,
    backgroundColor = colors.purple,
    backgroundColorHover = colors.purple,
    beforeText,
}: IProps) => {
    const [isHover, hoverOn, hoverOff] = useHoverHandler()

    const setOption = () => {
        updateField(value, name, subValue, subName)
        hideOptions()
    }

    return (
        <div
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
            className={styles.wrap}
            onClick={setOption}
        >
            <SalesText
                text={name}
                fontSize={fontSize}
                backgroundColor={isActive ? backgroundColor : (isHover ? backgroundColorHover : colors.transparent)}
                color={isActive ? color : (isHover ? colorHover : colors.black)}
                marginTopPixels={7}
                marginBottomPixels={subName || badgeName ? 0 : 5}
                marginLeftPixels={-20}
                marginRightPixels={-20}
                paddingLeftPixels={20}
                paddingRightPixels={20}
                subText={subText}
            />
            {!subName
                ? null
                : <SalesText
                    text={subName}
                    fontSize={fontSizeSubName}
                    backgroundColor={isActive ? backgroundColor : (isHover ? backgroundColorHover : colors.transparent)}
                    color={isActive ? color : (isHover ? colorHover : colors.grayLight)}
                    marginTopPixels={0}
                    marginBottomPixels={badgeName ? 0 : 5}
                    marginLeftPixels={-20}
                    marginRightPixels={-20}
                    paddingLeftPixels={20}
                    paddingRightPixels={20}
                    paddingBottomPixels={3}
                    maxLines={2}
                    beforeText={beforeText}
                />
            }
            {badgeName &&
            <div
                className={styles.badge}
                style={{
                    fontSize: fontSize ? fontSize : fontSizes.m,
                    backgroundColor: isActive ? backgroundColor : (isHover ? backgroundColorHover : colors.transparent)
                }}>
                <div>
                    <Badge
                        text={badgeName}
                        color='#aaa'
                    />
                </div>
            </div>}
        </div>
    )
}

export default SalesSelectSearchOption
