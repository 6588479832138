import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { IUserTaskResult, TStatus } from '../../../types/tasks/tasksTypes'
import { apiTasksService } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'
import { TaskKeys } from '../../store/user/userReducer'

interface ICreateUserTaskResultProps {
    code: TaskKeys
    status: TStatus
    result?: IUserTaskResult
}

const createUserTaskResult =
    ({ code, status, result }: ICreateUserTaskResultProps) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const params = {
            userId: userData.smsAuth.userId,
            code,
            status,
            result,
        }
        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
        }
        try {
            const { data } = await apiTasksService.post(
                '/createUserTaskResult',
                params,
                config,
            )
            if (data) {
                dispatch({
                    type: storeDispatchTypes.setAccountTaskInfo,
                    value: {
                        code,
                        task: data,
                    },
                })
            }
        } catch (e) {
            console.error('create userTask result failed', e)
            throw e
        }
    }

export default createUserTaskResult
