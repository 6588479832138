import { PropsWithChildren, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styles from './styles.module.scss'
import Loader from "../_atoms/Loader/Loader"
import { storeDispatchTypes } from "../../modules/store/storeDispatchTypes"
import { useGetSmsPhoneNumber, useGetSmsUserId } from "../../hooks/user/useAuth"
import { useSelector } from "../../modules/store/customSelector"

const SmsAuthHoc = ({ children }: PropsWithChildren<unknown>) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const isRefreshing  = useSelector(state => state.userData.smsAuth.isRefreshing)

    const [userId, setUserId] = useState<string| undefined>(useGetSmsUserId())
    const [phoneNumber, setPhoneNumber] = useState<string| undefined>(useGetSmsPhoneNumber())

    const userIdLocalStorage =  localStorage.getItem('userId') || undefined
    const phoneNumberLocalStorage= localStorage.getItem('phoneNumber') || undefined

    useEffect(() => {
        if(!userId && userIdLocalStorage) {
            setUserId(userIdLocalStorage)
            dispatch({ type: storeDispatchTypes.setUserIdSms, value: { userIdLocalStorage } })
        }
        if(!phoneNumber && phoneNumberLocalStorage) {
            setPhoneNumber(phoneNumberLocalStorage)
            dispatch({ type: storeDispatchTypes.setUserPhoneNumberSms, value: { phoneNumberLocalStorage } })
        }

        setIsLoading(false)
    }, [userId,phoneNumber])

    if (isLoading && (!userId || !phoneNumber) || isRefreshing) {
        return (
            <div className={styles.authHocWrap}>
                <div  className={styles.authHocWrap__content}>
                    <Loader diameterInPx={100}/>
                    <p>Проверка авторизации...</p>
                </div>
            </div>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default SmsAuthHoc
