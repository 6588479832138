export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const countDaysInStock = (createdAt: string): string => {
    const dateCreated = new Date(parseInt(createdAt, 10)).getTime()
    const dateNow = new Date().getTime()
    return String(Math.round((dateNow - dateCreated) / (1000 * 60 * 60 * 24)))
}

export const formatDate = (timestamp: string | number | undefined): string => {
    if (!timestamp) return ''
    const date = new Date(+timestamp)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())

    return `${day}.${month}.${year}`
}

export const getArrayFromNumber = (number: number) => {
    const tempArr = []
    for (let i = 1; i <= number; i++) {
        tempArr.push(i)
    }
    return tempArr
}

export function num_word(value:number, words: string[]) {
    value = Math.abs(value) % 100
    const num = value % 10
    if (value > 10 && value < 20) return words[2]
    if (num > 1 && num < 5) return words[1]
    if (num == 1) return words[0]
    return words[2]
  }

export const getTimeFromMilliseconds = (milliseconds: number) => {
    const timeInSeconds = milliseconds / 1000
    const divisorMinutes = timeInSeconds % (60 * 60)
    const minutes = Math.floor(divisorMinutes / 60)

    const divisorSeconds = divisorMinutes % 60
    const seconds = Math.ceil(divisorSeconds)

    // expected output for 1000 = '0:01'
    return `${minutes}:${String(seconds).padStart(2, '0')}`
}

export const getNoun = (number: number, one: string, two: string, five: string) => {
    number = Math.abs(number)
    number %= 100
    if (number >= 5 && number <= 20) {
        return five
    }
    number %= 10
    if (number == 1) {
        return one
    }
    if (number >= 2 && number <= 4) {
        return two
    }
    return five
}


export const convertThousands = (val: number, suffix = 'k') => {
    const valNumeric = Number(val)
    if (valNumeric < 1000) {
        return parseFloat(String(val))
    } else if (valNumeric >= 1000) {
        return parseFloat((valNumeric / 1000).toFixed(1)) + '\u00a0' + suffix

    }
}

export const convertThousandsSoldParts = (val: number, suffix = 'k') => {
    const valNumeric = Number(val)
    const fullSuffix = val > 0 ? suffix + '\u00a0' + '\u20BD' : '\u00a0' + '\u20BD'
    return parseFloat((valNumeric / 1000).toFixed(0)) + fullSuffix
}

export const convertToWords = (val: string, numOnly: boolean | undefined) => {
    const valNumeric = Number(val)
    if (valNumeric < 1000 || numOnly) {
        return parseFloat(val)
    } else if (valNumeric >= 1000 && valNumeric < 1000000) {
        return parseFloat((valNumeric / 1000).toFixed(1)) + '\u00a0тыс'
    } else if (valNumeric >= 1000000) {
        return parseFloat((valNumeric / 1000000).toFixed(1)) + '\u00a0млн'
    }
}
