import { Dispatch } from 'redux'
import { RootState } from '../../store/rootReducer'
import { apiInstance } from '../../api/apiInstance'
import anchor from '../../../constants/anchor'
import { PartListSearchParams } from '../../../types/part/partTypes'

const getPartsMarketplacesCount = (params?: PartListSearchParams) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void | Record<string, number>> => {
    const { userData } = getState()

    try {
        const { data: { status, data } } = await apiInstance.post('/getPartsMarketplacesCount', {
            userId:  userData.smsAuth.userId,
            ...(params && { params }),
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })

        if (status && data) {
            return data
        }
    } catch (e) {
        console.error('getPartsMarketplacesCount failed', e)
    }
}

export default getPartsMarketplacesCount
