import { carFields } from '../../../types/car/carTypes'
import { fieldTypes, valueTypes } from '../../../types/common/commonTypes'

export const initialPartState = {
    fields: [
        {
            field: carFields.markId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Марка',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите марку',
                column: 1,
            }
        },
        {
            field: carFields.modelId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Модель',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите модель',
                fieldParent: carFields.markId,
                column: 1,
            }
        },
        {
            field: carFields.modificationId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Модификация',
            specialProps: {
                isSelectSearch: true,
                placeholder: 'выберите модификацию',
                fieldParent: carFields.modelId,
                column: 1,
            }
        },
        {
            field: carFields.year,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Год',
            specialProps: {
                placeholder: 'выберите год',
                fieldParent: carFields.modificationId,
                column: 1,
            }
        },
    ],
}
