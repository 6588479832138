import React, { ReactElement } from 'react'
import { borderStyles, colors } from "../../../constants/salesStyles"
import SalesButton from "../../_atoms/SalesButton/SalesButton"

interface IProps {
    children: ReactElement
    onClick?: ()=>void
}

const SalesButtonIcon = ({ children, onClick }:IProps) => (
    <SalesButton
        paddingHorizontalPixels={12}
        paddingVerticalPixels={6}
        borderRadius={17}
        borderStyle={borderStyles.solid}
        borderColor={colors.grayBorder}
        borderWidth={1}
        onClick={onClick}
    >
        {children}
    </SalesButton>
)

export default SalesButtonIcon
