import React from 'react'
import { ChevronDown, XLg } from "react-bootstrap-icons"
import styles from './styles.module.scss'
import { colors, fontSizes } from '../../../constants/salesStyles'
import Loader from '../../_atoms/Loader/Loader'

interface IProps {
    isLoading: boolean
    text: string
    clearText: () => void
    color?: colors 
}

const SalesSelectSearchSuffix = ({ isLoading, text, clearText, color = colors.purple }: IProps) => {
    const suffixElement = () => {
        switch (true) {
        case isLoading:
            return <Loader diameterInPx={18} thicknessInPx={3} isDisplayFlex/>
        case text?.length > 0 && !isLoading:
            return <span onClick={clearText} className={styles.clearIcon} >
                <XLg color={color} size={fontSizes.m}/>
            </span>
        default:
            return <ChevronDown color={color} size={fontSizes.m}/>
        }
    }

    return (
        <div style={{ marginRight: '10px' }}>
            {suffixElement()}
        </div>
    )
}

export default SalesSelectSearchSuffix
