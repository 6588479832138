import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const getAddressSuggestions = (query: string) => async(
    dispatch: Dispatch,
): Promise<void> => {
    const params = {
        query,

    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'addressSuggestions', value: true } })
    try {
        const { data: { status, suggestions } } = await apiInstance.post('/suggestAddressDaData', params, config)
        if(status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setSalesAddressSuggestions,
                value: {
                    addressSuggestions: suggestions,
                },
            })
        }
    } catch (e) {
        console.error('get address suggestions failed', e)
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'addressSuggestions', value: false } })

}


export default getAddressSuggestions
