import { apiPriceChart } from "../../api/apiInstance"
import { IPriceChartResponse } from "../../../types/part/partTypes"

const getPartPriceRecommendedChart = async (typeId: number, markId: number, modelId: number, price: number ): Promise<IPriceChartResponse | null> => {
    try {
        const { data, status } = await apiPriceChart.post('/dschart/get_prices_chart', {
            sp_tid: typeId,
            mark_id: markId,
            model_id: modelId,
            usrprice: price,
        })
        if(status === 200) {
            let chartData =[...data.res]
            chartData = chartData.map((barData, i) => {

                if(i === 0) {
                    barData = { ...barData, isFirst: true }
                }

                if(i !== 0 && i === chartData.length -1) {
                    barData = { ...barData, isLast: true }
                }

                switch (barData.count) {
                case 0:
                    return { ...barData, value: 0.05 }
                case 1:
                    return { ...barData, value: 0.1 }
                default:
                    return { ...barData, value: (Math.log10(barData.count)*0.3).toFixed(2) }
                }
            })
            return { ...data, res: [...chartData] }
        }
        return null

    } catch (e) {
        console.error('load price chart failed', e)
        return null
    }
}

export default getPartPriceRecommendedChart
