import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"

const updateApplicabilityById = (
    id: string,
    partId: string[],
    typeId: number[],
    partNumber: string[],
) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        id,
        data: {
            partId,
            typeId,
            partNumber,
        }
    }
    try {
        await apiInstance.post('/updateApplicabilityById', params , config)
    } catch (e) {
        console.error('get applicability by partId error', e)
    }
}

export default updateApplicabilityById
