import SalesButton from '../../_atoms/SalesButton/SalesButton'
import SalesText from '../../_atoms/SalesText/SalesText'
import { colors, fontSizes } from '../../../constants/salesStyles'

interface Page {
    path: string
    label: string
    countKey: string
}

interface IProps {
    pages: Page[]
    currentPage: string
    counts?: Record<string, number>
    navigate: (path: string) => void
}

const NavigationButtons = ({ pages, currentPage, counts, navigate }: IProps) => (<>
    {pages.map(({ path, label, countKey }) => (
        <SalesButton
            key={path}
            borderRadius={10}
            backgroundColor={currentPage === path ? colors.grayDark : undefined}
            paddingHorizontalPixels={20}
            paddingVerticalPixels={7}
            onClick={() => navigate(path)}
        >
            <>
                <SalesText
                text={label}
                fontSize={fontSizes.s}
                color={currentPage === path ? colors.white : undefined}
                />
                {counts && (
                <SalesText
                    text={String(counts[countKey])}
                    marginLeftPixels={10}
                    fontSize={fontSizes.s}
                    color={currentPage === path ? colors.white : colors.purple}
                />
                )}
            </>
        </SalesButton>
    ))}
</>)

export default NavigationButtons
