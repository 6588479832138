import React, { useState } from 'react'
import styles from './styles.module.scss'

interface SelectableComponentProps {
  imageSrc: string;
  text: string;
  selected: boolean;
  onClick: () => void;
}

const SelectableComponent: React.FC<SelectableComponentProps> = ({
  imageSrc,
  text,
  selected,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false)

  const handleHover = () => {
    setHovered(!hovered)
  }

  const handleClick = () => {
    onClick()
  }

  return (
    <div
      className={`${styles.selectableComponent} ${selected ? styles.selected : ''}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onClick={handleClick}
    >
      <img src={imageSrc} alt={text} className={styles.icon} />
      <span className={`${styles.text} ${hovered ? styles.hovered : ''}`}>{text}</span>
    </div>
  )
}

export default SelectableComponent
