import { adminRouteAlias } from "./adminRouteAlias"
import CarList from "../pages/CarList/CarList"
import PartList from "../pages/PartList/PartList"
import Sales from "../pages/Sales/Sales"
import Marketplaces from "../pages/Marketplaces/Marketplaces"
import Shop from "../pages/Shop/Shop"
import NewCar from "../pages/NewCar/NewCar"
import NewPart from "../pages/NewPart/NewPart"
import PayWall from "../pages/PayWall/PayWall"
import SoldPartList from "../pages/SoldParts/SoldPartList"
import WarehouseList from "../pages/WarehouseList/WarehouseList"
import ArchivedPartList from "../pages/ArchivedParts/ArchivedPartList"
import DocumentList from "../pages/DocumentList/DocumentList"
import PartDraftList from "../pages/PartDraftList/PartDraftList"
import Onboarding from "../pages/Onboarding/Onboarding"
import CarDisassembly from "../pages/Onboarding/CarDisassembly/CarDisassembly"
import DownloadFromFile from "../pages/Onboarding/DownloadFromFile/DownloadFromFile"
import ActionLog from "../pages/ActionLog/ActionLog"

export const adminRoutes = [
    { path: adminRouteAlias.cars.location, element: <CarList /> },
    { path: adminRouteAlias.cars.location + '/:carId', element: <CarList /> },
    { path: adminRouteAlias.parts.location, element: <PartList /> },
    { path: adminRouteAlias.soldParts.location, element: <SoldPartList/> },
    { path: adminRouteAlias.soldParts.location + '/:partId', element: <SoldPartList /> },
    { path: adminRouteAlias.archivedParts.location, element: <ArchivedPartList/> },
    { path: adminRouteAlias.parts.location + '/:partId', element: <PartList /> },
    { path: adminRouteAlias.sales.location, element: <Sales /> },
    { path: adminRouteAlias.warehouses.location, element: <WarehouseList /> },
    { path: adminRouteAlias.marketplaces.location, element: <Marketplaces /> },
    { path: adminRouteAlias.shop.location, element: <Shop /> },
    { path: adminRouteAlias.newCar.location, element: <NewCar /> },
    { path: adminRouteAlias.newPart.location, element: <NewPart /> },
    { path: adminRouteAlias.newPart.location + '/:carId', element: <NewPart /> },
    { path: adminRouteAlias.payWall.location, element: <PayWall /> },
    { path: adminRouteAlias.documents.location, element: <DocumentList /> },
    { path: adminRouteAlias.draftsOfParts.location, element: <PartDraftList/> },
    { path: adminRouteAlias.draftsOfParts.location + '/:partId', element: <PartDraftList /> },
    { path: adminRouteAlias.onboarding.location, element: <Onboarding /> },
    { path: adminRouteAlias.onboardingCarDisassembly.location, element: <CarDisassembly /> },
    { path: adminRouteAlias.onboardingDownloadFromFile.location, element: <DownloadFromFile /> },
    { path: adminRouteAlias.actionLog.location, element: <ActionLog /> },
]
