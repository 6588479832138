import { Dispatch } from "redux"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import { DocumentType } from "../../../types/document/documentTypes"
import anchor from '../../../constants/anchor'

const getDocumentList = (
    page: number,
    perPage: number,
    type: DocumentType,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()

    dispatch({
        type: storeDispatchTypes.setDocumentListLoading,
        value: true,
    })
    try {
        const params = {
            pagination: {
                page,
                perPage,
            },
            type
        }
        const { data } = await apiDocument.post('/getDocumentList', {
            userId: userData.smsAuth.userId,
            params: params,
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        dispatch({
            type: storeDispatchTypes.setDocumentList,
            value: data,
        })
    } catch (e) {
        console.error('load visible documents error', e)
    }

    dispatch({
        type: storeDispatchTypes.setDocumentListLoading,
        value: false,
    })
}

export default getDocumentList
