import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllOilsOEMOil =
    (searchTerm: string, typeId?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsOEMOil', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/OilsOEMOil', { typeId })
            const oilsOEMOil: SelectOptionLocal[] = []

            if (status === 'ok') {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const OEMOilsNodes = xmlDoc.getElementsByTagName('OEMOil')

                for (let i = 0; i < OEMOilsNodes.length; i++) {
                    const OEMOilName = OEMOilsNodes[i].textContent?.trim()
                    if (OEMOilName && OEMOilName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        oilsOEMOil.push({ name: OEMOilName, value: OEMOilName })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllOilsOEMOil, value: { oilsOEMOil } })
        } catch (e) {
            console.error('load OEMOil failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsOEMOil', value: false } })
    }

export default getAllOilsOEMOil
