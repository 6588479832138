import React from 'react'
import { useDispatch } from "react-redux"
import { XCircleFill } from "react-bootstrap-icons"
import styles from './styles.module.scss'
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import { useSelector } from "../../../modules/store/customSelector"

interface IProps {
    filterName: 'filterDate' | 'carId' | 'filterVendorCode',
    slice?: 'partList' | 'actionLogList'
}

const ResetFilter = ({ filterName, slice = 'partList' }: IProps) => {

    const filterDate = useSelector((state) =>
        slice === 'partList'
            ? state.partList.filters.filterDate
            : state.actionLogList.filters.filterDate
        )

    const dispatch = useDispatch()


    const resetFilterDate = () => {
        dispatch({
            type: slice === 'partList'
                ? storeDispatchTypes.setFilterDateFilterParts
                : storeDispatchTypes.setFilterDateFilterActionLogList,
            value: {
                filterDate: ''
            }
        })
    }


    return (
        <>
            {filterName == 'filterDate' && filterDate !== '' && (
                <span className={styles.wrap}>
                    <span className={styles.clearIcon} onClick={resetFilterDate}>
                        <XCircleFill color='grey' size={14} />
                    </span>
                    <div>
                        {filterDate}
                    </div>
                </span>
            )
            }
        </>
    )
}

export default ResetFilter
