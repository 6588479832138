import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const linkPartsToCar = (partsId: string[], carId: string) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        partsId,
        carId,
    }
    try {
        const { data: { status } } = await apiInstance.post('/linkPartsToCar', params , config)

        if(status !== 'ok') {
            throw new Error('set part marketplaces failed')
        }
    } catch (e) {
        console.error('link parts to car failed', e)
    }
}

export default linkPartsToCar
