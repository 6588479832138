import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

interface Item {
  name: string;
  descTemplate: string;
}

const getPartDescTemplatesByPartId = async (userId: string, partId: string) => {
  try {
    const { data: responseData } = await apiInstance.post(
      '/getDescTemplatesByPartId',
      {
        userId: userId,
        partId,
      },
      {
        headers: {
          Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
      }
    )

    const modifiedData = responseData.data.map((item: Item) => ({
      title: item.name,
      value: item.descTemplate,
    }))

    return modifiedData
  } catch (e) {
    console.error('Error while fetching part desc templates:', e)
    // throw e // Re-throw the error to handle it outside this function.
  }
  return []
}

export default getPartDescTemplatesByPartId
