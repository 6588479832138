import React from 'react'
import { ExclamationCircleFill } from "react-bootstrap-icons"
import styles from "./styles.module.scss"
import { colors, fontSizes } from "../../../constants/salesStyles"
import SalesText from "../SalesText/SalesText"

interface IProps {
    text: string
}

const PayWallListItem = ({ text }: IProps) => (
    <div className={styles.wrap}>
        <ExclamationCircleFill color={colors.yellowLight} size={fontSizes.xl}/>
        <SalesText
            text={text}
            marginLeftPixels={15}
            marginBottomPixels={15}
        />
    </div>
)

export default PayWallListItem
