import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import { PartImage } from '../../../types/part/partTypes'
import anchor from '../../../constants/anchor'

const reorderCarImages = (carId: string, images: PartImage[]) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        carId,
        images
    }
    try {

        const { data: { status } } = await apiInstance.post('/setCarReorderImages', params , config)
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.reorderCarImages,
                value: {
                    id: carId,
                    images
                },
            }))
        }

    } catch (e) {
        console.error('reorder car images failed', e)
    }
}

export default reorderCarImages
