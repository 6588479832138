import React from 'react'
import { XCircleFill } from "react-bootstrap-icons"
import styles from "./styles.module.scss"
import { colors } from "../../../constants/salesStyles"
import useHoverHandler from "../../../hooks/sales/useHoverHandler"

interface IProps {
    file: File
    index: number
    removeFile: (index: number) => void
    setActiveImageIndex: (index: number) => void
}

const SalesThumbPreview = ({ file, index, removeFile, setActiveImageIndex }: IProps) => {
    const getImagePreviewThumb = (file: File) => URL.createObjectURL(file)
    const getMarginRight = (index: number) => ((index + 1) % 5 === 0 && index !== 0) ? '0px' : '6px'
    const [isHover, hoverOn, hoverOff] = useHoverHandler()

    return (
        <div
            key={file.name + index}
            className={styles.wrap}
            onMouseLeave={hoverOff}
            onMouseOver={hoverOn}
            style={{
                marginRight: getMarginRight(index),
            }}
        >
            <img
                src={getImagePreviewThumb(file)}
                alt="upload image"
                className={styles.thumbPreview}
                onClick={() => {
                    setActiveImageIndex(index)
                }}
            />
            {isHover
                ? <span onClick={() => removeFile(index)} className={styles.clearIcon}>
                    <XCircleFill color={colors.white} size={18}/>
                </span>
                : null}
        </div>
    )
}

export default SalesThumbPreview
