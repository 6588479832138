import React from 'react'
import { Nav } from "react-bootstrap"
import { NavLink, useLocation } from "react-router-dom"
import { useDispatch } from 'react-redux'
import styles from "./styles.module.scss"
import { TSvgComponent } from "../../../types/common/commonTypes"
import ComponentFromSvg from "../ComponentFromSvg/ComponentFromSvg"
import ListResetFiltersButton from "../ListResetFiltersButton/ListResetFiltersButton"
import { useSelector } from "../../../modules/store/customSelector"
import HoverOverlay from '../HoverOverlay/HoverOverlay'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import { AppDispatch } from '../../../modules/store/customDispatch'

type SideBarRoute = {
    location: string,
    name: string,
    icon: TSvgComponent,
    slice: string,
}

interface IProps {
    navLinks: SideBarRoute[]
}

const SidebarNavLinks = ({ navLinks }: IProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation()
    const activeRoute = (routeName: string) => location.pathname.indexOf(routeName) !== -1
    const partFilters = useSelector((state) => state.partList.filters)
    const carFilters = useSelector((state) => state.carList.filters)
    const actionLogListFilters = useSelector((state) => state.actionLogList.filters)

    const resetFilters = (route: SideBarRoute) => {
        switch (route.slice) {
        case 'carList':
            dispatch({
                type: storeDispatchTypes.resetFiltersCars,
            })
            break
        case 'partList':
            dispatch({
                type: storeDispatchTypes.resetFiltersParts,
            })
            break
        case 'actionLogList':
            dispatch({
                type: storeDispatchTypes.resetFiltersActionLogList,
            })
            break
        }
    }

    return (
        <div className={styles.wrap}>
            <ListResetFiltersButton
                slice={'partList'}
                leftInPixes={36}
                topInPixes={-14}
                currentFilters={partFilters}
                hoverOffset={[-5, 20]}

            />
            <ListResetFiltersButton
                slice={'carList'}
                leftInPixes={36}
                topInPixes={40}
                currentFilters={carFilters}
                hoverOffset={[52, 20]}
            />
            <ListResetFiltersButton
                slice={'actionLogList'}
                leftInPixes={36}
                topInPixes={266}
                currentFilters={actionLogListFilters}
                hoverOffset={[280, 20]}
            />


            <Nav className={styles.navLinks}>
                {navLinks.map((route) => (
                    <HoverOverlay
                        tooltipText={route.name}
                        placement="right"
                        offset={[-10,5]}
                        key={route.location}
                    >
                        <NavLink
                            to={route.location}
                            className={`${styles.navLinks__link} ${activeRoute(route.location) ? styles.navLinks__link__active : ''}`}
                            onClick={() => resetFilters(route)}
                        >
                            <ComponentFromSvg logo={route.icon}/>
                        </NavLink>

                    </HoverOverlay>

                ))}
            </Nav>
        </div>
    )
}

export default SidebarNavLinks
