import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const getPartTypeByTypeId = (typeId: number) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        typeId
    }
    try {
        const { data: { status, data } } = await apiInstance.post('/getPartTypeByTypeId', params , config)

        if(status === 'ok' && data) {
            dispatch(({
                type: storeDispatchTypes.setPartType,
                value: data,
            }))
        }
    } catch (e) {
        console.error('get partType by typeId failed', e)
    }
}

export default getPartTypeByTypeId
