import { useNavigate } from 'react-router'
import styles from './styles.module.scss'
import { DocumentType } from '../../../types/document/documentTypes'

interface IProps {
    slice?: DocumentType
}

const ListDocumentEmpty = ({ slice }: IProps) => {
    const navigate = useNavigate()
    let message = 'message'
    switch (slice) {
        case DocumentType.Invoice:
            message = 'первую расходную накладную'
            break
        case DocumentType.Receipt:
            message = 'первый чек'
            break
        case DocumentType.IncomingInvoice:
            message = 'первую приходную накладную'
            break
    }

    const goToPartList = () => {
        navigate(`/admin/parts`)
    }

    return (
        <div className={styles.wrap}>
            <p>Выделите позиции в <span className={styles.reset} onClick={goToPartList}>списке запчастей</span> галочками и сформируйте {message}</p>
        </div>
    )
}

export default ListDocumentEmpty
