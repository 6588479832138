import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const searchPartListModels = (
    searchTerm: string
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        search: searchTerm,
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    try {
        const { data: { status, categories } } = await apiInstance.post('/getPartsSelectSearchModel', params, config)
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setModelsParts,
                value: {
                    models: categories
                }
            })
        }
    } catch (e) {
        console.error('load parts models failed', e)
    }
}

export default searchPartListModels
