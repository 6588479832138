import React, { ReactNode } from 'react'
import styles from "./styles.module.scss"

interface IProps {
    text?: string
    textBootstrapColor?: string
    backgroundBootstrapColor?: string
    children?: ReactNode;
    heightInPx?: number
}

const OverlayWithText = ({ text, textBootstrapColor, backgroundBootstrapColor, children, heightInPx }: IProps) => (
    <div className={styles.soldOverlay} style={{ height: heightInPx ? heightInPx + 'px' : 'auto' }}>
        <div className={backgroundBootstrapColor}/>
        {text && (
            <p className={textBootstrapColor}>{text}</p>
        )}
        {children && (
            <div className={styles.children}>
                {children}
            </div>
        )}
    </div>
)

export default OverlayWithText
