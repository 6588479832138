import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"

const deletePartImage = (partId: string, imageId: string) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        partId,
        imageId
    }
    try {

        const { data: { status } } = await apiInstance.post('/deleteImageFromCloudById', params , config)
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.deletePartImage,
                value: {
                    id: partId,
                    imageId
                },
            }))
        }

    } catch (e) {
        console.error('set part sold status error', e)
    }
}

export default deletePartImage
