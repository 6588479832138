import { Dispatch } from 'redux'
import getBrowserFingerprint from 'get-browser-fingerprint'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { apiAuth } from "../../api/apiInstance"

const fingerprint = String(getBrowserFingerprint())

const checkSmsCode = (phone: string, code: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    try {
        dispatch({
            type: storeDispatchTypes.setIsLoadingSms,
            value: {
                isLoading: true
            }
        })
        const { data: { status, msg, data: { accessToken, refreshToken, userId } } } = await apiAuth.post('/checkCode', {
            phone,
            code,
            deviceId: fingerprint
        })
        if (status === 'ok') {
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('userId', userId)
            localStorage.setItem('phoneNumber', phone)
            dispatch({
                type: storeDispatchTypes.setUserIdSms,
                value: {
                    userId
                }
            })
            dispatch({
                type: storeDispatchTypes.setUserPhoneNumberSms,
                value: {
                    phoneNumber: phone
                }
            })
            //@ts-ignore
            window.ym(74629465, 'reachGoal', 'regweb')

        }

        if (status === 'error') {
            dispatch(({
                type: storeDispatchTypes.setAuthErrorSms,
                value: {
                    msg
                }
            }))
        }

    } catch (e) {
        console.error('check sms code failed', e)
    }
    dispatch({
        type: storeDispatchTypes.setIsLoadingSms,
        value: {
            isLoading: false
        }
    })
}

export default checkSmsCode
