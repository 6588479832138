import { Cashier } from '../modules/store/user/userReducer'
import { Part } from '../types/part/partTypes'

interface Register {
    Register: {
        Name: string
        Quantity: number
        Price: number
        Amount: number
        Department: number
        Tax: number
        SignMethodCalculation: number
        SignCalculationObject: number
    }
}

export function ExecuteCommand(
    Data?: any,           // Данные команды
    FunSuccess?: any,     // Функция выполняемая при успешном соединении
    FunError?: any,       // Функция выполняемая при ошибке соединения
    timeout?: any) {
    
    // Проверка стоит ли расширение, и если стоит то отправка через расширение
    // Для активации скрипта расширения ваша страница должна содержать в теге 'head' строку:
    // <script>var KkmServerAddIn = {};</script>
    const { KkmServer } = window

    try {
        if (KkmServer != undefined) {
            // Если данные - строка JSON конвентируем в объект
            if (typeof (Data) == 'string') Data = JSON.parse(Data)
            // Выполняем команду через расширение
            KkmServer.Execute(ExecuteSuccess, Data)
            //Возврат - вызов по Http не нужен
            return
        }
    } catch {
        console.error('KkmServer not found')
    }
}

// Функция вызываемая после обработки команды - обработка возвращаемых данных
// Здесь можно посмотреть как получить возвращаемые данные
function ExecuteSuccess(Rezult: any) {
    //----------------------------------------------------------------------
    // ОБЩЕЕ 
    //----------------------------------------------------------------------
    let MessageStatus
    if (Rezult.Status == 0) {
        MessageStatus = 'Ok'
    } else if (Rezult.Status == 1) {
        MessageStatus = 'Выполняется'
    } else if (Rezult.Status == 2) {
        MessageStatus = 'Ошибка!'
    } else if (Rezult.Status == 3) {
        MessageStatus = 'Данные не найдены!'
    }
    // Текст ошибки
    const MessageError = Rezult.Error
    
    //----------------------------------------------------------------------
    // Фискальные регистраторы
    //----------------------------------------------------------------------
    // Номер чека
    const MessageCheckNumber = Rezult.CheckNumber
    // Номер смены
    const MessageSessionNumber = Rezult.SessionNumber
    // Количество символов в строке
    const MessageLineLength = Rezult.LineLength
    // Сумма наличных в ККМ
    const MessageAmount = Rezult.Amount
}

// Герерация GUID
export function guid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}

const getRegister = (name: string, price: number, quantity?: number): Register => {
    const register = {
        Register: {
            // Наименование товара 64 символа, Тег 1059
            Name: name,
            
            // Количество товара (3 знака после запятой), Тег 1023
            Quantity: quantity ?? 1,
            
            // Цена за шт. без скидки (2 знака после запятой)
            Price: price,
            
            // Конечная сумма строки с учетом всех скидок /наценок; (2 знака после запятой), Из нее расчет тега 1079
            Amount: price * (quantity ?? 1),
            
            // Отдел, по которому ведется продажа
            Department: 0,
            
            // НДС в процентах или ТЕГ НДС: 0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20 /120), 110 (НДС 10 /110), Тег 1043, Из нее расчет тега 1079
            Tax: 20,
            
            // Признак способа расчета. Тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
            // 1: 'ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)'
            // 2: 'ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)'
            // 3: 'АВАНС'
            // 4: 'ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)'
            // 5: 'ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )'
            // 6: 'ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)'
            // 7: 'ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )'
            SignMethodCalculation: 4,
            
            // Признак предмета расчета. Тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
            // 1: 'ТОВАР (наименование и иные сведения, описывающие товар)'
            // 2: 'ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)'
            // 3: 'РАБОТА (наименование и иные сведения, описывающие работу)'
            // 4: 'УСЛУГА (наименование и иные сведения, описывающие услугу)'
            // 5: 'СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)'
            // 6: 'ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)'
            // 7: 'ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)'
            // 8: 'ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)'
            // 9: 'ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)'
            // 10: 'ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)'
            // 11: 'АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)'
            // 12: 'СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение'
            // 13: 'ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение'
            // 14: 'ИМУЩЕСТВЕННОЕ ПРАВО' (передача имущественных прав)
            // 15: 'ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД'
            // 16: 'СТРАХОВЫЕ ВЗНОСЫ' (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
            // 17: 'ТОРГОВЫЙ СБОР' (суммы уплаченного торгового сбора)
            // 18: 'КУРОРТНЫЙ СБОР'
            // 19: 'ЗАЛОГ'
            // 20: 'РАСХОД' - суммы произведенных расходов в соответствии со статьей 346.16 Налогового кодекса Российской Федерации, уменьшающих доход
            // 21: 'ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ ИП' или 'ВЗНОСЫ НА ОПС ИП'
            // 22: 'ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ' или 'ВЗНОСЫ НА ОПС'
            // 23: 'ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ ИП' или 'ВЗНОСЫ НА ОМС ИП'
            // 24: 'ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ' или 'ВЗНОСЫ НА ОМС'
            // 25: 'ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ СОЦИАЛЬНОЕ СТРАХОВАНИЕ' или 'ВЗНОСЫ НА ОСС'
            // 26: 'ПЛАТЕЖ КАЗИНО' прием и выплата денежных средств при осуществлении казино и залами игровых автоматов расчетов с использованием обменных знаков игорного заведения
            SignCalculationObject: 1,
        }
    }

    return register
}

const getCheckStringsAndCash = (parts: Part[]) => {
    const CheckStrings: Register[] = []
    let Cash = 0

    parts.forEach(part => {
        const priceMap = new Map<number, number>()

        // Учитываем цену основной детали
        if (part.isSold) {
            priceMap.set(part.price, (priceMap.get(part.price) ?? 0) + 1)
            Cash += part.price
        }

        // Учитываем цены дополнительных проданных деталей
        part.extraData?.soldQtyArr?.forEach(item => {
            priceMap.set(item.price, (priceMap.get(item.price) ?? 0) + 1)
            Cash += item.price
        })

        // Добавляем записи в CheckStrings для каждой уникальной цены
        priceMap.forEach((quantity, price) => {
            CheckStrings.push(getRegister(part.customTitle, price, quantity))
        })
    })

    return { CheckStrings, Cash }
}

const getCashierName = (cashier: Cashier) => `${cashier.familyName} ${cashier.name} ${cashier.surname}`

export function RegisterCheckByTitleAndPrice(title: string, price: number, cashier?: Cashier, NumDevice?: any, TypeCheck?: any, IsBarCode?: any, Print?: any) {
    const Data = {
        // Команда серверу
        Command: 'RegisterCheck',

        //***********************************************************************************************************
        // ПОЛЯ ПОИСКА УСТРОЙСТВА
        //***********************************************************************************************************
        
        // Номер устройства. Если 0 то первое не блокированное на сервере
        // Не рекомендовано для использования. 
        // Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
        NumDevice: 0,

        // ИНН ККМ для поиска. Если '' то ККМ ищется только по NumDevice,
        // Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
        InnKkm: '',
        
        //---------------------------------------------
        // Заводской номер ККМ для поиска. Если '' то ККМ ищется только по NumDevice,
        KktNumber: '',
        // **********************************************************************************************************
        
        // Уникальный идентификатор команды. Любая строка из 40 символов - должна быть уникальна для каждой подаваемой команды
        // По этому идентификатору можно запросить результат выполнения команды
        // Поле не обязательно
        IdCommand: guid(),
        
        // Это фискальный или не фискальный чек
        IsFiscalCheck: true,
        
        // Тип чека, Тег 1054;
        // 0 – продажа/приход;                                      10 – покупка/расход;
        // 1 – возврат продажи/прихода;                             11 - возврат покупки/расхода;
        // 2 – корректировка продажи/прихода;                       12 – корректировка покупки/расхода;
        // 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1)  13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
        TypeCheck: 0,
        
        // Количество копий документа
        NumberCopies: 0,
        
        // Продавец, Тег ОФД 1021
        CashierName: cashier ? getCashierName(cashier) : 'Надо указать кассира',

        // Строки чека
        CheckStrings: [
            getRegister(title, price)
        ],
        // Наличная оплата (2 знака после запятой), Тег 1031
        Cash: Number((price).toFixed(2)),
    }
    // Вызов команды
    ExecuteCommand(Data)
}

// Печать чека
export function RegisterCheckByParts(parts: Part[], cashier?: Cashier, NumDevice?: any, TypeCheck?: any, IsBarCode?: any, Print?: any) {
    const { CheckStrings, Cash } = getCheckStringsAndCash(parts)

    const Data = {
        // Команда серверу
        Command: 'RegisterCheck',

        //***********************************************************************************************************
        // ПОЛЯ ПОИСКА УСТРОЙСТВА
        //***********************************************************************************************************
        
        // Номер устройства. Если 0 то первое не блокированное на сервере
        // Не рекомендовано для использования. 
        // Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
        NumDevice: 0,

        // ИНН ККМ для поиска. Если '' то ККМ ищется только по NumDevice,
        // Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
        InnKkm: '',
        
        //---------------------------------------------
        // Заводской номер ККМ для поиска. Если '' то ККМ ищется только по NumDevice,
        KktNumber: '',
        // **********************************************************************************************************
        
        // Уникальный идентификатор команды. Любая строка из 40 символов - должна быть уникальна для каждой подаваемой команды
        // По этому идентификатору можно запросить результат выполнения команды
        // Поле не обязательно
        IdCommand: guid(),
        
        // Это фискальный или не фискальный чек
        IsFiscalCheck: true,
        
        // Тип чека, Тег 1054;
        // 0 – продажа/приход;                                      10 – покупка/расход;
        // 1 – возврат продажи/прихода;                             11 - возврат покупки/расхода;
        // 2 – корректировка продажи/прихода;                       12 – корректировка покупки/расхода;
        // 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1)  13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
        TypeCheck: 0,
        
        // Количество копий документа
        NumberCopies: 0,
        
        // Продавец, Тег ОФД 1021
        CashierName: cashier ? getCashierName(cashier) : 'Надо указать кассира',

        // Строки чека
        CheckStrings,
        // Наличная оплата (2 знака после запятой), Тег 1031
        Cash: Number((Cash).toFixed(2)),
    }
    // Вызов команды
    ExecuteCommand(Data)
}
