import { PartType } from '../types/part/partTypes'

const translations = {
    front: 'Перед',
    rear: 'Зад',
    left: 'Лево',
    right: 'Право'
}

export const getFrontRearLeftRight = (partType: PartType) => {
    const text: string[] = []

    if (partType.front_rear) {
        text.push(translations[partType.front_rear])
    }
    if (partType.left_right) {
        text.push(translations[partType.left_right])
    }

    return text.join(', ')
}
