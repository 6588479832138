import React, { ReactElement } from 'react'
import styles from './styles.module.scss'

interface IProps {
    children: ReactElement | null
    widthInPixels?: number
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch'
    debug?: boolean
}

const SalesTableData = ({ children, widthInPixels, justifyContent, debug, alignItems }: IProps) => (
    <td
        className={`${debug ? styles.debug : ''}`}
        style={
            {
                width: widthInPixels ? widthInPixels + 'px' : 'auto',
                flexGrow: 1,
                justifyContent: justifyContent ? justifyContent : 'flex-start',
                alignItems: alignItems ? alignItems : 'center',
            }
        }
    >
        {children}
    </td>

)

export default SalesTableData
