import React from 'react'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import TableHeadRangeFilter from '../../_atoms/TableHeadRangeFilter/TableHeadRangeFilter'

interface IProps {
    initialFromVal: number | undefined,
    initialToVal: number | undefined
}

const TableHeadPartsCountRangeCars = ({ initialFromVal, initialToVal }: IProps) => (
    <TableHeadRangeFilter
        initialTableHead={'Запчасти'}
        step={1}
        rightInPixels={-16}
        initialFromVal={initialFromVal}
        initialToVal={initialToVal}
        widthInPixels={107}
        storeActionSetRange={storeDispatchTypes.setPartsCountRangeCars}
        justifyContent={'flex-end'}
    />
)

export default TableHeadPartsCountRangeCars
