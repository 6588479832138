import { Dispatch } from 'redux'
import axios from "axios"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { CatalogModification } from "../../../types/common/commonTypes"

let localModifications = {}
let localBodyTypes = {}
const getAllCarModifications = (searchTerm: string, markId?: string) => async (dispatch: Dispatch): Promise<void> => {
    const loadModifications = async () => {
        if (Object.keys(localModifications).length) {
            return
        }
        try {
            const models = await axios.get('https://api.stock-pro.net/catalog/data/modifications.json')
            if (models.data) {
                localModifications = models.data
            }
        } catch (e) {
            console.error('loadModifications error', e)
        }
    }
    const loadBodyTypes = async () => {
        if (Object.keys(localBodyTypes).length) {
            return
        }
        try {
            const res = await axios.get('https://api.stock-pro.net/catalog/data/body_types.json')
            if (res.data) {
                localBodyTypes = res.data
            }
        } catch (e) {
            console.error('loadBodyTypes error', e)
        }
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'modifications', value: true } })
    try {
        await loadModifications()
        await loadBodyTypes()
        const data: Record<string, CatalogModification[]> = localModifications
        const bodyTypes: Record<string, string> = localBodyTypes
        let modifications
        let filteredList
        for (const key in data) {
            if(key === markId || key === "1") {
                modifications = [...data[key]]
            }
        }

        if(modifications) {
            filteredList = modifications.filter((modification: CatalogModification) => modification.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            filteredList.sort((a, b) => a.name.localeCompare(b.name))

            if (Object.keys(bodyTypes).length) {
                filteredList.map(modification => modification.bodyTypeTitle = bodyTypes[String(modification.bodyTypeId)] || '')
            }
        }
        dispatch({ type: storeDispatchTypes.setAllModificationsCars, value: { allModifications: filteredList } })
        dispatch({ type: storeDispatchTypes.setFilteredModificationsCars, value: { filteredModifications: filteredList } })

    } catch (e) {
        console.error('load cars marks failed', e)
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'modifications', value: false } })
}

export default getAllCarModifications
