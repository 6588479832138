import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import styles from './styles.module.scss'
import redTriangle from '../../../assets/icons/redTriangle.png'
import { fontSizes, fontWeights } from '../../../constants/salesStyles'
import { AppDispatch } from '../../../modules/store/customDispatch'
import getAccountPaymentInfo from '../../../modules/redux/user/getAccountPaymentInfo'
import { useSelector } from '../../../modules/store/customSelector'


const SubscriptionExpiryBanner = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [daysRemaining, setDaysRemaining] = useState<undefined | number>()

    const paymentInfo = useSelector(state => state.userData.paymentInfo)

    const getPaymentDueText = () => {
        switch (daysRemaining) {
            case 3:
                return <>До окончания вашей подписки осталось <b>3 дня</b>.</>
            case 2:
                return <>До окончания вашей подписки осталось <b>2 дня</b>.</>
            case 1:
                return <>Ваша подписка заканчивается <b>сегодня</b>.</>
            default:
                break
        }
    }

    useEffect(() => {
        dispatch(getAccountPaymentInfo())
    }, [])

    useEffect(() => {
        if (!paymentInfo) return

        const targetDate = moment(paymentInfo?.paymentDue)
        const currentDate = moment()
        setDaysRemaining(targetDate.diff(currentDate, 'days') + 1)
    }, [paymentInfo])

    if (!paymentInfo || !daysRemaining || daysRemaining > 3) return null

    return (
        <div className={styles.wrap}>
            <img src={redTriangle} alt='redTriangle' className={styles.icon}/>
            <div>
                <p style={{ fontSize: fontSizes.xs, fontWeight: fontWeights.normal }}>
                    {getPaymentDueText()} Ваш тариф {paymentInfo.paymentRemark}. Сумма оплаты {paymentInfo.paymentAmount} руб.
                    <br/>Пожалуйста оплатите по <a href={paymentInfo.paymentLink}>ссылке</a>.
                    <br/>Если вам необходим счет на безналичную оплату или обещанный платеж, свяжитесь с менеджером по телефону <a href='tel:+78006002504'>8 800 600 25 04</a>
                </p>
            </div>
        </div>
    )
}

export default SubscriptionExpiryBanner
