export interface SoldChartData {
    soldPartsByDay: SoldPartsByDay[],
    ticks: string[]
}

export const SoldChartDataDefaults: SoldChartData = {
    soldPartsByDay: [],
    ticks: [],
}

export interface SoldPartsByDay {
    date: string
    totalPrice: number
    totalQuantity: number
}
