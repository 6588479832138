import React, { useEffect } from 'react'
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'
import { useSelector } from '../../../modules/store/customSelector'
import SalesCard from "../SalesCard/SalesCard"
import SalesButtonStandard from "../SalesButtonStandard/SalesButtonStandard"
import SalesText from "../../_atoms/SalesText/SalesText"
import { colors } from "../../../constants/salesStyles"
import Loader from '../../_atoms/Loader/Loader'
import getHighlights from '../../../modules/redux/sales/getHighlights'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import { adminRouteAlias, getAdminNavigationPath } from "../../../router/adminRouteAlias"
import HoverOverlay from '../../_atoms/HoverOverlay/HoverOverlay'


const SalesCardHighlights = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector(state => state.sales.loaders.highlights)
    const highlights = useSelector(state => state.sales.highlights)
    const getHighlightsTotalCount = () => {
        const counts = highlights.map(h => h.totalCount)
        return Math.max(...counts) || undefined
    }

    const goToHighlight = (params?: string) => {
        navigate(getAdminNavigationPath(adminRouteAlias.parts.location) + (params && `?${params}`))
    }

    useEffect(() => {
        dispatch(getHighlights(false))
    }, [])

    return (
        <SalesCard   
            widthInPixels={1180}
            // heightInPixels={168}
            innerTitle={'Требуют внимания'}
            innerTitleCount={isLoading ? undefined : getHighlightsTotalCount()}
            marginBottomPixels={60}
        >
            {isLoading
                ? <Loader/>
                : (
                    <div className={styles.cardContentWrap}>
                        {highlights.map(item => (
                            <HoverOverlay tooltipText={item.call2action} key={item.alias} placement={'bottom'} preventWrapFullWidth>
                                <div>
                                    <SalesButtonStandard
                                        text={item.label}
                                        suffixElement={<SalesText text={String(item.count)} color={colors.purple}
                                            marginLeftPixels={10}/>}
                                        marginTopPixels={18}
                                        onClick={() => goToHighlight(item.params)}
                                        addButtonText={`на ${item.price.toLocaleString('RU-ru')} ₽`}
                                    />
                                </div>
                            </HoverOverlay>
                        ))}
                    </div>
                )
            }
        </SalesCard>
    )


}

export default SalesCardHighlights
