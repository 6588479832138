import { fieldTypes, valueTypes } from '../../../types/common/commonTypes'
import { partFields, partState } from '../../../types/part/partTypes'

export const initialPartState: partState = {
    fields: [
        {
            field: partFields.carId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            title: '',
            specialProps: {
                isRequired: true,
                renderValue: '',
                placeholder: 'выберите автомобиль',
                column: 1,
                isFullWidth: true,
            }
        },
    ],
}
