import React from 'react'
import { useNavigate } from "react-router"
import styles from './styles.module.scss'
import PALogo from '../../../assets/img/pa-expert-header.svg'
import PAText from '../../../assets/img/expert-text.png'

const LogoWithText = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.wrap} onClick={() => navigate('/')}>
            <img
                src={PALogo}
                alt="logo"
                className={styles.wrap__logo}
            />
            <div className={styles.wrap__textContainer}>
                <img
                    src={PAText}
                    alt="logo text"
                    className={styles.wrap__text}
                />
            </div>
        </div>
    )
}

export default LogoWithText
