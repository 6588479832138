import React, { forwardRef, useRef, useEffect, useState } from 'react'
import { XLg } from 'react-bootstrap-icons'
import styles from './styles.module.scss'
import HoverOverlay from '../HoverOverlay/HoverOverlay'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import { templateButton } from '../../../types/part/partTypes'
import SalesButtonStandard from '../../_molecules/SalesButtonStandard/SalesButtonStandard'
import useDebounce from '../../../hooks/useDebounce'

export interface SalesTextAreaRef {
  focus: () => void;
  // Add other methods or properties you need to access, if any.
}

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  fontSize?: fontSizes;
  fontWeight?: fontWeights;
  color?: colors;
  marginRightPixels?: number;
  marginLeftPixels?: number;
  marginTopPixels?: number;
  marginBottomPixels?: number;
  suffixElement?: React.ReactElement;
  disableAutoFocus?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  isHover?: boolean;
  minHeightInPixels?: number;
  maxLength?: number;
  templateButtons?: templateButton[];
  fillPlaceholderOnClick?: boolean;
  isClearButton?: boolean
  delay?: number
}

const SalesTextArea = forwardRef<SalesTextAreaRef, IProps>(
  (
    {
      value,
      onChangeValue,
      placeholder,
      fontSize,
      fontWeight,
      color,
      marginRightPixels,
      marginLeftPixels,
      marginTopPixels,
      marginBottomPixels,
      suffixElement,
      disableAutoFocus,
      isDisabled,
      errorMessage,
      minHeightInPixels,
      maxLength,
      templateButtons,
      fillPlaceholderOnClick,
      isClearButton,
      delay = 0,
    },
    ref
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const [autoFocus, setAutofocus] = React.useState(disableAutoFocus === undefined ? true : !disableAutoFocus)
    const [usePlaceholder, setUsePlaceholder] = useState(true)
    const [localValue, setLocalValue] = useState(value)

    const debouncedValue = useDebounce(localValue, delay)

    useEffect(() => {
      onChangeValue(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {
      if (value !== localValue) {
        setLocalValue(value)
      }
    }, [value])

    const fillPlaceholder = () => {
      if (!usePlaceholder) return
      if (fillPlaceholderOnClick && value === '' && placeholder) {
        setLocalValue(placeholder)
      }
    }

    const clearText = () => {
      setLocalValue('')
      setUsePlaceholder(false)
    }

    useEffect(() => {
      if (errorMessage) {
        // textareaRef.current?.setSelectionRange(value.length + 1, value.length + 1)
      }
    }, [errorMessage])

    // Method to focus the textarea
    const focus = () => {
      textareaRef.current?.focus()
    }

    React.useImperativeHandle(ref, () => ({
      focus,
      // Add other methods or properties you want to expose, if any.
    }))

    return (
      <div className={styles.wrap}>
        <div className={styles.textareaWrap} tabIndex={0}>
          <HoverOverlay tooltipText={errorMessage} maxWidthInPixels={300}>
            <>
              <textarea
                disabled={isDisabled}
                ref={textareaRef}
                autoFocus={autoFocus}
                value={localValue}
                onChange={(e) => {
                  e.preventDefault()
                  setLocalValue(e.target.value)
                }}
                onClick={fillPlaceholder}
                onFocus={() => setAutofocus(true)}
                onBlur={() => setAutofocus(false)}
                placeholder={placeholder}
                className={`${styles.textarea} ${isDisabled ? styles.disabled : ''} ${
                  errorMessage ? styles.error : ''
                } ${suffixElement ? styles.suffixPadding : ''} `}
                style={{
                  fontSize: fontSize ? fontSize : fontSizes.m,
                  fontWeight: fontWeight ? fontWeight : fontWeights.normal,
                  color: color ? color : colors.grayDisabled,
                  marginRight: marginRightPixels ? marginRightPixels + 'px' : 0,
                  marginLeft: marginLeftPixels ? marginLeftPixels + 'px' : 0,
                  marginTop: marginTopPixels ? marginTopPixels + 'px' : 0,
                  marginBottom: marginBottomPixels ? marginBottomPixels + 'px' : 0,
                  height: minHeightInPixels ? minHeightInPixels + 'px' : '92px',
                }}
              />
              {isClearButton &&
                <span onClick={clearText} className={styles.clearIcon} >
                  <XLg color={colors.purple} size={fontSizes.m}/>
                </span>
              }
              {maxLength ? (
                <div className={`${styles.valueLength} ${errorMessage ? styles.errorLength : ''}`}>
                  {`${localValue?.length || 0}/${maxLength}`}
                </div>
              ) : null}
            </>
          </HoverOverlay>
        </div>

        {templateButtons && templateButtons.length > 0 ? (
          <div className={styles.buttonsWrap}>
            {templateButtons.map((template: templateButton) => (
              <SalesButtonStandard
                key={template.title}
                text={template.title}
                onClick={() => setLocalValue(`${localValue} ${template.value}`)}
                marginBottomPixels={10}
              />
            ))}
          </div>
        ) : null}
      </div>
    )
  }
)

export default SalesTextArea
