import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

let prevController: AbortController | null = null

const searchPartListPartNumbers = (
    searchTerm: string,
    mainPartNumber?: string,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    // Отменить предыдущий запрос, если он существует
    if (prevController) {
        prevController.abort()
    }
    
    const controller = new AbortController()
    const { signal } = controller
    prevController = controller

    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        search: searchTerm,
        ...(mainPartNumber && { mainPartNumber }),
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` },
        signal
    }
    try {
        const data = await apiInstance.post('/getPartsSelectSearchPartNumber', params, config)
        if (data) {
            const { data: { status, partNumbers } } = data
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.setPartNumbersParts,
                    value: {
                        partNumbers
                    }
                })
            }
        }
    } catch (e) {
        console.error('load parts partNumbers failed', e)
    }
}

export default searchPartListPartNumbers
