import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { CatalogModification } from "../../../types/common/commonTypes"
import { RootState } from "../../store/rootReducer"

const getModificationYears = (searchTerm: string, modificationId?: string) => async (dispatch: Dispatch, getState: () => RootState,): Promise<void> => {
    const { carList: { filteredModifications } } = getState()

    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'modificationYears', value: true } })
    try {

        const modificationYears: number[] = []

        const modification = filteredModifications?.find((modificationObject: CatalogModification) => modificationObject.id === modificationId)

        if (modification) {
            const { yearFrom, yearTo } = modification
            let from = yearFrom
            while (from <= yearTo) {
                modificationYears.push(from)
                from++
            }
        }

        const modificationYearsFiltered = modificationYears.filter((year: number) => String(year).includes(searchTerm))
        dispatch({ type: storeDispatchTypes.setModificationYears, value: { modificationYears: modificationYearsFiltered } })

    } catch (e) {
        console.error('load cars marks failed', e)
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'modificationYears', value: false } })
}

export default getModificationYears
