import { useRef, useState } from 'react'
import { Files } from 'react-bootstrap-icons'
import { Overlay, Tooltip } from 'react-bootstrap'
import styles from './styles.module.scss'
import { colors, fontSizes } from '../../../constants/salesStyles'

type IProps = {
    copyText: string
    overlayText?: string
    isText?: boolean
}

const CopyButton = ({ copyText, overlayText, isText }: IProps) => {
    const [showCopy, setShowCopy] = useState(false)

    const copyButton = useRef(null)

    const copyVendorCode = () => {
        setShowCopy(true)
        setTimeout(() => {
            setShowCopy(false)
        }, 2000)
        navigator.clipboard.writeText(copyText)
    }

    return (
        <>
            {isText
            ? <span
                className={styles.copyText}
                onClick={copyVendorCode}
                ref={copyButton}
            >
                {copyText}
            </span>
            : <span
                className={styles.icon}
                onClick={copyVendorCode}
                ref={copyButton}
            >
                <Files
                    size={fontSizes.s}
                    color={colors.purple}
                />
            </span>}
            <Overlay target={copyButton.current} show={showCopy} placement="top">
                {(props) => (
                    <Tooltip id="overlay-error" {...props}>
                        {overlayText ? overlayText : 'Информация скопирована'}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}

export default CopyButton
