import React from 'react'
import styles from './styles.module.scss'
import { useGetSmsPhoneNumber } from "../../../hooks/user/useAuth"
import { useLogout } from "../../../hooks/user/useLogout"
import avatarPlaceholder from '../../../assets/icons/imagePlaceholder.svg'
import CustomLink from '../../_atoms/CustomLink/CustomLink'
import useHoverHandler from "../../../hooks/sales/useHoverHandler"
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'

const HeaderProfile = () => {
    const [isHover, hoverOn, hoverOff] = useHoverHandler()
    const phoneNumber = useGetSmsPhoneNumber()
    const logout = useLogout()
    const authorizedLinks = [
        { text: `Контакты`, href: getAdminNavigationPath(adminRouteAlias.shop.location), },
        // { text: `XML Данные`, href: '/', onClick: logout, target: '_blank' },
        { text: `Выход`, href: '/', onClick: logout, target: '_self' },
    ]

    return (
        <div
            className={`${styles.wrap} ${isHover ? styles.wrapWithBorder : ''}`}
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
        >
            <div className={styles.userInfoWrap}>
                <span>{phoneNumber}</span>
                <img src={avatarPlaceholder} alt="user avatar placeholder" className={styles.avatar}/>
            </div>
            {!isHover
                ? null
                : <div className={styles.menuItemsWrap}>
                    {authorizedLinks.map((item) => (
                        <div
                            className={styles.menuItem}
                            key={item.text}
                            onClick={hoverOff}
                        >
                            <CustomLink
                                text={item.text}
                                href={item.href}
                                onClick={item?.onClick}
                                target={item?.target}
                            />
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default HeaderProfile
