import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const getCarsSelectSearchCarTitleBodyTypeVINcode = (
    searchTerm: string
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        search: searchTerm,
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'cars', value: true } })
    try {
        const { data: { status, carList } } = await apiInstance.post('/getCarsSelectSearchCarTitleBodyTypeVINcode', params, config)
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setCars,
                value: {
                    cars: carList
                }
            })
        }
    } catch (e) {
        console.error('load cars title failed', e)
    }
    dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'cars', value: false } })
}

export default getCarsSelectSearchCarTitleBodyTypeVINcode
