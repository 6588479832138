import { ApiCarCreateUpdate } from "../../types/car/carTypes"

export const initialApiCarObject: ApiCarCreateUpdate = {
    id: '',
    markId: 0,
    modelId: 0,
    modificationId: 0,
    bodyTypeId: 0,
    year: 0,
    vinCode: '',
    price: 0,
    description: '',
    mileage: 0,
    isArchived: false,
    isDeleted: false,
    userId: '',
    descTemplate: ''
}
