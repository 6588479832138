import { Dispatch } from 'redux'

import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setSellPartModal =
    (modalState: { partId: string; isOpen: boolean }) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: storeDispatchTypes.setSellPartModal,
            value: {
                partId: modalState.partId,
                isOpen: modalState.isOpen,
            },
        })
    }

export default setSellPartModal
