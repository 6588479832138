import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from "../../../modules/store/customSelector"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'
import searchPartListPartNumbers from '../../../modules/redux/partList/SearchPartListPartNumbers'


const TableHeadPartNumberSearchParts = () => {
    const dispatch = useDispatch()
    const partNumbers = useSelector(state => state.partList.partNumbers)
    const partNumberFilter = useSelector((state) => state.partList.filters.partNumber)

    const onClear = () => {
        dispatch({
            type: storeDispatchTypes.setPartNumbersParts,
            value: {
                partNumbers: []
            }
        })
    }

    return (
        <TableHeadSelectSearch
            slice={'partList'}
            tableHead={'OEM'}
            placeholder={'Введите ОЕМ номер'}
            actionType={storeDispatchTypes.setPartNumberFilterParts}
            options={partNumbers}
            filterId={partNumberFilter}
            searchOptions={searchPartListPartNumbers}
            minSearchLength={3}
            allowEmptySearch={false}
            onClear={onClear}
        />
    )
}

export default TableHeadPartNumberSearchParts

