import React from 'react'
import { useSelector } from "../../../modules/store/customSelector"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import searchPartListModels from "../../../modules/redux/partList/SearchPartListModels"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'


const TableHeadModelSearchParts = () => {
    const models = useSelector(state => state.partList.models)
    const modelIdFilter = useSelector((state) => state.partList.filters.modelId)

    return (
        <TableHeadSelectSearch
            slice={'partList'}
            tableHead={'Модель'}
            placeholder={'Выберите модель'}
            actionType={storeDispatchTypes.setModelFilterParts}
            options={models}
            filterId={modelIdFilter}
            searchOptions={searchPartListModels}
        />
    )
}

export default TableHeadModelSearchParts

