import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'


const getMarketplacesContact = () => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'marketplacesContact', value: true } })
    try {
        const { data: { status, data } } = await apiInstance.post('/sales/marketplaces/getMarketplacesContact', params, config)
        if(status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setMarketplacesContact,
                value: {
                    contact: data
                },
            })
        }
    } catch (e) {
        console.error('get marketPlaces contact failed', e)
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'marketplacesContact', value: false } })

}


export default getMarketplacesContact
