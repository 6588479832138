import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiBilling } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const getIsActive = () => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        uid:  userData.smsAuth.userId,
        svc: "access",

    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    try {
        const { data: { status, data } } = await apiBilling.post('/services/checkActiveService', params, config)
        if(status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setIsActive,
                value: {
                    isActive: data.active
                },
            })
        }
    } catch (e) {
        console.error('get active status failed', e)
    }

}

export default getIsActive
