import { Reducer } from 'redux'
import firebase from "firebase/compat/app"
import { storeDispatchTypes } from "../storeDispatchTypes"
import { IUserTask } from '../../../types/tasks/tasksTypes'

export interface Cashier {
    name: string
    familyName: string
    surname: string
}

export interface TasksInfoType {
    onboarding?: IUserTask
}

export type TaskKeys = keyof TasksInfoType

interface stateType {
    user: firebase.User | undefined
    smsAuth: {
        userId?: string,
        phoneNumber?: string
        isLoading: boolean,
        isCodeSent: boolean,
        // milliseconds
        countDownResend: number,
        authErrorMessage: string,
        isRefreshing: boolean,
    },
    isActive: boolean,
    paymentInfo?: {
        paymentMethod: string
        paymentDue: string
        paymentLink: string
        paymentRemark: string
        paymentAmount: string
    }
    cashierInfo?: {
        connectCashbox: boolean
        cashier: Cashier
    }
    tasksInfo?: TasksInfoType
}

const initialState = {
    user: undefined,
    smsAuth: {
        isLoading: false,
        isCodeSent: false,
        countDownResend: 45000,
        authErrorMessage: '',
        userId: localStorage.getItem('userId') || undefined,
        phoneNumber: localStorage.getItem('phoneNumber') || undefined,
        isRefreshing: false,
    },
    isActive: true,
}

const createUserReducer: Reducer<stateType> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
    case storeDispatchTypes.setUserAuthData: {
        return { ...state, user: action.value }
    }
    case storeDispatchTypes.setUserIdSms: {
        return { ...state, smsAuth: { ...state.smsAuth, userId: action.value.userId } }
    }
    case storeDispatchTypes.setUserPhoneNumberSms: {
        return { ...state, smsAuth: { ...state.smsAuth, phoneNumber: action.value.phoneNumber } }
    }
    case storeDispatchTypes.setAuthErrorSms: {
        return { ...state, smsAuth: { ...state.smsAuth, authErrorMessage: action.value.msg } }
    }
    case storeDispatchTypes.setIsLoadingSms : {
        return { ...state, smsAuth: { ...state.smsAuth, isLoading: action.value.isLoading } }
    }
    case storeDispatchTypes.setCountDownResendSms : {
        return { ...state, smsAuth: { ...state.smsAuth, countDownResend: action.value.countDownResend } }
    }
    case storeDispatchTypes.setCodeSentSms : {
        return { ...state, smsAuth: { ...state.smsAuth, isCodeSent: action.value.isCodeSent } }
    }
    case storeDispatchTypes.setIsRefreshingSms : {
        return { ...state, smsAuth: { ...state.smsAuth, isRefreshing: action.value.isRefreshing } }
    }
    case storeDispatchTypes.setIsActive : {
        return { ...state, isActive: action.value.isActive }
    }
    case storeDispatchTypes.setAccountPaymentInfo : {
        return { ...state, paymentInfo: action.value.paymentInfo }
    }
    case storeDispatchTypes.setAccountCashierInfo : {
        return { ...state, cashierInfo: action.value.cashierInfo }
    }
    case storeDispatchTypes.setAccountTaskInfo: {
        return {
            ...state,
            tasksInfo: {
                ...state.tasksInfo,
                [action.value.code]: action.value.task
            }
        }
    }
    default: {
        return state
    }
    }
}

export default createUserReducer
