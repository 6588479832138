interface Sortable {
    [key: string]: any
}

export function sortByField<T extends Sortable>(arr: T[], fieldName: keyof T): T[] {
    return arr.slice().sort((a, b) => {
        if (a[fieldName] < b[fieldName]) {
            return -1
        }
        if (a[fieldName] > b[fieldName]) {
            return 1
        }
        return 0
    })
}
