import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import { Car } from '../../../types/car/carTypes'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import Loader from '../../_atoms/Loader/Loader'
import { AppDispatch } from '../../../modules/store/customDispatch'
import setCarDeletedStatus from '../../../modules/redux/carList/SetCarDeletedStatus'


interface IProps {
    car: Car
    isOpen: boolean
    closeModal: () => void
}

const ModalDeleteCar = ({ car, isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const [isLoading, setIsLoading] = useState(false)

    const onCloseModal = () => {
        closeModal()
    }

    const confirm = async () => {
        setIsLoading(true)
        await dispatch(setCarDeletedStatus(car.id))
        setIsLoading(false)
        closeModal()
    }

    return (
        <Modal
            show={isOpen}
            onHide={onCloseModal} 
            centered
            dialogClassName={styles.modal}
        >
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Удалить?</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>К этому авто прикреплены запчасти. Они тоже удалятся</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={confirm}>Удалить</Button>
                <Button variant='primary' onClick={onCloseModal}>Отмена</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalDeleteCar
