import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import TableHeadRangeFilter from '../../_atoms/TableHeadRangeFilter/TableHeadRangeFilter'

interface IProps {
    initialFromVal: number | undefined,
    initialToVal: number | undefined
}

const TableHeadPriceRangeParts = ({ initialFromVal, initialToVal }: IProps) => (
    <TableHeadRangeFilter
        initialTableHead={'Цена'}
        step={1000}
        leftInPixes={-35}
        initialFromVal={initialFromVal}
        initialToVal={initialToVal}
        widthInPixels={150}
        storeActionSetRange={storeDispatchTypes.setPriceRangeParts}
        isColumnSort
    />
)

export default TableHeadPriceRangeParts
