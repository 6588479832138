import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

let prevController: AbortController | null = null

const SearchPartListPartNumbersWithDescription = (
    searchTerm: string,
    partId?: string,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    // Отменить предыдущий запрос, если он существует
    if (prevController) {
        prevController.abort()
    }
    
    const controller = new AbortController()
    const { signal } = controller
    prevController = controller

    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        search: searchTerm,
        partId,
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` },
        signal
    }
    dispatch({
        type: storeDispatchTypes.setPartsLoader,
        value: { field: 'partNumbers', value: true }
    })
    try {
        const data = await apiInstance.post('/getPartsSelectSearchPartNumberWithDescription', params, config)
        if (data) {
            const { data: { status, partNumbers } } = data
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.setPartNumbersPartsWithDescription,
                    value: {
                        partNumbers
                    }
                })
            }
        }
    } catch (e) {
        console.error('load parts partNumbers failed', e)
    } finally {
        dispatch({
            type: storeDispatchTypes.setPartsLoader,
            value: { field: 'partNumbers', value: false }
        })
    }
}

export default SearchPartListPartNumbersWithDescription
