import { ApiPartCreateUpdate } from "../../types/part/partTypes"

export const initialApiPartObject: ApiPartCreateUpdate = {
    id: '',
    partNumber: '',
    customTitle: '',
    price: 0,
    conditionId: 0,
    groupId: 0,
    typeId: 0,
    year: 0,
    userId: '',
    markId: 0,
    modelId: 0,
    modificationId: 0,
    bodyTypeId: 0,
    description: '',
    carId: '',
    isArchived: false,
    isSold: false,
    isDeleted: false,
    vendorCode: '',
}
