import { Dispatch } from 'redux'
import { apiCatalog } from '../../api/apiInstance'
import { SelectOptionLocal } from "../../../types/common/commonTypes"
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllPartBrands = (searchTerm: string) => async (dispatch: Dispatch): Promise<void> => {

    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'partBrands', value: true } })

    try {
        const { data: { status, data } } = await apiCatalog.get('/avito/getPartsBrands')
        const partBrands: SelectOptionLocal[] = []

        if (status === 'ok') {
            for (let i = 0; i < data.length; i++) {
                const brandName = data[i]
                if (brandName && brandName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    partBrands.push({ name: brandName, value: brandName })
                }
            }

        }

        dispatch({ type: storeDispatchTypes.setAllPartBrands, value: { partBrands } })

    } catch (e) {
        console.error('load part brands failed', e)
    }

    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'partBrands', value: false } })

}

export default getAllPartBrands
