import { Button, Form, Modal, Col, Row } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from "./styles.module.scss"
import { useSelector } from '../../../modules/store/customSelector'
import setVendorData from '../../../modules/redux/documentList/SetVendorData'
import OverlayWithText from "../../_atoms/OverlayWithText/OverlayWithText"
import Loader from '../../_atoms/Loader/Loader'

interface IProps {
    isOpen: boolean
    closeModal: () => void
}

const ModalDataVendorDocument = ({ isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.documentList.loaders.isVendorDataCreated)

    const [startingNumber, setStartingNumber] = useState<number | undefined>()
    const [vendorName, setVendorName] = useState('')
    const [vendorITN, setVendorITN] = useState<number | undefined>()
    const [vendorAddress, setVendorAddress] = useState('')
    const [vendorTelephone, setVendorTelephone] = useState('')

    const onCloseModal = () => {
        closeModal()
    }

    const confirmChange = async () => {
        const vendorData = {
            startingNumber,
            vendorName,
            vendorITN,
            vendorAddress,
            vendorTelephone,
        }
        const vendorDataEdit = dispatch(setVendorData(vendorData))
        Promise.all([vendorDataEdit]).then(() => {
            closeModal()
        })
    }

    return (
        <>
            <Modal
                className={styles.modalDataDocument}
                show={isOpen}
                onHide={onCloseModal}
                centered
            >
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Укажите данные для документов</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formNumber">
                            <Form.Label column sm="5">Стартовый номер</Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setStartingNumber(Number(e.target.value))} type='number'/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formVendorName">
                            <Form.Label column sm="5">Название продавца</Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setVendorName(e.target.value)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formVendorITN">
                            <Form.Label column sm="5">ИНН продавца</Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setVendorITN(Number(e.target.value))} type='number'/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formVendorAddress">
                            <Form.Label column sm="5">Адрес продавца</Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setVendorAddress(e.target.value)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formVendorTelephone">
                            <Form.Label column sm="5">Телефон продавца</Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setVendorTelephone(e.target.value)}/>
                            </Col>
                        </Form.Group>
                        <div className='d-flex justify-content-end'>
                            <Button onClick={confirmChange}>Сохранить</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalDataVendorDocument
