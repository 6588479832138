import { colors, fontWeights } from '../../constants/salesStyles'
import { carFields } from '../car/carTypes'
import { RangeInput, SortFieldParts, SortOrder, fieldTypes, valueTypes } from '../common/commonTypes'

export type PartImage = {
    id: string
    publicUrl: string
    publicUrl_200x150?: string
    publicUrl_420x250?: string
    index: number
    cloudPath?: string
    cloudLongUrl?: string
}

export type Image = {
    id: string
    file: File
    index: number
}

export type SoldPart = {
    price: number
    marketplace: string
    soldAt: number | string
}

export type PartExtraData = {
    RimDIA?: number | string // 13002
    RimType?: number | string // 13002, 13000
    RimBolts?: number | string // 13002, 13000
    RimWidth?: number | string // 13002
    RimOffset?: number | string // 13002
    RimDiameter?: number | string // 13002, 13000
    RimBoltsDiameter?: number | string // 13002, 13000
    Brand?: string // 13000
    TireModel?: string // 13000
    TireType?: number | string // 13000
    TireAspectRatio?: number | string // 13000
    TireSectionWidth?: number | string // 13000
    Quantity?: number | string
    ResidualTread?: number | string
    PartBrand?: string
    warehouse?: {
        id: string
        row?: string
        shelf?: string
        place?: string
    }
    marketplacesDest?: string[]
    qty?: number
    soldQtyArr?: SoldPart[]
    pricePurchase?: number
    additionalPartNumbers?: string[]
    height?: number
    width?: number
    length?: number
    weight?: number
    VideoUrl?: string
    position?: string
    avitoParams?: {
        Id?: string
    }
    OilBrand?: string // 255, 256, 257, 258, 260
    SAE?: string // 258, 260
    Volume?: string // 255, 256, 257, 258, 260
    ACEA?: string // 258, 260
    API?: string // 258, 260
    OEMOil?: string // 255, 256, 257, 258, 260
    Color?: string // 255
    ASTM?: string // 255
    DOT?: string // 256
}

export type CarData = {
    markId: number
    modelId: number
    modificationId: number
    year: number
    bodyTypeId: number
}

export type PartTypeData = {
    groupId: number
    typeId: number
    extraData?: PartExtraData
}

export type Part = {
    bodyTypeId: number
    bodyTypeTitle: string
    carId?: number | string
    carPartTitle: string
    carVinCode?: string
    customTitle: string
    conditionId: number
    createdAt: string
    description: string
    generationTitle: string
    groupId: number
    groupTitle: string
    id: string
    images: PartImage[]
    isArchived: boolean
    isDeleted: boolean
    isSold: boolean
    markId: number
    markTitle: string
    modelId: number
    modelTitle: string
    modificationId: number
    modificationTitle: string
    partNumber: string
    price: number
    priceRcmd: number
    soldAt?: number | string
    title: string
    typeId: number
    updatedAt: string
    userId: string
    year: number
    vendorCode: string
    vendorCodePrefix: string
    position: string | null
    extraData?: PartExtraData
    incomingInvoiceTitle?: string
    pendingRemoval?: boolean // чтобы отслеживать, какие запчасти убирать из списка
}

export interface IPriceChartBar {
    value: number
    isFirst?: boolean
    isLast?: boolean
    count: number
    range: {
        max: number
        min: number
    }
    label?: {
        color: string
        value: string
    }
}

export interface IPriceChartResponse {
    q: {
        mark_id: number
        model_id: number
        sp_tid: number
        usrprice: number
    }
    rcmd_price: number
    res: IPriceChartBar[]
}

export enum partFields {
    partId = 'partId',
    partPrice = 'partPrice',
    partPricePurchase = 'partPricePurchase',
    conditionId = 'conditionId',
    groupId = 'groupId',
    typeId = 'typeId',
    year = 'year',
    userId = 'userId',
    markId = 'markId',
    modelId = 'modelId',
    modificationId = 'modificationId',
    bodyTypeId = 'bodyTypeId',
    description = 'description',
    carId = 'carId',
    isSold = 'isSold',
    isArchived = 'isArchived',
    isDeleted = 'isDeleted',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    useExistingCar = 'useExistingCar',
    partNumber = 'partNumber',
    customTitle = 'customTitle',
    RimDIA = 'RimDIA',
    RimType = 'RimType',
    RimBolts = 'RimBolts',
    RimWidth = 'RimWidth',
    RimOffset = 'RimOffset',
    RimDiameter = 'RimDiameter',
    RimBoltsDiameter = 'RimBoltsDiameter',
    Brand = 'Brand',
    PartBrand = 'PartBrand',
    TireModel = 'TireModel',
    TireType = 'TireType',
    TireAspectRatio = 'TireAspectRatio',
    TireSectionWidth = 'TireSectionWidth',
    partImages = 'partImages',
    noValue = 'noValue',
    warehouseTitle = 'warehouseTitle',
    warehouseRow = 'warehouseRow',
    warehouseShelf = 'warehouseShelf',
    warehousePlace = 'warehousePlace',
    marketplacesDest = 'marketplacesDest',
    descTemplate = 'descTemplate',
    vendorCode = 'vendorCode',
    ResidualTread = 'ResidualTread',
    Quantity = 'Quantity',
    IncomingInvoice = 'incomingInvoice',
    applicability = 'applicability',
    applicabilityButtons = 'applicabilityButtons',
    applicabilitySelected = 'applicabilitySelected',
    applicabilitySuggestion = 'applicabilitySuggestion',
    dimensionsAndWeight = 'dimensionsAndWeight',
    height = 'height',
    width = 'width',
    length = 'length',
    weight = 'weight',
    VideoUrl = 'VideoUrl',
    position = 'position',
    OilBrand = 'OilBrand',
    SAE = 'SAE',
    Volume = 'Volume',
    ACEA = 'ACEA',
    API = 'API',
    OEMOil = 'OEMOil',
    ATF = 'ATF',
    Color = 'Color',
    ASTM = 'ASTM',
    DOT = 'DOT',
}

export type fieldValuePair = {
    field: partFields | carFields
    value: number | string | boolean
}
export type fieldUpdateObject = {
    field: partFields | carFields
    value?: number | string | boolean
    specialPropsField?: partSpecialPropsFields
    specialPropsValue: number | string | boolean
}
export type templateButton = { title: string; value: string }

export type partSpecialProps = {
    hoverText?: string
    renderValue?: string
    isRequired?: boolean
    isVisibleEvery?: fieldValuePair[][] // Виден, если видны все
    isVisibleSwitch?: fieldValuePair[]
    isVisibleAny?: fieldValuePair[]
    fieldParent?: partFields | carFields
    fieldParentValue?: string | boolean | number
    fieldChild?: partFields | carFields
    isDisabled?: boolean
    placeholder?: string
    errorMessage?: string
    isSelect?: boolean
    switchRenderOptions?: string[]
    textareaMinHeight?: number
    textareaMaxLength?: number
    column?: number
    isTouched?: boolean
    isFullWidth?: boolean
    isTitleFullWidth?: boolean
    extraHandle?: 'wheels' | 'partImage' | 'carImage' | 'marketplacesDest' | 'partImages' | 'oils'
    templateButtons?: templateButton[]
    customWidth?: number
    checkboxItems?: partInput[]
    maxLength?: number
    borderTop?: boolean
    onClick?: () => void
    copyButton?: boolean
    printerButton?: boolean
    printerOnClick?: () => void
    isClearButton?: boolean
    href?: string
    avitoOverlayText?: string
    addedField?: partFields
    isAdded?: boolean
    maxAddedFields?: number
    applicabilityItems?: (ApplicabilityCreation | Applicability)[]
    applicabilitySuggestionItems?: Applicability[]
    addApplicability?: (applicability?: Applicability) => void
    isShowAccordionButton?: boolean
    onClickAddButton?: () => void
    rowHeight?: number
    borderColor?: colors
    fontWeight?: fontWeights
    additionalTitleText?: string
    additionalHref?: string
    alignItemsStretch?: boolean
    filterId?: string
    readOnly?: boolean
    readOnlyTextOverlowEllipsis?: true
}

export enum partSpecialPropsFields {
    hoverText = 'hoverText',
    renderValue = 'renderValue',
    isRequired = 'isRequired',
    fieldParent = 'fieldParent',
    fieldChild = 'fieldChild',
    fieldParentValue = 'fieldParentValue',
    isDisabled = 'isDisabled',
    placeholder = 'placeholder',
    errorMessage = 'errorMessage',
    isVisibleSwitch = 'isVisibleSwitch',
    isVisibleAny = 'isVisibleAny',
    textareaMinHeight = 'textareaMinHeight',
    textareaMaxLength = 'textareaMaxLength',
    column = 'column',
    isTouched = 'isTouched',
    isFullWidth = 'isFullWidth',
    extraHandle = 'extraHandle',
    templateButtons = 'templateButtons',
    onClick = 'onClick',
    printerOnClick = 'printerOnClick',
    checkboxItems = 'checkboxItems',
    avitoOverlayText = 'avitoOverlayText',
    addClick = 'addClick',
    deleteClick = 'deleteClick',
    applicabilityItems = 'applicabilityItems',
    applicabilitySuggestionItems = 'applicabilitySuggestionItems',
    addApplicability = 'addApplicability',
    onClickAddButton = 'onClickAddButton',
    additionalTitleText = 'additionalTitleText',
    additionalHref = 'additionalHref',
    filterId = 'filterId',
}

export type checkbox = {
    field: partFields | MarketplacesKeys
    value: boolean
}

export type partInput = {
    field: partFields | carFields | MarketplacesKeys
    fieldType: fieldTypes
    value?: string | number | boolean | File[] | checkbox[]
    valueType: valueTypes
    title: string
    specialProps?: partSpecialProps
}

export type partState = {
    fields: partInput[]
}

export enum partCreateUpdateActionTypes {
    updateField = 'updateField',
    updateManyFields = 'updateManyFields',
    initializeState = 'initializeState',
    updateSpecialProps = 'updateSpecialProps',
    addFiles = 'addFiles',
    removeFile = 'removeFile',
    reorderFiles = 'reorderFiles',
    addField = 'addField',
    deleteField = 'deleteField',
    fillDimensionsAndWeight = 'fillDimensionsAndWeight',
    addApplicabilityFromSuggestion = 'addApplicabilityFromSuggestion',
    returnApplicabilityToSuggestion = 'returnApplicabilityToSuggestion',
}

export type ApiPartCreateUpdate = {
    id: string
    partNumber: string
    customTitle: string
    price: number
    conditionId: number
    groupId: number
    typeId: number
    year: number
    userId: string
    markId: number
    modelId: number
    modificationId: number
    bodyTypeId: number
    description: string
    carId: string
    isArchived: boolean
    isSold: boolean
    isDeleted: boolean
    updatedAt?: string
    createdAt?: string
    extraData?: PartExtraData
    files?: File[]
    descTemplate?: string
    vendorCode?: string
}

export type PartListPageParams = {
    partId?: string
}

export type ApplicabilityCreation = {
    title: string
    markId: number
    modelId: number
    modificationId: number
    bodyTypeId: number
    year: number
}

export type Applicability = ApplicabilityCreation & {
    id: string
    userId: string
    partId: string[]
    partNumber: string[]
    typeId: number[]
    createdAt: number
    updatedAt: number
    isAdded?: boolean
}

export type PartListSearchParams = {
    pagination?: {
        page?: number
        perPage?: number
    }
    carId?: string[]
    modelId?: number[]
    markId?: number[]
    groupId?: number[]
    search?: string
    priceRange?: RangeInput
    yearRange?: RangeInput
    partId?: string[]
    partNumber?: string[]
    isSold?: boolean
    isArchived?: boolean
    isDraft?: boolean
    filterDate?: string
    filterDateField?: string
    warehouseId?: string
    dateRange?: RangeInput
    order?: [SortFieldParts, SortOrder][]
    withPrice?: boolean
    withPhoto?: boolean
    withDescription?: boolean
    withPartNumber?: boolean
    withMarkModelModificationAndTypeId?: boolean
    withCarId?: boolean
    withAvitoFullInfo?: boolean
    marketplace?: MarketplacesKeys
}

export type PartType = {
    height: number | null
    width: number | null
    length: number | null
    weight: number | null
    front_rear: 'front' | 'rear' | null
    left_right: 'left' | 'right' | null
}

export enum MarketplacesKeys {
    Avito = 'avito',
    Drom = 'drom',
    Yula = 'yula',
    Zzap = 'zzap',
    PaShop = 'paShop',
    Avtopiter = 'avtopiter',
    Avtoto = 'avtoto',
    Yandex = 'yandex',
    Ferio = 'ferio',
    Bibinet = 'bibinet',
    BamperBy = 'bamperBy',
    Japancar = 'japancar',
}

export const Marketplaces: Record<MarketplacesKeys, string> = {
    [MarketplacesKeys.Avito]: 'Авито',
    [MarketplacesKeys.Drom]: 'Дром',
    [MarketplacesKeys.Yandex]: 'Яндекс',
    [MarketplacesKeys.Zzap]: 'Zzap',
    [MarketplacesKeys.Ferio]: 'Ferio',
    [MarketplacesKeys.Bibinet]: 'Bibinet',
    [MarketplacesKeys.Yula]: 'Юла',
    [MarketplacesKeys.BamperBy]: 'Bamper.by',
    [MarketplacesKeys.Avtopiter]: 'Автопитер',
    [MarketplacesKeys.Avtoto]: 'Автото',
    [MarketplacesKeys.Japancar]: 'Japancar',
    [MarketplacesKeys.PaShop]: 'Интернет-магазин',
}
