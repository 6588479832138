import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"

const getApplicabilitySuggestion = (
    typeId: number,
    partNumber: string[],
    currentCar?: {
        markId: number,
        modelId: number,
        modificationId: number,
        year: number,
    } | string,
    partId?: string,
) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        },
    }
    const params = {
        userId: userData.smsAuth.userId,
        data: {
            partId,
            typeId,
            partNumber,
            currentCar,
        }
    }
    try {

        const { data: { status, data } } = await apiInstance.post('/getApplicabilitySuggestion', params , config)
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.setApplicabilitySuggestion,
                value: data,
            }))
        }

    } catch (e) {
        console.error('get applicability suggestion error', e)
    }
}

export default getApplicabilitySuggestion
