import React from 'react'
import { Pencil } from 'react-bootstrap-icons'
import styles from './styles.module.scss'
import { colors, fontSizes } from "../../../constants/salesStyles"
import useHoverHandler from "../../../hooks/sales/useHoverHandler"
import SalesRow from '../../_atoms/SalesRow/SalesRow'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesTableData from "../../_atoms/SalesTableData/SalesTableData"
import { apiMarketplacesInfoItem } from "../../../types/sales/salesTypes"
import CopyButton from '../../_atoms/CopyButton/CopyButton'

interface IProps {
    infoItem: apiMarketplacesInfoItem
    pencilOnClick: () => void
}

const SalesCardRowMarketplaces = ({ infoItem, pencilOnClick }: IProps) => {
    const [isHover, hoverOn, hoverOff] = useHoverHandler()

    const formateTime = (timestamp: string, type: string) => {
        const date = new Date(timestamp)
        const day = ("0" + date.getDate()).slice(-2)
        const month = ("0" + (date.getMonth() + 1)).slice(-2)
        const hours = ("0" + date.getHours()).slice(-2)
        const minutes = ("0" + date.getMinutes()).slice(-2)

        if (type === 'date') {
            return `${day}.${month}`
        } else {
            return `${hours}:${minutes}`
        }
    }

    const getDifference = (difference: number) => {
        if (difference > 0) {
            return `+${difference.toLocaleString('ru-RU')}`
        } else {
            return `${difference.toLocaleString('ru-RU')}`
        }
    }

    return (
        <SalesRow hoverOn={hoverOn} hoverOff={hoverOff}>
            <>
                <SalesTableData widthInPixels={50} justifyContent={'center'}>
                    <img className={styles.icon} src={infoItem.icon} alt={infoItem.alias + 'icon'}/>
                </SalesTableData>
                <SalesTableData widthInPixels={120}>
                    <>
                        {/* {isHover ? (
                            <a className={styles.rowLink} href={infoItem.link} target='_blank'>
                                <SalesText
                                    text={infoItem.title}
                                    color={isHover ? colors.purple : colors.blue}
                                />
                            </a>
                        )
                            : (
                                <SalesText
                                    text={infoItem.title}
                                    color={isHover ? colors.purple : colors.blue}
                                />
                            )
                        } */}

                        <SalesText
                            text={infoItem.title}
                            color={colors.blue}
                        />
                        {infoItem.link && <CopyButton copyText={infoItem.link} overlayText={`Ссылка на XML файл для ${infoItem.title} скопирована`}/>}
                    </>
                </SalesTableData>
                <SalesTableData widthInPixels={100} justifyContent={'flex-end'}>
                    <>
                        {infoItem.date &&
                        <>
                            <SalesText
                                text={formateTime(infoItem.date, 'date')}
                            />
                            <SalesText
                                text={formateTime(infoItem.date, 'time')}
                                fontSize={fontSizes.xs}
                                marginLeftPixels={4}
                                marginTopPixels={2}
                            />
                        </>}
                    </>
                </SalesTableData>
                <SalesTableData widthInPixels={100} justifyContent={'flex-end'}>
                    <SalesText
                        text={infoItem.publishedPartsCount ? infoItem.publishedPartsCount.toLocaleString('ru-RU') : '0'}
                        color={colors.black}
                    />
                </SalesTableData>
                <SalesTableData widthInPixels={60} justifyContent={'flex-start'}>
                    {infoItem.publishedPartsCount !== infoItem.previousCount ?
                        <SalesText
                            text={getDifference(infoItem.publishedPartsCount - infoItem.previousCount)}
                            marginLeftPixels={8}
                            color={infoItem.publishedPartsCount - infoItem.previousCount > 0 ? colors.green : colors.purple}
                        />
                        : <></>
                    }
                </SalesTableData>
                <SalesTableData widthInPixels={20} justifyContent={'flex-end'}>
                    <div className={styles.pencil} onClick={pencilOnClick}>
                        <Pencil color={colors.purple}/>
                    </div>
                </SalesTableData>
            </>
        </SalesRow>
    )
}

export default SalesCardRowMarketplaces
