export default  [
    { 
        value: 1,
        name: 'Россия',
        img: 'https://firebasestorage.googleapis.com/v0/b/rn-stockpro.appspot.com/o/systemFiles%2Fimages%2Fregions%2Frussia.png?alt=media&token=d028a324-f491-43c0-b05e-46feac63abb0'
    },
    {
        value: 2,
        name: 'Япония',
        img: 'https://firebasestorage.googleapis.com/v0/b/rn-stockpro.appspot.com/o/systemFiles%2Fimages%2Fregions%2Fjapan.png?alt=media&token=ce8374bf-362e-4d34-a6cc-343d2afa232b'
    },
    {
        value: 3,
        name: 'Европа',
        img: 'https://firebasestorage.googleapis.com/v0/b/rn-stockpro.appspot.com/o/systemFiles%2Fimages%2Fregions%2Feurope.png?alt=media&token=b14f3eb6-ad61-4ef6-ad24-f104f0f4d17b'
    },
    {
        value: 4,
        name: 'США',
        img: 'https://firebasestorage.googleapis.com/v0/b/rn-stockpro.appspot.com/o/systemFiles%2Fimages%2Fregions%2Fusa.png?alt=media&token=270ff3e8-8171-4be8-97d0-13b84c66f3dc'
    },
]
