import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { PartListSearchParams } from '../../../types/part/partTypes'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'

const updatePartsMarketplaces =
    (partsId: string[], marketplaces: Record<string, boolean>, params?: PartListSearchParams) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()

        try {
            await apiInstance.post(
                '/updatePartsMarketplaces',
                {
                    userId: userData.smsAuth.userId,
                    partsId,
                    marketplaces,
                    ...(params && { params }),
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )
        } catch (e) {
            console.error('updatePartsMarketplaces failed', e)
            throw e
        }
    }

export default updatePartsMarketplaces
