import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'

const getShopCategories = () => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        search: '',
        withPrice: true,
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'shopCategories', value: true } })

    try {
        const { data: { status, categories } } = await apiInstance.post('/getPartsSelectSearchCategory', params, config)
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setShopCategories,
                value: {
                    categories
                }
            })
        }

    } catch (e) {
        console.error('load  parts categories failed', e)
    }
    dispatch({ type: storeDispatchTypes.setSalesLoader, value: { field: 'shopCategories', value: false } })

}

export default getShopCategories
