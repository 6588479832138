import React from 'react'
import styles from "./styles.module.scss"
import { IPriceChartBar } from "../../../types/part/partTypes"
import { convertThousands } from '../../../utils/converters'


const ChartBar = ({ label, value, count, range, isFirst, isLast }: IPriceChartBar) => {
    const getRangeClass = () => {
        switch (true) {
        case isFirst:
            return `${styles.rangeBase} ${styles.rangeFull}`
        case  isLast:
            return `${styles.rangeBase} ${styles.rangeMaxLast}`
        default:
            return `${styles.rangeBase} ${styles.rangeMax}`
        }
    }

    const getPriceColor = () => label ? label.color : 'transparent'
    return (
        <div className={styles.wrap}>

            <div
                className={styles.labelPrice}
                style={{
                    color: getPriceColor(),
                }}>{label?.value}
            </div>
            <div
                className={styles.line}
                style={{
                    backgroundColor: getPriceColor(),
                    flexGrow: 1- value
                }}
            />

            <div
                className={styles.barWrap}
                style={{  flexGrow: value }}>
                <div className={styles.bar}>
                    {count
                        ? <div className={styles.barLabel}>
                            {count}
                        </div>
                        : null
                    }
                </div>

            </div>
            <div className={getRangeClass()}>
                {isFirst
                    ? <div>
                        {convertThousands(range.min)}
                    </div>
                    : null
                }
                <div>
                    {convertThousands(range.max)}
                </div>
            </div>
        </div>
    )
}

export default ChartBar
