import React, { useEffect } from 'react'
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import styles from './styles.module.scss'
import SalesCard from '../SalesCard/SalesCard'
import { useSelector } from "../../../modules/store/customSelector"
import Loader from "../../_atoms/Loader/Loader"
import getShopCategories from '../../../modules/redux/sales/getShopCategories'
import { apiPartCategory } from "../../../types/sales/salesTypes"
import SalesButtonStandard from "../SalesButtonStandard/SalesButtonStandard"
import SalesText from "../../_atoms/SalesText/SalesText"
import { colors } from "../../../constants/salesStyles"
import CategoryIcon from "../../_atoms/CategogyIcon/CategoryIcon"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import { adminRouteAlias, getAdminNavigationPath } from "../../../router/adminRouteAlias"
import getShopInfo from "../../../modules/redux/sales/getShopInfo"


const SalesCardShop = () => {
    const title = 'Витрина'
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector(state => state.sales.loaders.shopCategories)
    const categories = useSelector(state => state.sales.shop.categories)
    // const publishedPartsCount = useSelector(state => state.sales.shop.info.publishedPartsCount)
    const totalCount = useSelector(state => state.sales.userSummary.totalCount)

    useEffect(() => {
        dispatch(getShopCategories())
        dispatch(getShopInfo())
    }, [])

    const goToCategory = (categoryId: number) => {
        if (categoryId === -1) {
            navigate(getAdminNavigationPath(adminRouteAlias.parts.location + `?withCarId=false`))
        } else {
            navigate(getAdminNavigationPath(adminRouteAlias.parts.location + `?g=${categoryId}&priceFrom=1`))
        }
    }

    return (
        <SalesCard
            widthInPixels={570}
            outerTitle={title}
            innerSubtitle={totalCount > 0 ? "Объявлений опубликовано" : "Пока ничего не опубликовано"}
            innerSubtitleCount={totalCount > 0 ? totalCount : undefined}
            marginLeftPixels={40}
            footer={true}
            overflowY={'visible'}
            footerContent={
                <>
                    <SalesButtonStandard
                        text={"Контакты"}
                        onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.shop.location))}
                        backgroundColor={colors.white}
                    />
                    {totalCount > 0
                        ? <SalesButtonStandard
                            text={"Показать все"}
                            onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.parts.location))}
                            backgroundColor={colors.white}
                        />
                        : null
                    }

                </>

            }
        >
            {isLoading
                ? <Loader/>
                : <div className={styles.cardContentWrap}>
                    {
                        categories.map((category: apiPartCategory) => (
                            <SalesButtonStandard
                                text={category.title}
                                marginTopPixels={15}
                                prefixElement={category.id >= 0 ? <CategoryIcon categoryId={category.id}/> : undefined}
                                suffixElement={<SalesText text={String(category.count)} color={colors.purple}
                                    marginLeftPixels={10}/>}
                                onClick={() => goToCategory(category.id)}
                            />

                        ))
                    }
                </div>
            }
        </SalesCard>
    )
}

export default SalesCardShop
