import { Dispatch } from 'redux'
import { RootState } from '../../store/rootReducer'
import { apiTasksService } from '../../api/apiInstance'
import anchor from '../../../constants/anchor'
import { TaskKeys } from '../../store/user/userReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getUserTaskByCode = (code: TaskKeys) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        code: 'onboarding',
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    try {
        const { data } = await apiTasksService.post('/getUserTaskByCode', params, config)
        if (data) {
            dispatch({
                type: storeDispatchTypes.setAccountTaskInfo,
                value: {
                    code,
                    task: data.userTask
                }
            })
        }
    } catch(e) {
        console.error('get userTask by code failed', e)
    }
}

export default getUserTaskByCode
