import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { getOrderColumns } from '../../../constants/defaultCustomizableColumns'

const getUserActiveColumnsPartList = () => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
    }

    dispatch({
        type: storeDispatchTypes.setCustomizableColumnsLoading,
        value: true,
    })

    try {
        const { data: { status, data } } = await apiInstance.post('/getUserActiveColumnsPartList', params , config)

        if(status === 'ok' && data) {
            dispatch(({
                type: storeDispatchTypes.setCustomizableColumns,
                value: getOrderColumns(data.activeColumnsPartList),
            }))
        }
    } catch (e) {
        console.error('get user active columns failed', e)
    } finally {
        dispatch({
            type: storeDispatchTypes.setCustomizableColumnsLoading,
            value: false,
        })
    }
}

export default getUserActiveColumnsPartList
