import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import removeFromListDelay from '../../../constants/removeFromListDelay'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setPartDeletedStatus =
    (partId: string, newDeletedStatus = true) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        try {
            const {
                data: { status },
            } = await apiInstance.post(
                '/setPartDeletedStatus',
                {
                    userId: userData.smsAuth.userId,
                    partId,
                    newDeletedStatus,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.setPartDeletedStatus,
                    value: {
                        id: partId,
                        isDeleted: newDeletedStatus,
                    },
                })

                setTimeout(() => {
                    dispatch({
                        type: storeDispatchTypes.removeDeletedPartFormList,
                    })
                }, removeFromListDelay)
            }
        } catch (e) {
            console.error('set part deleted status failed', e)
        }
    }

export default setPartDeletedStatus
