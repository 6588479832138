import { Reducer } from 'redux'
import { storeDispatchTypes } from "../storeDispatchTypes"
import { Car } from "../../../types/car/carTypes"
import {
    ApiOption,
    CatalogMark,
    CatalogModel, CatalogModification,
    RangeInput,
    SelectOptionLocal,
    SortFieldCars,
    SortOrder
} from '../../../types/common/commonTypes'

interface CarList {
    data: Car[],
    total: number,
    dataCount: number,
}


enum RangeFields {
    partsCntRange = 'partsCntRange',
    priceRange = 'priceRange',
    mileageRange = 'mileageRange',
}

export interface stateTypeCars {
    carList: CarList;
    isLoading: boolean;
    loaders: {
        marks: boolean,
        models: boolean,
        modifications: boolean,
        bodyTypes: boolean,
        cars: boolean,
        modificationYears: boolean,
        isCarSaving: boolean,
        isCarCreated: boolean,
    }
    sortOrder: [SortFieldCars, SortOrder][],
    filters: {
        markId?: number;
        modelId?: number;
        modificationId?: number;
        bodyTypeId?: number;
        [RangeFields.partsCntRange]?: RangeInput;
        [RangeFields.priceRange]?: RangeInput;
        [RangeFields.mileageRange]?: RangeInput;
        page: number
    }
    cars: SelectOptionLocal[];
    marks: SelectOptionLocal[];
    models: SelectOptionLocal[];
    modifications: SelectOptionLocal[];
    bodyTypes: SelectOptionLocal[];
    modificationYears: SelectOptionLocal[];
    filteredModifications: CatalogModification[]
    bodyTypeCreateCar?: number
}

const initialState = {
    carList: { data: [], total: 0, dataCount: 0, },
    isLoading: false,
    loaders: {
        marks: false,
        models: false,
        modifications: false,
        bodyTypes: false,
        cars: false,
        modificationYears: false,
        isCarSaving: false,
        isCarCreated: false,
    },
    sortOrder: [],
    filters: { page:1 },
    cars: [],
    marks: [],
    models: [],
    modifications: [],
    bodyTypes: [],
    modificationYears: [],
    filteredModifications: [],
}

const createCarListReducer: Reducer<stateTypeCars> = (
    state = initialState,
    action,
) => {
    const getUpdatedPartIndex = (carId: string) => state.carList.data.findIndex(car => car.id === carId)
    const updateDataFieldById = (carId: string, field: string, value: string | number | boolean) => state.carList.data.map((car, index) => {
        if (index === getUpdatedPartIndex(carId)) {
            return { ...car, [field]: value }
        }
        return { ...car }
    })
    const setRangeByField = (field: RangeFields) => {
        const { from, to } = action.value
        if (from || to) {
            const newRange = {
                from: from ? Number(from) : undefined,
                to: to ? Number(to) : undefined
            }
            return { ...state, filters: { ...state.filters, [field]: newRange } }
        }
        const filters = { ...state.filters }
        if (field in filters) {
            delete filters[field]
        }
        return { ...state, filters }
    }
    const removeItemFromList = (field: 'isDeleted') => {
        const draftList = [...state.carList.data]
        const filteredList = draftList.filter(item => item[field] !== true)
        return { ...state, carList: { ...state.carList, data: filteredList } }
    }
    switch (action.type) {
    case storeDispatchTypes.setCarList: {
        return {
            ...state,
            carList: action.value,
        }
    }
    case storeDispatchTypes.setCarsLoading: {
        return {
            ...state,
            isLoading: action.value,
        }
    }
    case storeDispatchTypes.setCarDeletedStatus: {
        const draftData = updateDataFieldById(action.value.id, 'isDeleted', action.value.isDeleted)
        return { ...state, carList: { ...state.carList, data: draftData } }
    }
    case storeDispatchTypes.setMarkFilterCars: {
        return { ...state, filters: { ...state.filters, markId: action.value.filterId } }
    }
    case storeDispatchTypes.setCars: {
        const cars = action.value.cars?.map((mark: ApiOption) => ({
            value: mark.id,
            name: mark.title,
            subText: mark.bodyTypeTitle,
            badgeName: mark.vinCode ? `vin: ${mark.vinCode}` : '',
            customTitle: mark.customTitle,
        }))
        return { ...state, cars }
    }
    case storeDispatchTypes.setMarksCars: {
        const marks = action.value.marks?.map((mark: ApiOption) => ({ value: mark.id, name: mark.title }))
        return { ...state, marks }
    }
    case storeDispatchTypes.setAllMarksCars: {
        const marks = action.value.allMarks?.map((mark: CatalogMark) => ({ value: mark.id, name: mark.name }))
        return { ...state, marks }
    }
    case storeDispatchTypes.setModelFilterCars: {
        return { ...state, filters: { ...state.filters, modelId: action.value.filterId } }
    }
    case storeDispatchTypes.setModelsCars: {
        const models = action.value.models?.map((model: ApiOption) => ({ value: model.id, name: model.title }))
        return { ...state, models }
    }
    case storeDispatchTypes.setAllModelsCars: {
        const models = action.value.allModels?.map((model: CatalogModel) => ({ value: model.id, name: model.name }))
        return { ...state, models }
    }
    case storeDispatchTypes.setModificationFilterCars: {
        return { ...state, filters: { ...state.filters, modificationId: action.value.filterId } }
    }
    case storeDispatchTypes.setModificationsCars: {
        const modifications = action.value.modifications?.map((modification: ApiOption) => ({
            value: modification.id,
            name: modification.title
        }))
        return { ...state, modifications }
    }

    case storeDispatchTypes.setAllModificationsCars: {
        const modifications = action.value.allModifications?.map((model: CatalogModification) => ({
            value: model.id,
            name: model.name,
            subText: model.bodyTypeTitle,
        }))
        return { ...state, modifications }
    }
    case storeDispatchTypes.setFilteredModificationsCars: {
        return { ...state, filteredModifications: action.value.filteredModifications }
    }
    case storeDispatchTypes.setModificationYears: {
        const modificationYears = action.value.modificationYears?.map((year: number) => ({
            value: year,
            name: String(year)
        }))
        return { ...state, modificationYears }
    }
    case storeDispatchTypes.setBodyTypeCreateCar: {
        return { ...state, bodyTypeCreateCar: action.value.bodyTypeCreateCar }
    }
    case storeDispatchTypes.setBodyTypeFilterCars: {
        return { ...state, filters: { ...state.filters, bodyTypeId: action.value.filterId } }
    }
    case storeDispatchTypes.setBodyTypesCars: {
        const bodyTypes = action.value.bodyTypes?.map((bodyType: ApiOption) => ({
            value: bodyType.id,
            name: bodyType.title
        }))
        return { ...state, bodyTypes }
    }
    case storeDispatchTypes.setPartsCountRangeCars: {
        return setRangeByField(RangeFields.partsCntRange)
    }
    case storeDispatchTypes.setPriceRangeCars: {
        return setRangeByField(RangeFields.priceRange)
    }
    case storeDispatchTypes.setMileageRange: {
        return setRangeByField(RangeFields.mileageRange)
    }
    case storeDispatchTypes.setCarListSort: {
        const draftSortOrder = [...state.sortOrder]
        const currentSortOrderIndex = draftSortOrder.findIndex(item => item[0] === action.value.sortField)
        if (currentSortOrderIndex < 0) {
            draftSortOrder.push([action.value.sortField, action.value.sortOrder])
        } else {
            draftSortOrder[currentSortOrderIndex] = [action.value.sortField, action.value.sortOrder]
        }
        const finalSortOrder = draftSortOrder.filter(item => item[1] !== SortOrder.NONE)
        return { ...state, sortOrder: finalSortOrder }
    }
    case storeDispatchTypes.resetFiltersCars: {
        return { ...state, filters: { page: 1 } }
    }
    case storeDispatchTypes.removeDeletedCarFormList: {
        return removeItemFromList('isDeleted')
    }
    case storeDispatchTypes.seCarsLoader:
        return { ...state, loaders: { ...state.loaders, [action.value.field]: action.value.value } }
    case storeDispatchTypes.setPageFilterCars: {
        return { ...state, filters: { ...state.filters, page: action.value.page } }
    }
    case storeDispatchTypes.reorderCarImages: {
        const draftData = updateDataFieldById(action.value.id, 'images', action.value.images)
        return { ...state, carList: { ...state.carList, data: draftData } }
    }
    default: {
        return state
    }
    }
}

export default createCarListReducer
