import React from 'react'
import styles from "./styles.module.scss"
import { getPartStubImage } from "../../Images/getPartStubImage"

interface IProps {
    categoryId: number
    sizeInPixels?: number
}

const CategoryIcon = ({ categoryId, sizeInPixels }: IProps) => (
    <div className={styles.wrap}>
        <img
            src={getPartStubImage(categoryId)}
            alt="Иконка категории"
            style={{
                width: sizeInPixels ? sizeInPixels + 'px' : '30px',
                height: sizeInPixels ? sizeInPixels + 'px' : '30px',
            }}
        />
    </div>
)

export default CategoryIcon
