import React from 'react'
import { useSelector } from "../../../modules/store/customSelector"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from '../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch'
import searchCarListBodyTypes from "../../../modules/redux/carList/SearchCarListBodyTypes"


const TableHeadBodyTypeSearchCars = () => {
    const bodyTypes = useSelector(state => state.carList.bodyTypes)
    const bodyTypeId = useSelector((state) => state.carList.filters.bodyTypeId)

    return (
        <TableHeadSelectSearch
            slice={'carList'}
            tableHead={'Кузов'}
            placeholder={'Выберите тип кузова'}
            actionType={storeDispatchTypes.setBodyTypeFilterCars}
            options={bodyTypes}
            filterId={bodyTypeId}
            searchOptions={searchCarListBodyTypes}
        />
    )
}

export default TableHeadBodyTypeSearchCars

