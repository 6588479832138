import { useEffect, useState } from 'react'
import { XLg, Search } from 'react-bootstrap-icons'
import styles from './styles.module.scss'
import SalesInput from '../../_atoms/SalesInput/SalesInput'
import { colors, fontSizes } from '../../../constants/salesStyles'
import SalesText from '../../_atoms/SalesText/SalesText'

interface IProps {
    oldSearchTerm?: string
    setSearchTerm: (term: string) => void
    resultCount?: number
}

const SearchComponent = ({ oldSearchTerm, setSearchTerm, resultCount }: IProps) => {
    const [renderValue, setRenderValue] = useState<string>(oldSearchTerm ? oldSearchTerm : '')

    const handleClear = () => {
        setSearchTerm('')
        setRenderValue('')
    }

    useEffect(() => {
        setRenderValue(oldSearchTerm ? oldSearchTerm : '')
    }, [oldSearchTerm])

    return (
        <SalesInput
            value={renderValue}
            placeholder={'Поиск по названию, артикулу, OEM или Avito ID'}
            onChangeValue={setRenderValue}
            onSubmit={() => setSearchTerm(renderValue)}
            paddingRight={130}
            suffixElement={
                <>
                    {resultCount ? (
                        <SalesText
                            text={`${resultCount} рез`}
                            marginRightPixels={10}
                            color={colors.grayBorder}
                            fontSize={fontSizes.xxs}
                        />
                    ) : null}
                    {renderValue && (
                    <span className={styles.icon} onClick={handleClear}>
                        <XLg size={20} color={colors.purple} />
                    </span>)}
                    <span className={styles.icon} onClick={() => setSearchTerm(renderValue)}>
                        <Search size={20} color={colors.purple} />
                    </span>
                </>
            }
        />
    )
}

export default SearchComponent
