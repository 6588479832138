import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllOilsACEA =
    (searchTerm: string, typeId?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsACEA', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/OilsACEA', { typeId })
            const oilsACEA: SelectOptionLocal[] = []

            if (status === 'ok') {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const ACEANodes = xmlDoc.getElementsByTagName('ACEA')

                for (let i = 0; i < ACEANodes.length; i++) {
                    const ACEAName = ACEANodes[i].textContent?.trim()
                    if (ACEAName && ACEAName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        oilsACEA.push({ name: ACEAName, value: ACEAName })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllOilsACEA, value: { oilsACEA } })
        } catch (e) {
            console.error('load oilsACEA failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'oilsACEA', value: false } })
    }

export default getAllOilsACEA
