import React from 'react'
import styles from "./styles.module.scss"
import AppStore from '../../../assets/img/app-store.png'
import GooglePlay from '../../../assets/img/google-play.png'
import Mir from '../../../assets/img/mir.png'
import Sbp from '../../../assets/img/sbp.png'
import MasterCard from '../../../assets/img/mastercard.png'
import { appUrl } from '../../../constants/appUrl'
import SalesText from '../SalesText/SalesText'
import { colors, fontSizes, fontWeights } from "../../../constants/salesStyles"
import CustomLink from '../CustomLink/CustomLink'


const Footer = () => (
    <div className={styles.wrap}>
        <div className={styles.content}>
            <div className={styles.leftContainer}>
                <div className={styles.leftSubContainer}>
                    <div className={styles.textWrap}>
                        <SalesText
                            text={'PA Expert'}
                            fontWeight={fontWeights.semiBold}
                            // marginRightPixels={40}
                        />

                    </div>
                    <CustomLink
                        text={'О компании'}
                        href={'https://stock-pro.net/'}
                        target={"_blank"}
                    />

                    <CustomLink
                        text={'Контактная информация'}
                        href={'https://stock-pro.net/#kontacty'}
                        target={"_blank"}
                    />
                </div>
                <div className={styles.leftSubContainer}>
                    <SalesText
                        text={'Все права защищены'}
                        fontSize={fontSizes.s}
                        color={colors.grayLight}
                    />
                </div>

            </div>
            <div className={styles.rightContainer}>
                <div className={styles.rightSubContainer}>
                    <a href={appUrl.googlePlay} target='_blank' className={styles.storeWrap}>
                        <img src={GooglePlay} alt="google play icon" className={styles.store}/>
                    </a>
                    <a href={appUrl.appStore} target='_blank' className={styles.storeWrap}>
                        <img src={AppStore} alt="appstore icon" className={styles.store}/>
                    </a>
                </div>
                <div className={styles.rightSubContainer}>
                    <img src={Mir} alt="mir icon" className={styles.paySystem} style={{ height: '17px' }}/>
                    <img src={MasterCard} alt="mastercard icon" className={styles.paySystem} style={{ height: '22px' }}/>
                    <img src={Sbp} alt="spb icon" className={styles.paySystem} style={{ height: '27px' }}/>
                </div>

            </div>
        </div>

    </div>
)

export default Footer
