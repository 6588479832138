import React from 'react'
import SalesCardRowMarketplaces from "../SalesCardRowMarketplaces/SalesCardRowMarketplaces"
import { apiMarketplacesInfoItem } from "../../../types/sales/salesTypes"
import SalesText from '../../_atoms/SalesText/SalesText'

interface IProps {
    marketplaces: apiMarketplacesInfoItem[]
    limit?: number
    openModal: (marketplace: string) => void
}

const SalesCardTableMarketplaces = ({ marketplaces, limit, openModal }: IProps) => (
    <>
        {marketplaces.length > 0
            ?     <table>
                <tbody>
                    {
                    marketplaces
                    .filter(i => i.status != 'DISABLED')
                    .map((item, index) => {

                        if (limit && index >= limit) {
                            return null
                        } else {
                            return (
                                <SalesCardRowMarketplaces
                                    key={item.alias}
                                    infoItem={item}
                                    pencilOnClick={() => openModal(item.alias)}
                                />
                            )
                        }
                    })}
                </tbody>
            </table>
            : <SalesText
                text={"Подключайте маркетплейсы, чтобы ваше объявление увидело больше человек."}
                marginBottomPixels={110}
            />
        }
    </>


)

export default SalesCardTableMarketplaces
