import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { Car } from '../../../types/car/carTypes'

const getCarById = async (carId: string, userId: string): Promise<Car | undefined> => {
    try {
        const params = {
            userId,
            params: {
                pagination: {
                    page: 1,
                    perPage: 1,
                },
                carId: [carId],
            }
        }
        const config = {
            headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
        }
        const { data: { data } } = await apiInstance.post('/getCarsAdminList', params, config)
        return data[0]
    } catch (e) {
        console.error('load visible cars error', e)
    }

}

export default getCarById
