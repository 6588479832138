import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { CatalogPartGroup, SelectOptionLocal } from "../../../types/common/commonTypes"
import { apiCatalog } from '../../api/apiInstance'

const getAllPartGroups = () => async (dispatch: Dispatch): Promise<void> => {

    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'partGroups', value: true } })

    try {
        const { data } = await apiCatalog.get('/data/part_groups.json')
        const partGroups: SelectOptionLocal[] = []
        if (data) {
            data.map((group: CatalogPartGroup) => partGroups.push({ name: group.title, value: group.id }))
            partGroups.sort((a, b) => {
                const nameA = a.name.toLowerCase()
                const nameB = b.name.toLowerCase()

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
        }

        dispatch({ type: storeDispatchTypes.setAllPartGroups, value: { partGroups } })

    } catch (e) {
        console.error('load part groups failed', e)
    }

    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'partGroups', value: false } })
}

export default getAllPartGroups
