import React from 'react'
import styles from './styles.module.scss'
import { convertThousandsSoldParts } from "../../../utils/converters"

interface IProps {
    soldPercent: number
    carPrice: number
    sumSoldParts: number
    leftPartsSum: number
}

const yellow = '#EEA81E'
const green = '#088723'

const getSoldColor = (percent: number): string => {
    switch (true) {
    case percent > 30:
        return green
    default:
        return yellow
    }
}

const getSoldSumPercent = (soldSum: number, leftSum: number) => Math.round(soldSum / ((leftSum + soldSum) * 0.01))

const getSoldSumColor = (soldSumPercent: number): string => {
    switch (true) {
    case soldSumPercent > 30:
        return green
    default:
        return yellow
    }
}

const CellSoldPercent = ({ soldPercent, sumSoldParts, carPrice, leftPartsSum }: IProps) => {
    // const soldPercentColor = getSoldColor(soldPercent)
    // const soldSumColor = getSoldSumColor(getSoldSumPercent(sumSoldParts, leftPartsSum))
    const soldSumColor = sumSoldParts > carPrice ? green : yellow

    // Если нет продаж
    // if (!sumSoldParts) return <><span>0</span></>

    return (
        <>
            {carPrice > 1
                ? <span style={{ backgroundColor: soldSumColor }} className={styles.wrap}>
                    {sumSoldParts.toLocaleString('ru-RU')}
                </span>
                : <span>
                    {sumSoldParts.toLocaleString('ru-RU')}
                </span>
            }
        </>
    )
}

export default CellSoldPercent
