import React from 'react'
import styles from './styles.module.scss'
import SelectableComponent from '../SelectableComponent/SelectableComponent'
import { apiMarketplacesInfoItem } from '../../../types/sales/salesTypes'

interface SelectableMartketplaceListProps {
  componentData: apiMarketplacesInfoItem[];
  selectedComponent: string | null;
  onComponentClick: (index: string) => void;
}

const SelectableMartketplaceList: React.FC<SelectableMartketplaceListProps> = ({
  componentData,
  selectedComponent,
  onComponentClick,
}) => (
    <div className={styles.wrapperComponent}>
      {componentData.map((data) => (
        <SelectableComponent
          key={data.alias}
          imageSrc={data.icon}
          text={data.title}
          selected={selectedComponent == data.alias}
          onClick={() => onComponentClick(data.alias)}
        />
      ))}
    </div>
  )

export default SelectableMartketplaceList
