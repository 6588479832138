import React, { Dispatch } from 'react'
import { AnyAction } from "redux"
import SalesCardRowContacts from '../SalesCardRowContacts/SalesCardRowContacts'
import { contactInput } from "../../../types/sales/salesTypes"

interface IProps {
    contacts: contactInput[]
    dispatch: Dispatch<AnyAction>
    isEdited: boolean
    setIsEdited: (value:boolean) => void
}

const SalesTableMarketplacesContact = ({ contacts, dispatch, isEdited, setIsEdited }: IProps) => (<table>
    <tbody>
        {contacts.map((item, index) => (
            <SalesCardRowContacts
                key={item.field}
                input={item}
                isLast={index === contacts.length - 1}
                dispatch={dispatch}
                isEdited={isEdited}
                setIsEdited={setIsEdited}
            />
        ))}
    </tbody>
</table>)

export default SalesTableMarketplacesContact
