import React, { ReactElement } from 'react'
import { colors, fontSizes, fontWeights } from "../../../constants/salesStyles"
import SalesInput from "../../_atoms/SalesInput/SalesInput"
import SalesText from '../../_atoms/SalesText/SalesText'

interface IProps {
    isEditable: boolean
    renderValue: string
    onChangeValue: (value: string) => void
    placeholder?: string
    fontSize?: fontSizes
    fontWeight?: fontWeights
    color?: colors
    colorEditable?: colors
    marginRightPixels?: number
    marginLeftPixels?: number
    marginTopPixels?: number
    marginBottomPixels?: number
    suffixElement?: ReactElement
    disableAutoFocus?: boolean
    isDisabled?: boolean
    errorMessage?: string
    maxLength?: number
    onSubmit?: () => void
    backgroundColor?: colors
    inputPaddingRight?: number
}

const SalesTextEditable = ({
    isEditable,
    renderValue,
    onChangeValue,
    placeholder,
    fontSize,
    fontWeight,
    color,
    colorEditable,
    marginRightPixels,
    marginLeftPixels,
    marginTopPixels,
    marginBottomPixels,
    suffixElement,
    disableAutoFocus,
    isDisabled,
    errorMessage,
    maxLength,
    onSubmit,
    backgroundColor,
    inputPaddingRight,
}: IProps
) =>
    (
        <>
            {isEditable
                ? (<SalesInput
                    value={renderValue}
                    onChangeValue={onChangeValue}
                    placeholder={placeholder}
                    color={colorEditable}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    marginLeftPixels={marginLeftPixels}
                    marginRightPixels={marginRightPixels}
                    marginBottomPixels={marginBottomPixels}
                    marginTopPixels={marginTopPixels}
                    suffixElement={suffixElement}
                    disableAutoFocus={disableAutoFocus}
                    isDisabled={isDisabled}
                    errorMessage={errorMessage}
                    maxLength={maxLength}
                    onSubmit={onSubmit}
                    backgroundColor={backgroundColor}
                    paddingRight={inputPaddingRight}
                />)
                : (<SalesText
                    text={renderValue}
                    color={color}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    marginLeftPixels={marginLeftPixels}
                    marginRightPixels={marginRightPixels}
                    marginBottomPixels={marginBottomPixels}
                    marginTopPixels={marginTopPixels}
                />)

            }
        </>
    )

export default SalesTextEditable
