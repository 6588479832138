import { combineReducers } from "redux"
import createCarListReducer from "./carList/carListReducer"
import createUserReducer from "./user/userReducer"
import createPartListReducer from "./partList/partListReducer"
import createSalesReducer from "./sales/salesReducer"
import createWarehouseListReducer from "./warehouseList/warehouseListReducer"
import createDocumentListReducer from "./documentList/documentListReducer"
import createActionLogListReducer from "./actionLogList/actionLogListReducer"

const rootReducer = combineReducers({
    userData: createUserReducer,
    partList: createPartListReducer,
    warehouseList: createWarehouseListReducer,
    carList: createCarListReducer,
    sales: createSalesReducer,
    documentList: createDocumentListReducer,
    actionLogList: createActionLogListReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer
