import React from 'react'
import styles from './styles.module.scss'

interface IProps {
    text: string
    color: string
    fontColor?: string
}

const Badge = ({ text, color, fontColor = '' }: IProps) => {
    fontColor = fontColor || '#FFFFFF'
    return (
        <>
            <span style={{ backgroundColor: color, color: fontColor }} className={styles.wrap}>
                {text}
            </span>
        </>
    )
}

export default Badge
