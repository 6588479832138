import React from 'react'
import styles from './styles.module.scss'
import { PartImage } from "../../../types/part/partTypes"

interface IProps {
    picture: PartImage
}


const CellPicture = ({ picture }: IProps) => (
    <div className={styles.wrap}>
        <img
            src={picture.publicUrl_200x150}
            alt={''}
        />
    </div>
)

export default CellPicture
