import { Dispatch } from 'redux'
import { RootState } from '../../store/rootReducer'
import { apiDocument } from '../../api/apiInstance'
import anchor from '../../../constants/anchor'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'
import { Document } from '../../../types/document/documentTypes'

const getIncomingInvoicesByPartsId = (
    partsId: string[],
    getOne?: boolean,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void | Document> => {
    const { userData } = getState()

    try {
        const { data } = await apiDocument.post('/getIncomingInvoicesByPartsId', {
            userId: userData.smsAuth.userId,
            partsId,
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        if (data.data) {
            if (getOne) {
                dispatch({
                    type: storeDispatchTypes.setDocument,
                    value: data.data[0],
                })
                return data.data[0]
            }
            dispatch({
                type: storeDispatchTypes.updatePartsWithIncomingInvoicesTitle,
                value: data.data,
            })
        }
    } catch (e) {
        console.error('get incomingInvoices by partsId error', e)
        dispatch({
            type: storeDispatchTypes.setDocument,
            value: null,
        })
    }
}

export default getIncomingInvoicesByPartsId
