import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import TableHeadRangeFilter from '../../_atoms/TableHeadRangeFilter/TableHeadRangeFilter'

interface IProps {
    initialFromVal: number | undefined,
    initialToVal: number | undefined
}

const TableHeadMileageRangeCars = ({ initialFromVal, initialToVal }: IProps) => (
    <TableHeadRangeFilter
        initialTableHead={'Пробег'}
        step={10000}
        leftInPixes={-35}
        initialFromVal={initialFromVal}
        initialToVal={initialToVal}
        widthInPixels={150}
        storeActionSetRange={storeDispatchTypes.setMileageRange}
    />
)

export default TableHeadMileageRangeCars
