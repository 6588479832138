import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiDocument } from "../../api/apiInstance"
import removeFromListDelay from "../../../constants/removeFromListDelay"
import anchor from '../../../constants/anchor'
import { DocumentType } from '../../../types/document/documentTypes'

const setDocumentDeletedStatus = (
    documentId: string,
    type: DocumentType,
    newDeletedStatus = true,
) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        id: documentId,
        isDeleted: newDeletedStatus,
        documentType: type,
    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    try {
        const { data: { status } } = await apiDocument.post('/deleteDocument', params, config)
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.setDocumentDeletedStatus,
                value: {
                    id: documentId,
                    isDeleted: newDeletedStatus
                },
            }))
            setTimeout(() => {
                dispatch(({
                    type: storeDispatchTypes.removeDeletedDocumentFormList
                }))
            }, removeFromListDelay)
        }
    } catch (e) {
        console.error('set document deleted status failed', e)
    }
}

export default setDocumentDeletedStatus
