import { Dispatch } from 'redux'
import { RootState } from '../../store/rootReducer'
import { apiDocument } from '../../api/apiInstance'
import anchor from '../../../constants/anchor'
import { IncomingInvoiceCreation } from '../../../types/document/documentTypes'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const createIncomingInvoice = (
    documentObject: IncomingInvoiceCreation,
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        document: {
            ...documentObject,
            userId: userData.smsAuth.userId,
        },
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentCreated', value: true } })
    try {
        await apiDocument.post('/createIncomingInvoice', params, config)
    } catch(e) {
        console.error('save document failed', e)
    } finally {
        dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentCreated', value: false } })
    }
}

export default createIncomingInvoice
