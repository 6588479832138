import styles from "./styles.module.scss"
import { ReactComponent as PartsIcon } from '../../../assets/icons/part.svg'
import { ReactComponent as CarsIcon } from '../../../assets/icons/car.svg'
import { ReactComponent as SalesIcon } from '../../../assets/icons/sale.svg'
import { ReactComponent as WarehouseIcon } from '../../../assets/icons/warehouse.svg'
import { ReactComponent as FileLinesSolid } from '../../../assets/icons/fileLinesSolid.svg'
import { ReactComponent as ActionLog } from '../../../assets/icons/actionLog.svg'
import SidebarNavLinks from "../../_atoms/SidebarNavLinks/SidebarNavLinks"

const navLinks = [
    {
        location: "parts",
        name: 'Запчасти',
        icon: PartsIcon,
        slice: 'partList',
    },
    {
        location: "cars",
        name: 'Автомобили',
        icon: CarsIcon,
        slice: 'carList',
    },
    {
        location: "sales",
        name: 'Продажи',
        icon: SalesIcon,
        slice: 'sales',
    },
    {
        location: "warehouses",
        name: 'Cклады',
        icon: WarehouseIcon,
        slice: "warehousesList",
    },
    {
        location: "documents",
        name: 'Документы',
        icon: FileLinesSolid,
        slice: "documentsList",
    },
    {
        location: "actionLog",
        name: 'Журнал действий',
        icon: ActionLog,
        slice: "actionLog",
    },
]

const Sidebar = () => (
    <div className={styles.wrap}>
        <SidebarNavLinks navLinks={navLinks} />
    </div>
)

export default Sidebar
