import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { PlusLg } from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import gear from '../../assets/icons/gear.png'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ModalChangeCarData from '../../components/_molecules/ModalChangeCarData/ModalChangeCarData'
import ModalChangePartTypeData from '../../components/_molecules/ModalChangePartType/ModalChangePartType'
import ModalCustomizableColumns from '../../components/_molecules/ModalCustomizableColumns/ModalCustomizableColumns'
import ModalDataDocument from '../../components/_molecules/ModalDataDocument/ModalDataDocument'
import ModalEditPart from '../../components/_molecules/ModalEditPart/ModalEditPart'
import ModalIncomingInvoiceOpen from '../../components/_molecules/ModalIncomingInvoiceOpen/ModalIncomingInvoiceOpen'
import ModalLinkingToCar from '../../components/_molecules/ModalLinkingToCar/ModalLinkingToCar'
import ModalMarketplaces from '../../components/_molecules/ModalMarketplaces/ModalMarketplaces'
import ModalSellPart from '../../components/_molecules/ModalSellPart/ModalSellPart'
import ModalSellParts from '../../components/_molecules/ModalSellParts/ModalSellParts'
import NavigationButtons from '../../components/_molecules/NavigationButtons/NavigationButtons'
import SearchComponent from '../../components/_molecules/SearchComponent/SearchComponent'
import TablePartList from '../../components/_orgamisms/TablePartList/TablePartList'
import { pages } from '../../constants/parts/navigationButtonsPages'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import useResetFiltersOnUnmount from '../../hooks/partList/useResetFiltersOnUnmount'
import DatahubApiService from '../../modules/api/DatahubApiService'
import getCarById from '../../modules/redux/createUpdateForm/getCarById'
import getIncomingInvoicesByPartsId from '../../modules/redux/documentList/GetIncomingInvoicesByPartsId'
import GetPartsIdByDocumentId from '../../modules/redux/documentList/GetPartsIdByDocumentId'
import getPartsMarketplacesCount from '../../modules/redux/partList/GetPartsMarketplacesCount'
import loadPartList from '../../modules/redux/partList/LoadPartList'
import setPartArchivedStatus from '../../modules/redux/partList/setPartArchivedStatus'
import setSellPartModal from '../../modules/redux/partList/SetSellPartModal'
import updatePartsMarketplaces from '../../modules/redux/partList/UpdatePartsMarketplaces'
import getMarketplacesInfo from '../../modules/redux/sales/getMarketplacesInfo'
import loadMarketplacesList from '../../modules/redux/sales/loadMarketplacesList'
import getUserActiveColumnsPartList from '../../modules/redux/userSettings/GetUserActiveColumnsPartList'
import warehousesSelectSearch from '../../modules/redux/warehouseList/WarehousesSelectSearch'
import { AppDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { DocumentType } from '../../types/document/documentTypes'
import { Part, PartListPageParams, PartListSearchParams } from '../../types/part/partTypes'

import styles from './styles.module.scss'

const PartList = () => {
    const params = useParams<PartListPageParams>()
    const dispatch = useDispatch<AppDispatch>()
    const currentPart = useSelector(state => state.partList.currentPart)
    const customizableColumns = useSelector(state => state.partList.customizableColumns)

    // Модалки
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isSellPartsModalOpen, setIsSellPartsModalOpen] = useState(false)
    const [isDataDocumentModalOpen, setIsDataDocumentModalOpen] = useState(false)
    const [isIncomingInvoicePartsModalOpen, setIsIncomingInvoicePartsModalOpen] = useState(false)
    const [isLinkingToCarPartsModalOpen, setIsLinkingToCarPartsModalOpen] = useState(false)
    const [isMarketplacesModalOpen, setIsMarketplacesModalOpen] = useState(false)
    const [isCustomizableColumnsModalOpen, setIsCustomizableColumnsModalOpen] = useState(false)
    const [isChangeCarDataModalOpen, setIsChangeCarDataModalOpen] = useState(false)
    const [isChangePartTypeDataModalOpen, setIsChangePartTypeDataModalOpen] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()
    let pagesParts = pages

    // Параметры поиска
    const [partListSearchParams, setPartListSearchParams] = useState<PartListSearchParams>({})
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(0)
    const searchParamCarId = searchParams.get('carId')
    const searchParamPage = searchParams.get('p')
    const searchParamFilterDate = searchParams.get('d')
    const searchParamFilterDateField = searchParams.get('dField')
    const searchParamWarehouseId = searchParams.get('wh')
    const searchParamPartName = searchParams.get('pn')
    const searchParamCategoryId = searchParams.get('g')
    const searchParamMarkId = searchParams.get('markId')
    const searchParamModelId = searchParams.get('modelId')
    const searchParamPriceFrom = searchParams.get('priceFrom')
    const searchParamPriceTo = searchParams.get('priceTo')
    const searchParamPartNumber = searchParams.get('partNumber')
    const searchParamSortOrder = searchParams.get('sortOrder')
    const searchParamDocumentId = searchParams.get('documentId')
    const searchParamWithPrice = searchParams.get('withPrice')
    const searchParamWithPhoto = searchParams.get('withPhoto')
    const searchParamWithDescription = searchParams.get('withDescription')
    const searchParamWithPartNumber = searchParams.get('withPartNumber')
    const searchParamWithMarkModelModificationAndTypeId = searchParams.get('withMarkModelModificationAndTypeId')
    const searchParamWithCarId = searchParams.get('withCarId')
    const searchParamWithAvitoFullInfo = searchParams.get('withAvitoFullInfo')

    // Car filter
    const carFilter = useSelector(state => state.partList.filters.car)
    const page = useSelector(state => state.partList.filters.page)
    const perPage = useSelector(state => state.partList.filters.perPage)

    const navigateWithParams = (partId = '') => {
        const queryParams = new URLSearchParams(location.search)

        if (partId) {
            if (params.partId !== partId) {
                navigate(`/admin/parts/${partId}?${queryParams.toString()}`)
            }
        } else {
            navigate(`/admin/parts?${queryParams.toString()}`)
        }
    }

    const filterPartsByDate = (date: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterDateFilterParts,
            value: {
                filterDate: date,
            },
        })
    }

    const filterPartsByDateField = (field: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterDateFieldFilterParts,
            value: {
                filterDateField: field,
            },
        })
    }

    const filterPartsByPartName = (partName: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: partName,
            },
        })
    }

    const filterPartsByCategoryId = (categoryId: string) => {
        dispatch({
            type: storeDispatchTypes.setCategoryFilterParts,
            value: {
                filterId: categoryId,
            },
        })
    }

    const filterPartsByWarehouseId = (warehouseId: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterWarehouseIdParts,
            value: {
                warehouseId: warehouseId,
            },
        })
    }

    const filterPartsByMarkId = (markId: string) => {
        dispatch({
            type: storeDispatchTypes.setMarkFilterParts,
            value: {
                filterId: markId,
            },
        })
    }

    const filterPartsByModelId = (modelId: string) => {
        dispatch({
            type: storeDispatchTypes.setModelFilterParts,
            value: {
                filterId: modelId,
            },
        })
    }

    const filterPartsByPriceRange = (priceFrom: string | null, priceTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setPriceRangeParts,
            value: {
                from: priceFrom,
                to: priceTo,
            },
        })
    }

    const filterPartsByPartNumber = (partNumber: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNumberFilterParts,
            value: {
                filterId: partNumber,
            },
        })
    }

    const filterPartsBySortOrder = (sortField: string, sortOrder: string) => {
        dispatch({
            type: storeDispatchTypes.setPartListSort,
            value: {
                sortField,
                sortOrder,
            },
        })
    }

    const setCurrentPart = (currentPart?: Part) => {
        dispatch({
            type: storeDispatchTypes.setCurrentPart,
            value: currentPart,
        })
    }

    const openEditModal = (part: Part) => {
        if (!isSellPartModalOpen) {
            navigateWithParams(part.id)
            setIsEditModalOpen(true)
        }

        dispatch({
            type: storeDispatchTypes.setCurrentPart,
            value: part,
        })
    }

    const handleClose = () => {
        if (!isSellPartModalOpen) {
            navigateWithParams()
            setIsEditModalOpen(false)
        }
    }

    const { partId: soldPartId, isOpen: isSellPartModalOpen } = useSelector(state => state.partList.sellPartModal)
    const isLoading = useSelector(state => state.partList.isLoading.partList)
    const parts = useSelector(state => state.partList.partList.data)

    const userId = useSelector(state => state.userData.smsAuth.userId)

    // Sort
    const sortOrder = useSelector(state => state.partList.sortOrder)

    //Select search filter
    const modelId = useSelector(state => state.partList.filters.modelId)
    const markId = useSelector(state => state.partList.filters.markId)
    const categoryId = useSelector(state => state.partList.filters.categoryId)
    const partId = useSelector(state => state.partList.filters.partId)
    const partNumber = useSelector(state => state.partList.filters.partNumber)

    // search filter part title
    const partName = useSelector(state => state.partList.filters.partName)

    // Range Filters
    const priceRange = useSelector(state => state.partList.filters.priceRange)
    const priceFrom = useSelector(state => state.partList.filters.priceRange?.from)
    const priceTo = useSelector(state => state.partList.filters.priceRange?.to)

    const yearRange = useSelector(state => state.partList.filters.yearRange)
    const yearFrom = useSelector(state => state.partList.filters.yearRange?.from)
    const yearTo = useSelector(state => state.partList.filters.yearRange?.to)
    const filterDate = useSelector(state => state.partList.filters.filterDate)
    const filterDateField = useSelector(state => state.partList.filters.filterDateField)
    const filterWarehouseId = useSelector(state => state.partList.filters.warehouseId)
    const filterVendorCode = useSelector(state => state.partList.filters.filterVendorCode)

    const documentId = useSelector(state => state.partList.filters.document?.documentId)
    const withPrice = useSelector(state => state.partList.filters.withPrice)
    const withPhoto = useSelector(state => state.partList.filters.withPhoto)
    const withDescription = useSelector(state => state.partList.filters.withDescription)
    const withPartNumber = useSelector(state => state.partList.filters.withPartNumber)
    const withMarkModelModificationAndTypeId = useSelector(
        state => state.partList.filters.withMarkModelModificationAndTypeId,
    )
    const withCarId = useSelector(state => state.partList.filters.withCarId)
    const withAvitoFullInfo = useSelector(state => state.partList.filters.withAvitoFullInfo)

    // Counts
    const [counts, setCounts] = useState<Record<string, number>>()
    const itemsCount = useSelector(state => state.partList.partList.dataCount)
    const itemsTotal = useSelector(state => state.partList.partList.total)
    const draftCount = useSelector(state => state.partList.partList.draftCount)
    const archivedCount = useSelector(state => state.partList.partList.archivedCount)
    const soldCount = useSelector(state => state.partList.partList.soldCount)

    useEffect(() => {
        if (!isLoading) {
            setCounts({
                itemsTotal,
                draftCount,
                archivedCount,
                soldCount,
            })
        }
    }, [isLoading, itemsTotal, draftCount, archivedCount, soldCount])

    useEffect(() => {
        pagesParts = pagesParts.map(page => ({
            ...page,
            count: counts && counts[page.countKey],
        }))
    }, [counts])

    // load parts list data
    const loadParts = async () => {
        let partsId: string[] | undefined = undefined
        if (documentId) {
            await dispatch(GetPartsIdByDocumentId(documentId)).then(result => {
                partsId = Array.from(new Set(result))
            })
        }

        setPartListSearchParams({
            ...(carFilter?.carId && { carId: carFilter.carId }),
            ...(modelId && { modelId }),
            ...(markId && { markId }),
            ...(categoryId && { groupId: categoryId }),
            ...(partName && { search: partName }),
            ...(priceRange && { priceRange: { from: priceFrom, to: priceTo } }),
            ...(yearRange && { yearRange: { from: yearFrom, to: yearTo } }),
            ...(partId && { partId }),
            ...(partNumber && { partNumber }),
            ...(filterDate && { filterDate }),
            ...(filterDateField && { filterDateField }),
            ...(filterWarehouseId && { warehouseId: filterWarehouseId }),
            ...(withPrice && { withPrice }),
            ...(withPhoto && { withPhoto }),
            ...(withDescription && { withDescription }),
            ...(withPartNumber && { withPartNumber }),
            ...(withMarkModelModificationAndTypeId && {
                withMarkModelModificationAndTypeId,
            }),
            ...(withCarId && { withCarId }),
            ...(withAvitoFullInfo && { withAvitoFullInfo }),
        })

        dispatch(
            loadPartList({
                pagination: {
                    page,
                    perPage,
                },
                order: sortOrder,
                carId: carFilter?.carId,
                modelId,
                markId,
                groupId: categoryId,
                search: partName,
                priceRange: priceRange && { from: priceFrom, to: priceTo },
                yearRange: yearRange && { from: yearFrom, to: yearTo },
                partId: partId || partsId,
                partNumber,
                filterDate,
                filterDateField,
                warehouseId: filterWarehouseId,
                withPrice,
                withPhoto,
                withDescription,
                withPartNumber,
                withMarkModelModificationAndTypeId,
                withCarId,
                withAvitoFullInfo,
            }),
        )
    }

    const loadSearchParams = async () => {
        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterParts,
                value: {
                    page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1,
                },
            })
        }

        if (
            searchParamWarehouseId !== filterWarehouseId &&
            (searchParamWarehouseId || filterWarehouseId !== undefined)
        ) {
            filterPartsByWarehouseId(searchParamWarehouseId ? searchParamWarehouseId : '')
        }

        if (searchParamFilterDate !== filterDate && (searchParamFilterDate || filterDate !== undefined)) {
            filterPartsByDate(searchParamFilterDate ? searchParamFilterDate : '')
        }

        if (
            searchParamFilterDateField !== filterDateField &&
            (searchParamFilterDateField || filterDateField !== undefined)
        ) {
            filterPartsByDateField(searchParamFilterDateField ? searchParamFilterDateField : '')
        }

        if (searchParamPartName !== partName && (searchParamPartName || partName !== undefined)) {
            filterPartsByPartName(searchParamPartName ? searchParamPartName : '')
        }

        if (searchParamCategoryId !== `${categoryId}` && (searchParamCategoryId || categoryId !== undefined)) {
            filterPartsByCategoryId(searchParamCategoryId ? searchParamCategoryId : '')
        }

        if (searchParamMarkId !== `${markId}` && (searchParamMarkId || markId !== undefined)) {
            filterPartsByMarkId(searchParamMarkId ? searchParamMarkId : '')
        }

        if (searchParamModelId !== `${modelId}` && (searchParamModelId || modelId !== undefined)) {
            filterPartsByModelId(searchParamModelId ? searchParamModelId : '')
        }

        if (
            searchParamPriceFrom !== `${priceFrom}` ||
            (searchParamPriceFrom === null && priceFrom !== undefined) ||
            searchParamPriceTo !== `${priceTo}` ||
            (searchParamPriceTo === null && priceTo !== undefined)
        ) {
            if (searchParamPriceFrom || priceFrom !== undefined || searchParamPriceTo || priceTo !== undefined) {
                filterPartsByPriceRange(searchParamPriceFrom, searchParamPriceTo)
            }
        }

        if (searchParamPartNumber !== `${partNumber}` && (searchParamPartNumber || partNumber !== undefined)) {
            filterPartsByPartNumber(searchParamPartNumber ? searchParamPartNumber : '')
        }

        if (searchParamSortOrder && searchParamSortOrder !== sortOrder.map(pair => pair.join(',')).join(',')) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            // Для каждой пары из sortOrder, которая не содержится в searchParamSortOrder
            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    filterPartsBySortOrder(pair[0], 'NONE')
                }
            })

            // Для каждой пары из searchParamSortOrder, которая не содержится в sortOrder
            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    const [field, order] = param.split(',')
                    filterPartsBySortOrder(field, order)
                }
            })
        } else if (!searchParamSortOrder) {
            for (const field of sortOrder) {
                filterPartsBySortOrder(field[0], 'NONE')
            }
        }

        if (searchParamCarId !== `${carFilter?.carId[0]}`) {
            if (!searchParamCarId) {
                dispatch({ type: storeDispatchTypes.resetCarFilterParts })
            } else {
                if (userId) {
                    const car = await getCarById(searchParamCarId, userId)
                    if (car) {
                        dispatch({
                            type: storeDispatchTypes.setCarFilterParts,
                            value: {
                                car: {
                                    carId: [car.id],
                                    markTitle: car.markTitle,
                                    modelTitle: car.modelTitle,
                                    year: car.year,
                                },
                            },
                        })
                    }
                }
            }
        } else if (!carFilter?.carId[0]) {
            dispatch({ type: storeDispatchTypes.resetCarFilterParts })
        }

        if (searchParamDocumentId !== documentId && (searchParamDocumentId || documentId !== undefined)) {
            dispatch({
                type: storeDispatchTypes.setDocumentFilterParts,
                value: {
                    document: {
                        documentId: searchParamDocumentId,
                    },
                },
            })
        }

        if (searchParamWithPrice !== String(withPrice) && (searchParamWithPrice || withPrice !== undefined)) {
            dispatch({
                type: storeDispatchTypes.setWithPriceParts,
                value: {
                    withPrice: searchParamWithPrice === 'true',
                },
            })
        }

        if (searchParamWithPhoto !== String(withPhoto) && (searchParamWithPhoto || withPhoto !== undefined)) {
            dispatch({
                type: storeDispatchTypes.setWithPhotoParts,
                value: {
                    withPhoto: searchParamWithPhoto === 'true',
                },
            })
        }

        if (
            searchParamWithDescription !== String(withDescription) &&
            (searchParamWithDescription || withDescription !== undefined)
        ) {
            dispatch({
                type: storeDispatchTypes.setWithDescriptionParts,
                value: {
                    withDescription: searchParamWithDescription === 'true',
                },
            })
        }

        if (
            searchParamWithPartNumber !== String(withPartNumber) &&
            (searchParamWithPartNumber || withPartNumber !== undefined)
        ) {
            dispatch({
                type: storeDispatchTypes.setWithPartNumberParts,
                value: {
                    withPartNumber: searchParamWithPartNumber === 'true',
                },
            })
        }

        if (
            searchParamWithMarkModelModificationAndTypeId !== String(withMarkModelModificationAndTypeId) &&
            (searchParamWithMarkModelModificationAndTypeId || withMarkModelModificationAndTypeId !== undefined)
        ) {
            dispatch({
                type: storeDispatchTypes.setWithMarkModelModificationAndTypeIdParts,
                value: {
                    withMarkModelModificationAndTypeId: searchParamWithMarkModelModificationAndTypeId === 'true',
                },
            })
        }

        if (searchParamWithCarId !== String(withCarId) && (searchParamWithCarId || withCarId !== undefined)) {
            dispatch({
                type: storeDispatchTypes.setWithCarId,
                value: {
                    withCarId: searchParamWithCarId === 'true',
                },
            })
        }

        if (
            searchParamWithAvitoFullInfo !== String(withAvitoFullInfo) &&
            (searchParamWithAvitoFullInfo || withAvitoFullInfo !== undefined)
        ) {
            dispatch({
                type: storeDispatchTypes.setWithAvitoFullInfo,
                value: {
                    withAvitoFullInfo: searchParamWithAvitoFullInfo === 'true',
                },
            })
        }

        setSearchParamsLoaded(1)
    }

    // pre-load marketplaces list
    useEffect(() => {
        dispatch(getMarketplacesInfo())
    }, [])

    // Загрузка параметров из строки поиска
    useEffect(() => {
        loadSearchParams()
    }, [searchParams])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!searchParamsLoaded) {
            return
        }

        // Текущие параметры
        const currentPage = page > 1 ? `${page}` : null
        const currentCarId = carFilter?.carId ? `${carFilter.carId}` : null
        const currentFilterDate = filterDate ? `${filterDate}` : null
        const currentFilterDateField = filterDateField ? `${filterDateField}` : null
        const currentWarehouseId = filterWarehouseId ? `${filterWarehouseId}` : null
        const currentPartName = partName ? `${partName}` : null
        const currentCategoryId = categoryId ? `${categoryId}` : null
        const currentMarkId = markId ? `${markId}` : null
        const currentModelId = modelId ? `${modelId}` : null
        const currentPriceFrom = priceFrom !== undefined ? `${priceFrom}` : null
        const currentPriceTo = priceTo !== undefined ? `${priceTo}` : null
        const currentPartNumber = partNumber ? `${partNumber}` : null
        const currentSortOrder = sortOrder.length ? sortOrder : null
        const currentDocumentId = documentId ? `${documentId}` : null
        const currentWithPrice = withPrice !== undefined ? `${withPrice}` : null
        const currentWithPhoto = withPhoto !== undefined ? `${withPhoto}` : null
        const currentWithDescription = withDescription !== undefined ? `${withDescription}` : null
        const currentWithPartNumber = withPartNumber !== undefined ? `${withPartNumber}` : null
        const currentWithMarkModelModificationAndTypeId =
            withMarkModelModificationAndTypeId !== undefined ? `${withMarkModelModificationAndTypeId}` : null
        const currentWithCarId = withCarId !== undefined ? `${withCarId}` : null
        const currentWithAvitoFullInfo = withAvitoFullInfo !== undefined ? `${withAvitoFullInfo}` : null

        // Проверяем, изменились ли параметры строки поиска
        const pageChanged = searchParamPage !== currentPage
        const carIdChanged = searchParamCarId !== currentCarId
        const filterDateChanged = searchParamFilterDate !== currentFilterDate
        const filterDateChangedField = searchParamFilterDateField !== currentFilterDateField
        const warehouseIdChanged = searchParamWarehouseId !== currentWarehouseId
        const partNameChanged = searchParamPartName !== currentPartName
        const categoryIdChanged = searchParamCategoryId !== currentCategoryId
        const markIdChanged = searchParamMarkId !== currentMarkId
        const modelIdChanged = searchParamModelId !== currentModelId
        const priceFromChanged = searchParamPriceFrom !== currentPriceFrom
        const priceToChanged = searchParamPriceTo !== currentPriceTo
        const partNumberChanged = searchParamPartNumber !== currentPartNumber
        const documentIdChanged = searchParamDocumentId !== currentDocumentId
        const withPriceChanged = searchParamWithPrice !== currentWithPrice
        const withPhotoChanged = searchParamWithPhoto !== currentWithPhoto
        const withDescriptionChanged = searchParamWithDescription !== currentWithDescription
        const withPartNumberChanged = searchParamWithPartNumber !== currentWithPartNumber
        const withMarkModelModificationAndTypeIdChanged =
            searchParamWithMarkModelModificationAndTypeId !== currentWithMarkModelModificationAndTypeId
        const withCarIdChanged = searchParamWithCarId !== currentWithCarId
        const withAvitoFullInfoChanged = searchParamWithAvitoFullInfo !== currentWithAvitoFullInfo

        let sortOrderChanged = false
        if (searchParamSortOrder) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    sortOrderChanged = true
                    return
                }
            })

            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    sortOrderChanged = true
                    return
                }
            })
        } else if (!searchParamSortOrder && currentSortOrder) {
            sortOrderChanged = true
        }

        const searchParamsChanged =
            carIdChanged ||
            pageChanged ||
            filterDateChanged ||
            filterDateChangedField ||
            warehouseIdChanged ||
            partNameChanged ||
            categoryIdChanged ||
            markIdChanged ||
            modelIdChanged ||
            priceFromChanged ||
            priceToChanged ||
            partNumberChanged ||
            sortOrderChanged ||
            documentIdChanged ||
            withPriceChanged ||
            withPhotoChanged ||
            withDescriptionChanged ||
            withPartNumberChanged ||
            withMarkModelModificationAndTypeIdChanged ||
            withCarIdChanged ||
            withAvitoFullInfoChanged

        if (searchParamsChanged) {
            setSearchParams({
                ...(currentCarId ? { carId: `${currentCarId}` } : {}),
                ...(currentPage ? { p: `${currentPage}` } : {}),
                ...(currentFilterDate ? { d: `${currentFilterDate}` } : {}),
                ...(currentFilterDateField ? { dField: `${currentFilterDateField}` } : {}),
                ...(currentWarehouseId ? { wh: `${currentWarehouseId}` } : {}),
                ...(currentPartName ? { pn: `${currentPartName}` } : {}),
                ...(currentCategoryId ? { g: `${currentCategoryId}` } : {}),
                ...(currentMarkId ? { markId: `${currentMarkId}` } : {}),
                ...(currentModelId ? { modelId: `${currentModelId}` } : {}),
                ...(currentPriceFrom ? { priceFrom: `${currentPriceFrom}` } : {}),
                ...(currentPriceTo ? { priceTo: `${currentPriceTo}` } : {}),
                ...(currentPartNumber ? { partNumber: `${currentPartNumber}` } : {}),
                ...(sortOrder && sortOrder.length !== 0 ? { sortOrder: `${sortOrder}` } : {}),
                ...(currentDocumentId ? { documentId: `${currentDocumentId}` } : {}),
                ...(currentWithPrice ? { withPrice: `${currentWithPrice}` } : {}),
                ...(currentWithPhoto ? { withPhoto: `${currentWithPhoto}` } : {}),
                ...(currentWithDescription ? { withDescription: `${currentWithDescription}` } : {}),
                ...(currentWithPartNumber ? { withPartNumber: `${currentWithPartNumber}` } : {}),
                ...(currentWithMarkModelModificationAndTypeId
                    ? {
                          withMarkModelModificationAndTypeId: `${currentWithMarkModelModificationAndTypeId}`,
                      }
                    : {}),
                ...(currentWithCarId ? { withCarId: `${currentWithCarId}` } : {}),
                ...(currentWithAvitoFullInfo ? { withAvitoFullInfo: `${currentWithAvitoFullInfo}` } : {}),
            })
        }

        loadParts()
    }, [
        searchParamsLoaded,
        page,
        perPage,
        sortOrder,
        carFilter?.carId,
        modelId,
        markId,
        categoryId,
        partName,
        priceFrom,
        priceTo,
        yearFrom,
        yearTo,
        partId,
        partNumber,
        filterDate,
        filterWarehouseId,
        documentId,
        withPrice,
        withPhoto,
        withDescription,
        withPartNumber,
        withMarkModelModificationAndTypeId,
        withCarId,
        withAvitoFullInfo,
    ])

    // load part warehouses data
    useEffect(() => {
        dispatch(warehousesSelectSearch(''))
        dispatch(loadMarketplacesList())
    }, [])

    const getPartById = async () => {
        const partId = soldPartId && isSellPartModalOpen ? soldPartId : params.partId

        if (partId) {
            const part = await DatahubApiService.getPartByPartId(partId)

            if (!part) {
                return
            } else if (!isEditModalOpen) {
                openEditModal(part)
            }
            setCurrentPart(part)
        }
    }

    // open modal by partId
    useEffect(() => {
        if (params.partId) {
            getPartById()
        } else {
            setIsEditModalOpen(false)
        }
    }, [params.partId])

    useEffect(() => {
        if (isSellPartModalOpen) {
            getPartById()
        }
    }, [isSellPartModalOpen])

    // Выбранные запчасти в чекбоксах
    const [checksFromModalSellParts, setChecksFromModalSellParts] = useState<{
        documentType: DocumentType[]
        marketplace?: string
    }>({
        documentType: [],
        marketplace: '',
    })
    const selectAll = useSelector(state => state.partList.selectedParts.selectAll)
    const selectedParts = useSelector(state => state.partList.selectedParts.parts)

    const handleCheckboxChange = (part: Part) => {
        const newCheckedParts = [...selectedParts]
        const partIndex = newCheckedParts.findIndex(p => p.id === part.id)

        if (partIndex !== -1) {
            newCheckedParts.splice(partIndex, 1)
        } else {
            newCheckedParts.push(part)
        }

        dispatch({
            type: storeDispatchTypes.setSelectedParts,
            value: newCheckedParts,
        })
    }

    const handleAllCheckboxCancel = () => {
        dispatch({
            type: storeDispatchTypes.setSelectAllParts,
            value: false,
        })
    }

    const confirmModalMarketplacesChanges = async (partsId: string[], checkedMarketplaces: Record<string, boolean>) => {
        try {
            if (selectAll) {
                await dispatch(updatePartsMarketplaces(partsId, checkedMarketplaces, partListSearchParams))
            } else {
                await dispatch(updatePartsMarketplaces(partsId, checkedMarketplaces))
            }
            toast.success('Запчасти успешно привязаны')
        } catch (error) {
            toast.error(`Не удалось привязать к маркетплейсам`)
            console.error('Ошибка при привязке к маркетплейсам', error)
        }
        loadParts()
    }

    // Главный поиск
    const setNameSearch = (searchTerm: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: searchTerm,
            },
        })
    }

    useEffect(() => {
        dispatch(getUserActiveColumnsPartList())
    }, [])

    // Добавление номеров приходных накладных для загруженных деталей
    useEffect(() => {
        if (isLoading) return
        if (parts.length) {
            dispatch(getIncomingInvoicesByPartsId(parts.map(part => part.id)))
        }
    }, [isLoading])

    // Сброс фильтров при уходе со страницы
    useResetFiltersOnUnmount()

    return (
        <div className={styles.wrap}>
            <ToastContainer position="bottom-center" autoClose={5000} />
            {/* Модалки */}
            {currentPart && isEditModalOpen && (
                <ModalEditPart
                    part={currentPart}
                    isOpen={isEditModalOpen}
                    closeModal={() => handleClose()}
                    confirmChanges={() => loadParts()}
                />
            )}
            {isSellPartModalOpen && currentPart && (
                <ModalSellPart
                    part={currentPart}
                    isOpen={isSellPartModalOpen}
                    closeModal={() => {
                        dispatch(setSellPartModal({ partId: '', isOpen: false }))
                        setCurrentPart()
                    }}
                />
            )}
            {isSellPartsModalOpen && (
                <ModalSellParts
                    parts={selectedParts}
                    isOpen={isSellPartsModalOpen}
                    closeModal={() => {
                        setIsSellPartsModalOpen(false)
                    }}
                    count={selectedParts.length}
                    openDataDocumentModal={() => setIsDataDocumentModalOpen(true)}
                    setChecksFromModalSellParts={({
                        documentType,
                        marketplace,
                    }: {
                        documentType: DocumentType[]
                        marketplace?: string
                    }) =>
                        setChecksFromModalSellParts({
                            documentType,
                            marketplace,
                        })
                    }
                    setCheckedParts={handleAllCheckboxCancel}
                />
            )}
            {isDataDocumentModalOpen && (
                <ModalDataDocument
                    isOpen={isDataDocumentModalOpen}
                    closeModal={() => {
                        setIsDataDocumentModalOpen(false)
                        handleAllCheckboxCancel()
                        setChecksFromModalSellParts({
                            documentType: [],
                            marketplace: '',
                        })
                    }}
                    checks={checksFromModalSellParts}
                    parts={selectedParts}
                />
            )}
            {isIncomingInvoicePartsModalOpen && (
                <ModalIncomingInvoiceOpen
                    isOpen={isIncomingInvoicePartsModalOpen}
                    closeModal={() => {
                        setIsIncomingInvoicePartsModalOpen(false)
                        handleAllCheckboxCancel()
                    }}
                    parts={selectedParts}
                />
            )}
            {isLinkingToCarPartsModalOpen && (
                <ModalLinkingToCar
                    isOpen={isLinkingToCarPartsModalOpen}
                    closeModal={() => {
                        setIsLinkingToCarPartsModalOpen(false)
                        handleAllCheckboxCancel()
                    }}
                    parts={selectedParts}
                    confirmChanges={() => loadParts && loadParts()}
                />
            )}
            {isMarketplacesModalOpen && (
                <ModalMarketplaces
                    isOpen={isMarketplacesModalOpen}
                    closeModal={() => {
                        setIsMarketplacesModalOpen(false)
                        handleAllCheckboxCancel()
                    }}
                    parts={selectedParts}
                    count={selectAll ? itemsCount : selectedParts.length}
                    confirmChanges={confirmModalMarketplacesChanges}
                    loadPartsMarketplacesCount={async () =>
                        await dispatch(getPartsMarketplacesCount(partListSearchParams))
                    }
                />
            )}
            {isCustomizableColumnsModalOpen && (
                <ModalCustomizableColumns
                    isOpen={isCustomizableColumnsModalOpen}
                    closeModal={() => setIsCustomizableColumnsModalOpen(false)}
                />
            )}
            {isChangeCarDataModalOpen && (
                <ModalChangeCarData
                    isOpen={isChangeCarDataModalOpen}
                    closeModal={() => {
                        setIsChangeCarDataModalOpen(false)
                        handleAllCheckboxCancel()
                    }}
                    parts={selectedParts}
                    confirmChanges={() => loadParts()}
                    partListSearchParams={selectAll ? partListSearchParams : undefined}
                />
            )}
            {isChangePartTypeDataModalOpen && (
                <ModalChangePartTypeData
                    isOpen={isChangePartTypeDataModalOpen}
                    closeModal={() => {
                        setIsChangePartTypeDataModalOpen(false)
                        handleAllCheckboxCancel()
                    }}
                    parts={selectedParts}
                    confirmChanges={() => loadParts()}
                    partListSearchParams={selectAll ? partListSearchParams : undefined}
                />
            )}

            <div className={styles.rowWrap}>
                <SalesText
                    text={'Запчасти'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>
            <div className={styles.rowWrap}>
                <SearchComponent
                    oldSearchTerm={partName}
                    setSearchTerm={setNameSearch}
                    resultCount={itemsCount !== itemsTotal ? itemsCount : undefined}
                />
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.blueLight}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={6}
                    onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.newPart.location))}
                >
                    <>
                        <PlusLg size={24} color={colors.white} />
                        <SalesText
                            text={'Добавить запчасть'}
                            marginLeftPixels={10}
                            fontSize={fontSizes.s}
                            color={colors.white}
                        />
                    </>
                </SalesButton>
            </div>
            <div className={styles.rowWrap}>
                <Dropdown className={styles.customDropdown}>
                    <Dropdown.Toggle disabled={selectedParts.length == 0 && !selectAll}>
                        Массовые действия
                        {selectAll || selectedParts.length ? (
                            <span style={{ marginLeft: '8px' }}>{selectAll ? itemsCount : selectedParts.length}</span>
                        ) : (
                            <></>
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setIsSellPartsModalOpen(true)} disabled={selectAll}>
                            Продать
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                selectedParts.forEach(part => dispatch(setPartArchivedStatus(part.id)))
                                handleAllCheckboxCancel()
                            }}
                            disabled={selectAll}
                        >
                            В архив
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsIncomingInvoicePartsModalOpen(true)} disabled={selectAll}>
                            В приходную накладную
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsLinkingToCarPartsModalOpen(true)} disabled={selectAll}>
                            Привязать к автомобилю
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsMarketplacesModalOpen(true)}>Маркетплейсы</Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsChangeCarDataModalOpen(true)}>
                            Марка/модель/поколение/модификация
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsChangePartTypeDataModalOpen(true)}>
                            Изменить категорию
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <NavigationButtons
                    pages={pagesParts}
                    currentPage={getAdminNavigationPath(adminRouteAlias.parts.location)}
                    counts={counts}
                    navigate={navigate}
                />
                <SalesButton
                    borderRadius={10}
                    hoverBackgroundColor={colors.grayLighter}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={7}
                    marginLeftAuto
                    onClick={() => setIsCustomizableColumnsModalOpen(true)}
                >
                    <img src={gear} alt="gear" className={styles.icon} />
                </SalesButton>
                {!isLoading && parts.length === 0 ? (
                    <></>
                ) : (
                    <div>
                        <ListPagination
                            page={page}
                            setPage={page =>
                                dispatch({
                                    type: storeDispatchTypes.setPageFilterParts,
                                    value: { page },
                                })
                            }
                            perPage={perPage}
                            itemsCount={itemsCount}
                        />
                    </div>
                )}
            </div>
            {carFilter && (
                <div className={styles.rowWrap}>
                    <Link
                        to={`${getAdminNavigationPath(adminRouteAlias.cars.location)}/${carFilter?.carId}`}
                        style={{ textDecoration: 'none' }}
                    >
                        <SalesButton borderRadius={10} paddingHorizontalPixels={20} paddingVerticalPixels={7}>
                            <SalesText
                                text={`Перейти к авто ${carFilter?.markTitle} ${carFilter?.modelTitle} ${carFilter?.year}`}
                                fontSize={fontSizes.s}
                            />
                        </SalesButton>
                    </Link>
                </div>
            )}
            <TablePartList
                columns={customizableColumns}
                parts={parts}
                isLoading={isLoading}
                selectAll={selectAll}
                selectedParts={selectedParts}
                handleCheckboxChange={handleCheckboxChange}
                openEditModal={openEditModal}
            />
            {!isLoading && parts.length === 0 ? (
                <ListNoItems slice={'partList'} />
            ) : (
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterParts,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default PartList
