import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllBrakeDOT =
    (searchTerm: string, typeId?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'brakeDOT', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/BrakeDOT', { typeId })
            const brakeDOT: SelectOptionLocal[] = []

            if (status === 'ok') {
                for (let i = 0; i < data.length; i++) {
                    const DOT = data[i]
                    if (DOT && DOT.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        brakeDOT.push({ name: DOT, value: DOT })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllBrakeDOT, value: { brakeDOT } })
        } catch (e) {
            console.error('load brakeDOT failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'brakeDOT', value: false } })
    }

export default getAllBrakeDOT
