import React, { ReactElement } from 'react'
import { colors, fontSizes } from "../../../constants/salesStyles"
import SalesText from "../../_atoms/SalesText/SalesText"
import SalesButton from "../../_atoms/SalesButton/SalesButton"
import HoverOverlay from '../../_atoms/HoverOverlay/HoverOverlay'

interface IProps {
    text: string
    textColor?: colors
    backgroundColor?: colors
    prefixElement?: ReactElement
    suffixElement?: ReactElement
    marginTopPixels?: number
    marginRightPixels?: number
    marginBottomPixels?: number
    onClick?: () => void
    disabled?: boolean
    hoverText?: string
    addButtonText?: string
}

const SalesButtonStandard = ({
    text,
    textColor,
    backgroundColor,
    prefixElement,
    suffixElement,
    marginTopPixels,
    marginRightPixels,
    onClick,
    disabled,
    hoverText,
    marginBottomPixels,
    addButtonText,
}: IProps) => (
    <HoverOverlay
        tooltipText={hoverText}
        preventWrapFullWidth
        maxWidthInPixels={300}
        offset={[-6, 5]}

    >
        <div style={{ position: 'relative' }}>
            <SalesButton
                backgroundColor={backgroundColor}
                paddingHorizontalPixels={20}
                paddingVerticalPixels={7}
                marginRightPixels={marginRightPixels || marginRightPixels === 0 ? marginRightPixels : 10}
                marginTopPixels={marginTopPixels}
                marginBottomPixels={marginBottomPixels}
                onClick={onClick}
                disabled={disabled}
            >
                <>
                    {prefixElement}
                    <SalesText
                        text={text}
                        fontSize={fontSizes.m}
                        color={textColor}
                        marginLeftPixels={prefixElement ? 10 : 0}
                        marginRightPixels={prefixElement ? 10 : 0}
                    />
                    {suffixElement}
                </>
            </SalesButton>
            {addButtonText && (
                <div style={{ float: 'right' }}>
                    <SalesButton
                        backgroundColor={colors.grayLighter}
                        paddingHorizontalPixels={10}
                        paddingVerticalPixels={3}
                        onClick={onClick}
                        disabled={disabled}
                        marginTopPixels={-10}
                    >
                        <SalesText
                            text={addButtonText}
                            fontSize={fontSizes.xxxs}
                            color={textColor}
                        />
                    </SalesButton>
                </div>
            )}
        </div>
    </HoverOverlay>
)

export default SalesButtonStandard
