import { Dispatch } from 'redux'
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { PartListSearchParams, PartTypeData } from '../../../types/part/partTypes'

const changePartTypeDataForParts = (partsId: string[], partTypeData: PartTypeData, searchParams?: PartListSearchParams) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    const params = {
        userId: userData.smsAuth.userId,
        partsId,
        partTypeData,
        ...(searchParams && { params: searchParams }),
    }
    try {
        const { data: { status } } = await apiInstance.post('/changePartTypeDataForParts', params , config)

        if(status !== 'ok') {
            throw new Error('change partTypeData forParts failed')
        }
    } catch (e) {
        console.error('change partTypeData for parts failed', e)
    }
}

export default changePartTypeDataForParts
