import { useEffect } from 'react'
import { Table } from 'react-bootstrap'

import Badge from '../../components/_atoms/Badge/Badge'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ColumnSort from '../../components/_molecules/ColumnSort/ColumnSort'
import { fontSizes, fontWeights } from '../../constants/salesStyles'
import getActionLogList from '../../modules/redux/logList/GetActionLogList'
import { useDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'
import {
    SortFieldActionLogs,
    SortIconType,
} from '../../types/common/commonTypes'
import { countDaysInStock, formatDate, num_word } from '../../utils/converters'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const ActionLog = () => {
    const dispatch = useDispatch()

    const isLoading = useSelector(state => state.actionLogList.isLoading)
    const actionLogList = useSelector(
        state => state.actionLogList.actionLogList.data,
    )
    const itemsCount = useSelector(
        state => state.actionLogList.actionLogList.dataCount,
    )

    // Фильтры
    const sortOrder = useSelector(state => state.actionLogList.sortOrder)
    const page = useSelector(state => state.actionLogList.filters.page)
    const perPage = useSelector(state => state.actionLogList.filters.perPage)
    const filterVendorCode = useSelector(
        state => state.actionLogList.filters.filterVendorCode,
    )
    const partName = useSelector(state => state.actionLogList.filters.partName)
    const priceRange = useSelector(
        state => state.actionLogList.filters.priceRange,
    )
    const priceFrom = useSelector(
        state => state.actionLogList.filters.priceRange?.from,
    )
    const priceTo = useSelector(
        state => state.actionLogList.filters.priceRange?.to,
    )
    const modelId = useSelector(state => state.actionLogList.filters.modelId)
    const markId = useSelector(state => state.actionLogList.filters.markId)
    const partNumber = useSelector(
        state => state.actionLogList.filters.partNumber,
    )

    useEffect(() => {
        dispatch(
            getActionLogList({
                objectType: 'part',
                pagination: {
                    page,
                    perPage,
                },
                order: sortOrder,
                modelId,
                markId,
                search: partName,
                priceRange: priceRange && { from: priceFrom, to: priceTo },
                partNumber,
                filterVendorCode,
            }),
        )
    }, [
        page,
        perPage,
        sortOrder,
        modelId,
        markId,
        partName,
        priceFrom,
        priceTo,
        partNumber,
        filterVendorCode,
    ])

    const getDifference = (difference: number) => {
        if (difference > 0) {
            return `+${difference.toLocaleString('ru-RU')}`
        } else {
            return `${difference.toLocaleString('ru-RU')}`
        }
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.rowWrap}>
                <SalesText
                    text={'Журнал действий'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(160)}>
                            Дата изменения
                            <ColumnSort
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldActionLogs.UPDATED_AT}
                                slice={'actionLogList'}
                                reverseSort={true}
                            />
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            Аккаунт
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Тип Действия
                        </th>
                        <th scope="col" style={colWidth(210)}>
                            {/* <TableHeadPartSearch oldSearchTerm={partName} slice='actionLogList' /> */}
                            Запчасть
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            {/* <TableHeadMarkSearchActionLogList /> */}
                            Марка
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            {/* <TableHeadModelSearchActionLogList /> */}
                            Модель
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Артикул
                            {/* {filterVendorCode ? (<ResetFilter filterName='filterVendorCode' slice='actionLogList' />)
                                : (<ColumnSort
                                    iconType={SortIconType.NUMERIC}
                                    sortField={SortFieldActionLogs.VENDOR_CODE}
                                    slice={'actionLogList'}
                                    reverseSort={true}
                                />)
                            } */}
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            {/* <TableHeadPartNumberSearchActionLogList /> */}
                            OEM
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            {/* <TableHeadPriceRangeActionLogList initialFromVal={priceFrom} initialToVal={priceTo} /> */}
                            Цена
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Изменение цены
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Цена продажи
                            {/* <TableHeadRangeFilter
                                slice={'partList'}
                                initialTableHead={'Цена продажи'}
                                step={1000}
                                leftInPixes={-35}
                                initialFromVal={priceFrom}
                                initialToVal={priceTo}
                                widthInPixels={150}
                                storeActionSetRange={
                                    storeDispatchTypes.setPriceRangeActionLogList
                                }
                            /> */}
                        </th>
                    </tr>
                </thead>
                {isLoading || !actionLogList ? (
                    <ListSkeleton
                        rowCount={9}
                        columnCount={11}
                        marginVerticalRem={0.21}
                    />
                ) : (
                    <tbody>
                        {actionLogList.map((actionLog, index) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row">
                                    <div>
                                        <Badge
                                            text={formatDate(
                                                actionLog.updatedAt,
                                            )}
                                            color="#ddd"
                                            fontColor="#555"
                                        />
                                    </div>
                                    {+countDaysInStock(
                                        `${actionLog.updatedAt}`,
                                    ) > 10 ? (
                                        <div>
                                            <Badge
                                                text={
                                                    countDaysInStock(
                                                        `${actionLog.updatedAt}`,
                                                    ) +
                                                    ' ' +
                                                    num_word(
                                                        +countDaysInStock(
                                                            `${actionLog.updatedAt}`,
                                                        ),
                                                        ['день', 'дня', 'дней'],
                                                    )
                                                }
                                                color="#eee"
                                                fontColor="#777"
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td scope="row">
                                    {actionLog.details.telephoneNumber}
                                </td>
                                <td scope="row">{actionLog?.actionTypeText}</td>
                                <td scope="row">
                                    {actionLog.objectData?.title}
                                </td>
                                <td scope="row">
                                    {actionLog.objectData?.markTitle}
                                </td>
                                <td scope="row">
                                    {actionLog.objectData?.modelTitle}
                                </td>
                                <td scope="row">
                                    {actionLog.objectData?.vendorCode}
                                </td>
                                <td scope="row">
                                    {actionLog.objectData?.partNumber}
                                </td>
                                <td scope="row">
                                    {actionLog.details.price?.after ??
                                        actionLog.objectData?.price}
                                </td>
                                <td scope="row">
                                    {actionLog.details.price &&
                                        getDifference(
                                            Number(
                                                actionLog.details.price.after,
                                            ) -
                                                Number(
                                                    actionLog.details.price
                                                        .before,
                                                ),
                                        )}
                                </td>
                                <td scope="row">
                                    {actionLog.details.soldPrice?.toLocaleString(
                                        'ru-RU',
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>
            {!isLoading && actionLogList.length === 0 ? (
                <ListNoItems slice={'actionLogList'} />
            ) : (
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterActionLogList,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default ActionLog
