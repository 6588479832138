import { useState } from 'react'
import { Button } from "react-bootstrap"
import { Trash3 } from "react-bootstrap-icons"
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import setDocumentDeletedStatus from '../../../modules/redux/documentList/SetDocumentDeletedStatus'
import HoverOverlay from "../HoverOverlay/HoverOverlay"
import { DocumentType } from "../../../types/document/documentTypes"
import Loader from "../Loader/Loader"
import receipt from '../../../assets/icons/receipt.png'
import { useSelector } from '../../../modules/store/customSelector'
import { AppDispatch } from '../../../modules/store/customDispatch'
import getDocumentById from '../../../modules/redux/documentList/GetDocumentById'
import { RegisterCheckByParts } from '../../../utils/kkmserver'

interface IProps {
    documentId: string
    createXLS: (id: string) => void
    type: DocumentType
}

const CellButtonsDocuments = ({ documentId, createXLS, type }: IProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const [isLoading, setIsLoading] = useState(false)
    const cashierInfo = useSelector(state => state.userData.cashierInfo)

    const handleReceiptClick = async () => {
        setIsLoading(true)
        const document = await dispatch(getDocumentById(documentId))
        document && RegisterCheckByParts(document.extra_data.parts, cashierInfo?.cashier)
        setIsLoading(false)
    }

    return (
        <>
            {cashierInfo?.connectCashbox
                ? isLoading
                    ? <Loader diameterInPx={28} thicknessInPx={3} margin={0} />
                    : <img src={receipt} alt='receipt' className={styles.icon} onClick={handleReceiptClick}/>
                : null
            }
            <HoverOverlay tooltipText={'Скачать XLS'} preventWrapFullWidth>
                <Button
                    variant={'primary'}
                    style={{ width: 70 }}
                    size="sm"
                    onClick={() => createXLS(documentId)}
                >
                    XLS
                </Button>
            </HoverOverlay>
            <HoverOverlay tooltipText={'Удалено'} preventWrapFullWidth>
                <Button
                    variant="danger"
                    size="sm"
                    onClick={() => dispatch(setDocumentDeletedStatus(documentId, type))}
                    className="d-flex align-items-center"
                >
                    <Trash3 color={'white'} size={18}/>
                </Button>
            </HoverOverlay>
        </>
    )
}

export default CellButtonsDocuments
