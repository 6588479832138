import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { PlusLg } from "react-bootstrap-icons"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { browserName } from 'react-device-detect'
import styles from './styles.module.scss'
import { storeDispatchTypes } from "../../modules/store/storeDispatchTypes"
import { useSelector } from "../../modules/store/customSelector"
import loadPartList from "../../modules/redux/partList/LoadPartList"
import Badge from "../../components/_atoms/Badge/Badge"
import CategoryIcon from "../../components/_atoms/CategogyIcon/CategoryIcon"
import { countDaysInStock, formatDate, num_word } from "../../utils/converters"
import CellActionsParts from "../../components/_orgamisms/CellActionsParts/CellActionsParts"
import CellThumbCarousel from "../../components/_orgamisms/CellThumbCarousel/CellThumbCarousel"
import ListPagination from "../../components/_atoms/ListPagination/ListPagination"
import CellEditPrice from "../../components/_atoms/CellEditPrice/CellEditPrice"
import ListSkeleton from "../../components/_atoms/ListSkeleton/ListSkeleton"
import { SortFieldParts, SortIconType } from "../../types/common/commonTypes"
import ColumnSort from "../../components/_molecules/ColumnSort/ColumnSort"
import ResetFilter from "../../components/_molecules/ResetFilter/ResetFilter"
import { colWidth } from "../../utils/stylePatches"
import ListNoItems from "../../components/_atoms/ListNoItems/ListNoItems"
import TableHeadCategorySearchParts
    from "../../components/_molecules/TableHeadCategorySearchParts/TableHeadCategorySearchParts"
import TableHeadMarkSearchParts from "../../components/_molecules/TableHeadMarkSearchParts/TableHeadMarkSearchParts"
import TableHeadModelSearchParts from "../../components/_molecules/TableHeadModelSearchParts/TableHeadModelSearchParts"
import TableHeadPriceRangeParts from "../../components/_molecules/TableHeadPriceRangeParts/TableHeadPriceRangeParts"
import TableHeadPartNumberSearchParts
    from "../../components/_molecules/TableHeadPartNumberSearchParts/TableHeadPartNumberSearchParts"
import ModalEditPart from "../../components/_molecules/ModalEditPart/ModalEditPart"
import { Part, PartListPageParams } from "../../types/part/partTypes"
import SalesText from "../../components/_atoms/SalesText/SalesText"
import { borderStyles, colors, fontSizes, fontWeights } from "../../constants/salesStyles"
import SalesButton from "../../components/_atoms/SalesButton/SalesButton"
import { adminRouteAlias, getAdminNavigationPath } from "../../router/adminRouteAlias"
import TableHeadPartSearch from "../../components/_atoms/TableHeadPartSearch/TableHeadPartSearch"
import warehousesSelectSearch from "../../modules/redux/warehouseList/WarehousesSelectSearch"
import DatahubApiService from "../../modules/api/DatahubApiService"
import loadMarketplacesList from "../../modules/redux/sales/loadMarketplacesList"
import getMarketplacesInfo from "../../modules/redux/sales/getMarketplacesInfo"
import GetPartsIdByDocumentId from "../../modules/redux/documentList/GetPartsIdByDocumentId"
import { AppDispatch } from "../../modules/store/customDispatch"
import { pages } from "../../constants/parts/navigationButtonsPages"
import useResetFiltersOnUnmount from "../../hooks/partList/useResetFiltersOnUnmount"
import SearchComponent from "../../components/_molecules/SearchComponent/SearchComponent"
import NavigationButtons from "../../components/_molecules/NavigationButtons/NavigationButtons"
import CopyButton from "../../components/_atoms/CopyButton/CopyButton"

const PartDraftList = () => {
    const params = useParams<PartListPageParams>()
    const dispatch = useDispatch<AppDispatch>()
    const [perPage] = useState(50)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const currentPart = useSelector(state => state.partList.currentPart)

    const location = useLocation()
    const navigate = useNavigate()
    let pagesParts = pages

    // Параметры поиска
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(0)
    const searchParamCarId = searchParams.get('carId')
    const searchParamPage = searchParams.get('p')
    const searchParamFilterDate = searchParams.get('d')
    const searchParamWarehouseId = searchParams.get('wh')
    const searchParamPartName = searchParams.get('pn')
    const searchParamCategoryId = searchParams.get('g')
    const searchParamMarkId = searchParams.get('markId')
    const searchParamModelId = searchParams.get('modelId')
    const searchParamPriceFrom = searchParams.get('priceFrom')
    const searchParamPriceTo = searchParams.get('priceTo')
    const searchParamPartNumber = searchParams.get('partNumber')
    const searchParamSortOrder = searchParams.get('sortOrder')
    const searchParamDocumentId = searchParams.get('documentId')

    // Car filter
    const carFilter = useSelector((state) => state.partList.filters.car)
    const page = useSelector((state) => state.partList.filters.page)

    const navigateWithParams = (partId = '') => {
        const queryParams = new URLSearchParams(location.search)

        if (partId) {
            if (params.partId !== partId) {
                navigate(`/admin/parts/drafts/${partId}?${queryParams.toString()}`)
            }
        } else {
            navigate(`/admin/parts/drafts?${queryParams.toString()}`)
        }
    }

    const filterPartsByDate = (date: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterDateFilterParts,
            value: {
                filterDate: date
            }
        })
    }

    const filterPartsByPartName = (partName: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: partName
            }
        })
    }

    const filterPartsByCategoryId = (categoryId: string) => {
        dispatch({
            type: storeDispatchTypes.setCategoryFilterParts,
            value: {
                filterId: categoryId
            }
        })
    }

    const filterPartsByWarehouseId = (warehouseId: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterWarehouseIdParts,
            value: {
                warehouseId: warehouseId
            }
        })
    }

    const filterPartsByMarkId = (markId: string) => {
        dispatch({
            type: storeDispatchTypes.setMarkFilterParts,
            value: {
                filterId: markId,
            }
        })
    }

    const filterPartsByModelId = (modelId: string) => {
        dispatch({
            type: storeDispatchTypes.setModelFilterParts,
            value: {
                filterId: modelId,
            }
        })
    }

    const filterPartsByPriceRange = (priceFrom: string | null, priceTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setPriceRangeParts,
            value: {
                from: priceFrom,
                to: priceTo,
            }
        })
    }

    const filterPartsByPartNumber = (partNumber: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNumberFilterParts,
            value: {
                filterId: partNumber,
            }
        })
    }

    const filterPartsBySortOrder = (sortField: string, sortOrder: string) => {
        dispatch({
            type: storeDispatchTypes.setPartListSort,
            value: {
                sortField,
                sortOrder,
            }
        })
    }

    const setCurrentPart = (currentPart?: Part) => {
        dispatch({
            type: storeDispatchTypes.setCurrentPart,
            value: currentPart,
        })
    }

    const openModal = (part: Part) => {
        if (!isSellPartModalOpen) {
            navigateWithParams(part.id)
            setIsModalOpen(true)
        }

        setCurrentPart(part)
    }

    const handleClose = () => {
        if (!isSellPartModalOpen) {
            navigateWithParams()
            setIsModalOpen(false)
        }
    }

    const { partId: soldPartId, isOpen: isSellPartModalOpen } = useSelector((state) => state.partList.sellPartModal)
    const isLoading = useSelector((state) => state.partList.isLoading.partList)
    const parts = useSelector((state) => state.partList.partList.data)

    // Sort
    const sortOrder = useSelector((state) => state.partList.sortOrder)

    //Select search filter
    const modelId = useSelector((state) => state.partList.filters.modelId)
    const markId = useSelector((state) => state.partList.filters.markId)
    const categoryId = useSelector((state) => state.partList.filters.categoryId)
    const partId = useSelector((state) => state.partList.filters.partId)
    const partNumber = useSelector((state) => state.partList.filters.partNumber)

    // search filter part title
    const partName = useSelector((state) => state.partList.filters.partName)

    // Range Filters
    const priceRange = useSelector((state) => state.partList.filters.priceRange)
    const priceFrom = useSelector((state) => state.partList.filters.priceRange?.from)
    const priceTo = useSelector((state) => state.partList.filters.priceRange?.to)

    const yearRange = useSelector((state) => state.partList.filters.yearRange)
    const yearFrom = useSelector((state) => state.partList.filters.yearRange?.from)
    const yearTo = useSelector((state) => state.partList.filters.yearRange?.to)
    const filterDate = useSelector((state) => state.partList.filters.filterDate)
    const filterWarehouseId = useSelector((state) => state.partList.filters.warehouseId)

    const documentId = useSelector((state) => state.partList.filters.document?.documentId)

    // Counts
    const [counts, setCounts] = useState<Record<string, number>>()
    const itemsCount = useSelector(state => state.partList.partList.dataCount)
    const itemsTotal = useSelector(state => state.partList.partList.total)
    const draftCount = useSelector(state => state.partList.partList.draftCount)
    const archivedCount = useSelector(state => state.partList.partList.archivedCount)
    const soldCount = useSelector(state => state.partList.partList.soldCount)
    
    useEffect(() => {
        if (!isLoading) {
            setCounts({
                itemsTotal,
                draftCount,
                archivedCount,
                soldCount,
            })
        }
    }, [isLoading, itemsTotal, draftCount, archivedCount, soldCount])

    useEffect(() => {
        pagesParts = pagesParts.map(page => ({
            ...page,
            count: counts && counts[page.countKey],
        }))
    }, [counts])

    // load parts list data
    const loadParts = async () => {
        let partsId: string[] | undefined = undefined
        if (documentId) {
            await dispatch(GetPartsIdByDocumentId(documentId))
                .then(result => {
                    partsId = Array.from(new Set(result))
                })
        }

        dispatch(loadPartList({
            pagination: {
                page,
                perPage,
            },
            order: sortOrder,
            carId: carFilter?.carId,
            modelId, markId,
            groupId: categoryId,
            search: partName,
            priceRange: (priceRange && { from: priceFrom, to: priceTo }),
            yearRange: (yearRange && { from: yearFrom, to: yearTo }),
            partId: partId || partsId,
            partNumber,
            isDraft: true,
            filterDate,
            warehouseId: filterWarehouseId,
        }))
    }

    // pre-load marketplaces list
    useEffect(() => {
        dispatch(getMarketplacesInfo())
    }, [])

    // Загрузка параметров из строки поиска
    useEffect(() => {
        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterParts,
                value: { page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1 }
            })
        }

        if (searchParamWarehouseId !== filterWarehouseId && (searchParamWarehouseId || filterWarehouseId !== undefined)) {
            filterPartsByWarehouseId(searchParamWarehouseId ? searchParamWarehouseId : '')
        }

        if (searchParamFilterDate !== filterDate && (searchParamFilterDate || filterDate !== undefined)) {
            filterPartsByDate(searchParamFilterDate ? searchParamFilterDate : '')
        }

        if (searchParamPartName !== partName && (searchParamPartName || partName !== undefined)) {
            filterPartsByPartName(searchParamPartName ? searchParamPartName : '')
        }

        if (searchParamCategoryId !== `${categoryId}` && (searchParamCategoryId || categoryId !== undefined)) {
            filterPartsByCategoryId(searchParamCategoryId ? searchParamCategoryId : '')
        }

        if (searchParamMarkId !== `${markId}` && (searchParamMarkId || markId !== undefined)) {
            filterPartsByMarkId(searchParamMarkId ? searchParamMarkId : '')
        }

        if (searchParamModelId !== `${modelId}` && (searchParamModelId || modelId !== undefined)) {
            filterPartsByModelId(searchParamModelId ? searchParamModelId : '')
        }

        if (
            (searchParamPriceFrom !== `${priceFrom}` || (searchParamPriceFrom === null && priceFrom !== undefined)) ||
            (searchParamPriceTo !== `${priceTo}` || (searchParamPriceTo === null && priceTo !== undefined))
        ) {
            if (searchParamPriceFrom || priceFrom !== undefined || searchParamPriceTo || priceTo !== undefined) {
                filterPartsByPriceRange(searchParamPriceFrom, searchParamPriceTo)
            }
        }

        if ((searchParamPartNumber !== `${partNumber}`) && (searchParamPartNumber || partNumber !== undefined)) {
            filterPartsByPartNumber(searchParamPartNumber ? searchParamPartNumber : '')
        }

        if (searchParamSortOrder && (searchParamSortOrder !== sortOrder.map(pair => pair.join(',')).join(','))) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            // Для каждой пары из sortOrder, которая не содержится в searchParamSortOrder
            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    filterPartsBySortOrder(pair[0], 'NONE')
                }
            })

            // Для каждой пары из searchParamSortOrder, которая не содержится в sortOrder
            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    const [field, order] = param.split(',')
                    filterPartsBySortOrder(field, order)
                }
            })
        } else if (!searchParamSortOrder) {
            for (const field of sortOrder) {
                filterPartsBySortOrder(field[0], 'NONE')
            }
        }

        if (searchParamCarId !== `${carFilter?.carId[0]}`) {
            if (!searchParamCarId) {
                dispatch({ type: storeDispatchTypes.resetCarFilterParts })
            } else {
                dispatch({
                    type: storeDispatchTypes.setCarFilterParts,
                    value: {
                        car: {
                            carId: [searchParamCarId],
                        }
                    }
                })
            }
        } else if (!carFilter?.carId[0]) {
            dispatch({ type: storeDispatchTypes.resetCarFilterParts })
        }

        if ((searchParamDocumentId !== documentId) && (searchParamDocumentId || documentId !== undefined)) {
            dispatch({
                type: storeDispatchTypes.setDocumentFilterParts,
                value: {
                    document: {
                        documentId: searchParamDocumentId,
                    }
                }
            })
        }

        setSearchParamsLoaded(1)
    }, [searchParams])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!searchParamsLoaded) {
            return
        }

        // Текущие параметры
        const currentPage = page > 1 ? `${page}` : null
        const currentCarId = carFilter?.carId ? `${carFilter.carId}` : null
        const currentFilterDate = filterDate ? `${filterDate}` : null
        const currentWarehouseId = filterWarehouseId ? `${filterWarehouseId}` : null
        const currentPartName = partName ? `${partName}` : null
        const currentCategoryId = categoryId ? `${categoryId}` : null
        const currentMarkId = markId ? `${markId}` : null
        const currentModelId = modelId ? `${modelId}` : null
        const currentPriceFrom = priceFrom ? `${priceFrom}` : null
        const currentPriceTo = priceTo ? `${priceTo}` : null
        const currentPartNumber = partNumber ? `${partNumber}` : null
        const currentSortOrder = sortOrder.length ? sortOrder : null
        const currentDocumentId = documentId ? `${documentId}` : null

        // Проверяем, изменились ли параметры строки поиска
        const pageChanged = searchParamPage !== currentPage
        const carIdChanged = searchParamCarId !== currentCarId
        const filterDateChanged = searchParamFilterDate !== currentFilterDate
        const warehouseIdChanged = searchParamWarehouseId !== currentWarehouseId
        const partNameChanged = searchParamPartName !== currentPartName
        const categoryIdChanged = searchParamCategoryId !== currentCategoryId
        const markIdChanged = searchParamMarkId !== currentMarkId
        const modelIdChanged = searchParamModelId !== currentModelId
        const priceFromChanged = searchParamPriceFrom !== currentPriceFrom
        const priceToChanged = searchParamPriceTo !== currentPriceTo
        const partNumberChanged = searchParamPartNumber !== currentPartNumber
        const documentIdChanged = searchParamDocumentId !== currentDocumentId

        let sortOrderChanged = false
        if (searchParamSortOrder) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    sortOrderChanged = true
                    return
                }
            })

            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    sortOrderChanged = true
                    return
                }
            })
        } else if (!searchParamSortOrder && currentSortOrder) {
            sortOrderChanged = true
        }

        const searchParamsChanged =
        carIdChanged || pageChanged || filterDateChanged || warehouseIdChanged || partNameChanged ||
        categoryIdChanged || markIdChanged || modelIdChanged || priceFromChanged || priceToChanged ||
        partNumberChanged || sortOrderChanged || documentIdChanged

        if (searchParamsChanged) {
            setSearchParams({
                ... (currentCarId ? { carId: `${currentCarId}` } : {}),
                ... (currentPage ? { p: `${currentPage}` } : {}),
                ... (currentFilterDate ? { d: `${currentFilterDate}` } : {}),
                ... (currentWarehouseId ? { wh: `${currentWarehouseId}` } : {}),
                ... (currentPartName ? { pn: `${currentPartName}` } : {}),
                ... (currentCategoryId ? { g: `${currentCategoryId}` } : {}),
                ... (currentMarkId ? { markId: `${currentMarkId}` } : {}),
                ... (currentModelId ? { modelId: `${currentModelId}` } : {}),
                ... (currentPriceFrom ? { priceFrom: `${currentPriceFrom}` } : {}),
                ... (currentPriceTo ? { priceTo: `${currentPriceTo}` } : {}),
                ... (currentPartNumber ? { partNumber: `${currentPartNumber}` } : {}),
                ... ((sortOrder && sortOrder.length !== 0) ? { sortOrder: `${sortOrder}` } : {}),
                ... (currentDocumentId ? { documentId: `${currentDocumentId}` } : {}),
            })
        }

        loadParts()
    }, [
        searchParamsLoaded, page, perPage, sortOrder, carFilter?.carId,
        modelId, markId, categoryId, partName, priceFrom, priceTo, yearFrom, yearTo,
        partId, partNumber, filterDate, filterWarehouseId, documentId,
    ])

    // load part warehouses data
    useEffect(() => {
        dispatch(warehousesSelectSearch(''))
        dispatch(loadMarketplacesList())
    }, [])

    const getPartById = async () => {
        const partId = soldPartId && isSellPartModalOpen ? soldPartId : params.partId

        if (partId) {
            const part = await DatahubApiService.getPartByPartId(partId, true)

            if (!part) {
                return
            } else if (!isModalOpen) {
                openModal(part)
            }
        }
    }

    // open modal by partId
    useEffect(() => {
        if (params.partId) {
            getPartById()
        } else {
            setIsModalOpen(false)
        }
    }, [params.partId])

    useEffect(() => {
        getPartById()
    }, [isSellPartModalOpen])

    // Главный поиск
    const setNameSearch = (searchTerm: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: searchTerm
            }
        })
    }

    // Сброс фильтров при уходе со страницы
    useResetFiltersOnUnmount()

    return (
        <div className={styles.wrap}>
            {currentPart && isModalOpen &&
                <ModalEditPart
                    part={currentPart}
                    isOpen={isModalOpen}
                    closeModal={() => handleClose()}
                    isDraft
                    confirmChanges={() => loadParts()}
                />
            }

            <div className={styles.rowWrap}>
                <SalesText
                    text={'Черновики запчастей'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>
            <div className={styles.rowWrap}>
                <SearchComponent oldSearchTerm={partName} setSearchTerm={setNameSearch}/>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.blueLight}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={6}
                    onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.newPart.location), { state: { draft: true } })}
                >
                    <>
                        <PlusLg size={24} color={colors.white} />
                        <SalesText
                            text={'Добавить черновик'}
                            marginLeftPixels={10}
                            fontSize={fontSizes.s}
                            color={colors.white}
                        />
                    </>
                </SalesButton>
            </div>
            <div className={styles.rowWrap}>
                <NavigationButtons pages={pagesParts} currentPage={getAdminNavigationPath(adminRouteAlias.draftsOfParts.location)} counts={counts} navigate={navigate}/>
                {!isLoading && parts.length === 0
                    ? <></>
                    : <div className={styles.wrapRight}>
                        <ListPagination
                            page={page}
                            setPage={(page) =>
                                dispatch({
                                    type: storeDispatchTypes.setPageFilterParts,
                                    value: { page },
                                })
                            }
                            perPage={perPage}
                            itemsCount={itemsCount}
                        />
                    </div>}
            </div>

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(54)}><TableHeadCategorySearchParts /></th>
                        <th scope="col" style={colWidth(204)}>
                            <TableHeadPartSearch oldSearchTerm={partName} />
                        </th>
                        <th scope="col" style={colWidth(100)}
                            className={`${carFilter ? styles.filtered : ''}`}>
                            {carFilter ? carFilter.markTitle : <TableHeadMarkSearchParts />}
                        </th>
                        <th scope="col" style={colWidth(200)}
                            className={`${carFilter ? styles.filtered : ''}`}>
                            {carFilter ? carFilter.modelTitle : <TableHeadModelSearchParts />}
                        </th>
                        <th scope="col" style={colWidth(35)}>Фото</th>
                        <th scope="col" style={colWidth(103)}>
                            <TableHeadPartNumberSearchParts />
                        </th>
                        <th scope="col" style={colWidth(95)}>
                            <TableHeadPriceRangeParts initialFromVal={priceFrom} initialToVal={priceTo} />
                        </th>
                        <th scope="col" style={colWidth(85)}>
                            Дата
                            {filterDate ? (<ResetFilter filterName="filterDate" />)
                                : (<ColumnSort
                                    iconType={SortIconType.NUMERIC}
                                    sortField={SortFieldParts.CREATED_AT}
                                    slice={'partList'}
                                    reverseSort={true}
                                />)
                            }
                        </th>
                        <th scope="col" style={colWidth(80)}>Действия</th>
                    </tr>
                </thead>
                {isLoading || !parts
                    ? (<ListSkeleton rowCount={9} columnCount={9} marginVerticalRem={0.21} />)
                    : (
                        <tbody>
                            {
                                parts.map((part, index) => (
                                    <tr key={index} className={styles.row}>
                                        <td scope="row" onClick={() => openModal(part)}><CategoryIcon
                                            categoryId={part.groupId} /></td>
                                        <td scope="row" onClick={() => openModal(part)}>
                                            {part.customTitle ? part.customTitle : part.title}
                                        </td>
                                        <td scope="row" onClick={() => openModal(part)}>{part.markTitle}</td>
                                        <td scope="row" onClick={() => openModal(part)}>{part.modelTitle}
                                            {part.modificationTitle ?
                                                <div>
                                                    <Badge
                                                        text={part.modificationTitle}
                                                        color='#ddd'
                                                        fontColor='#555'
                                                    />
                                                </div>
                                                : ''
                                            }
                                            {part.carVinCode ?
                                                <div>
                                                    <Badge
                                                        text={'vin: ' + part.carVinCode}
                                                        color='#aaa'
                                                    />
                                                </div>
                                                : ''
                                            }
                                        </td>
                                        <td scope="row" className={styles.imageRow}><CellThumbCarousel pictures={part.images} part={part} /></td>
                                        <td scope="row" className={styles.default}>
                                            <CopyButton
                                                copyText={part.partNumber}
                                                overlayText={'ОЕМ скопирован в буфер обмена'}
                                                isText
                                            />
                                        </td>
                                        <td scope="row">
                                            <CellEditPrice
                                                id={part.id}
                                                price={part.price}
                                            />
                                        </td>
                                        <td scope="row" onClick={() => filterPartsByDate(formatDate(part.createdAt))} className={styles.alignRight}>
                                            <div>
                                                <Badge
                                                    text={formatDate(part.createdAt)}
                                                    color='#ddd'
                                                    fontColor='#555'
                                                />
                                            </div>
                                            {+countDaysInStock(part.createdAt) > 10 ?
                                                <div>
                                                    <Badge
                                                        text={countDaysInStock(part.createdAt) + ' ' + num_word(+countDaysInStock(part.createdAt), ['день', 'дня', 'дней'])}
                                                        color='#eee'
                                                        fontColor='#777'
                                                    />
                                                </div> : ''}

                                        </td>
                                        <td scope="row"><CellActionsParts part={part} isSafari={browserName === 'Safari'} deleteOnly/></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    )
                }
            </Table>
            {!isLoading && parts.length === 0
                ? <ListNoItems slice={'partList'} />
                : <ListPagination
                    page={page}
                    setPage={(page) =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterParts,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />}
        </div>
    )
}

export default PartDraftList
