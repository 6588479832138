import { Dispatch } from 'redux'
import axios from "axios"
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { CatalogModel } from "../../../types/common/commonTypes"

let localModels = {}
const getAllCarModels = (searchTerm: string, markId?: string) => async (dispatch: Dispatch): Promise<void> => {
    const loadModels = async () => {
        if (Object.keys(localModels).length) {
            return
        }
        try {
            const models = await axios.get('https://api.stock-pro.net/catalog/data/models.json')
            if (models.data) {
                localModels = models.data
            }
        } catch (e) {
            console.error('loadModels error', e)
        }
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'models', value: true } })
    try {
        await loadModels()
        const data: Record<string, CatalogModel[]> = localModels
        let models
        let filteredList
        for (const key in data) {
            if(key === markId || key === "1") {
                models = [...data[key]]
            }
        }

        if(models) {
            filteredList = models.filter((model: CatalogModel) => model.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            filteredList.sort((a, b) => a.name.localeCompare(b.name))

        }
        dispatch({ type: storeDispatchTypes.setAllModelsCars, value: { allModels: filteredList } })

    } catch (e) {
        console.error('load cars marks failed', e)
    }
    // dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'models', value: false } })
}

export default getAllCarModels
