import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from "../../../constants/anchor"

const searchCarListModifications = (
    searchTerm: string
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        search: searchTerm,
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    try {
        const { data: { status, modifications } } = await apiInstance.post('/getCarsSelectSearchModification', params, config)
        if (status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setModificationsCars,
                value: {
                    modifications
                }
            })
        }
    } catch (e) {
        console.error('load cars modifications failed', e)
    }
}

export default searchCarListModifications
