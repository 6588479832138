import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { Dropdown, Table } from "react-bootstrap"
import {
    ChevronLeft,
    ChevronRight,
} from "react-bootstrap-icons"
import { NavigateFunction, useNavigate, useSearchParams, Link, useParams } from "react-router-dom"
import { browserName } from "react-device-detect"
import moment from "moment"
import styles from "./styles.module.scss"
import { useSelector } from "../../modules/store/customSelector"
import CategoryIcon from "../../components/_atoms/CategogyIcon/CategoryIcon"
import { countDaysInStock, formatDate, num_word } from "../../utils/converters"
import CellThumbCarousel from "../../components/_orgamisms/CellThumbCarousel/CellThumbCarousel"
import ListPagination from "../../components/_atoms/ListPagination/ListPagination"
import ListSkeleton from "../../components/_atoms/ListSkeleton/ListSkeleton"
import { SortFieldParts, SortIconType } from "../../types/common/commonTypes"
import ColumnSort from "../../components/_molecules/ColumnSort/ColumnSort"
import { colWidth } from "../../utils/stylePatches"
import ListNoItems from "../../components/_atoms/ListNoItems/ListNoItems"
import TableHeadCategorySearchParts from "../../components/_molecules/TableHeadCategorySearchParts/TableHeadCategorySearchParts"
import TableHeadMarkSearchParts from "../../components/_molecules/TableHeadMarkSearchParts/TableHeadMarkSearchParts"
import TableHeadModelSearchParts from "../../components/_molecules/TableHeadModelSearchParts/TableHeadModelSearchParts"
import TableHeadPartNumberSearchParts from "../../components/_molecules/TableHeadPartNumberSearchParts/TableHeadPartNumberSearchParts"
import SalesText from "../../components/_atoms/SalesText/SalesText"
import { colors, fontSizes, fontWeights } from "../../constants/salesStyles"
import TableHeadPartSearch from "../../components/_atoms/TableHeadPartSearch/TableHeadPartSearch"
import TableHeadRangeFilter from "../../components/_atoms/TableHeadRangeFilter/TableHeadRangeFilter"
import { storeDispatchTypes } from "../../modules/store/storeDispatchTypes"
import { FilterSelect } from "../../constants/filterSelect"
import "moment/locale/ru"
import getSummaryByUserId from "../../modules/redux/sales/getSummaryByUserId"
import Badge from "../../components/_atoms/Badge/Badge"
import { adminRouteAlias, getAdminNavigationPath } from "../../router/adminRouteAlias"
import getCarById from "../../modules/redux/createUpdateForm/getCarById"
import ResetFilter from "../../components/_molecules/ResetFilter/ResetFilter"
import CopyButton from "../../components/_atoms/CopyButton/CopyButton"
import useResetFiltersOnUnmount from "../../hooks/partList/useResetFiltersOnUnmount"
import SearchComponent from "../../components/_molecules/SearchComponent/SearchComponent"
import NavigationButtons from "../../components/_molecules/NavigationButtons/NavigationButtons"
import loadPartList from "../../modules/redux/partList/LoadPartList"
import { pages } from "../../constants/parts/navigationButtonsPages"
import SalesButton from "../../components/_atoms/SalesButton/SalesButton"
import CellActionsParts from "../../components/_orgamisms/CellActionsParts/CellActionsParts"
import { Marketplaces, MarketplacesKeys, Part, PartListPageParams } from "../../types/part/partTypes"
import ModalViewPart from "../../components/_molecules/ModalViewPart/ModalViewPart"
import DatahubApiService from "../../modules/api/DatahubApiService"

const SoldPartList = () => {
    const params = useParams<PartListPageParams>()
    const dispatch = useDispatch()
    const [period, setPeriod] = useState<FilterSelect>(FilterSelect.ALL)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const navigate: NavigateFunction = useNavigate()
    let pagesParts = pages
    
    const currentPart = useSelector(state => state.partList.currentPart)
    const [isPartModalOpen, setIsPartModalOpen] = useState(false)

    const isLoading = useSelector((state) => state.partList.isLoading.partList)
    const parts = useSelector((state) => state.partList.partList.data)

    const userId = useSelector(state => state.userData.smsAuth.userId)

    // Sort
    const sortOrder = useSelector((state) => state.partList.sortOrder)

    // Car filter
    const carFilter = useSelector((state) => state.partList.filters.car)

    //Select search filter
    const modelId = useSelector((state) => state.partList.filters.modelId)
    const markId = useSelector((state) => state.partList.filters.markId)
    const categoryId = useSelector((state) => state.partList.filters.categoryId)
    const partId = useSelector((state) => state.partList.filters.partId)
    const partNumber = useSelector((state) => state.partList.filters.partNumber)

    // search filter part title
    const partName = useSelector((state) => state.partList.filters.partName)

    // Range Filters
    const priceRange = useSelector((state) => state.partList.filters.priceRange)
    const priceFrom = useSelector((state) => state.partList.filters.priceRange?.from)
    const priceTo = useSelector((state) => state.partList.filters.priceRange?.to)

    const yearRange = useSelector((state) => state.partList.filters.yearRange)
    const yearFrom = useSelector((state) => state.partList.filters.yearRange?.from)
    const yearTo = useSelector((state) => state.partList.filters.yearRange?.to)
    const dateRange = useSelector((state) => state.partList.filters.dateRange)
    const page = useSelector((state) => state.partList.filters.page)
    const perPage = useSelector((state) => state.partList.filters.perPage)
    const filterVendorCode = useSelector((state) => state.partList.filters.filterVendorCode)
    const marketplace = useSelector((state) => state.partList.filters.marketplace)

    // Параметры поиска
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(0)
    const searchParamCarId = searchParams.get('carId')
    const searchParamPage = searchParams.get('p')
    const searchParamPartName = searchParams.get('pn')
    const searchParamCategoryId = searchParams.get('g')
    const searchParamMarkId = searchParams.get('markId')
    const searchParamModelId = searchParams.get('modelId')
    const searchParamPriceFrom = searchParams.get('priceFrom')
    const searchParamPriceTo = searchParams.get('priceTo')
    const searchParamPartNumber = searchParams.get('partNumber')
    const searchParamSortOrder = searchParams.get('sortOrder')
    const searchParamMarketplace = searchParams.get('marketplace')

    // Counts
    const [counts, setCounts] = useState<Record<string, number>>()
    const itemsCount = useSelector(state => state.partList.partList.dataCount)
    const itemsTotal = useSelector(state => state.partList.partList.total)
    const draftCount = useSelector(state => state.partList.partList.draftCount)
    const archivedCount = useSelector(state => state.partList.partList.archivedCount)
    const soldCount = useSelector(state => state.partList.partList.soldCount)
    
    useEffect(() => {
        if (!isLoading) {
            setCounts({
                itemsTotal,
                draftCount,
                archivedCount,
                soldCount,
            })
        }
    }, [isLoading, itemsTotal, draftCount, archivedCount, soldCount])

    useEffect(() => {
        pagesParts = pagesParts.map(page => ({
            ...page,
            count: counts && counts[page.countKey],
        }))
    }, [counts])

    const filterPartsByPartName = (partName: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: partName
            }
        })
    }

    const filterPartsByCategoryId = (categoryId: string) => {
        dispatch({
            type: storeDispatchTypes.setCategoryFilterParts,
            value: {
                filterId: categoryId
            }
        })
    }

    const filterPartsByMarkId = (markId: string) => {
        dispatch({
            type: storeDispatchTypes.setMarkFilterParts,
            value: {
                filterId: markId,
            }
        })
    }

    const filterPartsByModelId = (modelId: string) => {
        dispatch({
            type: storeDispatchTypes.setModelFilterParts,
            value: {
                filterId: modelId,
            }
        })
    }

    const filterPartsByPriceRange = (priceFrom: string | null, priceTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setPriceRangeParts,
            value: {
                from: priceFrom,
                to: priceTo,
            }
        })
    }

    const filterPartsByPartNumber = (partNumber: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNumberFilterParts,
            value: {
                filterId: partNumber,
            }
        })
    }

    const filterPartsBySortOrder = (sortField: string, sortOrder: string) => {
        dispatch({
            type: storeDispatchTypes.setPartListSort,
            value: {
                sortField,
                sortOrder,
            }
        })
    }

    const filterPartsByMarketplace = (marketplace?: MarketplacesKeys) => {
        dispatch({
            type: storeDispatchTypes.setPartListParamsMarketplace,
            value: { marketplace }
        })
    }

    const loadSearchParams = async () => {
        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterParts,
                value: { page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1 }
            })
        }

        if (searchParamPartName !== partName && (searchParamPartName || partName !== undefined)) {
            filterPartsByPartName(searchParamPartName ? searchParamPartName : '')
        }

        if (searchParamCategoryId !== `${categoryId}` && (searchParamCategoryId || categoryId !== undefined)) {
            filterPartsByCategoryId(searchParamCategoryId ? searchParamCategoryId : '')
        }

        if (searchParamMarkId !== `${markId}` && (searchParamMarkId || markId !== undefined)) {
            filterPartsByMarkId(searchParamMarkId ? searchParamMarkId : '')
        }

        if (searchParamModelId !== `${modelId}` && (searchParamModelId || modelId !== undefined)) {
            filterPartsByModelId(searchParamModelId ? searchParamModelId : '')
        }

        if (
            (searchParamPriceFrom !== `${priceFrom}` || (searchParamPriceFrom === null && priceFrom !== undefined)) ||
            (searchParamPriceTo !== `${priceTo}` || (searchParamPriceTo === null && priceTo !== undefined))
        ) {
            if (searchParamPriceFrom || priceFrom !== undefined || searchParamPriceTo || priceTo !== undefined) {
                filterPartsByPriceRange(searchParamPriceFrom, searchParamPriceTo)
            }
        }

        if ((searchParamPartNumber !== `${partNumber}`) && (searchParamPartNumber || partNumber !== undefined)) {
            filterPartsByPartNumber(searchParamPartNumber ? searchParamPartNumber : '')
        }

        if (searchParamSortOrder && (searchParamSortOrder !== sortOrder.map(pair => pair.join(',')).join(','))) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            // Для каждой пары из sortOrder, которая не содержится в searchParamSortOrder
            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    filterPartsBySortOrder(pair[0], 'NONE')
                }
            })

            // Для каждой пары из searchParamSortOrder, которая не содержится в sortOrder
            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    const [field, order] = param.split(',')
                    filterPartsBySortOrder(field, order)
                }
            })
        } else if (!searchParamSortOrder) {
            for (const field of sortOrder) {
                filterPartsBySortOrder(field[0], 'NONE')
            }
        }

        if (searchParamCarId !== `${carFilter?.carId[0]}`) {
            if (!searchParamCarId) {
                dispatch({ type: storeDispatchTypes.resetCarFilterParts })
            } else {
                if (userId) {
                    const car = await getCarById(searchParamCarId, userId)
                    if (car) {
                        dispatch({
                            type: storeDispatchTypes.setCarFilterParts,
                            value: {
                                car: {
                                    carId: [car.id],
                                    markTitle: car.markTitle,
                                    modelTitle: car.modelTitle,
                                    year: car.year,
                                }
                            }
                        })
                    }
                }
            }
        } else if (!carFilter?.carId[0]) {
            dispatch({ type: storeDispatchTypes.resetCarFilterParts })
        }

        if ((searchParamMarketplace !== String(marketplace)) &&
        (searchParamMarketplace || marketplace !== undefined)) {
            dispatch({
                type: storeDispatchTypes.setPartListParamsMarketplace,
                value: {
                    marketplace: searchParamMarketplace,
                }
            })
        }

        setSearchParamsLoaded(1)
    }

    // Загрузка параметров из строки поиска
    useEffect(() => {
        loadSearchParams()
    }, [searchParams])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!searchParamsLoaded) {
            return
        }

        // Текущие параметры
        const currentPage = page > 1 ? `${page}` : null
        const currentCarId = carFilter?.carId ? `${carFilter.carId}` : null
        const currentPartName = partName ? `${partName}` : null
        const currentCategoryId = categoryId ? `${categoryId}` : null
        const currentMarkId = markId ? `${markId}` : null
        const currentModelId = modelId ? `${modelId}` : null
        const currentPriceFrom = priceFrom ? `${priceFrom}` : null
        const currentPriceTo = priceTo ? `${priceTo}` : null
        const currentPartNumber = partNumber ? `${partNumber}` : null
        const currentSortOrder = sortOrder.length ? sortOrder : null
        const currentMarketplace = marketplace !== undefined ? `${marketplace}` : null

        // Проверяем, изменились ли параметры строки поиска
        const pageChanged = searchParamPage !== currentPage
        const carIdChanged = searchParamCarId !== currentCarId
        const partNameChanged = searchParamPartName !== currentPartName
        const categoryIdChanged = searchParamCategoryId !== currentCategoryId
        const markIdChanged = searchParamMarkId !== currentMarkId
        const modelIdChanged = searchParamModelId !== currentModelId
        const priceFromChanged = searchParamPriceFrom !== currentPriceFrom
        const priceToChanged = searchParamPriceTo !== currentPriceTo
        const partNumberChanged = searchParamPartNumber !== currentPartNumber
        const markeplaceChanged = searchParamMarketplace !== currentMarketplace

        let sortOrderChanged = false
        if (searchParamSortOrder) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    sortOrderChanged = true
                    return
                }
            })

            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    sortOrderChanged = true
                    return
                }
            })
        } else if (!searchParamSortOrder && currentSortOrder) {
            sortOrderChanged = true
        }

        const searchParamsChanged =
        carIdChanged || pageChanged || partNameChanged ||
        categoryIdChanged || markIdChanged || modelIdChanged || priceFromChanged || priceToChanged ||
        partNumberChanged || sortOrderChanged || markeplaceChanged

        if (searchParamsChanged) {
            setSearchParams({
                ... (carFilter?.carId.length ? { carId: carFilter?.carId[0] || '' } : {}),
                ... (page > 1 ? { p: "" + page } : {}),
                ... (partName ? { pn: '' + partName } : {}),
                ... (categoryId ? { g: '' + categoryId } : {}),
                ... (markId ? { markId: '' + markId } : {}),
                ... (modelId ? { modelId: '' + modelId } : {}),
                ... (priceFrom ? { priceFrom: '' + priceFrom } : {}),
                ... (priceTo ? { priceTo: '' + priceTo } : {}),
                ... (partNumber ? { partNumber: '' + partNumber } : {}),
                ... ((sortOrder && sortOrder.length !== 0) ? { sortOrder: '' + sortOrder } : {}),
                ... (marketplace && { marketplace }),
            })
        }

        // load sold parts list data
        dispatch(
            loadPartList({
                pagination: {
                    page,
                    perPage,
                },
                order: sortOrder,
                carId: carFilter?.carId,
                modelId,
                markId,
                groupId: categoryId,
                search: partName,
                priceRange: (priceRange && { from: priceFrom, to: priceTo }),
                yearRange: (yearRange && { from: yearFrom, to: yearTo }),
                partId,
                partNumber,
                isSold: true,
                dateRange,
                marketplace,
            })
        )
    }, [
        searchParamsLoaded,
        page,
        perPage,
        sortOrder,
        carFilter?.carId,
        modelId,
        markId,
        categoryId,
        partName,
        priceFrom,
        priceTo,
        yearFrom,
        yearTo,
        partId,
        partNumber,
        dateRange,
        marketplace,
    ])

    useEffect(() => {
        dispatch({
            type: storeDispatchTypes.setDateRangeParts,
            value: {
                from: startDate,
                to: endDate,
            }
        })
    }, [startDate, endDate])

    const handlePeriodChange = (selectedPeriod: FilterSelect) => {
        setPeriod(selectedPeriod)
        setStartDate(null)
        setEndDate(null)

        const currentDate = new Date()
        let periodStartDate: Date | null = null
        let periodEndDate: Date | null = null

        switch (selectedPeriod) {
            case FilterSelect.YEAR:
                periodStartDate = moment(currentDate).startOf("year").toDate()
                periodEndDate = moment(currentDate).endOf("year").toDate()
                break
            case FilterSelect.QUARTER:
                periodStartDate = moment(currentDate)
                    .startOf("quarter")
                    .toDate()
                periodEndDate = moment(currentDate).endOf("quarter").toDate()
                break
            case FilterSelect.MONTH:
                periodStartDate = moment(currentDate).startOf("month").toDate()
                periodEndDate = moment(currentDate).endOf("month").toDate()
                break
            case FilterSelect.WEEK:
                periodStartDate = moment(currentDate)
                    .startOf("isoWeek")
                    .toDate()
                periodEndDate = moment(currentDate).endOf("isoWeek").toDate()
                break
            case FilterSelect.DAY:
                periodStartDate = moment(currentDate).startOf("day").toDate()
                periodEndDate = moment(currentDate).endOf("day").toDate()
                break
        }

        setStartDate(periodStartDate)
        setEndDate(periodEndDate)
    }

    const handlePreviousPeriod = () => {
        const periodDuration = getPeriodDuration(period)
        const previousStartDate = moment(startDate)
            .subtract(1, periodDuration)
            .toDate()
        const previousEndDate = moment(endDate)
            .subtract(1, periodDuration)
            .toDate()

        setStartDate(previousStartDate)
        setEndDate(previousEndDate)
    }

    const handleNextPeriod = () => {
        const periodDuration = getPeriodDuration(period)
        const nextStartDate = moment(startDate).add(1, periodDuration).toDate()
        const nextEndDate = moment(endDate).add(1, periodDuration).toDate()

        setStartDate(nextStartDate)
        setEndDate(nextEndDate)
    }

    const getPeriodDuration = (
        period: FilterSelect
    ): moment.unitOfTime.DurationConstructor => {
        switch (period) {
            case FilterSelect.MONTH:
                return "month"
            case FilterSelect.QUARTER:
                return "quarter"
            case FilterSelect.WEEK:
                return "week"
            case FilterSelect.YEAR:
                return "year"
            case FilterSelect.DAY:
                return "day"
            default:
                throw new Error(`Invalid period: ${period}`)
        }
    }

    const buttonText = {
        [FilterSelect.ALL]: null,
        [FilterSelect.YEAR]: moment(startDate).format("YYYY год"),
        [FilterSelect.QUARTER]: moment(startDate).format("Q Квартал YYYY"),
        [FilterSelect.MONTH]: ` Месяц: ${moment(startDate).format(
            "MMMM YYYY"
        )}`,
        [FilterSelect.WEEK]: `Неделя: ${moment(startDate).format(
            "DD MMM YYYY"
        )}-${moment(endDate).format("DD MMM YYYY")}`,
        [FilterSelect.DAY]: `${moment(startDate).format("DD MMMM")}`,
    }[period]

    // Статистика продаж
    const [profit, setProfit] = useState(0)
    const [soldParts, setSoldParts] = useState(0)
    const [averageCheck, setAverageCheck] = useState(0)
    const userSummary = useSelector(state => state.sales.userSummary)

    useEffect(() => {
        setProfit(userSummary.soldSum)
        setSoldParts(userSummary.soldCount)
        setAverageCheck(Math.round(userSummary.soldSum / userSummary.soldCount))
    }, [userSummary])

    useEffect(() => {
        dispatch(getSummaryByUserId(dateRange, marketplace))
    }, [dateRange, marketplace])

    // Главный поиск
    const setNameSearch = (searchTerm: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: searchTerm
            }
        })
    }

    const setCurrentPart = (currentPart?: Part) => {
        dispatch({
            type: storeDispatchTypes.setCurrentPart,
            value: currentPart,
        })
    }

    const navigateWithParams = (partId = '') => {
        const queryParams = new URLSearchParams(location.search)

        if (partId) {
            if (params.partId !== partId) {
                navigate(`/admin/parts/sold/${partId}?${queryParams.toString()}`)
            }
        } else {
            navigate(`/admin/parts/sold?${queryParams.toString()}`)
        }
    }

    const openPartModal = (part: Part) => {
        navigateWithParams(part.id)
        setIsPartModalOpen(true)
        setCurrentPart(part)
    }

    const handleClose = () => {
        navigateWithParams()
        setIsPartModalOpen(false)
    }

    const getPartById = async () => {
        if (params.partId) {
            const part = await DatahubApiService.getPartByPartId(params.partId)

            if (!part) {
                return
            } else {
                setCurrentPart(part)
                setIsPartModalOpen(true)
            }
        }
    }

    // open modal by partId
    useEffect(() => {
        if (params.partId) {
            getPartById()
        } else {
            setIsPartModalOpen(false)
        }
    }, [params.partId])

    // Сброс фильтров при уходе со страницы
    useResetFiltersOnUnmount()

    return (
        <div className={styles.wrap}>
            {currentPart && isPartModalOpen &&
                <ModalViewPart
                    part={currentPart}
                    isOpen={isPartModalOpen}
                    closeModal={() => handleClose()}
                />}
            <div className={styles.rowWrap}>
                <SalesText
                    text={'Проданные запчасти'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>
            <div className={styles.rowWrap}>
                <SearchComponent oldSearchTerm={partName} setSearchTerm={setNameSearch}/>
            </div>
            <div className={styles.rowWrap}>
                <NavigationButtons pages={pagesParts} currentPage={getAdminNavigationPath(adminRouteAlias.soldParts.location)} counts={counts} navigate={navigate}/>
                <Dropdown className={styles.customDropdown}>
                    <Dropdown.Toggle>
                        {marketplace ? Marketplaces[marketplace] : 'Выбрать маркетплейс'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => filterPartsByMarketplace(undefined)}>Все</Dropdown.Item>
                        {Object.entries(Marketplaces).map(([key, value]) =>
                            <Dropdown.Item key={key} onClick={() => filterPartsByMarketplace(key as MarketplacesKeys)}>{value}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={styles.sortControls}>
                {Object.entries(FilterSelect).map(([key, value]) => (
                    <SalesButton
                        key={key}
                        borderRadius={10}
                        backgroundColor={value === period ? colors.grayDark : undefined}
                        paddingHorizontalPixels={12}
                        paddingVerticalPixels={7}
                        onClick={() => handlePeriodChange(value)}
                    >
                        <SalesText
                            text={value}
                            fontSize={fontSizes.s}
                            color={value === period ? colors.white : undefined}
                        />
                    </SalesButton>
                ))}
                
                {carFilter &&
                <Link to={`${getAdminNavigationPath(adminRouteAlias.cars.location)}/${carFilter?.carId}`} style={{ textDecoration: 'none' }}>
                    <SalesButton
                        borderRadius={10}
                        paddingHorizontalPixels={20}
                        paddingVerticalPixels={7}
                    >
                        <SalesText
                            text={`Перейти к авто ${carFilter?.markTitle} ${carFilter?.modelTitle} ${carFilter?.year}`}
                            fontSize={fontSizes.s}
                        />
                    </SalesButton>
                </Link>}
            </div>
            {buttonText &&
            <div className={styles.sortControls}>
                <div className={styles.sortControls}>
                    <SalesButton
                        borderRadius={10}
                        backgroundColor={colors.grayDark}
                        paddingHorizontalPixels={12}
                        paddingVerticalPixels={7}
                        onClick={handlePreviousPeriod}
                    >
                        <ChevronLeft size={18} color={colors.white}/>
                    </SalesButton>
                    <SalesButton
                        borderRadius={10}
                        backgroundColor={colors.grayDark}
                        paddingHorizontalPixels={12}
                        paddingVerticalPixels={7}
                        onClick={() => handlePeriodChange(period)}
                    >
                        <SalesText
                            text={buttonText}
                            fontSize={fontSizes.s}
                            color={colors.white}
                        />
                    </SalesButton>
                    <SalesButton
                        borderRadius={10}
                        backgroundColor={colors.grayDark}
                        paddingHorizontalPixels={12}
                        paddingVerticalPixels={7}
                        onClick={handleNextPeriod}
                        disabled={moment(endDate).isSameOrAfter(new Date(), 'day')}
                    >
                        <ChevronRight size={18} color={colors.white}/>
                    </SalesButton>
                </div>
            </div>}
            <div className={styles.statiscticsBlock}>
                <p className={styles.statiscticsBlockText}>Выручка за период: <b>{profit.toLocaleString('ru-RU')}</b> ₽</p>
                {soldParts ? <p className={styles.statiscticsBlockText}>Продано запчастей: <b>{soldParts.toLocaleString('ru-RU')}</b></p> : <></>}
                {soldParts ? <p className={styles.statiscticsBlockText}>Средний чек: <b>{averageCheck.toLocaleString('ru-RU')}</b> ₽</p> : <></>}
                {!isLoading && parts.length === 0
                    ? <></>
                    : <div className={styles.wrapRight}>
                        <ListPagination
                            page={page}
                            setPage={(page) =>
                                dispatch({
                                    type: storeDispatchTypes.setPageFilterParts,
                                    value: { page },
                                })
                            }
                            perPage={perPage}
                            itemsCount={itemsCount}
                        />
                    </div>}
            </div>

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(54)}>
                            <TableHeadCategorySearchParts />
                        </th>
                        <th scope="col" style={colWidth(204)}>
                            <TableHeadPartSearch oldSearchTerm={partName} />
                        </th>
                        <th
                            scope="col"
                            style={colWidth(100)}
                            className={`${carFilter ? styles.filtered : ""}`}
                        >
                            {carFilter ? (
                                carFilter.markTitle
                            ) : (
                                <TableHeadMarkSearchParts />
                            )}
                        </th>
                        <th
                            scope="col"
                            style={colWidth(200)}
                            className={`${carFilter ? styles.filtered : ""}`}
                        >
                            {carFilter ? (
                                carFilter.modelTitle
                            ) : (
                                <TableHeadModelSearchParts />
                            )}
                        </th>
                        <th scope="col" style={colWidth(35)}>
                            Фото
                        </th>
                        <th scope="col" style={colWidth(135)}>
                            Артикул
                            {filterVendorCode ? (<ResetFilter filterName="filterVendorCode" />)
                                : (<ColumnSort
                                    iconType={SortIconType.NUMERIC}
                                    sortField={SortFieldParts.VENDOR_CODE}
                                    slice={'partList'}
                                    reverseSort={true}
                                />)
                            }
                        </th>
                        <th scope="col" style={colWidth(103)}>
                            <TableHeadPartNumberSearchParts />
                        </th>
                        <th scope="col" style={colWidth(35)}>
                            Кол.
                        </th>
                        <th
                            scope="col"
                            style={colWidth(95)}
                        >
                            <TableHeadRangeFilter
                                initialTableHead={"Цена продажи"}
                                step={1000}
                                leftInPixes={-35}
                                initialFromVal={priceFrom}
                                initialToVal={priceTo}
                                widthInPixels={150}
                                storeActionSetRange={
                                    storeDispatchTypes.setPriceRangeParts
                                }
                            />
                        </th>
                        <th scope="col" style={colWidth(85)}>
                            Дата
                            <ColumnSort
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldParts.SOLD_AT}
                                slice={"partList"}
                                reverseSort={true}
                            />
                        </th>
                        <th scope="col" style={colWidth(99)}>
                            Действия
                        </th>
                    </tr>
                </thead>
                {isLoading || !parts ? (
                    <ListSkeleton
                        rowCount={9}
                        columnCount={11}
                        marginVerticalRem={0.21}
                    />
                ) : (
                    <tbody>
                        {parts.map((part, index) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    <CategoryIcon categoryId={part.groupId} />
                                </td>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    {part.customTitle
                                        ? part.customTitle
                                        : part.title}
                                </td>
                                <td scope="row" onClick={() => openPartModal(part)}>{part.markTitle}</td>
                                <td scope="row">{part.modelTitle}</td>
                                <td scope="row" className={styles.imageRow}>
                                    <CellThumbCarousel pictures={part.images} />
                                </td>
                                <td scope="row" className={styles.default}>
                                    <CopyButton
                                        copyText={part.vendorCodePrefix + part.vendorCode}
                                        overlayText={'Артикул скопирован в буфер обмена'}
                                        isText
                                    />
                                </td>
                                <td scope="row" className={styles.default}>
                                    <CopyButton
                                        copyText={part.partNumber}
                                        overlayText={'ОЕМ скопирован в буфер обмена'}
                                        isText
                                    />
                                </td>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    {!part.extraData?.soldQtyArr ? 1
                                    : part.isSold ? part.extraData?.soldQtyArr.length + 1
                                    : part.extraData?.soldQtyArr.length
                                    }
                                </td>
                                <td
                                    scope="row"
                                    className={styles.alignRight}
                                    onClick={() => openPartModal(part)}
                                >
                                    {!part.extraData?.soldQtyArr ? part.price
                                    : part.isSold ? part.price + part.extraData.soldQtyArr.reduce((summ, current) => summ + (current.price ? current.price : 0), 0)
                                    : part.extraData.soldQtyArr.reduce((summ, current) => summ + (current.price ? current.price : 0), 0)
                                    }
                                </td>
                                <td scope="row" className={styles.alignRight}>
                                    <div>
                                        <Badge
                                            text={formatDate(part.soldAt)}
                                            color='#ddd'
                                            fontColor='#555'
                                        />
                                    </div>
                                    {+countDaysInStock(`${part.soldAt}`) > 10 ?
                                        <div>
                                            <Badge
                                                text={countDaysInStock(`${part.soldAt}`) + ' ' + num_word(+countDaysInStock(`${part.soldAt}`), ['день', 'дня', 'дней'])}
                                                color='#eee'
                                                fontColor='#777'
                                            />
                                        </div> : ''}
                                </td>
                                <td className={styles.default}><CellActionsParts part={part} isSafari={browserName === 'Safari'} isSoldPartList/></td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>
            {!isLoading && parts.length === 0
                ? <ListNoItems slice={'partList'} />
                : <ListPagination
                    page={page}
                    setPage={(page) =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterParts,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />}
        </div>
    )
}

export default SoldPartList
