import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { ApiCarCreateUpdate } from '../../../types/car/carTypes'


const saveCarInCloud = (
    carObject: ApiCarCreateUpdate,
    withImages: boolean
) => async (
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId: userData.smsAuth.userId,
        car: {
            ...carObject,
            userId: userData.smsAuth.userId,
        },
    }
    const config = {
        headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` }
    }
    dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'isCarCreated', value: false } })
    dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'isCarSaving', value: true } })

    try {
        const { data: { createdAt } } = await apiInstance.post('/saveCarInCloud', params, config)
        if (createdAt && !withImages) {
            dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'isCarCreated', value: true } })
        }
        // @ts-ignore
        window.ym(74629465, 'reachGoal', 'addautoweb')
    } catch (e) {
        console.error('save car in cloud failed', e)
    }
    if (!withImages) {
        dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'isCarSaving', value: false } })
    }
}

export default saveCarInCloud
