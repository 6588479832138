import React from 'react'

import { TCustomLink } from '../../../types/common/commonTypes'
import CustomLink from '../../_atoms/CustomLink/CustomLink'

import styles from './styles.module.scss'

interface IProps {
    links: TCustomLink[]
}

const HeaderLinks = ({ links }: IProps) => (
    <>
        {links.length > 0 && (
            <div className={styles.wrap}>
                {links.map(({ upperText, text, href, target }, index) => (
                    <CustomLink
                        key={text + index}
                        upperText={upperText}
                        text={text}
                        href={href}
                        target={target}
                        marginRight={0}
                    />
                ))}
            </div>
        )}
    </>
)

export default HeaderLinks
