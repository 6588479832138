import React from 'react'
import styles from './styles.module.scss'
import SalesButtonStandard from "../SalesButtonStandard/SalesButtonStandard"
import { colors } from "../../../constants/salesStyles"

interface IProps {
    options: string[],
    activeOption: number,
    onChangeValue: (value: number) => void
}

const PartSwitch = ({ options, activeOption, onChangeValue }: IProps) => (
    <div className={styles.wrap}>
        {options.map((option, index) => (
            <SalesButtonStandard
                key={option}
                text={option}
                backgroundColor={index === activeOption ? colors.white : colors.transparent}
                onClick={() => onChangeValue(index)}
                marginRightPixels={0}
            />
        ))}

    </div>
)

export default PartSwitch
