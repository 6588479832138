import { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import axios from 'axios'

import conditions from '../../../constants/conditions'
import partsTypeIdNotRequireCar from '../../../constants/partsTypeIdNotRequireCar'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import DatahubApiService from '../../../modules/api/DatahubApiService'
import StickersApiService from '../../../modules/api/StickersApiService'
import getCarsSelectSearchCarTitleBodyTypeVINcode from '../../../modules/redux/carList/getCarsSelectSearchCarTitleBodyTypeVINcode'
import getAllBrakeDOT from '../../../modules/redux/catalogs/getAllBrakeDOT'
import getAllCarMarks from '../../../modules/redux/catalogs/getAllCarMarks'
import getAllCarModels from '../../../modules/redux/catalogs/getAllCarModels'
import getAllCarModifications from '../../../modules/redux/catalogs/getAllCarModifications'
import getAllCoolantColors from '../../../modules/redux/catalogs/getAllCoolantColors'
import getAllOilsACEA from '../../../modules/redux/catalogs/getAllOilsACEA'
import getAllOilsAPI from '../../../modules/redux/catalogs/getAllOilsAPI'
import getAllOilsASTM from '../../../modules/redux/catalogs/getAllOilsASTM'
import getAllOilsBrands from '../../../modules/redux/catalogs/getAllOilsBrands'
import getAllOilsOEMOil from '../../../modules/redux/catalogs/getAllOilsOEMOil'
import getAllOilsVolumes from '../../../modules/redux/catalogs/getAllOilsVolumes'
import getAllPartBrands from '../../../modules/redux/catalogs/getAllPartBrands'
import getAllPartTypes from '../../../modules/redux/catalogs/getAllPartTypes'
import getAllTiresBrands from '../../../modules/redux/catalogs/getAllTiresBrands'
import getModificationYears from '../../../modules/redux/catalogs/getModificationYears'
import getCarById from '../../../modules/redux/createUpdateForm/getCarById'
import saveImageInCloud from '../../../modules/redux/createUpdateForm/saveImageInCloud'
import addPartsToInvoice from '../../../modules/redux/documentList/AddPartsToInvoice'
import getDocumentsSelectSearch from '../../../modules/redux/documentList/GetDocumentsSelectSearch'
import getIncomingInvoicesByPartsId from '../../../modules/redux/documentList/GetIncomingInvoicesByPartsId'
import removePartsIdFromIncomingInvoice from '../../../modules/redux/documentList/RemovePartsIdFromIncomingInvoice'
import deletePartImage from '../../../modules/redux/partList/DeletePartImage'
import getApplicabilityByPartId from '../../../modules/redux/partList/GetApplicabilityByPartId'
import getApplicabilitySuggestion from '../../../modules/redux/partList/GetApplicabilitySuggestion'
import getPartPriceRecommendedChart from '../../../modules/redux/partList/getPartPriceRecommendedChart'
import getPartDescTemplatesByPartId from '../../../modules/redux/partList/getPartTemplateButtons'
import getPartTypeByTypeId from '../../../modules/redux/partList/GetPartTypeByTypeId'
import reorderPartImages from '../../../modules/redux/partList/ReorderPartImages'
import savePartInCloud from '../../../modules/redux/partList/savePartInCloud'
import setApplicability from '../../../modules/redux/partList/SetApplicability'
import updateApplicabilityById from '../../../modules/redux/partList/UpdateApplicabilityById'
import updatePartExtraDataValue from '../../../modules/redux/partList/UpdatePartExtraDataValue'
import getMarketplacesSettings from '../../../modules/redux/sales/getMarketplacesSettings'
import setPartWarehousePosition from '../../../modules/redux/warehouseList/SetPartWarehousePosition'
import { AppDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { initialApiPartObject } from '../../../pages/NewPart/initialApiPartObject'
import { partReducer } from '../../../pages/NewPart/partReducer'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import { Car, carFields, carSpecialPropsFields } from '../../../types/car/carTypes'
import { CatalogModification } from '../../../types/common/commonTypes'
import { DocumentType } from '../../../types/document/documentTypes'
import {
    Part,
    templateButton,
    partFields,
    partInput,
    ApiPartCreateUpdate,
    partCreateUpdateActionTypes,
    partSpecialPropsFields,
    fieldValuePair,
    IPriceChartResponse,
    ApplicabilityCreation,
    Applicability,
    MarketplacesKeys,
} from '../../../types/part/partTypes'
import { getFrontRearLeftRight } from '../../../utils/getFrontRearLeftRight'
import { isDeepEqualSimple } from '../../../utils/misc'
import Loader from '../../_atoms/Loader/Loader'
import ModalImageGroup from '../../_atoms/ModalImageGroup/ModalImageGroup'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesButton from '../../_atoms/SalesButton/SalesButton'
import SalesText from '../../_atoms/SalesText/SalesText'
import YandexShare from '../../_atoms/YandexShare/YandexShare'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'
import PriceChart from '../PriceChart/PriceChart'
import SalesCard from '../SalesCard/SalesCard'

import { initialPartState } from './initialPartState'
import styles from './styles.module.scss'

interface IProps {
    part: Part
    isOpen: boolean
    closeModal: () => void
    isDraft?: boolean
    confirmChanges: () => void
}

const ModalEditPart = ({ part, isOpen, closeModal, isDraft = false, confirmChanges }: IProps) => {
    const title = 'Редактирование запчасти'
    const dispatchRedux = useDispatch<AppDispatch>()
    // @ts-ignore для structedClone
    const [state, dispatch] = useReducer(partReducer, structuredClone(initialPartState))
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const isPartLoading = useSelector(state => state.partList.isLoading.partList)

    const [imagesToDelete, setImagesToDelete] = useState<string[]>([])
    const [imagesToReorder, setImagesToReorder] = useState([...part.images])

    const userId = useSelector(state => state.userData.smsAuth.userId)

    let incomingInvoiceId = ''
    const document = useSelector(state => state.documentList.document)

    const warehouses = useSelector(state => state.warehouseList.warehousesSS)
    const applicabilitySelected = useSelector(state => state.partList.applicability.applicabilitySelected)
    const applicabilitySuggestion = useSelector(state => state.partList.applicability.applicabilitySuggestion)
    const partType = useSelector(state => state.partList.partType)
    const cars = useSelector(state => state.carList.cars)

    const markImg = (id: string) => {
        setImagesToDelete([id, ...imagesToDelete])
    }
    const unmarkImg = (id: string) => {
        setImagesToDelete(prevImages => prevImages.filter(img => img !== id))
    }

    const [priceChartResponse, setPriceChartResponse] = useState<IPriceChartResponse | null>(null)
    const getPriceChartRes = async () => {
        const res = await getPartPriceRecommendedChart(part.typeId, part.markId, part.modelId, part.price)
        setPriceChartResponse(res)
    }

    const getField = (fieldToSearch: carFields | partFields) =>
        state.fields.find((field: partInput) => field.field === fieldToSearch)

    const getCarNameFromExistingCar = () => getField(partFields.carId)?.specialProps?.renderValue.replace(',', '')
    const getCarNameFromWithoutCar = () => {
        const carModel = getField(carFields.modelId)?.specialProps?.renderValue
        const carMark = getField(carFields.markId)?.specialProps?.renderValue
        const carYear = getField(carFields.year)?.value

        if (carModel && carMark && carYear) {
            return `${carMark} ${carModel} ${carYear}`.replace(',', '')
        }
    }

    const getCustomTitle = () => {
        const currentSwitch = getField(partFields.useExistingCar)?.value

        const carField = getField(partFields.carId)
        const car = cars.find(car => car.value === carField.value)

        const carNameFromExistingCar = car?.customTitle ?? getCarNameFromExistingCar()
        const carNameFromWithoutCar = getCarNameFromWithoutCar()
        const partName = getField(partFields.typeId)?.specialProps?.renderValue

        if (carNameFromExistingCar && partName && currentSwitch === 1) {
            return partName + ' ' + carNameFromExistingCar
        } else if (partName && currentSwitch === 2 && carNameFromWithoutCar) {
            return `${partName} ${carNameFromWithoutCar}`.replace(',', '')
        } else if (partName && currentSwitch === 0) {
            return `${partName}`
        } else {
            return ''
        }
    }

    const getPartDescriptionTemplate = () => {
        const partType = getField(partFields.typeId)?.specialProps?.renderValue || 'Название запчасти'
        const switchPosition = getField(partFields.useExistingCar).value
        const carName = switchPosition === 2 ? getCarNameFromWithoutCar() : getCarNameFromExistingCar()
        const car = carName || 'марка модель'
        const partNumber = getField(partFields.partNumber).value || ''
        const partNumberPhrase = !partNumber
            ? ''
            : `
Номер запчасти ${partNumber}.    
`
        const condition = getField(partFields.conditionId)?.specialProps?.renderValue?.toLowerCase() || 'хорошее'

        return `${partType} (другие названия) оригинал на ${car}.
${partNumberPhrase}
Также подходит на марка модель.

Состояние ${condition}, снята с авто с пробегом ___ тыс. км.

Есть много других запчастей на эту модель.

Доставка транспортными компаниями по России. Отличные отзывы. Качественная упаковка. Гарантия.

Работаем без выходных. Звоните!`
    }

    const getApplicabilityDescriptionTemplate = () => {
        const applicabilitySelectedItems = getField(partFields.applicabilitySelected).specialProps?.applicabilityItems
        if (applicabilitySelectedItems && applicabilitySelectedItems.length) {
            let text = 'Подходит на автомобили:\n'
            applicabilitySelectedItems.forEach((item: Applicability | ApplicabilityCreation) => {
                text += `${item.title}\n`
            })
            return text
        } else {
            return ''
        }
    }

    const [templateButtons, setTemplateButtons] = useState<templateButton[]>([])

    const [bodyTypes, setBodyTypes] = useState({} as Record<string, string>)
    const filteredModifications = useSelector(state => state.carList.filteredModifications)
    const getBodyTypeId = (modificationId: number) =>
        Number(
            filteredModifications.find(
                (modificationObject: CatalogModification) => Number(modificationObject.id) === modificationId,
            )?.bodyTypeId,
        )
    const getBodyTypeRenderValue = (modificationId: number) => bodyTypes[String(getBodyTypeId(modificationId))] || ''

    const setIsDisabledFields = () =>
        state.fields.map((field: partInput) => {
            if (field.field === carFields.bodyTypeId) {
                const modificationId = getField(carFields.modificationId).value
                return {
                    ...field,
                    value: getBodyTypeId(modificationId),
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: getBodyTypeRenderValue(modificationId),
                    },
                }
            }
            if (field.field === carFields.applicabilityBodyTypeId) {
                const modificationId = getField(carFields.applicabilityModificationId).value
                return {
                    ...field,
                    value: getBodyTypeId(modificationId),
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: getBodyTypeRenderValue(modificationId),
                    },
                }
            }
            if (field.field === carFields.applicabilityYear) {
                const modificationId = getField(carFields.applicabilityModificationId).value
                const modification = filteredModifications.find(
                    (modification: CatalogModification) => Number(modification.id) === modificationId,
                )
                return {
                    ...field,
                    value: modification ? modification.yearFrom : NaN,
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: modification
                            ? `${modification.yearFrom} - ${modification.yearTo}`
                            : '',
                    },
                }
            }

            if (field.field === partFields.typeId && getField(partFields.useExistingCar) !== 0) {
                return {
                    ...field,
                    specialProps: {
                        ...field.specialProps,
                        [partSpecialPropsFields.errorMessage]: '',
                    },
                }
            }

            if (field.field === partFields.customTitle && !field.value) {
                const newCustomTitle = getCustomTitle()
                const maxCustomTitleLength = field?.specialProps?.textareaMaxLength
                return {
                    ...field,
                    value: field.value,
                    specialProps: {
                        ...field.specialProps,
                        // [partSpecialPropsFields.errorMessage]: maxCustomTitleLength && newCustomTitle.length > maxCustomTitleLength ? 'превышена допустимая длина названия' : '',
                        [partSpecialPropsFields.placeholder]: newCustomTitle
                            ? newCustomTitle
                            : 'название для маркетплейсов',
                    },
                }
            }

            const selectSearchText = field.specialProps?.renderValue
            const errorMessage = field.value ? '' : field.specialProps?.errorMessage

            if (field.specialProps?.fieldParent) {
                const fieldParentValue = getField(field.specialProps.fieldParent).value
                const fieldParentValueInChild = field.specialProps?.[partSpecialPropsFields.fieldParentValue]
                const isEnabled = fieldParentValue
                const isParentChanged =
                    fieldParentValueInChild &&
                    fieldParentValue !== fieldParentValueInChild &&
                    !field.specialProps.isAdded

                return {
                    ...field,
                    value: isParentChanged ? '' : field.value,
                    specialProps: {
                        ...field.specialProps,
                        [partSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                        [partSpecialPropsFields.isDisabled]: !isEnabled,
                        [partSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                        [partSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                    },
                }
            } else {
                return field
            }
        })

    const getVisibleAny = (isVisibleAnyFields: fieldValuePair[]) => {
        let isVisible = false
        isVisibleAnyFields.forEach((item: fieldValuePair) => {
            if (getField(item.field).value === item.value) {
                isVisible = true
                return
            }
        })
        return isVisible
    }

    const getVisibleEvery = (isVisibleAllFields: fieldValuePair[][]) => {
        let isVisible = true
        for (const array of isVisibleAllFields) {
            if (!getVisibleAny(array)) isVisible = false
        }
        return isVisible
    }

    const getVisibleFields = () =>
        state.fields.filter((field: partInput) => {
            if (field.specialProps?.isVisibleEvery) {
                return getVisibleEvery(field.specialProps?.isVisibleEvery)
            } else if (field.specialProps?.isVisibleSwitch && field.specialProps?.isVisibleAny) {
                return (
                    getVisibleAny(field.specialProps?.isVisibleSwitch) &&
                    getVisibleAny(field.specialProps?.isVisibleAny)
                )
            } else if (field.specialProps?.isVisibleSwitch && !field.specialProps?.isVisibleAny) {
                return getVisibleAny(field.specialProps?.isVisibleSwitch)
            } else if (field.specialProps?.isVisibleAny && !field.specialProps?.isVisibleSwitch) {
                return getVisibleAny(field.specialProps?.isVisibleAny)
            } else {
                return true
            }
        })

    const getVisibleColumn = (column: number) =>
        getVisibleFields().filter((field: partInput) => field.specialProps?.column === column)

    const getIsSubmitDisabled = () => {
        let isDisabled = false
        if (!isDraft) {
            getVisibleFields().forEach((field: partInput) => {
                if (field?.specialProps?.errorMessage) {
                    isDisabled = true
                }
            })
        }
        setIsSubmitDisabled(isDisabled)
    }

    const onCloseModal = (e?: React.MouseEvent) => {
        e?.preventDefault()
        closeModal()
    }

    const printPart = () => {
        const url = StickersApiService.getEncodeUrlFromPart(part)
        if (url) window.open(url, '_blank')
    }

    const fillField = (field: partInput, value: number | string, renderValue: string) => {
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field: field.field,
                value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.renderValue]: renderValue,
                },
            },
        })
    }

    const fillInitialState = () => {
        state.fields.map((field: partInput) => {
            switch (field.field) {
                case partFields.useExistingCar:
                    if (part.carId || part.markId) {
                        dispatch({
                            type: partCreateUpdateActionTypes.updateField,
                            value: {
                                field: field.field,
                                value: part.carId ? 1 : 2,
                            },
                        })
                    }
                    break
                case partFields.carId:
                    if (part.carId) fillField(field, part.carId, `${part.markTitle} ${part.modelTitle} ${part.year}`)
                    break
                case partFields.markId:
                    if (part.markId) fillField(field, part.markId, part.markTitle)
                    break
                case partFields.modelId:
                    if (part.modelId) fillField(field, part.modelId, part.modelTitle)
                    break
                case partFields.modificationId:
                    if (part.modificationId) fillField(field, part.modificationId, part.modificationTitle)
                    break
                case partFields.year:
                    if (part.year) fillField(field, part.year, String(part.year))
                    break
                case partFields.groupId:
                    if (part.groupId) fillField(field, part.groupId, part.groupTitle)
                    break
                case partFields.typeId:
                    if (part.typeId) fillField(field, part.typeId, part.title)
                    break
                case partFields.customTitle: {
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.customTitle,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.placeholder]: getCustomTitle(),
                            },
                        },
                    })
                    break
                }
                case partFields.Quantity:
                    if (part.extraData?.Quantity)
                        fillField(field, part.extraData.Quantity, String(part.extraData.Quantity))
                    break
                case partFields.TireType:
                    if (part.extraData?.TireType)
                        fillField(field, part.extraData.TireType, String(part.extraData.TireType))
                    break
                case partFields.RimType:
                    if (part.extraData?.RimType)
                        fillField(field, part.extraData.RimType, String(part.extraData.RimType))
                    break
                case partFields.Brand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.TireModel:
                    if (part.extraData?.TireModel) fillField(field, part.extraData.TireModel, part.extraData.TireModel)
                    break
                case partFields.RimDiameter:
                    if (part.extraData?.RimDiameter)
                        fillField(field, part.extraData.RimDiameter, String(part.extraData.RimDiameter))
                    break
                case partFields.TireSectionWidth:
                    if (part.extraData?.TireSectionWidth)
                        fillField(field, part.extraData.TireSectionWidth, String(part.extraData.TireSectionWidth))
                    break
                case partFields.TireAspectRatio:
                    if (part.extraData?.TireAspectRatio)
                        fillField(field, part.extraData.TireAspectRatio, String(part.extraData.TireAspectRatio))
                    break
                case partFields.RimWidth:
                    if (part.extraData?.RimWidth)
                        fillField(field, part.extraData.RimWidth, String(part.extraData.RimWidth))
                    break
                case partFields.RimOffset:
                    if (part.extraData?.RimOffset)
                        fillField(field, part.extraData.RimOffset, String(part.extraData.RimOffset))
                    break
                case partFields.RimBolts:
                    if (part.extraData?.RimBolts)
                        fillField(field, part.extraData.RimBolts, String(part.extraData.RimBolts))
                    break
                case partFields.RimBoltsDiameter:
                    if (part.extraData?.RimBoltsDiameter)
                        fillField(field, part.extraData.RimBoltsDiameter, String(part.extraData.RimBoltsDiameter))
                    break
                case partFields.RimDIA:
                    if (part.extraData?.RimDIA) fillField(field, part.extraData.RimDIA, String(part.extraData.RimDIA))
                    break
                case partFields.OilBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.SAE:
                    if (part.extraData?.SAE) fillField(field, part.extraData.SAE, part.extraData.SAE)
                    break
                case partFields.Volume:
                    if (part.extraData?.Volume) fillField(field, part.extraData.Volume, part.extraData.Volume)
                    break
                case partFields.ACEA:
                    if (part.extraData?.ACEA) fillField(field, part.extraData.ACEA, part.extraData.ACEA)
                    break
                case partFields.API:
                    if (part.extraData?.API) fillField(field, part.extraData.API, part.extraData.API)
                    break
                case partFields.OEMOil:
                    if (part.extraData?.OEMOil) fillField(field, part.extraData.OEMOil, part.extraData.OEMOil)
                    break
                case partFields.Color:
                    if (part.extraData?.Color) fillField(field, part.extraData.Color, part.extraData.Color)
                    break
                case partFields.ASTM:
                    if (part.extraData?.ASTM) fillField(field, part.extraData.ASTM, part.extraData.ASTM)
                    break
                case partFields.DOT:
                    if (part.extraData?.DOT) fillField(field, part.extraData.DOT, part.extraData.DOT)
                    break
                case partFields.conditionId:
                    if (part.conditionId)
                        fillField(
                            field,
                            part.conditionId,
                            conditions.find(condition => condition.id === part.conditionId)?.title ||
                                String(part.conditionId),
                        )
                    break
                case partFields.PartBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.ResidualTread:
                    if (part.extraData?.ResidualTread)
                        fillField(field, part.extraData.ResidualTread, String(part.extraData.ResidualTread))
                    break
                case partFields.partNumber:
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.partNumber,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.renderValue]: part.partNumber,
                                [partSpecialPropsFields.filterId]: part.id,
                            },
                        },
                    })
                    break
                case partFields.description:
                    if (part.description) fillField(field, part.description, part.description)
                    break
                case partFields.partPricePurchase:
                    if (part.extraData?.pricePurchase)
                        fillField(field, part.extraData.pricePurchase, String(part.extraData.pricePurchase))
                    break
                case partFields.partPrice:
                    if (part.price) fillField(field, part.price, String(part.price))
                    break
                case partFields.warehouseTitle:
                    if (part.extraData?.warehouse?.id)
                        fillField(
                            field,
                            part.extraData.warehouse.id,
                            warehouses.find(w => w.value === part.extraData?.warehouse?.id)?.name || '',
                        )
                    break
                case partFields.warehouseRow:
                    if (part.extraData?.warehouse?.row)
                        fillField(field, part.extraData.warehouse.row, part.extraData.warehouse.row)
                    break
                case partFields.warehouseShelf:
                    if (part.extraData?.warehouse?.shelf)
                        fillField(field, part.extraData.warehouse.shelf, part.extraData.warehouse.shelf)
                    break
                case partFields.warehousePlace:
                    if (part.extraData?.warehouse?.place)
                        fillField(field, part.extraData.warehouse.place, part.extraData.warehouse.place)
                    break
                case partFields.vendorCode:
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.vendorCode,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.placeholder]: part.vendorCode,
                                [partSpecialPropsFields.printerOnClick]: printPart,
                            },
                        },
                    })
                    break
                case partFields.marketplacesDest:
                    if (part.extraData?.marketplacesDest) {
                        const marketplacesDest = getField(field.field)
                        marketplacesDest.value.map(
                            (marketplace: { field: string; value: boolean }) => (marketplace.value = false),
                        )
                        marketplacesDest.specialProps.checkboxItems.map(
                            (marketplace: { field: string; value: boolean }) => (marketplace.value = false),
                        )

                        part.extraData.marketplacesDest.forEach(element => {
                            const el = marketplacesDest.value.find((item: partInput) => item.field === element)
                            if (el) {
                                el.value = true
                            }
                            const el2 = marketplacesDest.specialProps.checkboxItems.find(
                                (item: partInput) => item.field === element,
                            )
                            if (el2) {
                                el2.value = true
                            }
                        })
                    }
                    break
                case partFields.height:
                    if (part.extraData?.height) fillField(field, part.extraData.height, String(part.extraData.height))
                    break
                case partFields.width:
                    if (part.extraData?.width) fillField(field, part.extraData.width, String(part.extraData.width))
                    break
                case partFields.length:
                    if (part.extraData?.length) fillField(field, part.extraData.length, String(part.extraData.length))
                    break
                case partFields.weight:
                    if (part.extraData?.weight) fillField(field, part.extraData.weight, String(part.extraData.weight))
                    break
                case partFields.VideoUrl:
                    if (part.extraData?.VideoUrl) fillField(field, part.extraData.VideoUrl, part.extraData.VideoUrl)
                    break
                case partFields.partId:
                    fillField(field, part.id, part.id)
                    break
            }
        })

        if (part.extraData?.additionalPartNumbers?.length) {
            const field = getField(partFields.partNumber)
            part.extraData.additionalPartNumbers.map(partNumber => {
                dispatch({
                    type: partCreateUpdateActionTypes.addField,
                    value: {
                        field: {
                            fieldType: field.fieldType,
                            valueType: field.valueType,
                            value: '',
                            title: 'Номер запчасти',
                            specialProps: {
                                placeholder: 'введите OEM номер',
                                isAdded: true,
                                addedField: partFields.partNumber,
                                maxAddedFields: field.specialProps?.maxAddedFields,
                                renderValue: partNumber,
                                fieldParent: partFields.partNumber,
                                extraHandle: partFields.partNumber,
                            },
                        },
                        after: field.field,
                    },
                })
            })
        }
    }

    const getApiObjectFromState = async (initialObject: ApiPartCreateUpdate, isDraft?: boolean) => {
        setIsLoading(true)
        let apiObject = initialObject
        let isObjectReady = true
        const switchPosition = getField(partFields.useExistingCar).value

        getVisibleFields().map((item: partInput) => {
            if (item.field == partFields.IncomingInvoice) {
                if (typeof item.value === 'string') {
                    incomingInvoiceId = item.value
                    return
                }
            } else if (switchPosition === 0 && !isDraft) {
                if (
                    item.field === partFields.typeId &&
                    !partsTypeIdNotRequireCar.some(part => part.value === item.value) &&
                    !state.fields.find((field: partInput) => field.field === partFields.partNumber).value
                ) {
                    dispatch({
                        type: partCreateUpdateActionTypes.updateSpecialProps,
                        value: {
                            field: item.field,
                            specialProps: {
                                ...item.specialProps,
                                [partSpecialPropsFields.errorMessage]:
                                    'для этой запчасти надо указать автомобиль или OEM номер',
                            },
                        },
                    })
                    isObjectReady = false
                }
            }

            if (item.value && !item?.specialProps?.errorMessage && !item?.specialProps?.extraHandle) {
                if (item.field === partFields.partPrice) {
                    apiObject = { ...apiObject, price: Number(item.value) }
                } else if (item.value && item.field === partFields.partPricePurchase) {
                    apiObject = {
                        ...apiObject,
                        extraData: {
                            ...apiObject.extraData,
                            pricePurchase: Number(item.value),
                        },
                    }
                } else if (item.value && item.field === partFields.VideoUrl) {
                    apiObject = {
                        ...apiObject,
                        extraData: {
                            ...apiObject.extraData,
                            VideoUrl: String(item.value),
                        },
                    }
                } else if (
                    (item.value && item.field === partFields.height) ||
                    (item.value && item.field === partFields.width) ||
                    (item.value && item.field === partFields.length) ||
                    (item.value && item.field === partFields.weight)
                ) {
                    if (Number(item.value)) {
                        apiObject = {
                            ...apiObject,
                            extraData: {
                                ...apiObject.extraData,
                                [item.field]: Math.round(Number(item.value) * 10) / 10,
                            },
                        }
                    }
                } else {
                    apiObject = { ...apiObject, [item.field]: item.value }
                }
            } else if (item.value && item?.specialProps?.extraHandle === 'marketplacesDest') {
                let partApiObject = { ...apiObject } as ApiPartCreateUpdate

                partApiObject = {
                    ...partApiObject,
                    extraData: {
                        ...partApiObject.extraData,
                        // @ts-ignore
                        marketplacesDest: item.value.filter(({ value }) => Boolean(value)).map(({ field }) => field),
                    },
                }

                apiObject = { ...partApiObject }
            } else if (
                item.value &&
                item?.specialProps?.extraHandle &&
                ['wheels', 'oils'].includes(item?.specialProps?.extraHandle)
            ) {
                let partApiObject = { ...apiObject } as ApiPartCreateUpdate
                const itemField = ['PartBrand', 'OilBrand'].includes(item.field) ? 'Brand' : item.field

                partApiObject = {
                    ...partApiObject,
                    extraData: {
                        ...partApiObject.extraData,
                        [itemField]: item.value,
                    },
                }

                apiObject = { ...partApiObject }
            } else if (item?.specialProps?.renderValue && item?.specialProps?.addedField === 'partNumber') {
                let partApiObject = { ...apiObject } as ApiPartCreateUpdate
                let additionalPartNumbers = partApiObject.extraData?.additionalPartNumbers
                if (additionalPartNumbers) {
                    additionalPartNumbers.push(item?.specialProps?.renderValue)
                } else {
                    additionalPartNumbers = [item?.specialProps?.renderValue]
                }

                partApiObject = {
                    ...partApiObject,
                    extraData: {
                        ...partApiObject.extraData,
                        additionalPartNumbers: additionalPartNumbers,
                    },
                }
                apiObject = { ...partApiObject }
            } else if (item?.specialProps?.isRequired && !item.value && !isDraft) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: item.field,
                        specialProps: {
                            ...item.specialProps,
                            [partSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению',
                        },
                    },
                })
                isObjectReady = false
            } else if (item?.specialProps?.errorMessage && !isDraft) {
                isObjectReady = false
            }
        })

        if (userId && isObjectReady) {
            isObjectReady = false
            const partApiObject = { ...apiObject } as ApiPartCreateUpdate

            if (switchPosition === 0) {
                apiObject = { ...partApiObject }
                isObjectReady = true
            } else if (switchPosition === 1) {
                const car = await getCarById(partApiObject.carId, userId)
                const selectedCar = { ...car } as Car

                if (
                    selectedCar.year &&
                    (selectedCar.markId || selectedCar.markId === 0) &&
                    (selectedCar.modificationId || selectedCar.modificationId === 0) &&
                    (selectedCar.bodyTypeId || selectedCar.bodyTypeId === 0) &&
                    (selectedCar.modelId || selectedCar.modelId === 0)
                ) {
                    apiObject = {
                        ...partApiObject,
                        id: part.id,
                        year: selectedCar.year,
                        markId: selectedCar.markId,
                        modelId: selectedCar.modelId,
                        bodyTypeId: selectedCar.bodyTypeId,
                    }

                    isObjectReady = true
                }
            } else if (switchPosition === 2) {
                apiObject = { ...partApiObject }
                isObjectReady = true
            } else {
                isObjectReady = false
            }
        }

        if (isObjectReady) {
            apiObject.id = part.id
            // Иначе эти поля исчезают
            apiObject.extraData = {
                ...apiObject.extraData,
                qty: part.extraData?.qty,
                soldQtyArr: part.extraData?.soldQtyArr,
            }

            return apiObject
        } else {
            setIsSubmitDisabled(true)
        }
        setIsLoading(false)
    }

    const getCurrentCarInfo = () => {
        const useExistingCar = getField(partFields.useExistingCar).value
        const carId = getField(partFields.carId).value
        const markId = getField(partFields.markId).value
        const modelId = getField(partFields.modelId).value
        const modificationId = getField(partFields.modificationId).value
        const year = getField(partFields.year).value

        // Если неполные данные у машины
        if (useExistingCar === 2 && !year) {
            return undefined
        }

        switch (useExistingCar) {
            case 1:
                return carId
            case 2:
                return { markId, modelId, modificationId, year }
            default:
                return undefined
        }
    }

    // Функция для проверки, существует ли уже применимость с такими же параметрами
    function isDuplicateApplicability(
        newApplicability: ApplicabilityCreation,
        existingApplicabilities: (ApplicabilityCreation | Applicability)[],
    ) {
        return existingApplicabilities.some(
            existingApplicability =>
                existingApplicability.markId === newApplicability.markId &&
                existingApplicability.modelId === newApplicability.modelId &&
                existingApplicability.modificationId === newApplicability.modificationId &&
                existingApplicability.bodyTypeId === newApplicability.bodyTypeId &&
                existingApplicability.year === newApplicability.year,
        )
    }

    // Добавление применимости
    const addApplicability = (applicability?: Applicability) => {
        const applicabilityField = getField(partFields.applicability)
        const applicabilitySelectedField = getField(partFields.applicabilitySelected)
        // Больше 3 применимостей нельзя
        if (
            applicabilitySelectedField.specialProps?.applicabilityItems &&
            applicabilitySelectedField.specialProps.applicabilityItems.length < 3
        ) {
            const newApplicabilities: (ApplicabilityCreation | Applicability)[] = [
                ...applicabilitySelectedField.specialProps.applicabilityItems,
            ]

            let isObjectReady = true
            if (!applicability) {
                if (applicabilityField.value) {
                    state.fields.map((item: partInput) => {
                        if (
                            item.specialProps?.isRequired &&
                            item.specialProps?.addedField === partFields.applicability &&
                            !item.value
                        ) {
                            dispatch({
                                type: partCreateUpdateActionTypes.updateSpecialProps,
                                value: {
                                    field: item.field,
                                    specialProps: {
                                        ...item.specialProps,
                                        [partSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению',
                                    },
                                },
                            })
                            isObjectReady = false
                        }
                    })

                    if (userId && isObjectReady) {
                        const applicabilityMark = getField(carFields.applicabilityMarkId)
                        const applicabilityModel = getField(carFields.applicabilityModelId)
                        const applicabilityModification = getField(carFields.applicabilityModificationId)
                        const applicabilityBodyType = getField(carFields.applicabilityBodyTypeId)
                        const applicabilityYear = getField(carFields.applicabilityYear)

                        const newApplicability = {
                            title: `${applicabilityMark.specialProps.renderValue} ${applicabilityModel.specialProps.renderValue} ${applicabilityBodyType.specialProps.renderValue}`,
                            markId: applicabilityMark.value,
                            modelId: applicabilityModel.value,
                            modificationId: applicabilityModification.value,
                            bodyTypeId: applicabilityBodyType.value,
                            year: applicabilityYear.value,
                        }

                        if (!isDuplicateApplicability(newApplicability, newApplicabilities)) {
                            newApplicabilities.push(newApplicability)
                        }
                    }
                } else {
                    isObjectReady = false
                }
            } else {
                newApplicabilities.push(applicability)
                dispatch({
                    type: partCreateUpdateActionTypes.addApplicabilityFromSuggestion,
                    value: applicability,
                })
            }

            if (isObjectReady) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateField,
                    value: {
                        field: applicabilitySelectedField.field,
                        value: true,
                        specialProps: {
                            ...applicabilitySelectedField.specialProps,
                            [partSpecialPropsFields.applicabilityItems]: newApplicabilities,
                        },
                    },
                })
            }
        }
    }

    const confirmChange = async (isDraft?: boolean) => {
        const newPart = await getApiObjectFromState(initialApiPartObject, isDraft)
        const deleteImages = imagesToDelete.map(image => dispatchRedux(deletePartImage(part.id, image)))

        if (newPart) {
            setIsLoading(true)
            const images = getField(partFields.partImages).value
            const imageCount = images.length
            // save part
            const partCreated = dispatchRedux(savePartInCloud(newPart as ApiPartCreateUpdate, imageCount > 0, isDraft))

            // save warehouse
            const wId = getField(partFields.warehouseTitle).value
            const wRow = getField(partFields.warehouseRow).value
            const wShelf = getField(partFields.warehouseShelf).value
            const wPlace = getField(partFields.warehousePlace).value
            let setWarehouse: Promise<void> | null = null

            Promise.all([partCreated, [...deleteImages]]).then(async () => {
                if (wId) {
                    setWarehouse = dispatchRedux(
                        setPartWarehousePosition({
                            userId: part.userId,
                            partId: part.id,
                            warehouseId: wId,
                            row: wRow,
                            shelf: wShelf,
                            place: wPlace,
                        }),
                    )
                } else if (wRow || wShelf || wPlace) {
                    const newExtraData = {
                        warehouse: {
                            row: wRow,
                            shelf: wShelf,
                            place: wPlace,
                        },
                    }
                    setWarehouse = dispatchRedux(updatePartExtraDataValue(part.id, newExtraData))
                }

                const promises: (Promise<void> | null)[] = [setWarehouse]
                images.map((image: File, index: number) => {
                    promises.push(
                        dispatchRedux(
                            saveImageInCloud(
                                image,
                                index + part.images.length - imagesToDelete.length,
                                newPart.id,
                                'part',
                                index === imageCount - 1,
                            ),
                        ),
                    )
                })

                if (incomingInvoiceId && incomingInvoiceId !== (document && document.id)) {
                    promises.push(dispatchRedux(addPartsToInvoice({ partsId: [part.id], id: incomingInvoiceId })))
                } else if (incomingInvoiceId === '' && document) {
                    promises.push(
                        dispatchRedux(removePartsIdFromIncomingInvoice({ partsId: [part.id], id: document.id })),
                    )
                }

                if (!isDeepEqualSimple(part.images, imagesToReorder)) {
                    promises.push(
                        dispatchRedux(
                            reorderPartImages(
                                part.id,
                                imagesToReorder.filter(image => !imagesToDelete.includes(image.id)),
                            ),
                        ),
                    )
                }

                let isApplicabilityChanged = false
                const applicabilityField = getField(partFields.applicabilitySelected)
                const applicabilityIds: string[] = []
                // Сохранение применимостей
                if (applicabilityField) {
                    const applicability = applicabilityField.specialProps.applicabilityItems as (
                        | Applicability
                        | ApplicabilityCreation
                    )[]
                    applicability.map(item => 'id' in item && applicabilityIds.push(item.id))
                    const partNumbers: string[] = []
                    if (part.partNumber) {
                        partNumbers.push(newPart.partNumber)
                    }
                    if (newPart.extraData?.additionalPartNumbers && newPart.extraData.additionalPartNumbers.length) {
                        partNumbers.push(...newPart.extraData.additionalPartNumbers)
                    }
                    applicability.map(item => {
                        if ('id' in item) {
                            promises.push(
                                dispatchRedux(
                                    updateApplicabilityById(
                                        item.id,
                                        [...item.partId, part.id],
                                        [...item.typeId, newPart.typeId],
                                        [...item.partNumber, ...partNumbers],
                                    ),
                                ),
                            )
                            isApplicabilityChanged = true
                        } else {
                            promises.push(dispatchRedux(setApplicability(item, newPart.typeId, partNumbers, part.id)))
                            isApplicabilityChanged = true
                        }
                    })

                    // Сохранить привязку к машине в виде отдельной применимости
                    if (newPart.carId) {
                        const car = await getCarById(newPart.carId, part.userId)
                        const selectedCar = { ...car } as Car
                        const applicability: ApplicabilityCreation = {
                            title: `${selectedCar.markTitle} ${selectedCar.modelTitle} ${selectedCar.bodyTypeTitle}`,
                            markId: selectedCar.markId,
                            modelId: selectedCar.modelId,
                            modificationId: selectedCar.modificationId,
                            bodyTypeId: selectedCar.bodyTypeId,
                            year: selectedCar.year,
                        }
                        const newApplicabilities = getField(partFields.applicabilitySelected).specialProps
                            .applicabilityItems
                        if (!isDuplicateApplicability(applicability, newApplicabilities)) {
                            promises.push(dispatchRedux(setApplicability(applicability, newPart.typeId, partNumbers)))
                        }
                    } else if (newPart.markId && newPart.modelId && newPart.modificationId && newPart.year) {
                        const markRenderValue = getField(carFields.markId)?.specialProps?.renderValue
                        const modelRenderValue = getField(carFields.modelId)?.specialProps?.renderValue
                        const bodyTypeRenderValue = getBodyTypeRenderValue(newPart.modificationId)

                        const applicability: ApplicabilityCreation = {
                            title: `${markRenderValue} ${modelRenderValue} ${bodyTypeRenderValue}`,
                            markId: newPart.markId,
                            modelId: newPart.modelId,
                            modificationId: newPart.modificationId,
                            bodyTypeId: getBodyTypeId(newPart.modificationId),
                            year: newPart.year,
                        }
                        const newApplicabilities = getField(partFields.applicabilitySelected).specialProps
                            .applicabilityItems
                        if (!isDuplicateApplicability(applicability, newApplicabilities)) {
                            promises.push(dispatchRedux(setApplicability(applicability, newPart.typeId, partNumbers)))
                        }
                    }
                }

                // Удаление применимостей
                if (applicabilitySelected.length) {
                    applicabilitySelected.map(item => {
                        if (!applicabilityIds.includes(item.id) && 'id' in item) {
                            const partId = item.partId.filter(partId => partId !== part.id)
                            promises.push(
                                dispatchRedux(updateApplicabilityById(item.id, partId, item.typeId, item.partNumber)),
                            )
                            isApplicabilityChanged = true
                        }
                    })
                }

                Promise.all(promises)
                    .then(() => {
                        if (isApplicabilityChanged) {
                            promises.push(dispatchRedux(getApplicabilityByPartId(part.id)))
                        }
                    })
                    .then(async () => {
                        const newPart = await DatahubApiService.getPartByPartId(part.id)
                        if (newPart) setImagesToReorder(newPart.images)
                        // Чтобы обновился список
                        confirmChanges()
                        getField(partFields.partImages).value = []
                        setIsLoading(false)
                        closeModal()
                    })
            })
        }
    }

    const loadBodyTypes = async () => {
        try {
            const res = await axios.get('https://api.stock-pro.net/catalog/data/body_types.json')
            if (res.data) {
                setBodyTypes(res.data)
            }
        } catch (e) {
            console.error('loadBodyTypes error', e)
        }
    }

    useEffect(() => {
        if (!isLoading) {
            getIsSubmitDisabled()
            dispatch({
                type: partCreateUpdateActionTypes.initializeState,
                value: { state: { fields: setIsDisabledFields() } },
            })
        }
    }, [
        isLoading,
        getField(partFields.useExistingCar).value,
        getField(partFields.customTitle).value,
        getField(partFields.partNumber).value,
        getField(partFields.applicability).value,
        state.fields.length,
        ...state.fields
            .filter((field: partInput) => field.specialProps?.isRequired)
            .map((field: partInput) => field.value),
    ])

    // Первоначальная загрузка данных
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            try {
                // Отправка всех запросов параллельно
                const requests = [
                    dispatchRedux(getDocumentsSelectSearch(DocumentType.IncomingInvoice)),
                    dispatchRedux(getIncomingInvoicesByPartsId([part.id], true)),
                    dispatchRedux(getMarketplacesSettings(MarketplacesKeys.Avito)),
                    getPartDescTemplatesByPartId(part.userId, part.id).then(templateButtons =>
                        setTemplateButtons(templateButtons as templateButton[]),
                    ),
                    dispatchRedux(getApplicabilityByPartId(part.id)),
                    dispatchRedux(getAllPartTypes(part.title)),
                    loadBodyTypes().catch(),
                ]

                if (part.markId) {
                    requests.push(dispatchRedux(getAllCarMarks(part.markTitle)))
                }
                if (part.modelId) {
                    requests.push(dispatchRedux(getAllCarModels(part.modelTitle, String(part.markId))))
                }
                if (part.modificationId) {
                    requests.push(
                        dispatchRedux(getAllCarModifications(part.modificationTitle, String(part.modelId))).then(() => {
                            if (part.year) {
                                dispatchRedux(getModificationYears(String(part.year), String(part.modificationId)))
                            }
                        }),
                    )
                }
                if (part.carId) {
                    requests.push(
                        dispatchRedux(
                            getCarsSelectSearchCarTitleBodyTypeVINcode(
                                `${part.markTitle} ${part.modelTitle} ${part.year}`,
                            ),
                        ),
                    )
                }
                if (part.extraData?.Brand) {
                    requests.push(
                        dispatchRedux(getAllTiresBrands(part.extraData.Brand)),
                        dispatchRedux(getAllPartBrands(part.extraData.Brand)),
                        dispatchRedux(getAllOilsBrands(part.extraData.Brand, String(part.typeId))),
                    )
                }
                if (part.extraData?.Volume) {
                    requests.push(dispatchRedux(getAllOilsVolumes(part.extraData.Volume, String(part.typeId))))
                }
                if (part.extraData?.ACEA) {
                    requests.push(dispatchRedux(getAllOilsACEA(part.extraData.ACEA, String(part.typeId))))
                }
                if (part.extraData?.API) {
                    requests.push(dispatchRedux(getAllOilsAPI(part.extraData.API, String(part.typeId))))
                }
                if (part.extraData?.OEMOil) {
                    requests.push(dispatchRedux(getAllOilsOEMOil(part.extraData.OEMOil, String(part.typeId))))
                }
                if (part.extraData?.Color) {
                    requests.push(dispatchRedux(getAllCoolantColors(part.extraData.Color, String(part.typeId))))
                }
                if (part.extraData?.ASTM) {
                    requests.push(dispatchRedux(getAllOilsASTM(part.extraData.ASTM, String(part.typeId))))
                }
                if (part.extraData?.DOT) {
                    requests.push(dispatchRedux(getAllBrakeDOT(part.extraData.DOT, String(part.typeId))))
                }

                // Выполнение всех запросов
                await Promise.all(requests)

                fillInitialState()
            } finally {
                setIsLoading(false)
            }
        }

        getPriceChartRes()

        fetchData()
    }, [])

    useEffect(() => {
        if (state.fields.find((field: partInput) => field.field === partFields.partNumber).value) {
            const typeIdField = state.fields.find((field: partInput) => field.field === partFields.typeId)
            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field: typeIdField.field,
                    specialProps: {
                        ...typeIdField.specialProps,
                        [partSpecialPropsFields.errorMessage]: '',
                    },
                },
            })
            setIsSubmitDisabled(false)
        }
    }, [state.fields.find((field: partInput) => field.field === partFields.partNumber).value])

    useEffect(() => {
        setImagesToReorder([...part.images])
    }, [part])

    const settings = useSelector(state => state.sales.marketplaces.settings)
    const noIsRequired = 'Должны быть заполнены все обязательные поля.'
    const noDesc = 'Отсутствует описание.'
    const noOEM = 'Отсутствует OEM номер.'
    const noPrice = 'Отсутствует цена.'
    const noPhoto = 'Отсутствуют фотографии.'
    const priceFrom = `Цена должна быть выше ${settings.priceFrom}.`

    useEffect(() => {
        const marketplacesDest = getField(partFields.marketplacesDest)
        const avito = marketplacesDest.specialProps.checkboxItems.find(
            (item: partInput) => item.field === MarketplacesKeys.Avito,
        )
        if (avito) {
            let overlayText = ''
            const descriptionField = state.fields.find((field: partInput) => field.field === partFields.description)
            const OEMField = state.fields.find((field: partInput) => field.field === partFields.partNumber)
            const priceField = state.fields.find((field: partInput) => field.field === partFields.partPrice)
            const partPhotos = part.images
            const visibleFieldsWithIsRequired = getVisibleFields()
                .filter((field: partInput) => field.specialProps?.isRequired)
                .map((field: partInput) => field.value)

            if (
                visibleFieldsWithIsRequired.some(
                    (item: string | number | undefined) => item === undefined || item === '' || item === 0,
                )
            ) {
                overlayText += `${noIsRequired} `
            }
            if (descriptionField && !descriptionField.value) {
                overlayText += `${noDesc} `
            }
            if (OEMField && !OEMField.value) {
                overlayText += `${noOEM} `
            }
            if (settings.withPrice) {
                if (priceField && !priceField.value) {
                    overlayText += `${noPrice} `
                }
            }
            if (settings.withPhoto) {
                if (partPhotos && partPhotos.length === 0) {
                    overlayText += `${noPhoto} `
                }
            }
            if (settings.priceFrom) {
                if (priceField && priceField.value < settings.priceFrom) {
                    overlayText += `${priceFrom} `
                }
            }

            if (marketplacesDest.specialProps.avitoOverlayText !== overlayText) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: marketplacesDest.field,
                        specialProps: {
                            ...marketplacesDest.specialProps,
                            [partSpecialPropsFields.avitoOverlayText]: overlayText,
                        },
                    },
                })
            }
        }
    }, [
        ...state.fields.filter(
            (field: partInput) =>
                field.field === partFields.description ||
                field.field === partFields.partNumber ||
                field.field === partFields.partPrice,
        ),
        ...state.fields
            .filter((field: partInput) => field.specialProps?.isRequired)
            .map((field: partInput) => field.value),
        isLoading,
        part.images,
    ])

    // Обновить поле с применимостями
    useEffect(() => {
        if (applicabilitySelected.length) {
            const applicabilityField = getField(partFields.applicabilitySelected)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: applicabilityField.field,
                    value: true,
                    specialProps: {
                        ...applicabilityField.specialProps,
                        [partSpecialPropsFields.applicabilityItems]: applicabilitySelected,
                    },
                },
            })
        }
    }, [applicabilitySelected])

    // Обновить поле с возможными применимостями
    useEffect(() => {
        const applicabilityField = getField(partFields.applicabilitySuggestion)
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field: applicabilityField.field,
                value: applicabilitySuggestion.length ? true : false,
                specialProps: {
                    ...applicabilityField.specialProps,
                    [partSpecialPropsFields.applicabilitySuggestionItems]: applicabilitySuggestion,
                    [partSpecialPropsFields.addApplicability]: (applicability: Applicability) =>
                        addApplicability(applicability),
                },
            },
        })
    }, [applicabilitySuggestion, getField(partFields.applicabilitySelected).specialProps.applicabilityItems])

    // Добавление применимости
    useEffect(() => {
        const applicabilityField = getField(partFields.applicability)
        const applicabilityButtonsField = getField(partFields.applicabilityButtons)
        const applicabilitySelectedItems = getField(partFields.applicabilitySelected).specialProps.applicabilityItems

        // Дополнительный текст у поля 'Применимость'
        let text = ''
        if (!applicabilityField.value) {
            text = `(${applicabilitySelectedItems.length})`
        } else {
            text = ''
        }

        dispatch({
            type: partCreateUpdateActionTypes.updateSpecialProps,
            value: {
                field: applicabilityField.field,
                specialProps: {
                    ...applicabilityField.specialProps,
                    [partSpecialPropsFields.additionalTitleText]: text,
                    [partSpecialPropsFields.onClickAddButton]:
                        applicabilityField.value && !applicabilityButtonsField.value
                            ? () => {
                                  addApplicability()
                                  dispatch({
                                      type: partCreateUpdateActionTypes.updateField,
                                      value: {
                                          field: partFields.applicabilityButtons,
                                          value: true,
                                      },
                                  })
                              }
                            : undefined,
                },
            },
        })

        dispatch({
            type: partCreateUpdateActionTypes.updateSpecialProps,
            value: {
                field: applicabilityButtonsField.field,
                specialProps: {
                    ...applicabilityButtonsField.specialProps,
                    [partSpecialPropsFields.addApplicability]: () => addApplicability(),
                },
            },
        })

        if (!applicabilityField.value) {
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: applicabilityButtonsField.field,
                    value: false,
                },
            })
        }
        getIsSubmitDisabled()
    }, [
        getField(partFields.applicability).value,
        getField(partFields.applicabilityButtons).value,
        getField(carFields.applicabilityMarkId),
        getField(carFields.applicabilityModelId),
        getField(carFields.applicabilityModificationId),
        getField(carFields.applicabilityBodyTypeId),
        getField(carFields.applicabilityYear),
        getField(partFields.applicabilitySelected).specialProps.applicabilityItems,
    ])

    // Получаем применимости, которые могут подойти
    useEffect(() => {
        const typeId = getField(partFields.typeId).value
        const partNumber = getField(partFields.partNumber).value
        const currentCar = getCurrentCarInfo()
        // Добавил задержку, чтобы при изменении OEM не отправлялось много запросов
        const sendRequest = () => {
            if (typeId || partNumber) {
                dispatchRedux(getApplicabilitySuggestion(typeId, partNumber ? [partNumber] : [], currentCar, part.id))
            }
        }
        const timeoutId = setTimeout(sendRequest, 500)
        return () => clearTimeout(timeoutId)
    }, [
        getField(partFields.typeId).value,
        getField(partFields.partNumber).value,
        getField(partFields.useExistingCar).value,
        getField(partFields.carId).value,
        getField(partFields.year).value,
    ])

    // Кнопки под описанием
    useEffect(() => {
        if (!isLoading) {
            const descField = getField(partFields.description)
            const partDescriptionTemplate = getPartDescriptionTemplate()
            const applicabilityDescription = getApplicabilityDescriptionTemplate()

            const buttons = [
                {
                    title: 'Добавить шаблон описания',
                    value: partDescriptionTemplate,
                },
                ...templateButtons,
            ]
            if (applicabilityDescription) {
                buttons.push({
                    title: 'Применимость',
                    value: applicabilityDescription,
                })
            }
            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field: descField.field,
                    specialProps: {
                        ...descField.specialProps,
                        [partSpecialPropsFields.templateButtons]: buttons,
                    },
                },
            })
        }
    }, [
        isLoading,
        templateButtons,
        getField(partFields.useExistingCar).value,
        getField(partFields.typeId).value,
        getField(partFields.carId).value,
        getField(partFields.partNumber).value,
        getField(partFields.applicabilitySelected).specialProps.applicabilityItems.length,
        getField(carFields.markId).value,
        getField(carFields.modelId).value,
        getField(carFields.year).value,
        getField(partFields.conditionId).value,
    ])

    // Получаем partType по typeId
    useEffect(() => {
        const typeId = getField(partFields.typeId).value
        if (typeId) dispatchRedux(getPartTypeByTypeId(typeId))
    }, [getField(partFields.typeId).value])

    const fillFieldPlaceholder = (partField: partFields, value: number) => {
        const field = getField(partField)
        dispatch({
            type: partCreateUpdateActionTypes.updateSpecialProps,
            value: {
                field: field.field,
                value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.placeholder]: value,
                    [partSpecialPropsFields.onClick]: () =>
                        dispatch({
                            type: partCreateUpdateActionTypes.fillDimensionsAndWeight,
                        }),
                },
            },
        })
    }

    // Заполнение placeholder у размеров, веса и расположения
    useEffect(() => {
        if (partType) {
            if (partType.height) {
                fillFieldPlaceholder(partFields.height, partType.height)
            }
            if (partType.width) {
                fillFieldPlaceholder(partFields.width, partType.width)
            }
            if (partType.length) {
                fillFieldPlaceholder(partFields.length, partType.length)
            }
            if (partType.weight) {
                fillFieldPlaceholder(partFields.weight, partType.weight)
            }
            const positionField = getField(partFields.position)
            const position = getFrontRearLeftRight(partType)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: positionField.field,
                    specialProps: {
                        ...positionField.specialProps,
                        [partSpecialPropsFields.renderValue]: position,
                    },
                },
            })
        }
    }, [partType])

    // Ссылка на поиск запчасти на Drom
    const [dromHref, setDromHref] = useState<string>('')

    useEffect(() => {
        const switchPosition = getField(partFields.useExistingCar).value
        const car = getField(partFields.carId).specialProps.renderValue
        const mark = getField(partFields.markId).specialProps.renderValue
        const model = getField(partFields.modelId).specialProps.renderValue
        const type = getField(partFields.typeId).specialProps.renderValue

        const dromCar = car.substring(0, car.lastIndexOf(' '))

        if (switchPosition === 1) {
            setDromHref(`https://baza.drom.ru/sell_spare_parts/model/${dromCar}/?query=${type}`)
        } else if (switchPosition === 2) {
            setDromHref(`https://baza.drom.ru/sell_spare_parts/model/${mark}+${model}/?query=${type}`)
        } else {
            setDromHref(`https://baza.drom.ru/sell_spare_parts/?query=${type}`)
        }
    }, [
        getField(partFields.useExistingCar).value,
        getField(partFields.carId).value,
        getField(partFields.markId).value,
        getField(partFields.modelId).value,
        getField(partFields.typeId).value,
    ])

    useEffect(() => {
        const priceField = getField(partFields.partPrice)
        dispatch({
            type: partCreateUpdateActionTypes.updateSpecialProps,
            value: {
                field: priceField.field,
                specialProps: {
                    ...priceField.specialProps,
                    [partSpecialPropsFields.additionalHref]: dromHref,
                },
            },
        })
    }, [dromHref])

    return (
        <>
            <Modal show={isOpen} onHide={onCloseModal} size="xl" fullscreen={true}>
                {(isLoading || isPartLoading) && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={styles.contentWrap}>
                        <a href="#" onClick={onCloseModal}>
                            Назад к списку запчастей
                        </a>
                        <SalesText
                            text={`${title} ${part.title}`}
                            fontSize={fontSizes.xxl}
                            fontWeight={fontWeights.bold}
                            subText={part.carId || part.markId ? part.carPartTitle : ''}
                            subHref={
                                part.carId
                                    ? getAdminNavigationPath(adminRouteAlias.parts.location) + `?carId=${part.carId}`
                                    : ''
                            }
                        />
                        {isDraft && (
                            <SalesButton>
                                <SalesText text={'Черновик'} color={colors.white} backgroundColor={colors.grayLight} />
                            </SalesButton>
                        )}
                        <div className={'d-flex'}>
                            <SalesCard
                                widthInPixels={getVisibleColumn(2).length === 0 ? 570 : 1140}
                                minHeightInPixels={600}
                                marginTopPixels={24}
                                overflowY={'visible'}
                                footer={true}
                                footerContent={
                                    <>
                                        {isDraft && (
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => confirmChange(false)}
                                                disabled={isSubmitDisabled}
                                            >
                                                Сохранить как запчасть
                                            </Button>
                                        )}
                                        <Button
                                            variant="primary"
                                            onClick={() => confirmChange(isDraft)}
                                            disabled={!isDraft && isSubmitDisabled}
                                            style={{ marginLeft: '40px' }}
                                        >
                                            Сохранить изменения
                                        </Button>
                                    </>
                                }
                            >
                                <div className={styles.cardContentWrap}>
                                    <div className={styles.cardColumnWrap}>
                                        <PartCreateUpdateTable dispatch={dispatch} fields={getVisibleColumn(1)} />
                                    </div>
                                    {getVisibleColumn(2).length === 0 ? null : (
                                        <div className={styles.cardColumnWrap}>
                                            <PartCreateUpdateTable
                                                dispatch={dispatch}
                                                fields={getVisibleColumn(2)}
                                                top={
                                                    priceChartResponse && priceChartResponse.rcmd_price !== 0 ? (
                                                        <div className={styles.modalEditPart__priceChart}>
                                                            <Form.Label
                                                                className={`${styles.modalEditPart__formLabel}`}
                                                            >
                                                                Аналитика рынка по похожим запчастям
                                                            </Form.Label>
                                                            <PriceChart bars={priceChartResponse.res} />
                                                        </div>
                                                    ) : undefined
                                                }
                                                modalImageGroup={
                                                    imagesToReorder.length ? (
                                                        <td className={styles.modalImgGroup}>
                                                            <ModalImageGroup
                                                                images={imagesToReorder}
                                                                setImages={setImagesToReorder}
                                                                markImg={markImg}
                                                                unmarkImg={unmarkImg}
                                                                showMainImg={false}
                                                            />
                                                        </td>
                                                    ) : undefined
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </SalesCard>
                            <YandexShare
                                className={styles.shareIcons}
                                content={{
                                    title: getField(partFields.customTitle).value,
                                    url: `https://partsauto.market/parts/${part.id}`,
                                }}
                                theme={{
                                    services: 'whatsapp,telegram,vkontakte,viber',
                                    size: 'l',
                                    colorScheme: 'whiteblack',
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalEditPart
