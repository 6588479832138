import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import anchor from '../../../constants/anchor'
import { RangeInput } from '../../../types/common/commonTypes'
import { MarketplacesKeys } from '../../../types/part/partTypes'

const getSummaryByUserId = (dateRange?: RangeInput, marketplace?: MarketplacesKeys) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    const params = {
        userId:  userData.smsAuth.userId,
        dateRange,
        marketplace,
    }
    const config = {
        headers: {
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
        }
    }
    try {
        const { data: { status, data } } = await apiInstance.post('/sales/stats/getSummaryByUserId', params, config)
        if(status === 'ok') {
            dispatch({
                type: storeDispatchTypes.setUserSummary,
                value: {
                    userSummary: data
                },
            })
        }
    } catch (e) {
        console.error('get user summary failed', e)
    }

}


export default getSummaryByUserId
