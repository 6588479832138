import React, { useEffect, useReducer, useState } from 'react'
import axios from "axios"
import { v4 as uuidV4, v5 as uuidV5 } from 'uuid'
import { useDispatch } from "react-redux"
import styles from './styles.module.scss'
import { initialCarState } from "./initialCarState"
import { carReducer } from "./carReducer"
import { initialApiCarObject } from "./initialApiCarObject"
import { colors, fontSizes, fontWeights } from "../../constants/salesStyles"
import SalesText from "../../components/_atoms/SalesText/SalesText"
import SalesCard from '../../components/_molecules/SalesCard/SalesCard'
import {
    ApiCarCreateUpdate,
    carCreateUpdateActionTypes,
    carFields,
    carInput,
    carSpecialPropsFields,
} from "../../types/car/carTypes"
import SalesButtonStandard from "../../components/_molecules/SalesButtonStandard/SalesButtonStandard"
import { CatalogModification } from "../../types/common/commonTypes"
import { useSelector } from "../../modules/store/customSelector"
import PartCreateUpdateTable from '../../components/_molecules/PartCreateUpdateTable/PartCreateUpdateTable'
import Loader from '../../components/_atoms/Loader/Loader'
import saveCarInCloud from "../../modules/redux/carList/saveCarInCloud"
import saveImageInCloud from '../../modules/redux/createUpdateForm/saveImageInCloud'

const NewCar = () => {
    const title = 'Добавление авто'
    const dispatchRedux = useDispatch()
    const [state, dispatch] = useReducer(carReducer, initialCarState)
    const [bodyTypes, setBodyTypes] = useState({} as Record<string, string>)
    const [isDisabledAddCar, setIsDisabledAddCar] = useState(false)
    const filteredModifications = useSelector(state => state.carList.filteredModifications)
    const isCarCreated = useSelector(state => state.carList.loaders.isCarCreated)
    const isCarSaving = useSelector(state => state.carList.loaders.isCarSaving)

    const getField = (fieldToSearch: carFields) => state.fields.find((field: carInput) => field.field === fieldToSearch)

    const getBodyTypeId = (modificationId: number) => Number(filteredModifications.find((modificationObject: CatalogModification) => Number(modificationObject.id) === modificationId)?.bodyTypeId)
    const getBodyTypeRenderValue = (modificationId: number) => bodyTypes[String(getBodyTypeId(modificationId))] || ''

    const setIsDisabledFields = () => state.fields.map((field: carInput) => {

        if (field.field === carFields.bodyTypeId) {
            const modificationId = getField(carFields.modificationId).value

            return {
                ...field,
                value: getBodyTypeId(modificationId),
                specialProps: {
                    ...field.specialProps,
                    [carSpecialPropsFields.renderValue]: getBodyTypeRenderValue(modificationId),
                }
            }
        }

        const selectSearchText = field.specialProps?.renderValue
        const errorMessage = field.value ? '' : field.specialProps?.errorMessage

        if (field.specialProps?.fieldParent) {

            const fieldParentValue = getField(field.specialProps.fieldParent).value
            const fieldParentValueInChild = field.specialProps?.[carSpecialPropsFields.fieldParentValue]
            const isEnabled = fieldParentValue
            const isParentChanged = fieldParentValueInChild && fieldParentValue !== fieldParentValueInChild

            return {
                ...field,
                value: isParentChanged ? '' : field.value,
                specialProps: {
                    ...field.specialProps,
                    [carSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                    [carSpecialPropsFields.isDisabled]: !isEnabled,
                    [carSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                    [carSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                }
            }
        } else {
            return field
        }
    })

    const getVisibleColumn = (column: number) => state.fields.filter((field: carInput) => field.specialProps?.column === column)

    const getIsSubmitDisabled = () => {
        let isDisabled = false
        state.fields.forEach((fied: carInput) => {
            if (fied?.specialProps?.errorMessage) {
                isDisabled = true
            }
        })
        setIsDisabledAddCar(isDisabled)
    }

    const getApiObjectFromState = async (initialObject: ApiCarCreateUpdate, apiObjectType: 'car') => {
        let apiObject = initialObject
        let isObjectReady = true

        state.fields.map((item: carInput) => {
            if (item.value && !item?.specialProps?.errorMessage && !item?.specialProps?.extraHandle) {
                if (item.field === carFields.carPrice) {
                    apiObject = { ...apiObject, price: Number(item.value) }
                } else {
                    apiObject = { ...apiObject, [item.field]: item.value }
                }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'defects') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        // @ts-ignore
                        defects: item.value.filter(({ value }) => Boolean(value)).map(({ field }) => field)
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'steeringType') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        steeringType: item.value === 0 ? 'left' : 'right'
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'regionId') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        regionId: Number(item.value)
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'colorCode') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        colorCode: String(item.value)
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'equipment') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        equipment: String(item.value)
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'conditionId') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        conditionId: Number(item.value)
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item.value && item?.specialProps?.extraHandle === 'customTitle') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        customTitle: String(item.value)
                    }
                }
                apiObject = { ...carApiObject }
            }
            else if (item?.specialProps?.isRequired && (!item.value)) {
                dispatch({
                    type: carCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: item.field,
                        specialProps: {
                            ...item.specialProps,
                            [carSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению'
                        }
                    }
                })
                isObjectReady = false
            } 
            else if (item?.specialProps?.errorMessage) {
                isObjectReady = false
            }
        })

        if (apiObjectType === 'car' && isObjectReady) {
            const carApiObject = { ...apiObject } as ApiCarCreateUpdate
            const carId = uuidV5(
                JSON.stringify({
                    markId: carApiObject.markId,
                    modelId: carApiObject.modelId,
                    modificationId: carApiObject.modificationId,
                }),
                uuidV4(),
            )
            apiObject = { ...carApiObject, id: carId }
        }


        if (isObjectReady) {
            return apiObject
        } else {
            if (apiObjectType === 'car') {
                setIsDisabledAddCar(true)
            }
        }
    }

    const saveCar = async () => {
        const newCar = await getApiObjectFromState(initialApiCarObject, 'car')

        if (newCar) {
            const images = getField(carFields.carImages).value
            const imageCount = images.length
            const carCreated = dispatchRedux(saveCarInCloud(newCar as ApiCarCreateUpdate, imageCount > 0))

            Promise.all([carCreated]).then(() => {
                images.map((image: File, index: number) => {
                    dispatchRedux(saveImageInCloud(image, index, newCar.id, 'car', index === imageCount - 1))
                })
            })
        }
    }

    const loadBodyTypes = async () => {
        try {
            const res = await axios.get('https://api.stock-pro.net/catalog/data/body_types.json')
            if (res.data) {
                setBodyTypes(res.data)
            }
        } catch (e) {
            console.error('loadBodyTypes error', e)
        }
    }

    useEffect(() => {
        loadBodyTypes().catch()
        dispatch({
            type: carCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } }
        })
    }, [])

    useEffect(() => {
        getIsSubmitDisabled()
        dispatch({
            type: carCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } }
        })
    }, [
        state.fields[0].value,
        state.fields[1].value,
        state.fields[2].value,
        state.fields[3].value,
        state.fields[4].value,
        state.fields[5].value,
        state.fields[6].value,
        state.fields[7].value,
        state.fields[8].value,
        state.fields[9].value,
        state.fields[10].value,
        state.fields[11].value,
        state.fields[12].value,
        state.fields[13].value,
    ])

    useEffect(() => {
        if (isCarCreated) {
            dispatch({ type: carCreateUpdateActionTypes.initializeState, value: { state: initialCarState } })
        }
    }, [isCarCreated])

    return (
        <div className={styles.wrap}>
            <div className={styles.contentWrap}>
                <SalesText
                    text={title}
                    fontSize={fontSizes.xxl}
                    fontWeight={fontWeights.bold}
                    letterSpacing={-0.02}
                />
                <SalesCard
                    widthInPixels={getVisibleColumn(2).length === 0 ? 570 : 1140}
                    minHeightInPixels={600}
                    marginTopPixels={24}
                    overflowY={'visible'}
                    footer={true}
                    footerContent={
                        <SalesButtonStandard
                            text={'Далее'}
                            backgroundColor={isDisabledAddCar ? colors.grayBorderApp : colors.white}
                            onClick={saveCar}
                            disabled={isDisabledAddCar}
                            hoverText={isDisabledAddCar ? 'форма заполнена с ошибками' : ''}
                        />
                    }
                >
                    {isCarSaving
                        ? <Loader diameterInPx={120} />
                        : <div className={styles.cardContentWrap}>
                            <div className={styles.cardColumnWrap}>
                                <PartCreateUpdateTable
                                    dispatch={dispatch}
                                    fields={getVisibleColumn(1)}
                                />

                            </div>
                            {getVisibleColumn(2).length === 0
                                ? null
                                : <div className={styles.cardColumnWrap}>
                                    <PartCreateUpdateTable
                                        dispatch={dispatch}
                                        fields={getVisibleColumn(2)}
                                    />
                                </div>
                            }
                        </div>
                    }
                </SalesCard>
            </div>
        </div>
    )
}

export default NewCar
