import React from 'react'
import styles from "./styles.module.scss"
import CellButtonsParts from "../../_atoms/CellButtonsParts/CellButtonsParts"
import OverlayWithText from "../../_atoms/OverlayWithText/OverlayWithText"
import { Part } from "../../../types/part/partTypes"

interface IProps {
    part: Part
    isSafari: boolean
    deleteOnly?: boolean
    isSoldPartList?: boolean
    savedSuccessfully?: boolean
}

const CellActionsParts = ({ part, isSafari, deleteOnly = false, isSoldPartList = false, savedSuccessfully }: IProps) => {
    const overlayHeightInPx = () => {
        const baseHeight = 28
        const perLineHeight = 18
        if (isSafari) {
            const displayedTitle = part.customTitle ? part.customTitle : part.title
            const additionalLinesCount = Math.ceil(displayedTitle.length / 27)
            return baseHeight + (perLineHeight * additionalLinesCount)
        } else {
            return 0
        }
    }

    const getOverlay = () => {
        if (!isSoldPartList && part.isSold) {
            return (
                <OverlayWithText
                    text='ПРОДАНО'
                    backgroundBootstrapColor='bg-success'
                    textBootstrapColor='text-light'
                    heightInPx={overlayHeightInPx()}
                />
            )
        } else if (isSoldPartList && (!part.isSold && !part.extraData?.soldQtyArr)) {
            return (
                <OverlayWithText
                    text='Возвращено'
                    backgroundBootstrapColor='bg-success'
                    textBootstrapColor='text-light'
                    heightInPx={overlayHeightInPx()}
                />
            )
        } else if (part.isDeleted) {
            return (
                <OverlayWithText
                    text='УДАЛЕНО'
                    backgroundBootstrapColor='bg-danger'
                    textBootstrapColor='text-light'
                    heightInPx={overlayHeightInPx()}
                />
            )
        } else if (part.isArchived) {
            return (
                <OverlayWithText
                    text='Архивировано'
                    backgroundBootstrapColor='bg-black'
                    textBootstrapColor='text-light'
                    heightInPx={overlayHeightInPx()}
                />
            )
        } else if (savedSuccessfully === true) {
            return (
                <OverlayWithText
                    text='Изменения сохранены'
                    backgroundBootstrapColor='bg-success'
                    textBootstrapColor='text-light'
                    heightInPx={overlayHeightInPx()}
                />
            )
        } else if (savedSuccessfully === false) {
            return (
                <OverlayWithText
                    text='Ошибка сохранения данных'
                    backgroundBootstrapColor='bg-danger'
                    textBootstrapColor='text-light'
                    heightInPx={overlayHeightInPx()}
                />
            )
        }
        return null
    }

    return (
        <div className={styles.wrap}>
            {getOverlay() || (
                <CellButtonsParts
                    deleteOnly={deleteOnly}
                    partId={part.id}
                    isSoldPartList={isSoldPartList}
                />
            )}
        </div>
    )
}

export default CellActionsParts
