import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { KeyboardEventLocal } from "../../types/common/commonTypes"

interface IProps {
    widthInPixels: number
    leftInPixels?: number
    rightInPixels?: number
    step: number
    setIsEditable: (value: boolean) => void
    tableHead: string
    setTableHead: (value: string) => void
    fromVal: string
    setFromVal: (value: string) => void
    toVal: string
    setToVal: (value: string) => void
}

const RangeInput = ({
    widthInPixels,
    leftInPixels,
    step,
    setIsEditable,
    fromVal,
    setFromVal,
    toVal,
    setToVal,
    rightInPixels,
}: IProps) => {
    const divRef = useRef<HTMLDivElement | null>(null)
    const [fromError, setFromError] = useState(false)
    const [toError, setToError] = useState(false)

    const validateRangeInput = (from: string, to: string) => {
        const fromNumeric = Number(from)
        const toNumeric = Number(to)
        switch (true) {
        case (fromNumeric < 0):
            setFromError(true)
            break
        case (toNumeric < 0):
            setToError(true)
            break
        case (toNumeric < fromNumeric && to !== ''):
            setToError(true)
            break
        default:
            setFromError(false)
            setToError(false)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (divRef.current
                && !divRef.current.contains(event.target as Node)
                && !toError
                && !fromError
            ) {
                setIsEditable(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [divRef, toVal, fromVal])


    const keyHandler = (event: KeyboardEventLocal) => {
        switch (true) {
        case event.code === 'Enter' ||
        event.code === 'NumpadEnter' ||
        event.code === 'Escape'
        && !toError
        && !fromError:
            setIsEditable(false)
            break
        default:
            return
        }
    }

    return (
        <div
            className={styles.wrap}
            ref={divRef}
            style={{
                width: widthInPixels + 'px',
                left: leftInPixels + 'px',
                right: rightInPixels + 'px',
            }}
        >
            <div className={styles.inputs} onKeyDown={(event) => keyHandler(event)}>
                <div className="input-group">
                    <input
                        type="number"
                        step={step}
                        className={`${fromError ? 'border-danger' : ''} shadow-none form-control`}
                        placeholder="от"
                        aria-label="from"
                        value={fromVal}
                        onChange={e => {
                            validateRangeInput(e.target.value, toVal)
                            setFromVal(e.target.value)
                        }}
                        min={0}
                        autoFocus={true}
                    />
                </div>
                <div className="input-group">
                    <input
                        type="number"
                        step={step}
                        className={`${toError ? 'border-danger' : ''} shadow-none form-control`}
                        placeholder="до"
                        aria-label="to"
                        value={toVal}
                        onChange={e => {
                            validateRangeInput(fromVal, e.target.value)
                            setToVal(e.target.value)
                        }}
                        min={0}
                    />
                </div>
            </div>
        </div>
    )
}

export default RangeInput
