import React from 'react'
import searchCarListMarks from '../../../modules/redux/carList/SearchCarListMarks'
import { useSelector } from "../../../modules/store/customSelector"
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import TableHeadSelectSearch from "../../_atoms/TableHeadSelectSearch/TableHeadSelectSearch"


const TableHeadMarkSearchCars = () => {
    const marks = useSelector(state => state.carList.marks)
    const marksIdFilter = useSelector((state) => state.carList.filters.markId)

    return (
        <TableHeadSelectSearch
            slice={'carList'}
            tableHead={'Марка'}
            placeholder={'Выберите марку'}
            actionType={storeDispatchTypes.setMarkFilterCars}
            options={marks}
            filterId={marksIdFilter}
            searchOptions={searchCarListMarks}
        />
    )
}

export default TableHeadMarkSearchCars

