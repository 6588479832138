import { Part } from '../part/partTypes'

export type VendorExtraData = {
    startingNumber?: number
    vendorName?: string
    vendorITN?: number
    vendorAddress?: string
    vendorTelephone?: string
}

export type VendorData = {
    use_id: string
    extra_data: VendorExtraData
    createdAt?: number
    updatedAt?: number
}

export type DocumentExtraData = {
    number?: string
    buyerName?: string
    buyerAddress?: string
    buyerTelephone?: string
    comment?: string
    providerName?: string
    providerAddress?: string
    providerTelephone?: string
    parts: Part[]
    amount: number
}

export enum DocumentType {
    Invoice = 'invoice',
    Receipt = 'receipt',
    IncomingInvoice = 'incomingInvoice',
}
export type FileType = 'xlsx' | 'pdf'

export type Document = {
    id: string
    user_id: string
    type: DocumentType[]
    title: string
    extra_data: DocumentExtraData
    isDeleted?: boolean
    createdAt?: number
    updatedAt?: number
}

export type IncomingInvoiceExtraData = {
    number?: string
    providerName?: string
    providerAddress?: string
    providerTelephone?: string
    comment?: string
    amount?: number
}

export type IncomingInvoice = {
    id: string
    userId: string
    partsId: string[]
    title: string
    extraData: IncomingInvoiceExtraData
    isDeleted: boolean
    createdAt: number
    updatedAt: number
}

export type IncomingInvoiceCreation = {
    partsId: string[]
    title: string
    extraData: IncomingInvoiceExtraData
}
