import React from 'react'
import { Placeholder } from "react-bootstrap"
import { getArrayFromNumber } from '../../../utils/converters'

interface IProps {
    rowCount: number;
    columnCount: number;
    marginVerticalRem: number
}

const ListSkeleton = ({ rowCount, columnCount, marginVerticalRem }:IProps) => {
    const rows = getArrayFromNumber(rowCount)
    const columns = getArrayFromNumber(columnCount)

    return (
        <tbody>
            {rows.map((row, index) => (
                <tr key={'skeleton-row-' + index}>
                    {columns.map((column, index) => (
                        <td
                            scope="row"
                            key={'skeleton-column-' + index}
                        >
                            <Placeholder as={'p'} xs={12} size="lg" animation="wave"  style={{ margin: `${marginVerticalRem}rem 0` }}>
                                <Placeholder xs={11} size="lg"/>
                            </Placeholder>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    )
}

export default  ListSkeleton
