import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"

const loadMarketplacesList = () => async (
    dispatch: Dispatch,
    _: () => RootState,
): Promise<void> => {
    try {
        const { data, status } = await apiInstance.post('/sales/marketplaces/getMarkeplacesDict')

        if (status !== 200) {
            throw new Error('load marketplaces list error')
        }

        const list = Object.entries(data.data).map((item: any) => ({
            id: item[0],
            title: item[1].title,
            icon: item[1].icon,
        }))

        dispatch({
            type: storeDispatchTypes.setMarketplacesList,
            value: {
                list,
            },
        })
    } catch (e) {
        console.error('load marketplaces list error', e)
    }
}

export default loadMarketplacesList
