import React, { ReactElement, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from "@popperjs/core/lib/enums"
import stylesLocal from './styles.module.scss'
import useHoverHandler from '../../../hooks/sales/useHoverHandler'
import SalesText from '../SalesText/SalesText'
import { colors, fontSizes } from "../../../constants/salesStyles"

interface IProps {
    tooltipText?: string
    children: ReactElement
    placement?: Placement
    maxWidthInPixels?: number
    backgroundColorRGBA?: [number, number, number, number]
    offset?: [number, number]
    textColor?: colors
    preventWrapFullWidth?: boolean
}


const HoverOverlay = ({
    tooltipText,
    children,
    placement,
    maxWidthInPixels,
    backgroundColorRGBA,
    offset,
    textColor,
    preventWrapFullWidth
}: IProps) => {
    const [isHover, hoverOn, hoverOff] = useHoverHandler()
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    const openTimeoutId: { current: ReturnType<typeof setTimeout> | null } = useRef(null)
    const closeTimeoutId: { current: ReturnType<typeof setTimeout> | null } = useRef(null)

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            {
                name: "offset",
                options: {
                    offset: offset ? offset : [0, 5]
                }
            },
        ],
        placement: placement ? placement : "top"
    })

    const show = () => {

        if (closeTimeoutId.current) {
            clearTimeout(closeTimeoutId.current)
            closeTimeoutId.current = null

        }
        openTimeoutId.current = setTimeout(hoverOn, 600)
    }

    const hide = () => {
        if (openTimeoutId.current) {
            clearTimeout(openTimeoutId.current)
            openTimeoutId.current = null

        }
        closeTimeoutId.current = setTimeout(hoverOff, 400)

    }

    return (
        <>
            <div
                className={stylesLocal.wrap}
                style={{ flex: preventWrapFullWidth ? undefined : 1 }}
                ref={setReferenceElement}
                onMouseEnter={show}
                onMouseLeave={hide}
            >
                {children}
            </div>

            {!isHover || !tooltipText
                ? null
                : <div
                    ref={setPopperElement}
                    style={
                        {
                            ...styles.popper,
                            backgroundColor: backgroundColorRGBA
                                ? `rgba(${backgroundColorRGBA[0]}, ${backgroundColorRGBA[1]}, ${backgroundColorRGBA[2]}, ${backgroundColorRGBA[3]})`
                                : 'rgba(45,45,45,0.75)',
                            width: 'auto',
                            maxWidth: maxWidthInPixels ? maxWidthInPixels + 'px' : '200px',
                            padding: "8px 16px",
                            borderRadius: "8px",
                            zIndex: 1
                        }
                    }
                    {...attributes.popper}
                    id="tooltip"
                    role="tooltip"
                >
                    <SalesText
                        text={tooltipText}
                        fontSize={fontSizes.xxs}
                        color={textColor ? textColor : colors.white}
                    />

                    <div ref={setArrowElement} style={styles.arrow}/>
                </div>
            }

        </>
    )
}

export default HoverOverlay
