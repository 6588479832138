import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllTiresModels =
    (searchTerm: string, parentValue?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'tiresModels', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/TyresMake')
            const tiresModels: SelectOptionLocal[] = []

            if (status === 'ok' && parentValue) {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const makeNodes = xmlDoc.getElementsByTagName('make')

                for (let i = 0; i < makeNodes.length; i++) {
                    const makeName = makeNodes[i].getAttribute('name')

                    if (makeName === parentValue) {
                        const modelNodes = makeNodes[i].getElementsByTagName('model')

                        for (let j = 0; j < modelNodes.length; j++) {
                            const modelName = modelNodes[j].getAttribute('name')

                            if (modelName && modelName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                                tiresModels.push({ name: modelName, value: modelName })
                            }
                        }
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllTiresModels, value: { tiresModels } })
        } catch (e) {
            console.error('load tires models failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'tiresModels', value: false } })
    }

export default getAllTiresModels
